import React from "react";
import * as Styled from "./styles";
import Button from "@components/common/Button";
import { useClickOutside } from "@hook/useClickOutside";

interface IButtonPopover {
  popoverContent: React.ReactNode;
  title: any;
  disabled?: boolean;
}

const ButtonPopover: React.FC<IButtonPopover> = ({
  popoverContent,
  title,
  disabled,
}) => {
  const wrapperRef = React.useRef(null);
  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);

  useClickOutside(wrapperRef, () => {
    setIsPopoverVisible(false);
  });

  const togglePopover = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  return (
    <div ref={wrapperRef}>
      <Button title={title} onClick={togglePopover} disabled={disabled} />
      <Styled.PopoverWrapper isVisible={isPopoverVisible}>
        {popoverContent}
      </Styled.PopoverWrapper>
    </div>
  );
};

export default ButtonPopover;
