import { theme } from "src/styles/theme";
import styled from "styled-components";

export const ContainerName = styled.div`
  display: flex;

  > a {
    font-size: 13px;
    width: 100%;
    color: ${theme.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerUf = styled.div`
  display: flex;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;
