import InputRadioRequirements from "@components/Login/Inputs/InputRadioRequirements";
import { HiOutlineLockClosed } from "react-icons/hi";
import * as Styled from "../styles";
import InputDigits from "@components/Login/Inputs/InputDigits";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import { requirementsRadio } from "@helpers/securePassword";

interface ICreatePasswordComponent {
  newPassword: string;
  setNewPassword: any;
  validateInput: any;
  errorRadio: any;
  loading: boolean;
  handleEnter: () => void;
}

const CreatePasswordComponent = ({
  newPassword,
  setNewPassword,
  validateInput,
  errorRadio,
  loading,
  handleEnter,
}: ICreatePasswordComponent) => {
  return (
    <Styled.BodyCurrentLogin>
      <Styled.ContainerCreatePasswordTitle>
        <HiOutlineLockClosed />
        <span>Criar uma nova senha</span>
      </Styled.ContainerCreatePasswordTitle>

      <Styled.ContainerCreatePasswordSubTitle>
        <span>
          Para finalizar, escolha uma senha de acesso, conforme as seguintes
          regras:
        </span>
      </Styled.ContainerCreatePasswordSubTitle>

      <Styled.ContainerCreatePasswordBody>
        {requirementsRadio(validateInput).map((requirement) => (
          <div key={requirement.title}>
            <InputRadioRequirements
              title={requirement.title}
              value={requirement.value}
              checked={requirement.checked}
              error={requirement.error}
            />
          </div>
        ))}
      </Styled.ContainerCreatePasswordBody>

      <Styled.ContainerCode>
        <Styled.ContainerInputCode>
          <InputDigits
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value.replace(/\s/g, ""));
            }}
            message="Campo obrigatório"
            error={!!newPassword && errorRadio}
          />
        </Styled.ContainerInputCode>
      </Styled.ContainerCode>

      <Styled.ContainerButtonCreatePassword>
        <Button
          title={!loading ? "CONFIRMAR" : <Spinner />}
          onClick={() => handleEnter()}
          disabled={loading || !!errorRadio || !newPassword}
        />
      </Styled.ContainerButtonCreatePassword>
    </Styled.BodyCurrentLogin>
  );
};

export default CreatePasswordComponent;
