import { theme } from "src/styles/theme";
import styled from "styled-components";

interface ITooltip {
  isRight?: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
`;
export const TooltipArea = styled.div`
  position: absolute;
  top: calc(100% - 35px);
  left: ${(props: ITooltip) => (props.isRight ? "calc(100% - 165px)" : "0px")};
  transform: translateX(10px);
  background: ${theme.text};
  color: white;
  padding: 4px;
  border-radius: 8px;
  z-index: 1;
  font-size: 12px;
  width: max-content;
  max-width: 200px;
  white-space: normal;
  word-wrap: break-word;
`;
