import styled from "styled-components";
import { theme } from "../../styles/theme";
import { device } from "src/styles/variable";

type Sidebar = {
  open: boolean;
};

export const Area = styled.div<Sidebar>`
  display: flex;
  flex-direction: column;
  position: relative;
  left: ${({ open }) => (!open ? " 80px" : "230px")};
  width: ${({ open }) => (!open ? `calc(100% - 80px)` : `calc(100% - 230px)`)};
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${theme.disable100};
  transition: all 0.2s ease;

  @media ${device.tablet} {
    width: 100%;
    left: 0;
  }
`;
