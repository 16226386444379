import React from "react";
import { LoginContext } from "@context/loginContext";
import Menu from "../Menu";
import { BsChevronRight } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import logoSmall from "../../../assets/logo_small.svg";
import logo from "../../../assets/logo.svg";
import logoDirecional from "../../../assets/LogoDirecional.png";
import logoDirecionalSmall from "../../../assets/LogoDirecionalSmall.png";
import logoRiva from "../../../assets/Rivalogo.png";
import logoRivaSmall from "../../../assets/RivaLogoSmall.png";
import { useAuth } from "src/routes";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@helpers/format";
import { linkSidebar } from "@helpers/linkSidebar";
import * as Styled from "./styles";
import { useToast } from "@chakra-ui/react";
import { UsuallyContext } from "@context/usuallyContex";

type SubItem = { name: string };

const SideBar = () => {
  const toast = useToast();
  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const { setOpenSidebar, openSidebar, signOut, pageSidebar, setPageSidebar } =
    React.useContext(LoginContext);
  const { setHashSalesSidebar, setHashDetailOportunity } =
    React.useContext(UsuallyContext);
  const isAuthenticated = useAuth();
  const navigate = useNavigate();
  const user: any = localStorage.getItem("@user");

  const handleOptionClick = (label: string) => {
    setPageSidebar(label);
    localStorage.setItem("@option", label);
    toast.closeAll();
  };

  const handleClickImg = (e: any) => {
    e.stopPropagation();
    setPageSidebar("Oportunidades");
    navigate("/oportunidades");
    // navigate("/");
    // localStorage.setItem("@option", "Principal");
    localStorage.setItem("@option", "Oportunidades");
    toast.closeAll();
  };

  const handleSignOut = (e: any) => {
    e.stopPropagation();
    signOut();
    toast.closeAll();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setOpenSidebar(!openSidebar);
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  };

  function handleNavigateSubMenu(name: string) {
    if (name.toLowerCase() === "buscar clientes")
      return setHashSalesSidebar("Oportunity");

    if (name.toLowerCase() === "minhas oportunidades")
      return (
        setHashSalesSidebar("MyOportunityComponent"),
        setHashDetailOportunity("DetailOportunity")
      );

    return setHashSalesSidebar("");
  }

  const handleSubItem = (subItem: any) => {
    return subItem?.map((link: SubItem) => (
      <span
        key={link?.name}
        onClick={() => {
          handleNavigateSubMenu(link?.name);
        }}
      >
        {link?.name}
      </span>
    ));
  };

  const renderSidebarOptions = () => {
    return linkSidebar.map(({ icon, label, to, subItem }) => (
      <Styled.ContainerOtions
        ref={sidebarRef}
        isSubItem={pageSidebar === label && !!subItem && openSidebar}
        key={label}
        onClick={(e) => e.stopPropagation()}
      >
        <Styled.Otions
          key={label}
          isOpen={openSidebar}
          isSelected={pageSidebar === label}
          onClick={() => {
            handleOptionClick(label);
            navigate(to);
          }}
        >
          {openSidebar ? (
            <>
              {icon}
              <span>{label}</span>
              <BsChevronRight size="15px" />
            </>
          ) : (
            icon
          )}
        </Styled.Otions>
        {pageSidebar === label && subItem && openSidebar && (
          <Styled.ContainerSubOtions>
            {handleSubItem(subItem)}
          </Styled.ContainerSubOtions>
        )}
      </Styled.ContainerOtions>
    ));
  };

  React.useEffect(() => {
    const storedOption: any = localStorage.getItem("@option");
    setPageSidebar(storedOption);
  }, [setPageSidebar]);

  return (
    <Styled.Container isLogged={isAuthenticated} isOpen={openSidebar}>
      <Menu
        selectedOption={pageSidebar}
        setSelectedOption={setPageSidebar}
        isOpen={openSidebar}
        isSelected={pageSidebar === "Profile"}
        onClickProfile={() => {
          navigate("/myProfile");
          setPageSidebar("Profile");
          localStorage.setItem("@option", "Profile");
        }}
      />

      <Styled.ContainerTotal onClick={(e: any) => handleClickOutside(e)}>
        <Styled.ContainerTop>
          <Styled.ContainerLogo>
            {openSidebar ? (
              <Styled.ContainerBackgroundLogo onClick={handleClickImg}>
                <Styled.ImgLarge alt="logo" src={logo} />
              </Styled.ContainerBackgroundLogo>
            ) : (
              <Styled.ContainerBackgroundLogoSmall onClick={handleClickImg}>
                <Styled.ImgSmall alt="logo" src={logoSmall} />
              </Styled.ContainerBackgroundLogoSmall>
            )}
          </Styled.ContainerLogo>

          {renderSidebarOptions()}
        </Styled.ContainerTop>

        <Styled.Divider />

        <Styled.ContainerConfig>
          <Styled.ContainerOtionsConfig>
            <Styled.OtionsConfig isOpen={openSidebar}>
              {openSidebar ? (
                <>
                  <Styled.ContainerUser>
                    <Styled.TextUser>{capitalize(user)}</Styled.TextUser>
                    <Styled.TextEmail>
                      {localStorage.getItem("@email")}
                    </Styled.TextEmail>
                  </Styled.ContainerUser>
                  <Styled.ContainerLogout>
                    <MdOutlineLogout size="22px" onClick={handleSignOut} />
                  </Styled.ContainerLogout>
                </>
              ) : (
                <Styled.ContainerIconConfigClose
                  isSelected={pageSidebar === "Config"}
                  onClick={handleSignOut}
                >
                  <MdOutlineLogout size="22px" />
                </Styled.ContainerIconConfigClose>
              )}
            </Styled.OtionsConfig>
          </Styled.ContainerOtionsConfig>
        </Styled.ContainerConfig>

        <Styled.ContainerAnyLogos>
          <Styled.ContainerLogo>
            {openSidebar ? (
              <Styled.ContainerBackgroundAnyLogos>
                <Styled.ImgLarge alt="logo" src={logoDirecional} style={{height: "17px"}} />
              </Styled.ContainerBackgroundAnyLogos>
            ) : (
              <Styled.ContainerBackgroundAnyLogosSmall>
                <Styled.ImgSmall alt="logo" src={logoDirecionalSmall} style={{height: "26px"}}/>
              </Styled.ContainerBackgroundAnyLogosSmall>
            )}
          </Styled.ContainerLogo>

          <Styled.ContainerLogo>
            {openSidebar ? (
              <Styled.ContainerBackgroundAnyLogos>
                <Styled.ImgLarge alt="logo" src={logoRiva} style={{height: "28px"}} />
              </Styled.ContainerBackgroundAnyLogos>
            ) : (
              <Styled.ContainerBackgroundAnyLogosSmall>
                <Styled.ImgSmall alt="logo" src={logoRivaSmall} style={{height: "28px"}}/>
              </Styled.ContainerBackgroundAnyLogosSmall>
            )}
          </Styled.ContainerLogo>
        </Styled.ContainerAnyLogos>
      </Styled.ContainerTotal>
    </Styled.Container>
  );
};

export default SideBar;
