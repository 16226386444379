import React from "react";
import * as Styled from "../../styles";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import InputText from "@components/Sales/Inputs/InputText";
import SelectCheckbox from "@components/Sales/Inputs/SelectCheckbox";
import { Checkbox, Text } from "@chakra-ui/react";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import {
  optionBestHours,
  optionBusinessUnity,
  optionCivilStatus,
  optionCommercialRegional,
  optionDetailsLive,
  optionIncome,
  optionMarriageRegime,
  optionNationality,
  optionNumberKids,
  optionOriginAccount,
  optionPurposePurchase,
  optionSex,
  optionTreatment,
  optionTypeWork,
} from "@helpers/optionSelect";
import { documentMask, phoneMask, rgMask } from "@helpers/format";
import { isEmail } from "@helpers/emailCheck";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import { optionCapaign, optionProfession } from "../options";
import { ISectionMainInformations } from "src/@types/Sales";
import InputDate from "@components/Sales/Inputs/InputDate";

export const SectionMainInformations = ({
  formik,
  error,
  setError,
  originAccount,
  setOriginAccount,
  treatment,
  setTreatment,
  regional,
  commercialRegional,
  setCommercialRegional,
  selectedCheckBox,
  setSelectedCheckBox,
  businessUnity,
  setBusinessUnity,
  income,
  setIncome,
  campaign,
  setCampaign,
  selectCampaign,
  setSelectCampaign,
  loadingCampaign,
  bestHours,
  setBestHours,
  purposePurchase,
  setPurposePurchase,
  detailsLive,
  setDetailsLive,
  typeWork,
  setTypeWork,
  numberKids,
  setNumberKids,
  dataCampaign,
  getCampaign,
  setIdCampaign,

  civilStatus,
  setCivilStatus,
  dateBirth,
  setDateBirth,
  dateIssue,
  setDateIssue,
  nationality,
  setNationality,
  profession,
  setProfession,
  selectProfession,
  setSelectProfession,
  getProfession,
  loadingProfession,
  dataProfession,
  setIdProfession,
  isCreateAccount,
  sex,
  setSex,
  weddingDate,
  setWeddingDate,
  marriageRegime,
  setMarriageRegime,
}: ISectionMainInformations) => {
  const nameParts = formik.getFieldProps("nameAccount").value?.split(" ");
  const filteredNameParts = nameParts?.filter(
    (part: string) => part?.trim() !== ""
  );

  const getDateToDay = () => {
    const today = new Date();
    today.setDate(today.getDate());
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleCheckboxChange = (value: string) => {
    if (selectedCheckBox.includes(value)) {
      setSelectedCheckBox(selectedCheckBox.filter((v) => v !== value));
    } else {
      setSelectedCheckBox([...selectedCheckBox, value]);
    }
  };

  const renderedOptions = optionCapaign(
    dataCampaign,
    selectCampaign,
    setSelectCampaign,
    setCampaign,
    setIdCampaign
  );

  const renderedOptionsProfession = optionProfession(
    dataProfession,
    selectProfession,
    setSelectProfession,
    setProfession,
    setIdProfession
  );

  const handleMessageNameAccount = () => {
    if (/\d/.test(formik.values.nameAccount)) {
      return "O nome deve conter apenas letras";
    }

    if (/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(formik.values.nameAccount)) {
      return "O nome deve conter apenas letras";
    }

    if (filteredNameParts?.length < 2) {
      return "Insira nome e sobrenome";
    }

    return undefined;
  };

  return (
    <>
      <Styled.ContainerBoxTitleInfo>
        <span>Informações principais</span>
      </Styled.ContainerBoxTitleInfo>
      <Styled.ContainerBoxInputs>
        {!isCreateAccount && (
          <>
            <InputSelect
              isRequirement
              title="Origem da conta:"
              label={originAccount || "Selecione uma opção"}
              isSelected={originAccount}
              options={optionOriginAccount}
              selectedOption={originAccount}
              onChange={(e) => setOriginAccount(e.currentTarget.value)}
            />
            <InputSelect
              title="Tratamento:"
              label={treatment || "Selecione uma opção"}
              isSelected={treatment}
              options={optionTreatment}
              selectedOption={treatment}
              onChange={(e) => setTreatment(e.currentTarget.value)}
            />
          </>
        )}
        <InputText
          isRequirement
          label="Nome da Conta:"
          placeholder="Nome Completo (Nome e Sobrenome)"
          name="nameAccount"
          value={formik.values.nameAccount}
          onChange={formik.handleChange}
          error={
            (filteredNameParts?.length < 2 &&
              formik.values.nameAccount !== "") ||
            /\d/.test(formik.values.nameAccount) ||
            /[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(formik.values.nameAccount)
          }
          messageError={handleMessageNameAccount()}
        />
        <InputText
          label="Regional"
          name="stateRegistration"
          value={regional}
          readOnly
        />
        <InputSelect
          isRequirement
          title="Regional Comercial:"
          label={commercialRegional || "Selecione uma opção"}
          isSelected={commercialRegional}
          options={optionCommercialRegional}
          selectedOption={commercialRegional}
          onChange={(e) => setCommercialRegional(e.currentTarget.value)}
        />
        <Styled.ContainerBoxInfoCheckbox>
          <InputText
            isTelephone
            isRequirement
            label="Telefone principal:"
            placeholder="(XX) XXXXX-XXXX"
            name="mainPhone"
            value={phoneMask(formik.values.mainPhone)}
            onChange={(e) => {
              formik.handleChange(e);
              setError((prevState: any) => {
                return {
                  ...prevState,
                  telefone: false,
                };
              });
            }}
            maxLength={15}
            error={
              (phoneMask(formik.values.mainPhone)?.length < 15 &&
                phoneMask(formik.values.mainPhone)) ||
              error.telefone
            }
            messageError={
              phoneMask(formik.values.mainPhone)?.length < 15
                ? "Formato inválido"
                : "Telefone já cadastrado"
            }
          />
          <SelectCheckbox value={selectedCheckBox}>
            <Checkbox
              key="Whatsapp"
              value="Whatsapp"
              onChange={() => handleCheckboxChange("Whatsapp")}
              borderRadius={8}
              disabled={selectedCheckBox.includes("WhatsappComplementary")}
            >
              <Text fontSize={12} fontWeight="bold">
                Whatsapp
              </Text>
            </Checkbox>
            <Checkbox
              key="Celular"
              value="Celular"
              onChange={() => handleCheckboxChange("Celular")}
              disabled={selectedCheckBox.includes("CelularComplementary")}
            >
              <Text fontSize={12} fontWeight="bold">
                Celular
              </Text>
            </Checkbox>
          </SelectCheckbox>
        </Styled.ContainerBoxInfoCheckbox>

        <InputText
          isRequirement
          label="E-mail principal:"
          placeholder="exemplo@provedor.com"
          name="mainEmail"
          value={formik.values.mainEmail}
          onChange={(e) => {
            formik.handleChange(e);
            setError((prevState: any) => {
              return {
                ...prevState,
                email: false,
              };
            });
          }}
          error={
            (!isEmail(formik.values.mainEmail) && formik.values.mainEmail) ||
            error.email
          }
          messageError={
            error.email ? "E-mail já cadastrado " : "E-mail inválido"
          }
        />
        <InputSelect
          isRequirement
          title="Unidade de negócio:"
          label={businessUnity || "Selecione uma opção"}
          isSelected={businessUnity}
          options={optionBusinessUnity}
          selectedOption={businessUnity}
          onChange={(e) => setBusinessUnity(e.currentTarget.value)}
        />
        {!isCreateAccount && (
          <>
            <InputSelect
              title="Renda:"
              label={income || "Selecione uma opção"}
              isSelected={income}
              options={optionIncome}
              selectedOption={income}
              onChange={(e) => setIncome(e.currentTarget.value)}
            />

            <Styled.ContainerBoxInfoCheckbox>
              <InputText
                isTelephone
                label="Telefone Complementar:"
                placeholder="(XX) XXXXX-XXXX"
                name="complementaryPhone"
                value={phoneMask(formik.values.complementaryPhone)}
                onChange={formik.handleChange}
                maxLength={15}
                error={
                  phoneMask(formik.values.complementaryPhone)?.length < 15 &&
                  formik.values.complementaryPhone
                }
                messageError={"Formato inválido"}
              />

              <SelectCheckbox value={selectedCheckBox}>
                <Checkbox
                  key="WhatsappComplementary"
                  value="WhatsappComplementary"
                  onChange={() => handleCheckboxChange("WhatsappComplementary")}
                  borderRadius={8}
                  disabled={selectedCheckBox.includes("Whatsapp")}
                >
                  <Text fontSize={12} fontWeight="bold">
                    Whatsapp
                  </Text>
                </Checkbox>
                <Checkbox
                  key="CelularComplementary"
                  value="CelularComplementary"
                  onChange={() => handleCheckboxChange("CelularComplementary")}
                  disabled={selectedCheckBox.includes("Celular")}
                >
                  <Text fontSize={12} fontWeight="bold">
                    Celular
                  </Text>
                </Checkbox>
              </SelectCheckbox>
            </Styled.ContainerBoxInfoCheckbox>

            <InputText
              label="E-mail Complementar:"
              placeholder="exemplo@provedor.com"
              name="complementaryEmail"
              value={formik.values.complementaryEmail}
              onChange={formik.handleChange}
              error={
                !isEmail(formik.values.complementaryEmail) &&
                formik.values.complementaryEmail
              }
              messageError={"E-mail inválido"}
            />
          </>
        )}

        <InputText
          label="CPF:"
          placeholder="XXX.XXX.XXX-XX"
          name="cpf"
          value={formik.values.cpf}
          onChange={(e) => {
            const newValue = documentMask(e.target.value);
            formik.handleChange(e);
            formik.setFieldValue("cpf", newValue);
          }}
          maxLength={14}
        />
        <InputText
          label="RG:"
          placeholder="XX.XXX.XXX"
          name="rg"
          value={formik.values.rg}
          onChange={(e) => {
            const newValue = rgMask(e.target.value);
            formik.handleChange(e);
            formik.setFieldValue("rg", newValue);
          }}
          maxLength={18}
        />
        <InputText
          label="Org. Expedidor:"
          placeholder="Org. Expedidor"
          name="orgaoExpedidorRG"
          value={formik.values.orgaoExpedidorRG}
          onChange={formik.handleChange}
        />
        <InputDate
          type="date"
          max={getDateToDay()}
          name={"dateIssue"}
          placeholder="__/__/___"
          label="Data de Emissão:"
          value={dateIssue}
          onChange={(e) => setDateIssue(e.target.value)}
        />
        <InputDate
          type="date"
          max={getDateToDay()}
          name={"dateBirth"}
          placeholder="__/__/___"
          label="Data de nascimento:"
          value={dateBirth}
          onChange={(e) => setDateBirth(e.target.value)}
        />

        {isCreateAccount && (
          <InputSelect
            title="Sexo:"
            label={sex || "Selecione uma opção"}
            isSelected={sex}
            options={optionSex}
            selectedOption={sex}
            onChange={(e) => setSex(e.currentTarget.value)}
          />
        )}

        <InputSelect
          widht="28%"
          title="Nacionalidade:"
          label={nationality || "Selecione uma opção"}
          isSelected={nationality}
          options={optionNationality}
          selectedOption={nationality}
          onChange={(e) => setNationality(e.currentTarget.value)}
        />
        <InputSelectSearch
          width="31%"
          height="15%"
          title="Profissão:"
          placeholder="Pesquise por profissão"
          value={selectProfession}
          onChangeInput={(e) => setSelectProfession(e.target.value)}
          isSelected={profession}
          onChangeIcon={() => {
            setSelectProfession("");
            setProfession("");
          }}
          onClickSearchIcon={getProfession}
          loading={loadingProfession}
        >
          {renderedOptionsProfession}
        </InputSelectSearch>

        <InputSelect
          widht="28%"
          title="Estado Civil:"
          label={civilStatus || "Selecione uma opção"}
          isSelected={civilStatus}
          options={optionCivilStatus}
          selectedOption={civilStatus}
          onChange={(e) => setCivilStatus(e.currentTarget.value)}
        />

        {civilStatus === "Casado(a)" && (
          <>
            <InputDate
              isRequirement
              type="date"
              max={getDateToDay()}
              name={"weddingDate"}
              placeholder="__/__/___"
              label="Data casamento:"
              value={weddingDate}
              onChange={(e) => setWeddingDate(e.target.value)}
            />

            <InputSelect
              isRequirement
              widht="30%"
              title="Regime casamento:"
              label={marriageRegime || "Selecione uma opção"}
              isSelected={marriageRegime}
              options={optionMarriageRegime}
              selectedOption={marriageRegime}
              onChange={(e) => setMarriageRegime(e.currentTarget.value)}
            />
          </>
        )}

        {!isCreateAccount && (
          <>
            <InputSelectSearch
              width="31%"
              height="15%"
              title="Campanha:"
              placeholder="Pesquise por campanha"
              value={selectCampaign}
              onChangeInput={(e) => setSelectCampaign(e.target.value)}
              isSelected={campaign}
              onChangeIcon={() => {
                setSelectCampaign("");
                setCampaign("");
              }}
              onClickSearchIcon={getCampaign}
              loading={loadingCampaign}
            >
              {renderedOptions}
            </InputSelectSearch>

            <InputSelect
              title="Melhor horário de atendimento:"
              label={bestHours || "Selecione uma opção"}
              isSelected={bestHours}
              options={optionBestHours}
              selectedOption={bestHours}
              onChange={(e) => setBestHours(e.currentTarget.value)}
            />
            <InputSelect
              title="Finalidade da Compra:"
              label={purposePurchase || "Selecione uma opção"}
              isSelected={purposePurchase}
              options={optionPurposePurchase}
              selectedOption={purposePurchase}
              onChange={(e) => setPurposePurchase(e.currentTarget.value)}
            />
            {purposePurchase === "Para morar" && (
              <InputSelect
                title="Detalhes - Para Morar:"
                label={detailsLive || "Selecione uma opção"}
                isSelected={detailsLive}
                options={optionDetailsLive}
                selectedOption={detailsLive}
                onChange={(e) => setDetailsLive(e.currentTarget.value)}
              />
            )}

            <InputSelect
              title="Tipo de trabalho:"
              label={typeWork || "Selecione uma opção"}
              isSelected={typeWork}
              options={optionTypeWork}
              selectedOption={typeWork}
              onChange={(e) => setTypeWork(e.currentTarget.value)}
            />
            <InputSelect
              title="Número de Filhos:"
              label={numberKids || "Selecione uma opção"}
              isSelected={numberKids}
              options={optionNumberKids}
              selectedOption={numberKids}
              onChange={(e) => setNumberKids(e.currentTarget.value)}
            />
          </>
        )}
      </Styled.ContainerBoxInputs>

      {!isCreateAccount && (
        <Styled.ContainerBoxInfoTextBox>
          <InputTextArea
            name="comments"
            label="Observações:"
            placeholder="Observações sobre a conta em questão..."
            value={formik.values.comments}
            onChange={formik.handleChange}
          />
        </Styled.ContainerBoxInfoTextBox>
      )}
    </>
  );
};
