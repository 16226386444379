import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

export const Area = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: ${theme.backgroundSecondary};
  overflow: hidden;

  @media ${device.mobileL} {
    background: none;
  }
`;

export const AreaNeedHelp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media ${device.tablet} {
    height: 88%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 78%;
  height: 97%;
  margin-top: 10px;

  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
  }
  @media ${device.tablet} {
    align-items: center;
    justify-content: center;
    width: 85%;
    min-height: 100vh;
  }
  @media ${device.laptop} {
    min-height: 97%;
    max-height: 85%;
  }
  @media ${device.laptopL} {
    margin-top: 15px;
    min-height: 95vh;
    max-height: 88%;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: center center; 
  }

   @media ${device.tablet} {
    display: none;
     width: 0;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
  }
  @media ${device.tablet} {
    width: 80%;
    height: 95%;
    border-radius: 8px;
  }
`;

export const AreaRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;

  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
  }
`;

export const ContainerRightTab = styled.div`
  display: flex;
  width: 100%;
  height: 14%;
  margin-top: 20px;
  align-self: center;
  justify-content: space-between;

  > img {
    display: flex;
    width: fit-content;
    height: 44px;
    object-fit: contain;

    @media ${device.tablet} {
      height: 42px;
    }
  }

  @media ${device.mobileL} {
    margin-top: 5px;
  }
  @media ${device.laptopL} {
    margin-top: 30px;
  }
`;

export const BodyRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BodyRightTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  font-weight: bold;
  > span {
    color: ${theme.text};
    font-size: 17px;
  }

  @media ${device.laptopL} {
    margin-top: 8px;
  }
`;

export const BodyRightSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
  height: fit-content;

  > span {
    color: ${theme.text};
    font-size: 11px;
    > strong {
      color: black;
    }

    @media ${device.laptopL} {
      font-size: 14px;
    }
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
`;

export const ContainerCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  margin-top: 17px;
`;

export const ContainerRegional = styled.div`
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: center;

  @media ${device.laptopXL} {
    height: 15%;
  }
`;

export const SubContainerRegional = styled.div`
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: center;
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  align-items: center;
  background: ${theme.disable100};
  border-radius: 8px;

  @media ${device.laptopXL} {
    height: 75%;
  }
`;

export const AreaInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  margin-top: 6px;
`;

export const Items = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 15px;
  border-radius: 8px;

  &:hover {
    background-color: #e7edfe;
  }
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
export const LabelRadioButton = styled.label`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p`
  color: ${theme.text};
  font-size: 12px;
  padding-left: 0.5rem;
`;

export const RadioBox = styled.div`
  display: flex;
  width: 19px;
  height: 17px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.disable};
  border-radius: 100%;
  transition: background 0.15s, border-color 0.15s;
  cursor: pointer;

  &::after {
    display: flex;
    content: "";
    width: 10px;
    height: 9px;
    background: ${theme.primary};
    border-radius: 100%;
    cursor: pointer;
    transform: scale(0);
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    &::after {
      transform: scale(1);
    }
  }
`;


export const ContainerButtonHelper = styled.div`
  display: flex;
  width: 80%;
  height: fit-content;
  gap: 10px;
`;


export const ContainerSuccessAccessTitle = styled.div`
  display: flex;
  width: 85%;
  height: fit-content;

  > span {
    display: flex;
    width: 100%;
    font-size: 17px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;

    @media ${device.laptopXL} {
      font-size: 20px;
    }
  }

  > svg {
    width: 25px;
    color: ${theme.primary};
    font-size: 25px;

    @media ${device.laptopXL} {
      width: fit-content;
      font-size: 25px;
    }
  }
`;

export const ContainerSuccessAccessBody = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  > span {
    width: 100%;
    margin-top: 12px;
    font-size: 15px;
    color: ${theme.text};
    align-items: center;

    @media ${device.laptopXL} {
      font-size: 17px;
    }
  }
`;
