import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface ITab {
  name: string;
  select?: string;
}

function bgColor(props: ITab) {
  if (props.name === "Disponível") return theme.available;
  if (props.name === "Mirror") return theme.gold;
  if (props.name === "Reservada") return theme.error;
  if (props.name === "Bloqueada") return theme.text;
  if (props.name === "Vendida") return theme.disable;
  return theme.primary;
}

function bgColorSelect(props: ITab) {
  if (props.select === "Disponível") return theme.available;
  if (props.select === "Mirror") return theme.gold;
  if (props.select === "Reservada") return theme.error;
  if (props.select === "Bloqueada") return theme.text;
  if (props.select === "Vendida") return theme.disable;
  return theme.primary;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const Title = styled.span`
  display: flex;
  font-size: 10px;
`;
export const ContainerTab = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
export const Tab = styled.div<ITab>`
  display: flex;
  width: max-content;
  border-radius: 8px;
  padding: 4px;
  border: 2px solid ${(props) => bgColor(props)};
  color: ${(props) => (props.select === props.name ? "white" : bgColor(props))};
  background: ${(props) =>
    props.select === props.name ? bgColorSelect(props) : "transparent"};
  cursor: pointer;

  > span {
    font-weight: bold;
  }
`;
