import React from "react";
import * as Styled from "./styles";
import useKey from "@hook/useKey";
import { regexNumber, regexRepeat, seqPassword } from "@helpers/securePassword";
import forgotPasswordService from "@services/Login/forgotPasswordService";
import firstAccessService from "@services/Login/firstAccessService";
import { LoginContext } from "@context/loginContext";
import { formatDate, documentMask } from "@helpers/format";
import InputSlider from "@components/common/InputSlider";
import ForgotPasswordComponent from "./components/ForgotPasswordComponent";
import TwoFactorAuthenticationComponent from "./components/TwoFactorAuthenticationComponent";
import CreatePasswordComponent from "./components/CreatePasswordComponent";
import SuccessAccessComponent from "./components/SuccessAccessComponent";
import { IHashMapKeyString } from "src/@types/Login";

const ForgotPassword = () => {
  useKey("Enter", handleEnter);
  useKey("NumpadEnter", handleEnter);
  const { signIn, loadingLogin, setPage, setErrorForgotPassword } =
    React.useContext(LoginContext);
  const [hash, setHash] = React.useState<string>("ForgotPassword");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingResend, setLoadingResend] = React.useState<boolean>(false);
  const [errorRadio, setErrorRadio] = React.useState<boolean>(false);
  const [document, setDocument] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [date, setDate] = React.useState<string>("");
  const [name, setName] = React.useState("");
  const [digits, setDigits] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [countSteps, setCountSteps] = React.useState<string>("");
  const [error, setError] = React.useState({
    confirmCode: false,
    errorDocument: false,
    errorTitle: "",
    errorMessage: "",
  });
  const [validateInput, setValidateInput] = React.useState({
    number: false,
    length: false,
    repeat: false,
    document: false,
    sequecial: false,
    date: false,
  });

  function handleEnter() {
    if (hash === "ForgotPassword") {
      handleSendCode();
    } else if (hash === "TwoFactorAuthentication") {
      handleConfirmCode();
    } else if (hash === "CreatePassword") {
      handleCreatePassword();
    }
  }

  const handleSendCode = async () => {
    setLoading(true);
    try {
      const response = await forgotPasswordService.sendCodeForgotPassword({
        cpf: document,
      });

      if (response.data?.statusCode >= 400) {
        setLoading(false);
        setError((prevState) => {
          return {
            ...prevState,
            errorDocument: true,
            errorTitle: response.titulo
              ? response?.titulo
              : response.data?.titulo,
            errorMessage: response.data?.mensagem,
          };
        });
        return;
      }

      setName(response?.nomeCorretor);
      setDate(formatDate(response.dataNascimento));
      setEmail(response.email);
      setLoading(false);
      setHash("TwoFactorAuthentication");
    } catch (error: any) {
      setLoading(false);
      console.log("errorHandleSendCode", error);
    }
  };

  const handleResendCode = async () => {
    setLoadingResend(true);
    try {
      const response = await forgotPasswordService.resendCode({
        email,
      });

      if (!response) {
        setLoadingResend(false);
        return;
      }

      setLoadingResend(false);
    } catch (error: any) {
      setLoadingResend(false);
      console.log("errorHandleResendCode", error);
    }
  };

  const handleConfirmCode = async () => {
    setLoading(true);
    try {
      const response = await forgotPasswordService.confirmCodeForgotPassword({
        email,
        senhaTemporaria: "Teste@123",
        codigoVerificacao: digits,
      });

      if (response.data?.statusCode >= 400 || response.data?.status >= 400) {
        setLoading(false);
        setError((prevState) => {
          return {
            ...prevState,
            confirmCode: true,
          };
        });
        return;
      }

      setLoading(false);
      setHash("CreatePassword");
    } catch (error: any) {
      setLoading(false);
      console.log("errorConfirmCode", error);
    }
  };

  const handleCreatePassword = async () => {
    setLoading(true);
    try {
      const response = await firstAccessService.createNewPassword({
        email,
        SenhaAntiga: "Teste@123",
        SenhaAtual: newPassword,
      });

      if (response.status >= 400) {
        setLoading(false);
        console.log("retornoHandleCreatePassword", response);
        return;
      }

      setLoading(false);
      setHash("SuccessAccess");
    } catch (error: any) {
      setLoading(false);
      console.log("errorhandleCreatePassword", error);
    }
  };

  const handleLogin = async () => {
    const data = { email, password: newPassword };
    await signIn(data);
  };

  const securePassword = (password: string) => {
    const length = !password || password.length >= 6;
    const DocumentEqualPassword =
      documentMask(document) !== documentMask(password);
    const dateEqueal = date !== password;

    setValidateInput({
      number: !password || regexNumber.test(password),
      length,
      repeat: regexRepeat.test(password),
      document: !password || DocumentEqualPassword,
      sequecial: seqPassword(password),
      date: !password || dateEqueal,
    });
  };

  const CurrentForgotPassowrdHashMap: IHashMapKeyString = {
    ForgotPassword: (
      <ForgotPasswordComponent
        document={document}
        setDocument={setDocument}
        setDigits={setDigits}
        setPage={setPage}
        setErrorForgotPassword={setErrorForgotPassword}
        loading={loading}
        handleEnter={handleEnter}
        error={error}
        setError={setError}
      />
    ),
    TwoFactorAuthentication: (
      <TwoFactorAuthenticationComponent
        email={email}
        digits={digits}
        setDigits={setDigits}
        loading={loading}
        loadingResend={loadingResend}
        handleResendCode={handleResendCode}
        error={error}
        setError={setError}
      />
    ),
    CreatePassword: (
      <CreatePasswordComponent
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        loading={loading}
        validateInput={validateInput}
        handleEnter={handleEnter}
        errorRadio={errorRadio}
      />
    ),
    SuccessAccess: (
      <SuccessAccessComponent
        loadingLogin={loadingLogin}
        name={name}
        handleLogin={handleLogin}
      />
    ),
  };

  React.useEffect(() => {
    const isError =
      !newPassword ||
      !validateInput.number ||
      !validateInput.length ||
      validateInput.sequecial ||
      !validateInput.document ||
      validateInput.repeat ||
      !validateInput.date;

    setErrorRadio(isError);
  }, [validateInput, newPassword]);

  React.useEffect(() => {
    securePassword(newPassword);
  }, [newPassword]);

  React.useEffect(() => {
    if (digits.length === 6) {
      handleEnter();
    } else {
      return;
    }
  }, [digits]);

  React.useEffect(() => {
    function mapHashToCountSteps(hash: string) {
      switch (hash) {
        case "ForgotPassword":
          return "1";
        case "TwoFactorAuthentication":
          return "2";
        case "CreatePassword":
          return "3";
        default:
          return "4";
      }
    }
    const countSteps = mapHashToCountSteps(hash);
    setCountSteps(countSteps);
  }, [hash]);

  return (
    <Styled.ContainerCurrentLogin>
      {CurrentForgotPassowrdHashMap[hash]}

      <InputSlider
        onBack={() => {
          if (hash === "ForgotPassword") {
            setPage("");
          } else {
            setHash("ForgotPassword");
          }
        }}
        value={countSteps}
        countSteps={countSteps}
        max={4}
      />
    </Styled.ContainerCurrentLogin>
  );
};

export default ForgotPassword;
