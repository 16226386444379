import { BiSearch } from "react-icons/bi";
import { theme } from "src/styles/theme";
import styled from "styled-components";

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

export const IconBiSearch = styled(BiSearch)`
  color: ${theme.disable};
  font-size: 22px;
  width: fit-content;
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${theme.text};
  margin-left: 5px;
`;
