import styled from "styled-components";
import { theme } from "../../../../styles/theme";

interface IInput {
  cursor?: string;
  size?: string;
  error?: boolean;
}

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  border: 2px solid
    ${(props: IInput) => (props.error ? `${theme.error}` : `${theme.disable}`)};
  background: white;
  border-radius: 8px;
  align-items: center;
  font-size: 20px;
  cursor: ${(props: IInput) => (props.cursor ? "pointer" : "default")};

  > svg {
    color: ${theme.disable};
    margin-right: 15px;
    font-size: ${(props: IInput) => props.size};
    width: fit-content;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${theme.text};
  margin-left: 5px;
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  height: 38px;
  border: none;
  border-radius: 8px;
  padding: 0 10px;
  outline: none;
  color: ${(props: IInput) =>
    props.error ? `${theme.error}` : `${theme.text}`};
  align-self: center;
  font-size: 14px;
  justify-content: center;

  ::placeholder {
    color: ${theme.text};
    font-size: 14px;
  }
`;

export const MessageError = styled.span`
  display: flex;
  width: 95%;
  justify-content: flex-end;
  font-size: 10px;
  color: ${theme.text};
`;
