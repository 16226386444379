import React from "react";
import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "../App";
import Login from "../pages/Login";
import Home from "src/pages/Home";
import NeedHelp from "src/pages/NeedHelp";
import MyProfile from "src/pages/MyProfile";
import Enterprises from "src/pages/Enterprises";
import SuccessUpdatePassword from "src/pages/MyProfile/components/SuccessUpdatePassword";
import Sales from "src/pages/Sales";
import NeedHelpAuthorizated from "src/pages/NeedHelpAuthorizated";
import DetailEnterpriseComponent from "src/pages/Enterprises/Steps/DetailEnterpriseComponent";
import UnityComponent from "src/pages/Enterprises/Steps/UnityComponent";
import Commission from "src/pages/Commission";

export const useAuth = () => {
  const token = sessionStorage.getItem("@token");

  return !!token;
};

const ProtectedRoute = ({ children }: any) => {
  const auth = useAuth();
  return auth ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }: any) => {
  const auth = useAuth();
  return !auth ? children : <Navigate to="/" />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="*" element={<Navigate to="/" />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/myProfile"
        element={
          <ProtectedRoute>
            <MyProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/empreendimentos"
        element={
          <ProtectedRoute>
            <Enterprises />
          </ProtectedRoute>
        }
      />
      <Route
        path="/detalhesEmpreendimentos"
        element={
          <ProtectedRoute>
            <DetailEnterpriseComponent />
          </ProtectedRoute>
        }
      />
      <Route
        path="/unidade"
        element={
          <ProtectedRoute>
            <UnityComponent />
          </ProtectedRoute>
        }
      />
      <Route
        path="/oportunidades"
        element={
          <ProtectedRoute>
            <Sales />
          </ProtectedRoute>
        }
      />
      <Route
        path="/comissoes"
        element={
          <ProtectedRoute>
            <Commission />
          </ProtectedRoute>
        }
      />
      <Route
        path="/needHelpAuth"
        element={
          <ProtectedRoute>
            <NeedHelpAuthorizated />
          </ProtectedRoute>
        }
      />

      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/needHelp"
        element={
          <PublicRoute>
            <NeedHelp />
          </PublicRoute>
        }
      />
      <Route
        path="/successUpdatePassword"
        element={
          <PublicRoute>
            <SuccessUpdatePassword />
          </PublicRoute>
        }
      />
    </Route>
  )
);
