import React from "react";
import * as Styled from "../styles";
import { AiOutlineCloseCircle } from "react-icons/ai";
import FilterCondition from "@components/Enterprises/FilterCondition";
import AccordionBlock from "@components/Enterprises/AccordionBlock";

interface IShowAllUnityComponent {
  dataSalesMirror: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowSalesMirrorComponent = ({
  setShow,
  dataSalesMirror,
  showError,
  setShowError,
}: IShowAllUnityComponent) => {
  const [index, setIndex] = React.useState(-1);

  return (
    <>
      <Styled.ContainerTitleSection>
        <div>
          <Styled.TitleSection>Espelho de vendas</Styled.TitleSection>
          <AiOutlineCloseCircle onClick={() => setShow(false)} />
        </div>
      </Styled.ContainerTitleSection>

      <Styled.ContainerFilterCondition>
        <FilterCondition
          dataSalelMirror={dataSalesMirror}
          setShowError={setShowError}
          setIndex={setIndex}
        />
      </Styled.ContainerFilterCondition>

      <Styled.Divider />

      <Styled.ContainerAccordion>
        <AccordionBlock
          dataSalelMirror={dataSalesMirror}
          showError={showError}
          setShowError={setShowError}
          index={index}
          setIndex={setIndex}
        />
      </Styled.ContainerAccordion>
    </>
  );
};

export default ShowSalesMirrorComponent;
