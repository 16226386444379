import styled from "styled-components";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";

type Sidebar = {
  isLogged?: boolean;
  isOpen?: boolean;
  size?: string;
  isSelected?: boolean;
  isSubItem?: boolean;
};

export const Container = styled.div<Sidebar>`
  display: ${({ isLogged }) => (isLogged ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  width: ${({ isOpen }) => (!isOpen ? "80px" : "230px")};
  height: 100%;
  background: ${theme.text};
  z-index: 999;
  transition: 0.2s;

  @media ${device.tablet} {
    width: 0;
  }
`;
export const ContainerTotal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContainerLogo = styled.div`
  display: flex;
  width: 95%;
  height: 13%;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  transition: width 0.2s ease;

  @media ${device.laptopXL} {
    height: 9%;
  }
  @media ${device.tablet} {
    display: none;
    width: none;
  }
  @media ${device.mobileL} {
    display: none;
    width: none;
  }
`;

export const ContainerAnyLogos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 120px;
  padding-bottom: 5px;

  @media ${device.laptopXL} {
    height: 260px;
    gap: 15px;
  }

  @media ${device.tablet} {
    display: none;
    width: none;
  }
  @media ${device.mobileL} {
    display: none;
    width: none;
  }
`;

export const ContainerBackgroundLogo = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${theme.backgroundGray};
  cursor: pointer;
`;

export const ContainerBackgroundLogoSmall = styled.div`
  display: flex;
  width: 60%;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${theme.backgroundGray};
  cursor: pointer;
`;
export const ContainerBackgroundAnyLogos = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerBackgroundAnyLogosSmall = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
`;

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;

  @media ${device.laptopXL} {
    height: 550px;
  }

  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;

export const ImgSmall = styled.img`
  display: flex;
  width: fit-content;
  height: 35px;
  object-fit: contain;
`;
export const ImgLarge = styled.img`
  display: flex;
  width: fit-content;
  height: 27px;
  object-fit: contain;
`;

export const ContainerOtions = styled.div<Sidebar>`
  display: flex;
  flex-direction: column;
  width: 97%;
  height: fit-content;
  min-height: 55px;
  justify-content: center;
  align-items: center;
  margin-top: ${({ isSubItem }) => (!isSubItem ? "0" : "5px")};
  margin-bottom: ${({ isSubItem }) => (!isSubItem ? "0" : "15px")};

  @media ${device.laptopXL} {
    height: 60px;
  }
`;
export const ContainerSubOtions = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  height: 55px;
  justify-content: center;
  color: white;

  @media ${device.laptopXL} {
    height: 15px;
    margin-top: 10px;
  }

  > span {
    width: fit-content;
    padding-left: 15px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Otions = styled.div<Sidebar>`
  display: flex;
  width: ${({ isOpen }) => (!isOpen ? "55%" : "95%")};
  height: 80%;
  padding-left: ${({ isOpen }) => (!isOpen ? "0" : "8px")};
  justify-content: ${({ isOpen }) => (!isOpen ? "center" : "")};
  align-items: center;
  border-radius: 8px;
  background: ${({ isSelected }) =>
    isSelected ? "white" : `${theme.backgroundGray}`};
  cursor: pointer;

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
  }

  > span {
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
    font-size: 15px;
    font-weight: bold;
    padding-left: 8px;
    width: 80%;
  }
`;

export const ContainerNeedHelp = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  height: 10%;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;

export const ContainerOtionsNeedHelp = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;

  @media ${device.laptopXL} {
    height: 60%;
  }
`;

export const Divider = styled.div`
  display: flex;
  width: 90%;
  height: 2px;
  background: white;
  border-radius: 8px;
  align-self: center;

  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;

export const ContainerConfig = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 10%;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;

export const ContainerOtionsConfig = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
`;

export const OtionsConfig = styled.div<Sidebar>`
  display: flex;
  width: ${({ isOpen }) => (!isOpen ? "55%" : "100%")};
  height: 80%;
  padding-left: ${({ isOpen }) => (!isOpen ? "0" : "8px")};
  justify-content: ${({ isOpen }) => (!isOpen ? "center" : "")};
  align-items: center;
  border-radius: 8px;
`;

export const ContainerIconConfigClose = styled.div<Sidebar>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ isSelected }) =>
    isSelected ? "white" : `${theme.backgroundGray}`};
  cursor: pointer;

  @media ${device.laptopXL} {
    height: 70%;
  }

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  flex-direction: column;
  width: 88%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export const TextUser = styled.span`
  display: flex;
  color: white;
  font-size: 11px;
  width: 100%;
`;
export const TextEmail = styled.span`
  display: flex;
  color: white;
  font-size: 10px;
  width: 100%;
`;

export const ContainerLogout = styled.div<Sidebar>`
  display: flex;
  width: fit-content;
  height: 100%;
  justify-content: center;
  align-items: center;

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: white;
    cursor: pointer;
  }
`;
