import React from "react";
import * as Styled from "../stylesView&Create";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { OportunityContext } from "@context/oportunityContex";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import quotesService from "@services/Quotes/QuotesService";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionFlowType } from "@helpers/optionSelect";
import InputText from "@components/Sales/Inputs/InputText";
import InputDate from "@components/Sales/Inputs/InputDate";
import { Box, Text, useToast } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { theme } from "src/styles/theme";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { ICreateMensaPsComponent } from "src/@types/Sales";

const CreateMensaPsComponent = ({
  onBack,
  setSuccessCreateMensalPs,
  maxQuantity,
}: ICreateMensaPsComponent) => {
  const toast = useToast();
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const { accessQuote } = React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [require, setRequire] = React.useState(false);

  const [flowType, setFlowType] = React.useState("");
  const [quantity, setQuantity] = React.useState("");

  const [dateMensalPs, setDateMensalPs] = React.useState("");

  const handleDateFormat = (date: string) => {
    if (!date) {
      return date;
    }
    const data = parseISO(date);
    const formattedDate = format(data, "dd/MM/yyyy", { locale: ptBR });
    return formattedDate;
  };

  const getDateToDay = () => {
    const today = new Date();
    today.setDate(today.getDate());
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const localDate = new Date(e.target.value + "T00:00:00");
    const day = localDate.getDate();

    if ([5, 10, 15].includes(day)) {
      setDateMensalPs(e.target.value);
    } else {
      toast({
        status: undefined,
        isClosable: true,
        containerStyle: {
          w: "fit-content",
          boxShadow: "dark-lg",
          borderRadius: "8px",
        },
        render: () => (
          <Box display={"flex"} bg={theme.error} p={4} borderRadius={"8px"}>
            <Text color={"white"}>
              Selecione apenas os dias 5, 10 ou 15 de qualquer mês.
            </Text>
          </Box>
        ),
      });
    }
  };

  const handleCreateMensalPs = async () => {
    setLoading(true);
    setSuccessCreateMensalPs(false);
    try {
      const response = await quotesService.createMensalPs({
        idCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
        dataDePagamentoFormatada: handleDateFormat(dateMensalPs),
        fluxo: flowType,
        months: Number(quantity),
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({
          message: response?.data
            ? response?.data
            : "Erro ao criar mensalidade PS!",
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      displayMessage("Mensal PS salvo com sucesso!");
      setSuccessCreateMensalPs(true);
      onBack();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("errorHandleCreateSchedule", error);
    }
  };

  React.useEffect(() => {
    const isRequirement = !dateMensalPs || !flowType || !quantity;

    setRequire(isRequirement);
  }, [dateMensalPs, flowType, quantity]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Styled.ContainerSchedule>
        <Styled.TitleSchedule>Gerar mensal PS</Styled.TitleSchedule>

        <Styled.ContainerInputsSchedule>
          <InputSelect
            isRequirement
            widht="60%"
            height="36%"
            title="Tipo de fluxo:"
            label={flowType || "Selecione uma opção"}
            isSelected={flowType}
            options={optionFlowType}
            selectedOption={flowType}
            onChange={(e) => setFlowType(e.target.value)}
          />
        </Styled.ContainerInputsSchedule>

        <Styled.ContainerInputsSchedule>
          <InputText
            isRequirement
            label={`Quantidade de meses PS (máximo ${maxQuantity}):`}
            name="quantity"
            placeholder="Digite a quantidade desejada"
            value={quantity}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              if (newValue > maxQuantity) {
                setQuantity(maxQuantity?.toString());
              } else {
                setQuantity(e.target.value);
              }
            }}
          />
        </Styled.ContainerInputsSchedule>

        <Styled.ContainerInputsSchedule>
          <InputDate
            type="date"
            isRequirement
            min={getDateToDay()}
            name={"dateMensalPs"}
            placeholder="__/__/___"
            label="Data para mensal PS (Selecione apenas os dias 5, 10 ou 15):"
            value={dateMensalPs}
            onChange={handleDateChange}
          />
        </Styled.ContainerInputsSchedule>
      </Styled.ContainerSchedule>

      <Styled.DividerBoxInput />

      <Styled.ContainerFooterBox>
        <Styled.ContainerDuoMandatory>
          <span>(*) Campo de preenchimento obrigatório</span>
          <span>
            (<AiOutlineInfoCircle />) O campo apresenta formato ou dado inválido
          </span>
        </Styled.ContainerDuoMandatory>
        <Styled.ContainerButtonFooter>
          <Button error title="CANCELAR" onClick={onBack} />

          <Button
            title={loading ? <Spinner /> : "SALVAR"}
            onClick={handleCreateMensalPs}
            disabled={require || loading || Number(quantity) > maxQuantity}
          />
        </Styled.ContainerButtonFooter>
      </Styled.ContainerFooterBox>
    </>
  );
};

export default CreateMensaPsComponent;
