import React from "react";
import { MdOutlineEditNote } from "react-icons/md";
import * as Styled from "./styles";

interface IInputLogin {
  placeholder?: string;
  isLabel?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  iconClick?: React.MouseEventHandler<SVGElement>;
  error?: any;
  message?: string;
  isName?: boolean;
}

const NewInputEmail = ({
  value,
  onChange,
  placeholder,
  isLabel,
  error,
  message,
  iconClick,
  isName,
}: IInputLogin) => {
  return (
    <Styled.Area>
      {isLabel && <Styled.Label>{isLabel}</Styled.Label>}

      <Styled.Container error={error}>
        <Styled.Input
          placeholder={placeholder}
          type={isName ? "text" : "email"}
          value={value}
          onChange={onChange}
          error={error}
        />

        <MdOutlineEditNote size="36px" />
      </Styled.Container>

      {error && <Styled.MessageError>{message}</Styled.MessageError>}
    </Styled.Area>
  );
};

export default NewInputEmail;
