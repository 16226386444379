import { Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import * as Styled from "./styles";

interface ITabFilter {
  onTabClick: any;
}

const TabFilter = ({ onTabClick }: ITabFilter) => {
  const handleTabClick = (tab: any) => {
    onTabClick(tab);
  };

  return (
    <Styled.Container>
      <Styled.Title>Filtrar por status: </Styled.Title>
      <Tabs position="relative">
        <TabList color={theme.disable} fontWeight="bold">
          <Tab fontSize={12} onClick={() => handleTabClick("Todos")}>
            Todos
          </Tab>
          <Tab fontSize={12} onClick={() => handleTabClick("EM OBRAS")}>
            Em Obra
          </Tab>
          <Tab fontSize={12} onClick={() => handleTabClick("PRONTO")}>
            Pronto
          </Tab>
        </TabList>
        <TabIndicator mt="-1.5px" bg={theme.primary} borderRadius="1px" />
      </Tabs>
    </Styled.Container>
  );
};

export default TabFilter;
