import { theme } from "src/styles/theme";
import styled from "styled-components";

interface ITextArea {
  isError?: boolean;
  readOnly?: boolean;
}

const handleColor = (isError?: boolean, readOnly?: boolean) => {
  if (isError) return theme.error;
  if (readOnly) return theme.disable100;
  return theme.disable;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContainerTextArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  border: 2px solid
    ${(props: ITextArea) => handleColor(props.isError, props.readOnly)};
  background: white;
  border-radius: 8px;
`;

export const TextArea = styled.textarea`
  display: flex;
  height: 90%;
  border-radius: 8px;
  padding: 6px 8px;
  outline: none;
  resize: none;
  border: none;
  overflow: auto;
  background: white;
  font-size: 14px;
  ::placeholder {
    color: ${(props: ITextArea) =>
      props.isError ? `${theme.error}` : `${theme.disable}`};
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${theme.text};
  margin-left: 5px;
`;

export const Counter = styled.span`
  display: flex;
  font-size: 11px;
  color: ${(props: ITextArea) =>
    props.isError ? `${theme.error}` : `${theme.text}`};
  justify-content: flex-end;
  width: 99%;
  height: 15px;
`;

export const MessageError = styled.span`
  display: flex;
  width: 95%;
  margin-top: 1px;
  justify-content: flex-end;
  font-size: 10px;
  color: ${theme.text};
`;
