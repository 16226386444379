import React, { ReactNode } from "react";
import * as Styled from "./styles";
import Spinner from "@components/common/Spinner";
import { Link } from "react-router-dom";

interface IHelper {
  isResendCode?: boolean;
  isModify?: boolean;
  loadingResend?: boolean;
  onClickResend?: () => void;
  children?: ReactNode;
}

interface IHashMapHelper {
  [key: string]: React.ReactNode;
}

const Helper = ({
  isResendCode,
  isModify,
  loadingResend,
  onClickResend = () => {},
  children,
}: IHelper) => {
  const [seconds, setSeconds] = React.useState(0);
  const [hash, setHash] = React.useState<string>("Traditional");

  const resendOTP = () => {
    if (seconds <= 0) {
      setSeconds(60);
      onClickResend();
    } else {
      return;
    }
  };

  const HelperHashMap: IHashMapHelper = {
    Traditional: (
      <>
        {isResendCode ? (
          <Styled.ContainerHelpBody>
            {!loadingResend ? (
              <Styled.ContainerCount>
                {seconds !== 0 ? (
                  <Styled.Text>
                    Reenviar código em {seconds < 10 ? `0${seconds}` : seconds}{" "}
                    segundos
                  </Styled.Text>
                ) : (
                  <Styled.Text onClick={resendOTP}>
                    Não recebeu o código?
                  </Styled.Text>
                )}
              </Styled.ContainerCount>
            ) : (
              <Spinner />
            )}
          </Styled.ContainerHelpBody>
        ) : null}

        <Styled.ContainerHelpBody>
          <Link to="/needHelp">Precisa de ajuda?</Link>
        </Styled.ContainerHelpBody>
      </>
    ),
    Modify: <Styled.ContainerHelpBody>{children}</Styled.ContainerHelpBody>,
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  React.useEffect(() => {
    if (isModify) {
      setHash("Modify");
    }
  }, [isModify]);

  return <Styled.ContainerHelp>{HelperHashMap[hash]}</Styled.ContainerHelp>;
};

export default Helper;
