import styled from "styled-components";
import { theme } from "../../styles/theme";
import { device } from "src/styles/variable";
import { HiOutlineLockClosed } from "react-icons/hi";

type Sidebar = {
  open: boolean;
};

export const Area = styled.div<Sidebar>`
  display: flex;
  position: relative;
  flex-direction: column;
  left: ${({ open }) => (!open ? " 80px" : "230px")};
  width: ${({ open }) => (!open ? `calc(100% - 80px)` : `calc(100% - 230px)`)};
  height: 100%;
  background: ${theme.disable100};
  transition: all 0.2s ease;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  @media ${device.tablet} {
    width: 100%;
    left: 0;
  }
`;
export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: max-content;
`;

export const SubContainer = styled.div`
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  height: max-content;
  background: ${theme.disable100};
  padding-top: 25px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 40px;
`;
export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 20px;
`;

export const ContainerError = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 25px;
`;
export const SubContainerError = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: blue;
  > span {
    display: flex;
    font-size: 22px;
  }
`;

export const ContainerTop = styled.section`
  display: flex;
  width: fit-content;
  height: 10%;
  padding-top: 30px;

  > span {
    font-size: 15px;
    margin-bottom: 15px;
    color: ${theme.primary};
  }
`;

export const ContainerTitlePage = styled.section`
  display: flex;
  width: 100%;
  height: 8%;
  justify-content: space-between;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const TitlePage = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: ${theme.text};
`;

export const ContainerButtonTilte = styled.div`
  display: flex;
  width: 280px;
  margin-right: 10px;

  @media ${device.tablet} {
    width: 45%;
  }
  @media ${device.mobileL} {
    width: 50%;
  }
`;

export const IconHiOutlineLockClosed = styled(HiOutlineLockClosed)`
  color: ${theme.error};
  font-size: 22px;
  width: fit-content;
`;

export const Description = styled.span`
  font-size: 14px;
  color: ${theme.text};
`;

export const ContainerSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 32%;
  background: white;
  margin-top: 10px;
  border-radius: 8px;
  padding: 10px 12px;
`;

export const ContainerTitle = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 16%;
  justify-content: center;
`;

export const Title = styled.span`
  display: flex;
  font-size: 23px;
  font-weight: bold;
  color: ${theme.text};
`;

export const ContainerInput = styled.section`
  display: flex;
  width: 100%;
  height: 40%;
  margin-top: 10px;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;

export const SubContainerInput = styled.section`
  display: flex;
  width: 20%;
  padding-right: 15px;

  @media ${device.tablet} {
    width: 80%;
  }
`;

export const Items = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 15px;
  border-radius: 8px;

  &:hover {
    background-color: #e7edfe;
  }
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
export const LabelRadioButton = styled.label`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p`
  color: ${theme.text};
  font-size: 12px;
  padding-left: 0.5rem;
`;

export const RadioBox = styled.div`
  display: flex;
  width: 1.3rem;
  height: 1.1rem;
  align-items: center;
  justify-content: center;
  padding: 1px 1px;
  border: 2px solid ${theme.disable};
  border-radius: 50%;
  transition: background 0.15s, border-color 0.15s;
  cursor: pointer;

  &::after {
    content: "";
    width: 9.3px;
    height: 9px;
    display: block;
    background: ${theme.primary};
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    &::after {
      transform: scale(1);
    }
  }
`;

export const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 22vh;
  justify-content: center;
  align-items: center;
`;

export const ContainerUpdatePassword = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 20px;
`;
export const ContainerUpdatePasswordLeft = styled.section`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  gap: 10px;
`;
export const ContainerButtonLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 15px;
`;
export const ButtonNeedHelpLeft = styled.span`
  display: none;
  color: ${theme.primary};
  text-decoration: underline;
  font-size: 14px;
  justify-content: center;
  align-items: center;
`;
export const ContainerUpdatePasswordRight = styled.section`
  display: flex;
  width: 50%;
  height: 100%;
`;

export const ContainerCreatePasswordBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div {
    margin-bottom: 8px;
  }
`;

export const Divider = styled.div`
  display: flex;
  align-self: center;
  width: 90%;
  height: 4px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
`;
export const DividerVertical = styled.div`
  display: flex;
  width: 4px;
  height: 240px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
`;
