import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border: none;

  > span {
    width: 100%;
    height: fit-content;
    font-size: 11px;
    color: ${theme.text};
    margin: 3px 4px;
  }
`;
