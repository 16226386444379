import { AiOutlineCloseCircle } from "react-icons/ai";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

type ISales = {
  open?: boolean;
  hash?: string;
  currentFase?: boolean;
  indexTab?: boolean;
};

type IRelationship = {
  isRelationship?: boolean;
};

export const Area = styled.div<ISales>`
  display: flex;
  position: relative;
  flex-direction: column;
  left: ${({ open }) => (!open ? " 80px" : "230px")};
  width: ${({ open }) => (!open ? `calc(100% - 80px)` : `calc(100% - 230px)`)};
  height: 100%;
  background: ${theme.disable100};
  transition: all 0.2s ease;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  > form {
    display: flex;
    flex-direction: column;
  }

  @media ${device.tablet} {
    width: 100%;
    left: 0;
  }
`;

export const Container = styled.div<ISales>`
  display: flex;
  position: relative;
  flex-direction: column;
  height: max-content;
`;

export const SubContainer = styled.div`
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  height: max-content;
  padding-top: 25px;

  @media ${device.mobileL} {
    padding-left: 3px;
  }
`;

export const ContainerTop = styled.section`
  display: flex;
  width: 100%;
  margin-top: 35px;

  @media ${device.laptop} {
    margin-top: 23px;
  }
  @media ${device.tablet} {
    margin-top: 35px;
  }
  @media ${device.mobileL} {
    margin-top: 35px;
  }
`;

export const ContainerTitlePage = styled.section`
  display: flex;
  width: 99%;
  height: 20%;
  align-items: center;

  @media ${device.laptopXL} {
    height: 40px;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerAnyButtons = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;

  @media ${device.laptop} {
    width: 30%;
  }
  @media ${device.tablet} {
    width: 30%;
  }

  @media ${device.mobileL} {
    width: 100%;
  }
`;
export const TitleButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: fit-content;
    color: white;
    font-size: 18px;
  }
`;
export const ContainerButtonPlus = styled.div<ISales>`
  display: flex;
  width: ${({ indexTab }) => (indexTab ? "100%" : "70%")};
  gap: 6px;

  @media ${device.mobileL} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.laptop} {
    width: 100%;
  }
`;

export const ContainerUpdateButton = styled.div<ISales>`
  display: flex;
  width: 60px;
  margin-right: 8px;
`;
export const AreaButtonsPopover = styled.div`
  display: flex;
`;
export const BoxButtonsPopover = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TitleButtonsPopover = styled.span`
  font-size: 13px;
  color: ${theme.text};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

export const DividerButtonsPopover = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  height: 2px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  width: 85%;

  @media ${device.laptopXL} {
    width: 90%;
  }
  @media ${device.laptop} {
    gap: 6px;
  }
  @media ${device.mobileL} {
    width: 100%;
  }

  > svg {
    display: flex;
    width: fit-content;
    font-size: 26px;
  }
`;
export const TitlePage = styled.span`
  width: 90%;
  font-size: 28px;
  font-weight: bold;
  color: ${theme.text};

  @media ${device.laptop} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 100%;
    font-size: 21px;
    margin-bottom: 8px;
  }
`;

export const ContainerTitleButtons = styled.div`
  display: flex;
  width: 30%;
  margin-right: 15px;
  gap: 20px;

  @media ${device.laptopXL} {
    width: 15%;
  }
  @media ${device.laptop} {
    width: 35%;
  }
  @media ${device.mobileL} {
    width: 55%;
  }
`;
export const AreaTitleButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: fit-content;
    margin-right: 6px;
    color: white;
    font-size: 16px;
  }
`;

export const ContainerDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;

  @media print {
    display: none;
  }

  @media ${device.laptop} {
    width: 60%;
    margin-top: 10px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-top: 10px;
  }
  @media ${device.mobileL} {
    margin-top: 70px;
    width: 96%;
  }
`;

export const ContainerDisplaySearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;

  @media print {
    display: none;
  }

  @media ${device.laptop} {
    width: 60%;
    margin-top: 10px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-top: 10px;
  }
  @media ${device.mobileL} {
    margin-top: 20px;
    width: 96%;
  }
`;

export const ContainerDisplaySearchOportunity = styled.div`
  display: flex;
  width: 98%;
  height: 60%;
  margin-top: 15px;
  gap: 6px;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;
export const TitleDisplay = styled.span`
  display: flex;
  font-size: 13px;
`;

export const ContainerDisplayInput = styled.div`
  display: flex;
  gap: 15px;
  height: 50%;
  margin-top: 5px;

  > div {
    width: 100%;

    @media ${device.laptopXL} {
      width: 70%;
    }
  }
  > button {
    width: 40%;

    @media ${device.laptopXL} {
      width: 20%;
    }
  }
`;

export const ContainerSearchInputOportunity = styled.div`
  display: flex;
  width: 80%;

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const ContainerSelectInputOportunity = styled.div`
  display: flex;
  width: 95%;
  gap: 6px;
`;

export const ContainerSelectInputDateOportunity = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  @media ${device.laptop} {
    width: 60%;
  }

  > div {
    display: flex;
    gap: 6px;
  }
`;

export const LabelInputDateOportunity = styled.span`
  font-size: 12px;
  color: ${theme.text};
  margin-left: 5px;
  height: 18px;
`;

export const ContainerRadioButtonSearch = styled.div`
  display: flex;
  width: 262px;
  height: 50%;
  gap: 10px;
`;
export const ContainerRadioButtonSearchMyOportunity = styled.div`
  display: flex;
  width: max-content;
  height: 50%;
  gap: 10px;
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 100%;
  margin-top: 12px;
  background: white;
  border-radius: 8px;

  @media ${device.laptop} {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  overflow: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  @media ${device.laptopXL} {
    height: 600px;
  }

  @media ${device.mobileL} {
    padding-left: 0px;
  }
`;

export const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 350px;
  justify-content: center;
  align-items: center;

  @media ${device.laptopXL} {
    height: 500px;
  }
`;

export const AreaQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerSuccess = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 110px;
  justify-content: space-between;

  > span {
    font-size: 12px;
    color: ${theme.text};
    margin-top: 10px;
  }
`;
export const ContainerQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 35%;

  > span {
    font-size: 12px;
    color: ${theme.text};
    font-weight: bold;
  }
`;

export const ContainerRadioButtonQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  gap: 6px;
  margin-top: 4px;
`;

export const ContainerTitleNotify = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 30%;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const ContainerButtonNotify = styled.div`
  display: flex;
  width: 90%;
  height: fit-content;
  gap: 10px;
  margin-top: 8px;
`;

export const Divider = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContainerBoxAccounts = styled.div<IRelationship>`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 100%;
  margin-top:${({ isRelationship }) => (isRelationship ? " 0" : "12px")}; 
  background: white;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: ${({ isRelationship }) => (isRelationship ? " 5px" : "80px")};

  @media ${device.laptopXL} {
    margin-bottom: 0px;
    height: 89%;
  }

  @media ${device.laptop} {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const ContainerTitleBox = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  gap: 6px;

  @media ${device.laptop} {
    height: 55px;
  }

  @media ${device.mobileM} {
    height: 100px;
  }

  > span {
    font-size: 20px;
    font-weight: bold;
    color: ${theme.text};
  }
`;
export const ContainerTitleWithCloseButton = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  @media ${device.tablet} {
    flex-direction: column;
    height: 55px;
  }
  @media ${device.mobileL} {
    height: 60px;
  }
  @media ${device.mobileM} {
    height: 100px;
  }

  > span {
    font-size: 20px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const ContainerCloseButtonBox = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: space-between;

  @media ${device.laptopXL} {
    width: 30%;
  }
  @media ${device.laptop} {
    width: 78%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }

  > span {
    font-size: 20px;
    font-weight: bold;
    color: ${theme.text};
    width: max-content;
  }
`;

export const IconAiOutlineCloseCircle = styled(AiOutlineCloseCircle)`
  margin-right: 5px;
  font-size: 26px;
  width: fit-content;
  cursor: pointer;
`;

export const ContainerBoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-top: 12px;
  border-radius: 8px;
  border: 1px solid ${theme.disable};
  padding: 3px;

  @media ${device.laptopXL} {
    padding-bottom: 15px;
  }
`;

export const ContainerBoxInfoProduct = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-top: 12px;
  border-radius: 8px;
  border: 1px solid ${theme.disable};
  padding: 3px;

  @media ${device.laptopXL} {
    padding-bottom: 15px;
  }
`;

export const ContainerBoxSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 12px;
  padding: 3px;
  justify-content: center;
  align-items: center;
`;

export const ContainerBoxTitleInfo = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  > span {
    font-size: 20px;
    font-weight: bold;
    color: ${theme.text};
    width: fit-content;
  }
`;

export const ContainerBoxInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media print {
    grid-row-gap: 25px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;

export const ContainerBoxInfoCheckbox = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
`;

export const ContainerBoxInfoTextBox = styled.div`
  display: flex;
  height: 140px;
  width: 100%;
`;

export const ContainerBoxProductsInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media print {
    grid-row-gap: 25px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;
export const ContainerBoxMyOportunityProductsInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media print {
    grid-row-gap: 25px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;

export const DividerBoxInput = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  height: 2px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContainerFooterBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  @media ${device.laptopXL} {
    height: 65px;
    align-items: flex-end;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  width: 50%;
  height: fit-content;
  gap: 10px;

  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.tablet} {
    width: 90%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 15px;
  }
`;

export const ContainerMandatory = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  > span {
    color: ${theme.error};
    font-size: 13px;
    font-weight: bold;
  }
`;
export const ContainerDuoMandatory = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  > span {
    display: flex;
    color: ${theme.error};
    font-size: 13px;
    font-weight: bold;
    align-items: center;

    > svg {
      width: fit-content;
      height: fit-content;
    }
  }
`;
export const ContainerSearchMessageUnityModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  border: 1px solid ${theme.disable100};
  padding: 5px;
  margin: 5px;

  @media ${device.laptopXL} {
    padding: 8px;
    width: 99%;
  }
  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;

export const BodySearchUnityModalMessage = styled.span`
  display: flex;
  font-size: 12px;
  color: ${theme.text};
`;
export const TitleSection = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.text};
`;

export const BodySearchUnityModalMessageError = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.error};
`;

export const ContainerEmptyMessage = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 14px;
    color: ${theme.error};
  }
`;

export const ContainerFaseDatailOportunity = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  justify-content: center;
`;
export const ContainerStepp = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  padding-left: 30px;

  @media ${device.mobileL} {
    padding-left: 10px;
    overflow: auto;

    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.primary};
      border-radius: 20px;
      width: 5px;
    }
  }
`;

export const ContainerFollow = styled.div`
  display: flex;
  width: 90%;
  margin-top: 2px;
  align-items: center;

  > span {
    width: fit-content;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};

    @media ${device.mobileL} {
      font-size: 14px;
    }
  }
`;
export const DividerFollow = styled.div`
  display: flex;
  width: 100%;
  height: 2px;
  border-radius: 8px;
  background: #e5e9f0;
  margin-left: 5px;
`;

export const AreaFaseDatailOportunity = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  padding: 8px;
  border-radius: 8px;
  justify-content: center;
  margin-top: 10px;
  background: white;

  @media ${device.mobileL} {
    padding: 4px;
  }

  > span {
    width: 100%;
    font-size: 10px;
    color: ${theme.text};
  }
`;

export const AreaFaseDatailOportunityLoading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: white;
`;

export const ButtonFaseDatailOportunity = styled.div<ISales>`
  display: flex;
  width: 200px;
  min-height: 30px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
  padding: 2px;
  align-items: center;
  justify-content: center;
  background: ${({ currentFase }) => (currentFase ? theme.primary : "white")};

  > span {
    font-size: 14px;
    color: ${({ currentFase }) => (currentFase ? "white" : theme.text)};
    text-align: center;
    font-weight: bold;
  }
`;

export const ContainerActivityDatailOportunity = styled.div`
  display: flex;
  width: 100%;
  background: white;
  padding: 4px;
  gap: 6px;
  border-radius: 8px;
  margin-top: 15px;
`;

export const ContainerTitleButtonEnterprises = styled.div`
  display: flex;
  margin-right: 15px;
  width: 15%;
  gap: 10px;

  @media ${device.laptopXL} {
    width: 10%;
  }
  @media ${device.laptop} {
    width: 80%;
  }
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
