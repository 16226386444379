import React from "react";
import * as Styled from "../styles";
import { MdMarkEmailRead } from "react-icons/md";
import { hideEmail } from "@helpers/emailCheck";
import Spinner from "@components/common/Spinner";
import InputDigits from "@components/Login/Inputs/InputDigits";
import Helper from "@components/Login/Helper";
import Button from "@components/common/Button";

interface ITwoFactorAuthnticationComponent {
  loading: boolean;
  loadingResend: boolean;
  email: string;
  code: string;
  setCode: any;
  error: any;
  setError: any;
  handleResendCode: () => void;
}

const TwoFactorAuthnticationComponent = ({
  loading,
  loadingResend,
  email,
  code,
  setCode,
  error,
  setError,
  handleResendCode,
}: ITwoFactorAuthnticationComponent) => {
  return (
    <>
      <Styled.BodyCurrentLogin>
        <Styled.ContainerTwoAuthenticationTitle>
          <MdMarkEmailRead />
          <span>Autenticação em dois fatores</span>
        </Styled.ContainerTwoAuthenticationTitle>
        <Styled.ContainerTwoAuthenticationSubTitle>
          <span>
            Enviamos um código de verificação para o e-mail cadastrado:
          </span>

          <p>{hideEmail(email)}</p>
        </Styled.ContainerTwoAuthenticationSubTitle>

        <Styled.ContainerCode>
          <span>Insira o código de 6 dígitos no campo abaixo:</span>

          <Styled.ContainerInputCode>
            {loading ? (
              <Spinner />
            ) : (
              <InputDigits
                value={code.replace(/[^0-9]/g, "")}
                onChange={(e) => {
                  setCode(e.target.value);
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      errorConfirmCode: false,
                    };
                  });
                }}
                maxLength={6}
                message="Campo obrigatório"
              />
            )}
          </Styled.ContainerInputCode>
        </Styled.ContainerCode>

        {error.errorConfirmCode ? (
          <Styled.ContainerButtonForgotPassword>
            <Button
              title="CÓDIGO INVÁLIDO"
              onClick={() => {}}
              error={error.errorConfirmCode}
            />
          </Styled.ContainerButtonForgotPassword>
        ) : (
          <Styled.ContainerButtonForgotPassword />
        )}
      </Styled.BodyCurrentLogin>

      <Helper
        isResendCode
        loadingResend={loadingResend}
        onClickResend={handleResendCode}
      />
    </>
  );
};

export default TwoFactorAuthnticationComponent;
