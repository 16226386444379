import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { theme } from "src/styles/theme";
import { useScrollableBoxProps } from "@hook/useEqualCode";

interface IBreadcrumbItem {
  name: string;
  link?: string;
  onClick?: () => void | React.MouseEventHandler<HTMLAnchorElement>;
}

interface IBreadcrumb {
  items: IBreadcrumbItem[];
}

const BreadCrumb = ({ items }: IBreadcrumb) => {
  const scrollProps = useScrollableBoxProps();
  return (
    <Breadcrumb {...scrollProps}>
      {items.map((item, index) => (
        <BreadcrumbItem
          key={item.name}
          isCurrentPage={index === items.length - 1}
          w="fit-content"
          height="100%"
        >
          {item.link || item.onClick ? (
            <BreadcrumbLink
              fontSize={14}
              fontWeight="bold"
              color={theme.primary}
              as={Link}
              to={item.link}
              onClick={item.onClick}
            >
              <span style={{ display: "flex", width: "max-content" }}>
                {item.name}
              </span>
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              fontSize={14}
              fontWeight="bold"
              color={theme.primary}
            >
              <span style={{ display: "flex", width: "100%" }}>
                {item.name}
              </span>
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumb;
