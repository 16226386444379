import React, { InputHTMLAttributes } from "react";
import * as Styled from "./styles";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import Tooltip from "@components/common/Tooltip";

interface IInputLocked extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  value: string;
  placeholder?: string;
  iconClick?: React.MouseEventHandler<SVGElement>;
  isRight?: boolean;
}

const InputLocked = ({
  label,
  name,
  placeholder,
  iconClick,
  value,
  isRight,
}: IInputLocked) => {
  return (
    <Styled.Area>
      <Styled.ContainerTop>
        {label && <Styled.Label>{label}</Styled.Label>}

        {value?.length >= 25 && (
          <Tooltip text={value} isRight={isRight}>
            <Styled.IconBsFillInfoCircleFill size="12px" />
          </Tooltip>
        )}
      </Styled.ContainerTop>

      <InputGroup>
        <Input
          placeholder={placeholder}
          name={name}
          defaultValue={value}
          readOnly
          type={"text"}
          borderWidth={2}
          borderColor={theme.disable100}
          focusBorderColor="none"
          height="44px"
          borderRadius={8}
          fontSize={14}
          textColor={theme.text}
          fontWeight="bold"
          _hover={{ color: "none" }}
          cursor="default"
        />
        <InputRightElement>
          <Styled.IconHiOutlineLockClosed size="25px" onClick={iconClick} />
        </InputRightElement>
      </InputGroup>
    </Styled.Area>
  );
};
export default InputLocked;
