import React, { InputHTMLAttributes } from "react";
import * as Styled from "./styles";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IInputLocked extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  iconClick?: React.MouseEventHandler<SVGElement>;
  size?: number;
}

const InputLocked = ({ label, iconClick, size, ...props }: IInputLocked) => {
  return (
    <Styled.Area>
      <Styled.ContainerTop>
        {label && <Styled.Label>{label}</Styled.Label>}
      </Styled.ContainerTop>

      <InputGroup>
        <Input
          readOnly
          type="text"
          borderWidth={2}
          borderColor={theme.disable100}
          focusBorderColor="none"
          height="44px"
          borderRadius={8}
          fontSize={14}
          textColor={theme.text}
          fontWeight="bold"
          _hover={{ color: "none" }}
          cursor="default"
          size={size?.toString()}
          {...props}
        />
        <InputRightElement>
          <Styled.IconHiOutlineLockClosed size="25px" onClick={iconClick} />
        </InputRightElement>
      </InputGroup>
    </Styled.Area>
  );
};

export default InputLocked;
