import React from "react";
import * as Styled from "../styles";
import Box from "@components/Login/Box";
import InputCPF from "@components/Login/Inputs/InputCPF";
import { documentMask } from "@helpers/format";
import Button from "@components/common/Button";
import Helper from "@components/Login/Helper";
import Spinner from "@components/common/Spinner";
import { cpf } from "cpf-cnpj-validator";
import { messageBox } from "./messageBox";

interface IForgotPasswordComponent {
  error: any;
  setError: any;
  document: string;
  setDocument: any;
  loading: boolean;
  setDigits: any;
  setPage: any;
  setErrorForgotPassword: any;
  handleEnter: () => void;
}

const ForgotPasswordComponent = ({
  error,
  setError,
  document,
  setDocument,
  setDigits,
  setPage,
  setErrorForgotPassword,
  loading,
  handleEnter,
}: IForgotPasswordComponent) => {
  const messageButtonText = error.errorDocument
    ? "ACESSO INVÁLIDO"
    : "VERIFICAR";

  const buttonText = loading ? <Spinner /> : messageButtonText;

  const handleCleanError = () => {
    setError((prevState: any) => {
      return {
        ...prevState,
        errorMessage: "",
        errorDocument: false,
      };
    });
  };

  return (
    <>
      <Styled.BodyCurrentLogin>
        <Styled.ContainerInputLogin>
          <InputCPF
            placeholder="CPF"
            value={documentMask(document)}
            onChange={(e) => {
              setDocument(e.target.value);
              handleCleanError();
            }}
            iconClick={() => {
              setDocument("");
              handleCleanError();
            }}
            message={<span>Campo obrigatório</span>}
            error={error.errorDocument}
          />
        </Styled.ContainerInputLogin>

        <Styled.ContainerButtonLogin>
          <Button
            title={buttonText}
            onClick={() => {
              setDigits("");
              handleEnter();
            }}
            disabled={
              loading || (!cpf.isValid(document) && document.length < 14)
            }
            error={error.errorDocument}
          />
        </Styled.ContainerButtonLogin>

        <Styled.ContainerBox>
          {error.errorDocument && (
            <Box
              title={
                error.errorTitle ||
                "Utilize um CPF/CNPJ com formato válido para ativar seu cadastro."
              }
              body={
                <Styled.ContainerErrorForgotPassword>
                  {messageBox(
                    error.errorMessage,
                    setPage,
                    setErrorForgotPassword
                  )}
                </Styled.ContainerErrorForgotPassword>
              }
            />
          )}
        </Styled.ContainerBox>
      </Styled.BodyCurrentLogin>

      <Helper />
    </>
  );
};

export default ForgotPasswordComponent;
