import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

export const ContainerAction = styled.div`
  display: flex;
  background: ${props => props.theme.primary};
  border-radius: 8px;
  align-self: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;

  @media ${device.laptop} {
    width: 120px;
    height: 38px;
    text-align: center;
  }
  @media ${device.laptopXL} {
    width: 200px;
  }

  > span {
    display: flex;
    font-size: 13px;
    font-weight: bold;
    align-self: center;
    justify-content: center;
    color: white;
  }
`;

export const ContainerTypeAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerRegional = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerNameAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerOriginAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    text-align: center;
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerSearchMessageUnityModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  border: 1px solid ${theme.disable100};
  padding: 5px;
  margin: 5px;

  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;

export const BodySearchUnityModalMessage = styled.span`
  display: flex;
  font-size: 12px;
  color: ${theme.text};
`;
export const TitleSection = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.text};
`;

export const BodySearchUnityModalMessageError = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.error};
`;

export const PopUpTitle = styled.div`
  padding: 0px 0px 16px 0px;  
  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const PopUpSpacer = styled.div`
  height: 8px
`