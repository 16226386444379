const size = {
  mobileM: "430px",
  mobile: "576px",
  tablet: "768px",
  tabletL: "992px",
  desktop: "1200px",
  laptopL: "1366px",
  desktopL: "1400px",

  mobileS: "375px",
  laptop: "1024px",
  laptopXL: "1920px",
};

export const device = {
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(width <= ${size.desktop})`,
  laptopL: `(width <= ${size.laptopL})`,
  laptopXL: `(width >=  ${size.desktopL})`,
};
