import React from "react";
import * as Styled from "../stylesView&Create";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import oportunityService from "@services/Oportunity/OportunityService";
import InputText from "@components/Sales/Inputs/InputText";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionTypeRelationship } from "@helpers/optionSelect";
import { FiPlus } from "react-icons/fi";
import { OportunityContext } from "@context/oportunityContex";
import { documentMask, phoneMask } from "@helpers/format";
import { optionAccount } from "../../components/options";
import salesService from "@services/Sales/SalesService";
import InputSelectSearchAccount from "@components/Sales/Inputs/InputSelectSearchAccount";
import MessageToast from "@components/Sales/MessageToast";
import RadioSelect from "@components/Sales/Inputs/RadioSelect";
import { isEmail } from "@helpers/emailCheck";
import { useMessageToast } from "@hook/useMessageToast";
import { ICreateRalationshipComponent } from "src/@types/Sales";
import Notify from "@components/Sales/Notify";
import QuestionsCreateAccountComponent from "../../components/QuestionsCreateAccountComponent";
import Modal from "@components/Sales/Modal";
import AccountsPhysicalComponents from "../CreateAccount/AccountsPhysicalComponents";
import { UsuallyContext } from "@context/usuallyContex";
import AccountsLegalComponents from "../CreateAccount/AccountsLegalComponents";

const CreateRalationshipComponent = ({
  idRelacionamentoComprador,
  idAvaliacaoCreditoSalesforce,
  accountName,
  onBack,
  setSuccessCreateRalationship,
  setIdSuccessCreateRalationship,
  navigationCreateAccount,
  mandatoryCreateAccount,
  setMandatoryCreateAccount,
}: ICreateRalationshipComponent) => {
  const displayMessage = useMessageToast();
  const { typeAccount, setTypeAccount } = React.useContext(UsuallyContext);
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const { signOut } = React.useContext(LoginContext);
  const { accessCreateOp, setAccessRelationship } =
    React.useContext(OportunityContext);
  const [typeRelationship, setTypeRelationship] = React.useState(
    "Compositor de Renda"
  );
  const [cpf, setCpf] = React.useState("");
  const [require, setRequire] = React.useState(false);

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingAccount, setloadingAccount] = React.useState(false);

  const [account, setAccount] = React.useState("");
  const [selectAccount, setSelectAccount] = React.useState("");
  const [idAccount, setIdAccount] = React.useState("");
  const [dataAccount, setDataAccount] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [ehLead, setEhLead] = React.useState(false);
  const [disabledSearchButton, setDisabledSearchButton] = React.useState(false);
  const [selectRadio, setSelectRadio] = React.useState("Email");
  const radioTel = "Telefone";
  const radioEmail = "Email";
  const radioCpf = "CPFCNPJ";

  const [showCreateNewAccount, setShowCreateNewAccount] = React.useState(false);
  const [showCreateUser, setShowCreateUser] = React.useState(false);
  const [isCreateAccount, setIsCreateAccount] = React.useState(false);
  const [cnpjCreateAccount, setCnpjCreateAccount] = React.useState(false);
  const [lengthAccount, setLengthAccount] = React.useState("");

  const handleSelectOptionPlaceholder = () => {
    if (selectRadio === "Telefone") return "(XX) XXXXX-XXXX";
    if (selectRadio === "Email") return "teste@provedor.com";
    if (selectRadio === "CPFCNPJ")
      return "XXX.XXX.XXX-XX ou XX.XXX.XXX/XXXX-XX";
  };

  const handleSelectOptionOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): string => {
    if (selectRadio === "Telefone")
      return phoneMask(e.target.value?.replace(/\D/g, ""));
    if (selectRadio === "Email") return e.target.value;
    if (selectRadio === "CPFCNPJ") {
      setLengthAccount(documentMask(e.target.value?.replace(/\D/g, "")));
      return documentMask(e.target.value?.replace(/\D/g, ""));
    } else return "";
  };

  const handleSelectOptionMaxLength = () => {
    if (selectRadio === "Telefone") return 15;
    if (selectRadio === "Email") return undefined;
    if (selectRadio === "CPFCNPJ") return 18;
  };

  const handleOptionChangeSearch = (value: string) => {
    setSelectRadio(value);
    setSelectAccount("");
    setAccount("");
  };

  const renderedOptions = optionAccount(
    dataAccount,
    selectAccount,
    setSelectAccount,
    setAccount,
    setIdAccount
  );

  const handleSelectOptionGet = () => {
    if (selectRadio === radioTel) return { telefone: selectAccount };
    if (selectRadio === radioEmail) return { email: selectAccount };
    else return { CPFCNPJ: selectAccount };
  };

  const handleGetAccount = async () => {
    setloadingAccount(true);
    setIsCreateAccount(false);
    try {
      const response = await salesService.getSales(handleSelectOptionGet());

      if (response?.status === 401) {
        setloadingAccount(false);
        setIsUnauthorized(true);
        setDataAccount([]);
        return;
      }

      if (!response || response === undefined) {
        setloadingAccount(false);
        setDataAccount([]);
        setIsCreateAccount(true);
        return;
      }

      if (
        response.filter !== undefined &&
        response.filter((r: any) => r.ehLead === false).length === 0
      ) {
        setloadingAccount(false);
        setIsCreateAccount(true);
        setDataAccount([]);
        return;
      }

      setEhLead(response[0]?.ehLead);
      setDataAccount(response);
      setloadingAccount(false);
      setIsCreateAccount(false);
    } catch (error: any) {
      console.log("errorHandleGetSales", error);
      setloadingAccount(false);
    }
  };

  const handleTypeRelationship = (typeRelationship: string) => {
    if (typeRelationship === "Compositor de Renda") return 1;
    if (typeRelationship === "Cônjuge") return 2;
    if (typeRelationship === "Representante Legal") return 3;
    if (typeRelationship === "Fiador") return 4;
    if (typeRelationship === "Comprador Principal") return 5;
    return null;
  };

  const handleCreateRelationship = async () => {
    setLoading(true);
    setSuccessCreateRalationship(false);
    setError(false);
    try {
      const response = await oportunityService.createRelationship({
        idRelacionamentoComprador,
        accountIdSalesforce: idAccount,
        contactIdSalesforceCorretorLogado: idCorretor,
        idAvaliacaoCreditoSalesforce,
        cpf,
        oportunidade: accessCreateOp.oportunityIdSalesforce,
        tipoRelacionamento: handleTypeRelationship(typeRelationship),
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        setError(true);
        setErrorMessage(response?.data);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      displayMessage({
        title: "Relacionamento criado com sucesso!",
        message:
          "O novo relacionamento foi criada e você já pode acessar para continuar sua venda.",
        titleButton: "ACESSAR RELACIONAMENTO",
        onClick: () => {},
      });
      setAccessRelationship({
        nomeConta: "",
        codigoRelacionamentoComComprador: "",
      });
      setIdSuccessCreateRalationship(response.idRelacionamentoComprador);
      setSuccessCreateRalationship(true);
      setLoading(false);
      setMandatoryCreateAccount(false);
      onBack();
    } catch (error) {
      setLoading(false);
      console.log("errorHandleUpdateRelationship", error);
    }
  };

  const messageErrors = () => {
    if (errorMessage) return errorMessage;
    if (ehLead)
      return "Não é possível criar relacionamento com o comprador com este e-mail.";

    return "Erro ao criar relacionamento com o comprador!";
  };

  const handleRequiredCpf = () => {
    if (cnpjCreateAccount) return false;
    else if (selectRadio === "CPFCNPJ" && lengthAccount.length > 18)
      return false;
    else return true;
  };

  React.useEffect(() => {
    if (ehLead) {
      setError(true);
    } else {
      setError(false);
    }
  }, [ehLead]);

  React.useEffect(() => {
    if (selectRadio === radioTel) {
      setDisabledSearchButton(phoneMask(selectAccount)?.length < 15);
    }
    if (selectRadio === radioCpf) {
      const isCnpjComplete = documentMask(selectAccount)?.length === 18;

      setDisabledSearchButton(
        documentMask(selectAccount)?.length < 14 ||
          (documentMask(selectAccount)?.length > 14 && !isCnpjComplete)
      );
    }
    if (selectRadio === radioEmail) {
      setDisabledSearchButton(!isEmail(selectAccount));
    }
  }, [selectAccount, selectRadio]);

  React.useEffect(() => {
    const isCnpjComplete = documentMask(cpf)?.length === 18;
    const validDocument =
      documentMask(cpf)?.length < 14 ||
      (documentMask(cpf)?.length > 14 && !isCnpjComplete);
    if (
      cnpjCreateAccount ||
      (selectRadio === "CPFCNPJ" && documentMask(lengthAccount).length > 18)
    ) {
      const isRequirement = !account || !typeRelationship;

      setRequire(isRequirement);
    } else {
      const isRequirement = !account || !typeRelationship || validDocument;

      setRequire(isRequirement);
    }
  }, [
    account,
    typeRelationship,
    cpf,
    cnpjCreateAccount,
    selectRadio,
    lengthAccount,
  ]);

  return (
    <>
      <MessageToast onOpen={error} message={messageErrors()} />

      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Notify
        onOpen={showCreateNewAccount}
        onClose={() => setShowCreateNewAccount(false)}
      >
        <Styled.AreaQuestions>
          <Styled.ContainerTitleNotify>
            <span>Criar nova conta</span>
          </Styled.ContainerTitleNotify>

          <QuestionsCreateAccountComponent
            setShowCreateNewAccount={setShowCreateNewAccount}
            navigation={() => setShowCreateUser(true)}
          />
        </Styled.AreaQuestions>
      </Notify>

      <Modal
        isCreateAccount
        onOpen={showCreateUser}
        title={`Criar conta: Cliente - Pessoa ${
          typeAccount === "Fisica" ? "Física" : "Jurídica"
        }`}
        onClose={() => {
          setShowCreateUser(false);
          setShowCreateNewAccount(false);
          setTypeAccount("");
        }}
      >
        {typeAccount === "Fisica" ? (
          <AccountsPhysicalComponents
            isCreateAccount
            setCpfCreateAccount={setCpf}
            setMandatoryCreateAccount={setMandatoryCreateAccount}
            setCnpjCreateAccount={() => {}}
            onBack={() => {
              setShowCreateUser(false);
              setShowCreateNewAccount(false);
              setTypeAccount("");
            }}
          />
        ) : (
          <AccountsLegalComponents
            isCreateAccount
            setMandatoryCreateAccount={setMandatoryCreateAccount}
            setCnpjCreateAccount={setCnpjCreateAccount}
            setCpfCreateAccount={() => {}}
            onBack={() => {
              setShowCreateUser(false);
              setShowCreateNewAccount(false);
              setTypeAccount("");
            }}
          />
        )}
      </Modal>

      <Styled.AreaSchedule>
        <Styled.ContainerSchedule>
          <Styled.ContainerHeaderWithButton>
            <Styled.TitleCreateRelationship>
              <h1>Informações do relacionamento</h1>
            </Styled.TitleCreateRelationship>

            <Styled.ContaineButtonsCreateHeader>
              <Button
                title={
                  <Styled.TitleButon>
                    <FiPlus />
                    CRIAR CONTA DE RELACIONAMENTO
                  </Styled.TitleButon>
                }
                onClick={() => setShowCreateNewAccount(true)}
                disabled={!isCreateAccount}
              />
            </Styled.ContaineButtonsCreateHeader>
          </Styled.ContainerHeaderWithButton>

          <Styled.ContainerInputsRelationship>
            <Styled.ContainerDisplayCreateRelationship>
              <Styled.TitleDisplay>
                <Styled.TitleDisplayRequired>*</Styled.TitleDisplayRequired>
                Conta: <p> (Necessário clicar na lupa para pesquisar)</p>
              </Styled.TitleDisplay>
              <Styled.ContainerRadioButtonSearch>
                <RadioSelect
                  label="Telefone"
                  checked={selectRadio === radioTel}
                  onChange={() => handleOptionChangeSearch(radioTel)}
                />
                <RadioSelect
                  label="E-mail"
                  checked={selectRadio === radioEmail}
                  onChange={() => handleOptionChangeSearch(radioEmail)}
                />
                <RadioSelect
                  label="CPF/CNPJ"
                  checked={selectRadio === radioCpf}
                  onChange={() => handleOptionChangeSearch(radioCpf)}
                />
              </Styled.ContainerRadioButtonSearch>

              <Styled.ContainerDisplayInput>
                <InputSelectSearchAccount
                  width="55%"
                  height="20%"
                  title=""
                  disabledButton={disabledSearchButton}
                  placeholder={handleSelectOptionPlaceholder()}
                  value={selectAccount}
                  onChangeInput={(e) => {
                    setSelectAccount(handleSelectOptionOnChange(e));
                    setIsCreateAccount(false);
                  }}
                  isSelected={account}
                  onChangeIcon={() => {
                    setAccount("");
                    setSelectAccount("");
                    setIsCreateAccount(false);
                  }}
                  onClickSearchIcon={handleGetAccount}
                  loading={loadingAccount}
                  maxLength={handleSelectOptionMaxLength()}
                >
                  {renderedOptions}
                </InputSelectSearchAccount>
              </Styled.ContainerDisplayInput>
            </Styled.ContainerDisplayCreateRelationship>

            <Styled.ContainerButton>
              <InputText
                readOnly
                label="Oportunidade:"
                name="Oportunidade"
                value={accessCreateOp.oportunidadeId}
              />
            </Styled.ContainerButton>
          </Styled.ContainerInputsRelationship>

          <Styled.ContainerInputsCreateRelationship>
            <InputSelect
              isRequirement
              widht="42%"
              title="Tipo de relacionamento:"
              label={typeRelationship || "Selecione uma opção"}
              isSelected={typeRelationship}
              options={optionTypeRelationship}
              selectedOption={typeRelationship}
              onChange={(e) => setTypeRelationship(e.currentTarget.value)}
            />

            {handleRequiredCpf() && (
              <InputText
                isRequirement
                label="CPF/CNPJ:"
                name="CPF"
                placeholder="Digite um CPF ou CNPJ"
                value={cpf}
                onChange={(e) => setCpf(documentMask(e.target.value))}
                maxLength={18}
              />
            )}
          </Styled.ContainerInputsCreateRelationship>
        </Styled.ContainerSchedule>
      </Styled.AreaSchedule>

      <Styled.DividerBoxInput />

      <Styled.ContainerFooterBox>
        <Styled.ContainerDuoMandatory>
          <span>(*) Campo de preenchimento obrigatório</span>
          <span>
            (<AiOutlineInfoCircle />) O campo apresenta formato ou dado inválido
          </span>
        </Styled.ContainerDuoMandatory>
        <Styled.ContainerButtonFooterEdit>
          <Button
            error
            title="CANCELAR"
            onClick={onBack}
            disabled={mandatoryCreateAccount}
          />

          <Button
            title={loading ? <Spinner /> : "SALVAR"}
            onClick={handleCreateRelationship}
            disabled={loading || require || ehLead}
          />
        </Styled.ContainerButtonFooterEdit>
      </Styled.ContainerFooterBox>
    </>
  );
};

export default CreateRalationshipComponent;
