import React from "react";
import * as Styled from "../styles";
import { capitalize } from "@helpers/format";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import { GiPartyPopper } from "react-icons/gi";

interface ISuccessAccessComponent {
  name: string;
  loadingLogin: boolean;
  handleLogin: () => void;
}

const SuccessAccessComponent = ({
  loadingLogin,
  name,
  handleLogin,
}: ISuccessAccessComponent) => {
  return (
    <Styled.BodyCurrentLogin>
      <Styled.ContainerSuccessAccessTitle>
        <GiPartyPopper />
        <span>Muito bom, {capitalize(name)}!</span>
      </Styled.ContainerSuccessAccessTitle>

      <Styled.ContainerSuccessAccessBody>
        <span>Sua senha foi alterada com sucesso!</span>

        <span>
          Agora você já pode fazer login e aproveitar todas as funcionalidades
          do portal Bora Vender.
        </span>

        <p>Mantenha sempre suas informações de perfil atualizadas.</p>
      </Styled.ContainerSuccessAccessBody>

      <Styled.ContainerButtonLogin>
        <Button
          title={loadingLogin ? <Spinner /> : "ACESSAR O PORTAL"}
          onClick={handleLogin}
          disabled={loadingLogin}
        />
      </Styled.ContainerButtonLogin>
    </Styled.BodyCurrentLogin>
  );
};

export default SuccessAccessComponent;
