import React from "react";
import * as Styled from "../styles";
import { BsCheck2Circle } from "react-icons/bs";
import { capitalize } from "@helpers/format";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";

interface ISuccessAccessComponent {
  name: string;
  loadingLogin: boolean;
  handleLogin: () => void;
}

const SuccessAccessComponent = ({
  loadingLogin,
  name,
  handleLogin,
}: ISuccessAccessComponent) => {
  return (
    <Styled.BodyCurrentLogin>
      <Styled.ContainerSuccessAccessTitle>
        <BsCheck2Circle />
        <span>Muito bom, {capitalize(name)}</span>
      </Styled.ContainerSuccessAccessTitle>

      <Styled.ContainerSuccessAccessBody>
        <span>Seu cadastro foi realizado com sucesso!</span>
        <span>
          Sua conta foi ativada e agora você já pode fazer login e aproveitar
          todas as funcionalidades do portal Bora Vender.
        </span>
        <text>Mantenha sempre suas informações de perfil atualizadas.</text>
      </Styled.ContainerSuccessAccessBody>

      <Styled.ContainerButtonLogin>
        <Button
          title={loadingLogin ? <Spinner /> : "ACESSAR O PORTAL"}
          onClick={handleLogin}
          disabled={loadingLogin}
        />
      </Styled.ContainerButtonLogin>
    </Styled.BodyCurrentLogin>
  );
};

export default SuccessAccessComponent;
