import React from "react";
import { LoginContext } from "@context/loginContext";
import firstAccessService from "@services/Login/firstAccessService";
import useKey from "@hook/useKey";
import { documentMask, formatDate } from "@helpers/format";
import { regexNumber, regexRepeat, seqPassword } from "@helpers/securePassword";
import * as Styled from "./styles";
import InputSlider from "@components/common/InputSlider";
import FirstLoginComponent from "./components/FirstLoginComponent";
import TwoFactorAuthnticationComponent from "./components/TwoFactorAuthnticationComponent";
import CreatePasswordComponent from "./components/CreatePasswordComponent";
import SuccessAccessComponent from "./components/SuccessAccessComponent";
import { IHashMapKeyString } from "src/@types/Login";

const FirstLogin = () => {
  useKey("Enter", handleEnter);
  useKey("NumpadEnter", handleEnter);
  const oldPassword = "Teste@123";
  const { signIn, loadingLogin } = React.useContext(LoginContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingResend, setLoadingResend] = React.useState<boolean>(false);
  const [hash, setHash] = React.useState<string>("FirstLogin");
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState("");
  const [date, setDate] = React.useState<string>("");
  const [document, setDocument] = React.useState<string>("");
  const [code, setCode] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [countSteps, setCountSteps] = React.useState<string>("");
  const [error, setError] = React.useState({
    errorConfirmCode: false,
    errorDocument: false,
    errorValidPassword: false,
    errorTitle: "",
    errorMessage: "",
  });
  const [validateInput, setValidateInput] = React.useState({
    number: false,
    length: false,
    sequecial: false,
    document: false,
    repeat: false,
    date: false,
  });

  const securePassword = (password: string) => {
    const length = !password || password.length >= 6;
    const DocumentEqualPassword =
      documentMask(document) !== documentMask(password);
    const dateEqueal = date !== password;

    setValidateInput({
      number: !password || regexNumber.test(password),
      length,
      repeat: regexRepeat.test(password),
      document: !password || DocumentEqualPassword,
      sequecial: seqPassword(password),
      date: !password || dateEqueal,
    });
  };
  function handleEnter() {
    if (hash === "FirstLogin") {
      handleSendCode();
    } else if (hash === "TwoFactorAuthntication") {
      handleConfirmationCode();
    } else if (hash === "CreatePassword") {
      handleCreatePassword();
    }
  }

  const handleSendCode = async () => {
    setLoading(true);

    try {
      const response = await firstAccessService.postFirstAccessDocument({
        cpf: document,
        senha: oldPassword,
      });

      if (
        response.retornoPrimeiroAcessoEnum === 2 &&
        response?.titulo === "Há divergência no cadastro"
      ) {
        setLoading(false);
        setError((prevState) => {
          return {
            ...prevState,
            errorDocument: true,
            errorTitle: response?.titulo,
            errorMessage: response?.mensagem,
          };
        });
        return;
      }

      if (response.data?.statusCode >= 400) {
        setLoading(false);
        setError((prevState) => {
          return {
            ...prevState,
            errorDocument: true,
            errorTitle: response?.titulo
              ? response?.titulo
              : response?.data?.titulo,
            errorMessage: response.data?.mensagem,
          };
        });
        return;
      }

      if (response === false) {
        setLoading(false);
        console.log("responseFalse", response);
        return;
      }

      setLoading(false);
      setName(response?.nomeCorretor);
      setDate(formatDate(response.dataNascimento));
      setEmail(response.email);
      setHash("TwoFactorAuthntication");
    } catch (error: any) {
      setLoading(false);
      console.log("errorPostFirstAccessDocument", error);
    }
  };

  const handleResendCode = async () => {
    setLoadingResend(true);
    try {
      const response = await firstAccessService.resendCode({
        email,
      });

      if (response.data?.statusCode >= 400) {
        setLoadingResend(false);
        return;
      }

      setLoadingResend(false);
    } catch (error: any) {
      setLoadingResend(false);
      console.log("errorRendCode", error);
    }
  };

  const handleConfirmationCode = async () => {
    setLoading(true);
    try {
      const response = await firstAccessService.sendCodeConfirmation({
        email,
        codigoVerificacao: code,
      });

      if (response.data?.statusCode >= 400 || response.data?.status >= 400) {
        setLoading(false);
        setError((prevState) => {
          return {
            ...prevState,
            errorConfirmCode: true,
          };
        });
        return;
      }

      setLoading(false);
      setHash("CreatePassword");
    } catch (error: any) {
      setLoading(false);
      console.log("errorSendCodeConfirmation", error);
    }
  };

  const handleCreatePassword = async () => {
    setLoading(true);
    try {
      const response = await firstAccessService.createNewPassword({
        email,
        SenhaAntiga: oldPassword,
        SenhaAtual: newPassword,
      });

      if (response.status >= 400) {
        setLoading(false);
        setError((prevState) => {
          return {
            ...prevState,
            errorDocument: true,
            errorMessage: "Um erro inesperado aconteceu",
          };
        });
        return;
      }

      setLoading(false);
      setHash("SuccessAccess");
    } catch (error: any) {
      setLoading(false);
      console.log("errorCreateNewPassword", error);
    }
  };

  const handleLogin = async () => {
    const data = { email, password: newPassword };

    await signIn(data);
  };

  const FirstLoginHashMap: IHashMapKeyString = {
    FirstLogin: (
      <FirstLoginComponent
        error={error}
        setError={setError}
        document={document}
        setDocument={setDocument}
        loading={loading}
        setCode={setCode}
        handleEnter={handleEnter}
      />
    ),
    TwoFactorAuthntication: (
      <TwoFactorAuthnticationComponent
        loading={loading}
        loadingResend={loadingResend}
        code={code}
        setCode={setCode}
        email={email}
        error={error}
        setError={setError}
        handleResendCode={handleResendCode}
      />
    ),
    CreatePassword: (
      <CreatePasswordComponent
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        loading={loading}
        validateInput={validateInput}
        handleCreatePassword={handleCreatePassword}
        error={error}
      />
    ),
    SuccessAccess: (
      <SuccessAccessComponent
        loadingLogin={loadingLogin}
        name={name}
        handleLogin={handleLogin}
      />
    ),
  };

  React.useEffect(() => {
    const isError =
      !newPassword ||
      !validateInput.number ||
      !validateInput.length ||
      validateInput.sequecial ||
      !validateInput.document ||
      validateInput.repeat ||
      !validateInput.date;

    setError((prevState) => {
      return {
        ...prevState,
        errorValidPassword: isError,
      };
    });
  }, [newPassword, validateInput]);

  React.useEffect(() => {
    securePassword(newPassword);
  }, [newPassword]);

  React.useEffect(() => {
    if (code.length === 6) {
      handleEnter();
    } else {
      return;
    }
  }, [code]);

  React.useEffect(() => {
    function mapHashToCountSteps(hash: string) {
      switch (hash) {
        case "FirstLogin":
          return "1";
        case "TwoFactorAuthentication":
          return "2";
        case "":
          return "3";
        default:
          return "4";
      }
    }

    const countSteps = mapHashToCountSteps(hash);
    setCountSteps(countSteps);
  }, [hash]);

  return (
    <Styled.ContainerCurrentLogin>
      {FirstLoginHashMap[hash]}

      <InputSlider
        onBack={() => setHash("FirstLogin")}
        value={countSteps}
        countSteps={countSteps}
        max={4}
        disabled={hash === "FirstLogin"}
      />
    </Styled.ContainerCurrentLogin>
  );
};

export default FirstLogin;
