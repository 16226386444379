import React from "react";
import Button from "@components/common/Button";
import Tabs from "@components/Login/Tabs";
import InputRadio from "@components/Login/Inputs/InputRadio";
import { LoginContext } from "@context/loginContext";
import CurrentLogin from "./Steps/CurrentLogin";
import FirstLogin from "./Steps/FirstLogin";
import Logo from "../../assets/logo.svg";
import * as Styled from "./styles";
import ForgotPassword from "../ForgotPassword";
import Helper from "@components/Login/Helper";
import { useLocation } from "react-router-dom";
import { IHashMapKeyString } from "src/@types/Login";
import { Collapse } from "@chakra-ui/react";

const Login = () => {
  const location = useLocation();
  const {
    hashLogin,
    setHashLogin,
    page,
    setErrorLogin,
    setPageHelper,
    errorForgotPassword,
  } = React.useContext(LoginContext);
  const FirstAccess = "FirstAccess";
  const OtherAccess = "CurrentLogin";
  const [hash, setHash] = React.useState<string>("PreLogin");
  const [selectRadio, setSelectRadio] = React.useState<string>("");
  const [disableRadio, setDisableRadio] = React.useState<boolean>(false);
  const [errorRadio, setErrorRadio] = React.useState<boolean>(false);

  const isRadioSelected = (value: string): boolean => selectRadio === value;
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectRadio(e.currentTarget.value);
    setErrorRadio(false);
  };

  const TabsHashMap: IHashMapKeyString = {
    CurrentLogin: <CurrentLogin />,
    FirstAccess: <FirstLogin />,
    "Esqueci minha senha": <ForgotPassword />,
  };

  const LoginHashMap: IHashMapKeyString = {
    PreLogin: (
      <>
        <Styled.BodyRightTitle>
          <span>Queremos te dar boas-vindas!</span>
        </Styled.BodyRightTitle>
        <Styled.BodyRightSubTitle>
          <span>
            Este é o <strong>Bora Vender,</strong> novo portal para consultoras
            e consultores imobiliários do grupo Direcional. Antes de seguir,
            selecione uma das opções abaixo:
          </span>
        </Styled.BodyRightSubTitle>

        <Styled.ContainerRadioButton>
          <div>
            <InputRadio
              title={"É meu primeiro acesso ao portal"}
              value={FirstAccess}
              onChange={handleRadioClick}
              checked={isRadioSelected(FirstAccess)}
              disabled={disableRadio && !isRadioSelected(FirstAccess)}
              error={errorRadio}
            />
            <Collapse in={isRadioSelected(FirstAccess)} animateOpacity>
              <Styled.ContainerRadioButtonBody>
                <span>
                  <strong>
                    Caso não tenha feito o primeiro acesso no Bora Vender,
                  </strong>{" "}
                  você precisará utilizar seu CPF e validar o e-mail e telefone
                  vinculados ao seu cadastro, além de criar uma senha.
                </span>
              </Styled.ContainerRadioButtonBody>
            </Collapse>
          </div>

          <div>
            <InputRadio
              title={"Já utilizei o portal outras vezes"}
              value={OtherAccess}
              onChange={handleRadioClick}
              checked={isRadioSelected(OtherAccess)}
              disabled={disableRadio && !isRadioSelected(OtherAccess)}
              error={errorRadio}
            />

            <Collapse in={isRadioSelected(OtherAccess)} animateOpacity>
              <Styled.ContainerRadioButtonBody>
                <span>
                  <strong>
                    Caso já tenha feito o primeiro acesso ao novo portal,
                  </strong>{" "}
                  é só utilizar o e-mail e senha que já foram validados na
                  primeira vez que você acessou.
                </span>
              </Styled.ContainerRadioButtonBody>
            </Collapse>
          </div>
        </Styled.ContainerRadioButton>

        <Styled.ContainerBox>
          <span>
            <strong>O antigo portal foi substituído pelo Bora Vender.</strong>{" "}
            Todos os consultores devem fazer o <strong>primeiro acesso</strong>{" "}
            para ativar sua conta e cadastrar uma nova senha.
          </span>
        </Styled.ContainerBox>

        <Styled.ContainerButton>
          <Button
            title={errorRadio ? "SELECIONE UMA OPÇÃO" : "CONTINUAR"}
            onClick={() => {
              if (!disableRadio) {
                setErrorRadio(true);
              } else {
                setHash("Login");
              }
            }}
            error={errorRadio}
            colorDisabled={errorRadio ? false : !selectRadio}
          />
        </Styled.ContainerButton>

        <Helper />
      </>
    ),
    Login: (
      <Styled.ContainerLogin>
        <Styled.ContainerTabs>
          <Tabs
            isActive={hashLogin}
            page={page}
            onClickLeft={() => {
              setErrorLogin(false);
              setHashLogin("CurrentLogin");
            }}
            onClickRight={() => {
              setErrorLogin(false);
              setHashLogin("FirstAccess");
            }}
            childrean={
              <Styled.AreaLogin>
                {TabsHashMap[page || hashLogin]}
              </Styled.AreaLogin>
            }
          />
        </Styled.ContainerTabs>
      </Styled.ContainerLogin>
    ),
  };

  React.useEffect(() => {
    if (errorForgotPassword) {
      setHashLogin("FirstAccess");
    }
  }, [errorForgotPassword]);

  React.useEffect(() => {
    setPageHelper(hashLogin);
    if (location.state?.message) {
      setHash("Login");
    }
  }, [hashLogin, location.state?.message, setPageHelper]);

  React.useEffect(() => {
    setHashLogin(location.state?.message ? "CurrentLogin" : selectRadio);
  }, [location.state, selectRadio]);

  React.useEffect(() => {
    if (!isRadioSelected(FirstAccess) && !isRadioSelected(OtherAccess)) {
      setDisableRadio(false);
    } else {
      setDisableRadio(true);
    }
  }, [isRadioSelected]);

  return (
    <Styled.Area>
      <Styled.Container>
        <Styled.ContainerLeft>
          <img
            alt="background"
            src={require("../../assets/background-left.png")}
          />
        </Styled.ContainerLeft>

        <Styled.ContainerRight>
          <Styled.AreaRight>
            <Styled.ContainerRightLogo>
              <img src={Logo} alt="Logo" />
            </Styled.ContainerRightLogo>

            <Styled.BodyRight>{LoginHashMap[hash]}</Styled.BodyRight>
          </Styled.AreaRight>
        </Styled.ContainerRight>
      </Styled.Container>
    </Styled.Area>
  );
};

export default Login;
