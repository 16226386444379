import React from "react";
import * as Styled from "../stylesStepTab";
import { TiInfoOutline } from "react-icons/ti";
import { FiEdit, FiPlus } from "react-icons/fi";
import { BsPatchCheck, BsClockHistory, BsBank } from "react-icons/bs";
import Button from "@components/common/Button";
import OportunityService from "@services/Oportunity/OportunityService";
import Spinner from "@components/common/Spinner";
import { OportunityContext } from "@context/oportunityContex";
import { ICreditRatingComponent } from "src/@types/Sales";

const CreditRatingComponent = ({
  index,
  indexFirstTab,
  navigateCreateCreditRating,
  successCreateCreditRating,
  setHashCreateCreditRating,
  updateData,
}: ICreditRatingComponent) => {
  const {
    faseOp,
    setAccessCreditRating,
    accessCreateOp,
    setStatusCreditRating,
  } = React.useContext(OportunityContext);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const { setAllowSendToAnalisys } = React.useContext(OportunityContext);

  const filteredRows = React.useMemo(() => {
    if (!data) return [];
    return data;
  }, [data]);

  const handleFormatStatus = (value: string): any => {
    if (!value) return "";
    if (value?.toLocaleLowerCase().includes("aprovad")) return "Aprovada";
    if (value?.toLocaleLowerCase().includes("reprovad")) return "Reprovada";
    if (value?.toLocaleLowerCase().includes("lise")) return "Em análise";
    if (value?.toLocaleLowerCase().includes("rascun")) return "Rascunho";
  };
  const handleFormatStatusIcon = (value: string) => {
    if (!value) return null;
    if (value?.toLocaleLowerCase().includes("aprovad")) return <BsPatchCheck />;
    if (value?.toLocaleLowerCase().includes("reprovad"))
      return <TiInfoOutline />;
    if (value?.toLocaleLowerCase().includes("lise")) return <BsClockHistory />;
    if (value?.toLocaleLowerCase().includes("rascun")) return <FiEdit />;
  };

  const handleGetCreditRating = async () => {
    setLoading(true);
    try {
      const response = await OportunityService.getCreditRating({
        OpportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
      });

      if (response.status === 401) {
        setLoading(false);
        return;
      }

      setData(response);
      if (
        typeof response !== "string" &&
        response.find((d: any) => d.status?.toLocaleLowerCase()?.includes("aprovad")) !== undefined
      ) {
        setAllowSendToAnalisys(false);
      } else {
        setAllowSendToAnalisys(true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("ErrorHandleGetCreditRating", error);
    }
  };

  React.useEffect(() => {
    if (index === 2 && indexFirstTab === 1) {
      handleGetCreditRating();
    }
    if (successCreateCreditRating) {
      handleGetCreditRating();
    }
  }, [
    index,
    accessCreateOp,
    indexFirstTab,
    successCreateCreditRating,
    updateData,
  ]);

  return (
    <Styled.ContainerHistoricSchedule>
      <Styled.ContainerHeader>
        <Styled.ContaineButtonsHeader>
          <Button
            title={
              <Styled.TitleButon>
                <FiPlus />
                NOVA AVALIAÇÂO
              </Styled.TitleButon>
            }
            onClick={navigateCreateCreditRating}
            disabled={faseOp}
          />
        </Styled.ContaineButtonsHeader>
      </Styled.ContainerHeader>

      {loading ? (
        <Styled.ContainerTodoHistoricLoading>
          <Spinner />
        </Styled.ContainerTodoHistoricLoading>
      ) : (
        <>
          <Styled.ContainerTitleCreditRating>
            {filteredRows.length === 0 ? (
              <Styled.ContainerTodoHistoricEmpty>
                <span>Não há avaliação nesta oportunidade.</span>
              </Styled.ContainerTodoHistoricEmpty>
            ) : (
              <>
                <Styled.TitleLabelHistoricRatingRightLeft>
                  Avaliação de crédito:
                </Styled.TitleLabelHistoricRatingRightLeft>
                <Styled.TitleLabelHistoricCreditRatingRight>
                  Status
                </Styled.TitleLabelHistoricCreditRatingRight>
                <Styled.TitleLabelDataHistoricCreditRatingRight>
                  Data de avaliação:
                </Styled.TitleLabelDataHistoricCreditRatingRight>
              </>
            )}
          </Styled.ContainerTitleCreditRating>

          <Styled.ContainerTodoHistoric>
            {filteredRows?.map(
              ({
                idAvaliacaoCreditoSalesforce,
                status,
                nome,
                fid,
                banco,
                dataCriacao,
              }) => (
                <Styled.ContainerScheduleSeeSchedule
                  key={idAvaliacaoCreditoSalesforce}
                >
                  <Styled.ContainerLeftCreditRating>
                    <Styled.ContainerIconHistoricCreditRating
                      status={handleFormatStatus(status)}
                    >
                      {handleFormatStatus(status) === "Rascunho" ? (
                        <FiEdit />
                      ) : (
                        <BsBank />
                      )}
                    </Styled.ContainerIconHistoricCreditRating>
                    <Styled.ContainerTextHistoric>
                      <Styled.TitleTextHistoric>
                        {nome}
                      </Styled.TitleTextHistoric>
                      <Styled.SubTitleTextHistoric>
                        FID: {fid} / Banco: {banco}
                      </Styled.SubTitleTextHistoric>
                    </Styled.ContainerTextHistoric>
                  </Styled.ContainerLeftCreditRating>

                  <Styled.ContainerDataCreditRating>
                    <Styled.ContainerIconTextCreditRating>
                      <Styled.AreaIconCreditRating>
                        <Styled.ContainerIconCreditRating
                          status={handleFormatStatus(status)}
                        >
                          <span>{handleFormatStatus(status)}</span>
                          {handleFormatStatusIcon(status)}
                        </Styled.ContainerIconCreditRating>
                      </Styled.AreaIconCreditRating>

                      <Styled.BoxDivider>
                        <Styled.DividerActivityCreditRating />
                      </Styled.BoxDivider>

                      <Styled.ContainerTexDataStatusCreditRating>
                        <Styled.SubTitleTextDataStatus>
                          {dataCriacao}
                        </Styled.SubTitleTextDataStatus>
                      </Styled.ContainerTexDataStatusCreditRating>
                    </Styled.ContainerIconTextCreditRating>

                    <Styled.ContainerButtonDataCreditRating>
                      <Button
                        title="VISUALIZAR"
                        onClick={() => {
                          setStatusCreditRating(handleFormatStatus(status));
                          setAccessCreditRating({
                            idAvaliacaoCreditoSalesforce,
                            nome,
                          });
                          setHashCreateCreditRating(
                            "ViewCreditRatingComponent"
                          );
                        }}
                      />
                    </Styled.ContainerButtonDataCreditRating>
                  </Styled.ContainerDataCreditRating>
                </Styled.ContainerScheduleSeeSchedule>
              )
            )}
          </Styled.ContainerTodoHistoric>
        </>
      )}
    </Styled.ContainerHistoricSchedule>
  );
};

export default CreditRatingComponent;
