import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

export const Area = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: ${theme.backgroundSecondary};
  overflow: hidden;

  @media ${device.mobileL} {
    background: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 78%;
  height: 97%;
  margin-top: 10px;

  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
  }
  @media ${device.tablet} {
    align-items: center;
    justify-content: center;
    width: 85%;
    min-height: 100vh;
  }
  @media ${device.laptop} {
    min-height: 97%;
    max-height: 85%;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

   @media ${device.tablet} {
    display: none;
     width: 0;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
  }
  @media ${device.tablet} {
    width: 80%;
    height: 90%;
    border-radius: 8px;
  }
`;

export const AreaRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;

  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
  }
  @media ${device.laptopL} {
    width: 58%;
  }
`;

export const ContainerRightLogo = styled.div`
  display: flex;
  width: 80%;
  height: 60px;
  margin-top: 10px;
  align-self: center;
  justify-content: center;
  object-fit: contain;

  @media ${device.mobileL} {
    margin-top: 5px;
  }
  @media ${device.laptopL} {
    margin-top: 30px;
  }
`;

export const BodyRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media ${device.laptopXL} {
    margin-top: 10px;
  }
`;

export const BodyCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 85%;
`;

export const ContainerButtonLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
  margin-top: 20px;

  @media ${device.tablet} {
    height: 20%;
  }
`;

export const ContainerSuccessAccessTitle = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  > span {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;
  }

  > svg {
    width: 25px;
    color: ${theme.primary};
  }
`;

export const ContainerSuccessAccessBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-bottom: 40px;

  > span {
    display: flex;
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    color: ${theme.text};
    font-weight: bold;
    align-items: center;
    margin-bottom: 20px;
  }

  > p {
    display: flex;
    width: 100%;
    font-size: 14px;
    color: ${theme.text};
    align-items: center;
  }
`;
