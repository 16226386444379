import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { BsPersonSquare } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  > span {
    width: 100%;
    height: fit-content;
    font-size: 11px;
    color: ${theme.text};
    margin: 3px 4px;
  }

  > text {
    width: 100%;
    height: fit-content;
    font-size: 11px;
    color: ${theme.error};
    margin: 3px 4px;
  }
`;

export const IconBsPersonSquare = styled(BsPersonSquare)`
  color: ${theme.primary};
  margin-right: 15px;
  margin-top: 5px;
  font-size: 20px;
  width: fit-content;
`;
export const IconAiOutlineCloseCircle = styled(AiOutlineCloseCircle)`
  color: ${theme.primary};
  margin-right: 15px;
  margin-top: 5px;
  font-size: 20px;
  width: fit-content;
  cursor: pointer;
`;
