import React from "react";
import { useToast } from "@chakra-ui/react";
import "react-toastify/dist/ReactToastify.css";
import Button from "@components/common/Button";
import Spinner from "../../common/Spinner";
import { UsuallyContext } from "@context/usuallyContex";
import * as Styled from "./styles";
import { LoginContext } from "@context/loginContext";
import { useNavigate } from "react-router-dom";

interface INotify {
  onClickSave: React.FormEventHandler<HTMLButtonElement>;
  onClickCancel: React.FormEventHandler<HTMLButtonElement>;
  onOpen: boolean;
  isUpdatePassword?: boolean;
}
interface IMessage {
  onClickSave: React.FormEventHandler<HTMLButtonElement>;
  onClickCancel: React.FormEventHandler<HTMLButtonElement>;
}

const Message = ({ onClickSave, onClickCancel }: IMessage) => {
  const { loadingUpdateProfile, isSuccess, emptyInput } =
    React.useContext(UsuallyContext);

  function titleMessage() {
    if (emptyInput.length > 0)
      return (
        <Styled.ContainerTitleError>
          <span>Campos com preenchimento obrigatório!</span>
        </Styled.ContainerTitleError>
      );
    if (emptyInput.length === 0)
      return (
        <Styled.ContainerTitle>
          <span>Quer salvar as alterações feitas?</span>
        </Styled.ContainerTitle>
      );
    if (isSuccess)
      return (
        <Styled.ContainerTitleSuccess>
          <span>Alteração salva com sucesso!</span>
        </Styled.ContainerTitleSuccess>
      );
  }

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => window.location.reload(), 2000);
    }
  }, [isSuccess]);

  return (
    <Styled.Container isSuccess={isSuccess}>
      <Styled.ContainerHeader>
        <span>Bora Vender</span>
      </Styled.ContainerHeader>
      {titleMessage()}

      {!isSuccess ? (
        <>
          <Styled.Obs>
            <span>
              {emptyInput.length > 0
                ? "É necessário prencher os campos sinalizados em vermelho para seguir com o seu cadastro."
                : `Se você clicar em "Cancelar", todas as alterações feitas vão ser descartadas.`}
            </span>
          </Styled.Obs>

          <Styled.ContainerButton>
            <Button
              error
              title="CANCELAR"
              onClick={onClickCancel}
              disabled={loadingUpdateProfile}
            />

            <Button
              title={!loadingUpdateProfile ? "SALVAR" : <Spinner />}
              disabled={loadingUpdateProfile || emptyInput.length > 0}
              onClick={onClickSave}
            />
          </Styled.ContainerButton>
        </>
      ) : (
        <Styled.ContainerLoading>
          <Spinner />
        </Styled.ContainerLoading>
      )}
    </Styled.Container>
  );
};

const MessageUpdatePassword = ({ onClickSave, onClickCancel }: IMessage) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setPageSidebar } = React.useContext(LoginContext);

  const handleNeedHelp = () => {
    navigate("/needHelpAuth");
    toast.closeAll();
    setPageSidebar("Preciso de ajuda");
    localStorage.setItem("@option", "Preciso de ajuda");
  };
  return (
    <Styled.ContainerUpdatePassword>
      <Styled.ContainerHeader>
        <span>Bora Vender</span>
      </Styled.ContainerHeader>

      <Styled.ContainerTitle>
        <span>Deseja alterar sua senha?</span>
      </Styled.ContainerTitle>

      <Styled.ObsUpdatePassword>
        <span>
          Você pode mudar ou redefinir sua senha por motivos de segurança ou
          quando não se lembrar dela. Se você tiver problemas para redefinir sua
          senha ou não conseguir fazer login na conta.
        </span>
      </Styled.ObsUpdatePassword>

      <Styled.ContainerButtonUpdatePassword>
        <Button error title="CANCELAR" onClick={onClickCancel} />

        <Button title={"CONFIRMAR"} onClick={onClickSave} />
      </Styled.ContainerButtonUpdatePassword>
    </Styled.ContainerUpdatePassword>
  );
};

const Notify = ({
  onClickSave,
  onClickCancel,
  onOpen,
  isUpdatePassword,
}: INotify) => {
  const toast = useToast();
  const toastIdRef: React.MutableRefObject<any> = React.useRef();

  function handleMessage() {
    if (isUpdatePassword)
      return (
        <MessageUpdatePassword
          onClickSave={onClickSave}
          onClickCancel={onClickCancel}
        />
      );
    return <Message onClickSave={onClickSave} onClickCancel={onClickCancel} />;
  }

  const displayMessage = () => {
    toastIdRef.current = toast({
      position: "top-right",
      icon: false,
      duration: null,
      status: undefined,
      render: () => handleMessage(),
      containerStyle: {
        maxWidth: isUpdatePassword ? "400px" : "370px",
        marginTop: "65px",
        boxShadow: "dark-lg",
        borderRadius: "8px",
        bg: "white",
      },
    });
  };

  React.useEffect(() => {
    if (onOpen) {
      displayMessage();
    } else {
      toast.close(toastIdRef.current);
    }
  }, [onOpen, toast]);

  return <div />;
};

export default Notify;
