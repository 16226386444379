import React from "react";
import * as Styled from "../styles";
import { optionEnterprise } from "./options";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionNumberRooms, optionNumberVacancy } from "@helpers/optionSelect";
import { ISectionproductsInterest } from "src/@types/Sales";

export const SectionProductsInterest = ({
  setIdEnterprise,
  selectMainEnterprises,
  setSelectMainEnterprises,
  dataEnterprises,
  mainEnterprises,
  setMainEnterprises,
  loadingEnterprises,
  getEnterprises,
  numberRooms,
  setNumberRooms,
  numberVacancy,
  setNumberVacancy,
}: ISectionproductsInterest) => {
  const renderedOptions = optionEnterprise(
    dataEnterprises,
    selectMainEnterprises,
    setSelectMainEnterprises,
    setMainEnterprises,
    setIdEnterprise
  );

  return (
    <>
      <Styled.ContainerBoxTitleInfo>
        <span>Produtos de interesse</span>
      </Styled.ContainerBoxTitleInfo>
      <Styled.ContainerBoxProductsInputs>
        <InputSelectSearch
          width="31%"
          height="15%"
          title="Empreendimento de interesse principal:"
          placeholder="Pesquise por empreendimento"
          value={selectMainEnterprises}
          onChangeInput={(e) => setSelectMainEnterprises(e.target.value)}
          isSelected={mainEnterprises}
          onChangeIcon={() => {
            setMainEnterprises("");
            setSelectMainEnterprises("");
          }}
          onClickSearchIcon={getEnterprises}
          loading={loadingEnterprises}
        >
          {renderedOptions}
        </InputSelectSearch>

        <InputSelect
          title="Número de quartos:"
          label={numberRooms || "Selecione uma opção"}
          isSelected={numberRooms}
          options={optionNumberRooms}
          selectedOption={numberRooms}
          onChange={(e) => setNumberRooms(e.currentTarget.value)}
        />
        <InputSelect
          title="Número de vagas:"
          label={numberVacancy || "Selecione uma opção"}
          isSelected={numberVacancy}
          options={optionNumberVacancy}
          selectedOption={numberVacancy}
          onChange={(e) => setNumberVacancy(e.currentTarget.value)}
        />
      </Styled.ContainerBoxProductsInputs>
    </>
  );
};
