import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { theme } from "src/styles/theme";

type Props = {
  dataProposalAnalysis: any[];
};

const titleHeaderTable = [
  { id: "#", name: "#" },
  { id: "Critério:", name: "Critério:" },
];

const TableProposalAnalysis = ({ dataProposalAnalysis }: Props) => {
  const handleAdjustWidth = (id: string) => {
    if (id === "#") return "6%";

    return undefined;
  };

  return (
    <Stack display={"flex"} flexDirection={"column"}>
      <Box display={"flex"}>
        {titleHeaderTable.map(({ id, name }) => (
          <Box key={id} display={"flex"} w={handleAdjustWidth(id)}>
            <Text fontSize={"10px"} color={theme.text}>
              {name}
            </Text>
          </Box>
        ))}
      </Box>

      {dataProposalAnalysis.length === 0 ? (
        <Box
          display={"flex"}
          bg={theme.backgroundGray}
          h={"40px"}
          borderRadius={"8px"}
          alignItems={"center"}
          pl={"8px"}
        >
          <Text fontSize={"11px"} fontWeight={"bold"}>
            Nenhum erro foi encontrado na proposta.
          </Text>
        </Box>
      ) : (
        <>
          {dataProposalAnalysis.map((message, index) => (
            <Box key={index} display={"flex"} gap={"4px"}>
              <Box display={"flex"} w="6%" alignItems={"center"}>
                <Box
                  display={"flex"}
                  w={"40px"}
                  h={"40px"}
                  borderRadius={"8px"}
                  bg={theme.error}
                  color={"white"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  textAlign={"center"}
                >
                  <Text fontSize={"15px"} fontWeight={"bold"}>
                    {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                  </Text>
                </Box>
              </Box>

              <Box
                display={"flex"}
                w="75%"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={"12px"} fontWeight={"bold"}>
                  {message}
                </Text>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Stack>
  );
};

export default TableProposalAnalysis;
