import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { device } from "../../../styles/variable";

interface ITabs {
  isActive: string;
  page?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Nav = styled.div`
  display: flex;
  width: 100%;
  height: 12%;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptopXL} {
    height: 8%;
  }
`;

export const TabsLeft = styled.button`
  display: flex;
  color: ${(props: ITabs) =>
    props.isActive === "CurrentLogin"
      ? `${theme.primary}`
      : `${theme.disable}`};
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const TabsRight = styled.button`
  display: flex;
  color: ${(props: ITabs) =>
    props.isActive === "FirstAccess" ? `${theme.primary}` : `${theme.disable}`};
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ContainerCenter = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  > svg {
    display: flex;
    font-size: 25px;
    width: fit-content;
    color: ${theme.disable};
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const TabsCenter = styled.button`
  display: flex;
  color: ${theme.primary};
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  background: none;
  border: none;
  outline: none;
  opacity: ${(props: ITabs) => props.isActive === props.page && 1};
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  @media ${device.tablet} {
    margin-top: 30px;
  }
`;

export const Slider = styled.div`
  width: ${(props: ITabs) => (props.page ? "60%" : "50%")};
  height: 6px;
  align-self: ${(props: ITabs) => props.page && "center"};
  background: ${theme.primary};
  border-radius: 8px;
  transition: 0.4s;
  transform: ${(props: ITabs) => {
    if (props.isActive === "CurrentLogin") return "translateX(0px)";
    if (props.page === "Esqueci minha senha") return "null";
    return "translateX(100%)";
  }};
`;
