import { Link } from "react-router-dom";
import * as Styled from "../styles";

export const messageBox = (message: any) => {
  if (message) {
    if (
      message ===
      "Clique no link Precisa de ajuda? para enviar uma solicitação para o nosso time de Recursos Humanos liberar seu acesso no Bora Vender."
    ) {
      const messageBody = (
        <>
          Clique no link <Link to="/needHelp">Precisa de ajuda?</Link> para
          enviar uma solicitação...
        </>
      );

      return messageBody;
    } else if (
      message ===
      "Esse CPF não está vinculado a nossa base de dados. Se deseja fazer parte do time de vendas, entre em contato pelo link "
    ) {
      const messageBody = (
        <>
          Esse CPF não está vinculado a nossa base de dados. Se deseja fazer
          parte do time de vendas, entre em contato pelo link{" "}
          <Link to="/needHelp">Precisa de ajuda?</Link>
        </>
      );

      return messageBody;
    } else if (
      message ===
      "Caso esteja com dificuldade para acessar o portal, entre em contato pelo link "
    ) {
      const messageBody = (
        <>
          Caso esteja com dificuldade para acessar o portal, entre em contato
          pelo link <Link to="/needHelp">Precisa de ajuda?</Link>
        </>
      );

      return messageBody;
    } else if (
      message ===
      "Se deseja fazer parte do time de vendas, entre em contato pelo link "
    ) {
      const messageBody = (
        <>
          Se deseja fazer parte do time de vendas, entre em contato pelo link{" "}
          <Link to="/needHelp">Precisa de ajuda?</Link>
        </>
      );

      return messageBody;
    } else {
      const messageBody = <Styled.Text>{message}</Styled.Text>;

      return messageBody;
    }
  } else {
    const messageBody = (
      <>
        Caso não lembre seus dados ou tenha alguma dúvida entre em contato pelo{" "}
        <Link to="/needHelp">Precisa de ajuda?</Link>
      </>
    );

    return messageBody;
  }
};
