import React from "react";

export const useCustomFormikEdit = (formik: any, initialValues: any) => {
  const [isEdited, setIsEdited] = React.useState(false);
  const [updates, setUpdates] = React.useState<string[]>([]);

  React.useEffect(() => {
    const fieldsToCheck = [
      "sexo",
      "schooling",
      "dateBt",
      "estadoCivil",
      "nacionality",
      "naturalness",
      "pis",
      "uf",
      "telephone",
      "cell",
      "cell2",
      "otherTell",
      "fatherName",
      "spouse",
      "motherName",
      "quantitySoon",
      "bank",
      "agency",
      "typeAccount",
      "account",
      "digiteAccount",
      "typeCorretor",
      "commissionBilling",
      "cnpj",
      "addressName",
      "registerType",
      "number",
      "neighborhood",
      "logradouro",
      "state",
      "complement",
      "city",
      "cep",
    ];

    const checkFormikEdit = () => {
      const newUpdates: string[] = [];

      fieldsToCheck.forEach((field) => {
        const fieldValue = formik.getFieldProps(field).value;
        const initialValue = initialValues[field];

        if (fieldValue !== initialValue && fieldValue !== null) {
          newUpdates.push(field);
        }
      });

      if (newUpdates.length > 0) {
        setIsEdited(true);
        setUpdates(newUpdates);
      } else {
        setIsEdited(false);
        setUpdates([]);
      }
    };

    checkFormikEdit();
  }, [formik.getFieldProps]);

  return { isEdited, updates };
};
