import React from "react";
import * as Styled from "../styles";
import InputSearch from "@components/Enterprises/Inputs/InputSearch";
import { AiOutlineCloseCircle } from "react-icons/ai";

interface IShowAllUnityComponent {
  title: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  searchResults: any[];
}

const ShowAllOptionComponent = ({
  title,
  search,
  setSearch,
  setShow,
  searchResults,
}: IShowAllUnityComponent) => {
  return (
    <>
      <Styled.ContainerTitleSection>
        <div>
          <Styled.TitleSection>{title}</Styled.TitleSection>
          <AiOutlineCloseCircle onClick={() => setShow(false)} />
        </div>
      </Styled.ContainerTitleSection>

      <Styled.ContainerSearchUnityModal>
        <Styled.TitleDisplay>Pesquisa Relacionada:</Styled.TitleDisplay>
        <Styled.ContainerDisplayInput>
          <InputSearch
            placeholder="Pesquisar por unidade do empreendimento"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Styled.ContainerDisplayInput>
      </Styled.ContainerSearchUnityModal>

      {search !== "" && (
        <Styled.ContainerSearchMessageUnityModal>
          <Styled.TitleSection>
            Pesquisa por unidade do empreendimento
          </Styled.TitleSection>

          {searchResults?.length !== 0 ? (
            <Styled.BodySearchUnityModalMessage>
              De acordo com a pesquisa, encontramos o seguinte resultado:
            </Styled.BodySearchUnityModalMessage>
          ) : (
            <>
              <Styled.BodySearchUnityModalMessageError>
                Sua pesquisa por {search} não correspondeu a nenhum resultado.
              </Styled.BodySearchUnityModalMessageError>

              <Styled.BodySearchUnityModalMessage>
                Se preferir, pesquise novamente utilizando as seguintes
                sugestões:
              </Styled.BodySearchUnityModalMessage>
              <Styled.BodySearchUnityModalMessage>
                - Certifique-se de que todas as palavras estejam digitadas
                corretamente;
              </Styled.BodySearchUnityModalMessage>
              <Styled.BodySearchUnityModalMessage>
                - Tente escolher palavras-chave diferentes das que você digitou;
              </Styled.BodySearchUnityModalMessage>
              <Styled.BodySearchUnityModalMessage>
                - Use termos mais genéricos, ou seja, com um conceito mais
                amplo.
              </Styled.BodySearchUnityModalMessage>
            </>
          )}
        </Styled.ContainerSearchMessageUnityModal>
      )}
    </>
  );
};

export default ShowAllOptionComponent;
