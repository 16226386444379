import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import Button from "@components/common/Button";

interface IAlert {
  onOpen: boolean;
  title: string;
  titleButton?: string;
  description: string;
  onClick?: any;
  disabledButton?: boolean;
}

const Alert = ({
  onOpen,
  title,
  titleButton,
  description,
  onClick,
  disabledButton,
}: IAlert) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={onOpen}
      onClose={onClick}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={theme.error}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>{description}</ModalBody>

        {titleButton && (
          <ModalFooter>
            <Button
              title={titleButton}
              onClick={onClick}
              disabled={disabledButton}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Alert;
