import React from "react";
import * as Styled from "../stylesView&Create";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { OportunityContext } from "@context/oportunityContex";
import { AiOutlineInfoCircle } from "react-icons/ai";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import enterprisesService from "@services/Enterprises/enterprisesService";
import {
  optionCreditRating,
  optionEnterprise,
  optionUnity,
} from "../../components/options";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import unityService from "@services/Unity/unityService";
import quotesService from "@services/Quotes/QuotesService";
import oportunityService from "@services/Oportunity/OportunityService";
import InputSelectWithService from "@components/Sales/Inputs/InputSelectWithService";
import { CloseButton, useToast } from "@chakra-ui/react";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { ICreateQuotesComponent } from "src/@types/Sales";

const CreateQuotesComponent = ({
  onBack,
  setSuccessCreateQuote,
  setIsEdit,
  setHash,
}: ICreateQuotesComponent) => {
  const toast = useToast();
  const displayMessageError = useMessageErrorToast();
  const regionalUser: any = localStorage.getItem("@regional");
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const { accessCreateOp, accessQuote, setAccessQuote } =
    React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [require, setRequire] = React.useState(false);

  const [dataEnterprises, setDataEnterprises] = React.useState([]);
  const [loadingEnterprises, setLoadingEnterprises] = React.useState(false);
  const [idEnterprises, setIdEnterprises] = React.useState("");
  const [enterprises, setEnterprises] = React.useState("");
  const [selectEnterprises, setSelectEnterprises] = React.useState("");

  const [dataProduct, setDataProduct] = React.useState([]);
  const [loadingProduct, setLoadingProduct] = React.useState(false);
  const [idProduct, setIdProduct] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [selectProduct, setSelectProduct] = React.useState("");

  const [dataPaymentTerms, setDataPaymentTerms] = React.useState([]);
  const [dataDescription, setDataDescription] = React.useState([]);
  const [loadingPaymentTerms, setLoadingPaymentTerms] = React.useState(false);
  const [idPaymentTerms, setIdPaymentTerms] = React.useState("");
  const [paymentTerms, setPaymentTerms] = React.useState("");

  const [dataCreditRating, setDataCreditRating] = React.useState([]);
  const [loadingCreditRating, setLoadingCreditRating] = React.useState(false);
  const [idCreditRating, setIdCreditRating] = React.useState("");
  const [creditRating, setCreditRating] = React.useState("");
  const [selectCreditRating, setSelectCreditRating] = React.useState("");

  const renderedOptions = optionEnterprise(
    dataEnterprises,
    selectEnterprises,
    setSelectEnterprises,
    setEnterprises,
    setIdEnterprises
  );

  const renderedOptionsUnity = optionUnity(
    dataProduct,
    selectProduct,
    setSelectProduct,
    setProduct,
    setIdProduct
  );

  const renderedOptionsCreditRating = optionCreditRating(
    dataCreditRating,
    selectCreditRating,
    setSelectCreditRating,
    setCreditRating,
    setIdCreditRating
  );

  const handleGetEnterprises = async () => {
    setLoadingEnterprises(true);

    try {
      const response = await enterprisesService.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprises(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprises(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprises(false);
    } catch (error) {
      setLoadingEnterprises(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const handleGetUnity = async () => {
    setLoadingProduct(true);
    try {
      const response = await unityService.getUnity({
        idEmpreendimentoSales: idEnterprises,
        somenteDisponiveis: true,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingProduct(false);
        console.log("status 400", response);
        return;
      }

      if (response?.status === 401) {
        setLoadingProduct(false);
        console.log("status 401", response);
        return;
      }

      const dataArray: any = Object.values(response);

      setLoadingProduct(false);
      setDataProduct(dataArray ? dataArray : []);
    } catch (error: any) {
      setLoadingProduct(false);
      console.log("errorHandleGetUnity", error);
    }
  };

  const handleGetConditionPayment = async () => {
    setLoadingPaymentTerms(true);
    try {
      const response = await quotesService.getConditionPayment({
        empreendimentoId: idEnterprises,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingPaymentTerms(false);
        console.log("status 400", response);
        return;
      }

      if (response?.status === 401) {
        setLoadingPaymentTerms(false);
        console.log("status 401", response);
        setIsUnauthorized(true);
        return;
      }

      setDataDescription(
        response?.map((data: any) => {
          return data?.descricao;
        })
      );
      setLoadingPaymentTerms(false);
      setDataPaymentTerms(response ? response : []);
    } catch (error: any) {
      setLoadingPaymentTerms(false);
      console.log("errorHandleGetConditionPayment", error);
    }
  };

  const handleGetCreditRating = async () => {
    setLoadingCreditRating(true);
    try {
      const response = await oportunityService.getCreditRating({
        OpportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        somenteAprovadas: true,
      });

      if (response.status === 401) {
        setLoadingCreditRating(false);
        return;
      }

      setDataCreditRating(response ? response : []);
      setLoadingCreditRating(false);
    } catch (error) {
      setLoadingCreditRating(false);
      console.log("ErrorHandleGetCreditRating", error);
    }
  };

  const displayMessage = ({ message }: { message: string }) => {
    toast.closeAll();
    toast({
      position: "top-right",
      status: undefined,
      duration: 6000,
      render: () => (
        <Styled.ContainerToastButton>
          <Styled.ContainerHeaderToast>
            <span>Bora Vender</span>
            <CloseButton onClick={() => toast.closeAll()} />
          </Styled.ContainerHeaderToast>

          <Styled.ContainerMessageToast>
            <span>{message}</span>
          </Styled.ContainerMessageToast>

          <Styled.ContainerButtonToast>
            <Button
              title={"ACESSAR COTAÇÃO"}
              onClick={() => {
                setSuccessCreateQuote(false);
                setHash("ViewQuotesComponent");
              }}
            />
          </Styled.ContainerButtonToast>
        </Styled.ContainerToastButton>
      ),
      containerStyle: {
        maxWidth: "350px",
        marginTop: "65px",
        boxShadow: "dark-lg",
        borderRadius: "8px",
        bg: "white",
      },
    });
  };

  const handleCreateQuote = async () => {
    setLoading(true);
    setSuccessCreateQuote(false);
    try {
      const response = await quotesService.createQuote({
        opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        idAvaliacaoCreditoSalesforce: idCreditRating,
        idCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
        idEmpreendimentoSales: idEnterprises,
        contactIdSalesforceCorretorLogado: idCorretor,
        nomeDaCotacao: accessQuote.nomeDaCotacao,
        condicaoPagamentoMatrizSelecionada: idPaymentTerms,
        produto: idProduct,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({
          message: response.data ? response.data : "Erro ao criar cotação!",
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      displayMessage({ message: "Cotação criada com sucesso!" });
      setSuccessCreateQuote(true);
      setIsEdit(false);
      setAccessQuote({
        idCotacaoSalesforce: response?.idCotacaoSalesforce,
        nomeDaCotacao: response?.nomeDaCotacao,
      });
      onBack();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("errorHandleCreateSchedule", error);
    }
  };

  React.useEffect(() => {
    const isRequirement = !selectEnterprises || !selectProduct || !paymentTerms;

    setRequire(isRequirement);
  }, [paymentTerms, selectEnterprises, selectProduct]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Styled.ContainerSchedule>
        <Styled.TitleSchedule>Nova Cotação</Styled.TitleSchedule>

        <Styled.ContainerInputsSchedule>
          <InputSelectSearch
            isRequirement
            width="60%"
            height="36%"
            title="Empreendimento:"
            placeholder="Pesquise por empreendimento"
            value={selectEnterprises}
            onChangeInput={(e) => setSelectEnterprises(e.target.value)}
            isSelected={enterprises}
            onChangeIcon={() => {
              setEnterprises("");
              setSelectEnterprises("");
              setProduct("");
              setSelectProduct("");
              setPaymentTerms("");
            }}
            onClickSearchIcon={handleGetEnterprises}
            loading={loadingEnterprises}
          >
            {renderedOptions}
          </InputSelectSearch>
        </Styled.ContainerInputsSchedule>

        <Styled.ContainerInputsSchedule>
          <InputSelectSearch
            isRequirement
            width="60%"
            height="36%"
            title="Unidade:"
            placeholder="Pesquise por unidade"
            value={selectProduct}
            onChangeInput={(e) => setSelectProduct(e.target.value)}
            isSelected={product}
            onChangeIcon={() => {
              setProduct("");
              setSelectProduct("");
            }}
            onClickSearchIcon={handleGetUnity}
            loading={loadingProduct}
            disabled={!selectEnterprises}
          >
            {renderedOptionsUnity}
          </InputSelectSearch>
        </Styled.ContainerInputsSchedule>

        <Styled.ContainerInputsSchedule>
          <InputSelectWithService
            isRequirement
            widht="60%"
            height="36%"
            title="Condição de Pagamento:"
            label={paymentTerms || "Selecione uma opção"}
            isSelected={paymentTerms}
            options={dataPaymentTerms}
            selectedOption={paymentTerms}
            onClick={() => handleGetConditionPayment()}
            onChange={(e) => setPaymentTerms(e.target.value)}
            setId={setIdPaymentTerms}
            loading={loadingPaymentTerms}
            disabled={!selectEnterprises}
            description={dataDescription}
          />
        </Styled.ContainerInputsSchedule>

        <Styled.ContainerInputsSchedule>
          <InputSelectSearch
            width="60%"
            height="36%"
            title="Avaliação de crédito:"
            placeholder="Pesquise por avaliação"
            value={selectCreditRating}
            onChangeInput={(e) => setSelectCreditRating(e.target.value)}
            isSelected={creditRating}
            onChangeIcon={() => {
              setCreditRating("");
              setSelectCreditRating("");
            }}
            onClickSearchIcon={handleGetCreditRating}
            loading={loadingCreditRating}
          >
            {renderedOptionsCreditRating}
          </InputSelectSearch>
        </Styled.ContainerInputsSchedule>
      </Styled.ContainerSchedule>

      <Styled.DividerBoxInput />

      <Styled.ContainerFooterBox>
        <Styled.ContainerDuoMandatory>
          <span>(*) Campo de preenchimento obrigatório</span>
          <span>
            (<AiOutlineInfoCircle />) O campo apresenta formato ou dado inválido
          </span>
        </Styled.ContainerDuoMandatory>
        <Styled.ContainerButtonFooter>
          <Button error title="CANCELAR" onClick={onBack} />

          <Button
            title={loading ? <Spinner /> : "SALVAR"}
            onClick={handleCreateQuote}
            disabled={require || loading}
          />
        </Styled.ContainerButtonFooter>
      </Styled.ContainerFooterBox>
    </>
  );
};

export default CreateQuotesComponent;
