import axios from "axios";
import api from "../api";

interface ILogin {
  email: string;
  senha: string;
}
interface IRefreshToken {
  refreshToken: string;
}
interface IDeleteUser {
  access_token: string;
}

class LoginService {
  async postLogin({ email, senha }: ILogin) {
    try {
      const response = await api.post("/autenticacao/login", {
        email,
        senha,
      });
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async postRefreshToken({ refreshToken }: IRefreshToken) {
    try {
      const response = await api.post("/autenticacao/refresh-token", {
        refreshToken,
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async deleteUser({ access_token }: IDeleteUser) {
    try {
      const response = await api.delete(
        "/autenticacao/deletar-usuario-cognito",
        {
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
}

const loginServiceInstance = new LoginService();

export default loginServiceInstance;
