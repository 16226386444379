import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React, { FormEvent, useEffect } from "react";
import { theme } from "src/styles/theme";
import * as Styled from "../Steps/stylesStepTab";
import InputText from "@components/Sales/Inputs/InputText";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import { OportunityContext } from "@context/oportunityContex";
import { optionCreditRating } from "./options";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import { filterDecimalNumber } from "./AccordionCreditRatingComponent";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import { IAccordionQuotesComponent } from "src/@types/Sales";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";

const AccordionQuotesComponent = ({
  formik,
  edit,
  creditRating,
  setCreditRating,
  selectCreditRating,
  selectCreditRatingId,
  setSelectCreditRating,
  setSelectCreditRatingId,
  getCreditRating,
  loadingCreditRating,
  dataCreditRating,
  disabledIncome,
  bonusAdimplencia,
}: IAccordionQuotesComponent) => {
  const displayMessageError = useMessageErrorToast();
  const { paymentCondition, discount, setDiscount } =
    React.useContext(OportunityContext);

  const isAssociative =
    paymentCondition?.toLowerCase()?.includes("associativ") ||
    paymentCondition?.toLowerCase()?.includes("sfh");

  const handleChangeMoneyDiscount = (
    event: React.ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>
  ) => {
    let inputValue = (event.target as HTMLInputElement).value;

    inputValue = inputValue.replace(/\D/g, "");

    const numericValue = Number(inputValue) / 100;

    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue);

    return { numericValue, formattedValue };
  };

  const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    return inputValue;
  };

  const formatMoney = (valor: any) => {
    const newValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
    return newValue;
  };

  const creditRatingOptions = optionCreditRating(
    dataCreditRating,
    selectCreditRating,
    setSelectCreditRating,
    setCreditRating,
    async (id) => {
      setSelectCreditRatingId(id);

      const creditRating = await oportunityServiceInstance.getCreditRatingById({
        IdAvaliacaoCreditoSalesforce: id as string,
      });
      console.log(creditRating);

      if (creditRating.idAvaliacaoCreditoSalesforce === id) {
        formik.setFieldValue(
          "fgtsCalculated",
          formatMoney(creditRating.fgtsApurado)
        );
        formik.setFieldValue(
          "subsidyAssessed",
          formatMoney(creditRating.valorDeSubsidio)
        );
        formik.setFieldValue(
          "financingCreditAssessment",
          formatMoney(creditRating.valorDoFinanciamento)
        );
        formik.setFieldValue(
          "calculatedIncome",
          formatMoney(creditRating.rendaApurada)
        );
        formik.setFieldValue("olderAge", creditRating.idadeMaisVelho);
        formik.setFieldValue("bankFee", creditRating.taxaBanco);
      }
    }
  );

  useEffect(() => {
    setDiscount(formik.values.discount);
  }, [formik.values.discount]);

  return (
    <Accordion allowMultiple>
      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Informações da cotação
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputsCreditRating>
            <InputText
              isRequirement
              readOnly
              label="Nome da conta:"
              name="nameAccount"
              value={formik.values.nameAccount}
            />
            <InputText
              isRequirement
              readOnly
              label="Data da proposta:"
              name="proposalDate"
              value={formik.values.proposalDate}
            />
            <InputText
              isRequirement
              readOnly
              label="Condição de pagamento matriz selecionada:"
              name="paymentTerms"
              value={formik.values.paymentTerms}
            />
            <InputText
              readOnly
              label="Produto:"
              name="product"
              value={formik.values.product}
            />

            <InputText
              isRequirement
              readOnly
              label="Status:"
              name="status"
              value={formik.values.status}
            />
            {/* 
            <InputText
              readOnly
              label="Comentário:"
              name="comment"
              value={formik.values.comment}
            /> */}

            {!isAssociative && (
              <InputSelectSearch
                disabled={!edit}
                width="24%"
                height="15%"
                title="Avaliação de crédito:"
                placeholder="Pesquise por avaliação"
                value={selectCreditRating}
                isSelected={selectCreditRatingId}
                searchOnClick
                onChangeInput={(e) => setSelectCreditRating(e.target.value)}
                onChangeIcon={() => {
                  setSelectCreditRating("");
                  setCreditRating("");
                  setSelectCreditRatingId("");
                }}
                onClickSearchIcon={getCreditRating}
                loading={loadingCreditRating}
              >
                {creditRatingOptions}
              </InputSelectSearch>
            )}
          </Styled.ContainerBoxInputsCreditRating>

          <Styled.ContainerBoxInputsObservations>
            <InputTextArea
              readOnly={!edit}
              label="Observações:"
              placeholder=""
              name="observations"
              value={formik.values.observations}
              onChange={formik.handleChange}
              maxLenght={200}
            />
          </Styled.ContainerBoxInputsObservations>
        </AccordionPanel>
      </AccordionItem>

      {isAssociative && (
        <AccordionItem mt={"10px"} borderRadius={8}>
          <AccordionButton bg={theme.disable100} borderRadius={8}>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontWeight={"bold"}
              fontSize={"18px"}
            >
              Identificação da Avaliação de Crédito
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <Styled.ContainerBoxInputsCreditRating>
              <InputSelectSearch
                disabled={!edit}
                width="24%"
                height="15%"
                title="Avaliação de crédito:"
                placeholder="Pesquise por avaliação"
                value={selectCreditRating}
                searchOnClick
                onChangeInput={(e) => setSelectCreditRating(e.target.value)}
                isSelected={selectCreditRatingId}
                onChangeIcon={() => {
                  setSelectCreditRating("");
                  setCreditRating("");
                  setSelectCreditRatingId("");
                }}
                onClickSearchIcon={getCreditRating}
                loading={loadingCreditRating}
              >
                {creditRatingOptions}
              </InputSelectSearch>

              <InputText
                readOnly
                label="FGTS apurado:"
                name="fgtsCalculated"
                value={formik.values.fgtsCalculated}
              />
              <InputText
                readOnly
                label="Subsídio avaliado:"
                name="subsidyAssessed"
                value={formik.values.subsidyAssessed}
              />
              <InputText
                readOnly
                label="Financiamento avaliação de crédito:"
                name="financingCreditAssessment"
                value={formik.values.financingCreditAssessment}
              />
              <InputText
                readOnly
                label="Renda apurada:"
                name="calculatedIncome"
                value={formik.values.calculatedIncome}
              />
              <InputText
                readOnly={!edit || !!creditRating || !!selectCreditRating}
                label="Idade mais velho:"
                name="olderAge"
                value={formik.values.olderAge}
                onChange={(e) => {
                  const newValue = handleChangeNumber(e);
                  formik.handleChange(e);
                  formik.setFieldValue("olderAge", newValue);
                }}
              />
              <InputText
                readOnly={!edit || !!creditRating || !!selectCreditRating}
                label="Taxa banco:"
                name="bankFee"
                value={formik.values.bankFee}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue(
                    "bankFee",
                    String(filterDecimalNumber(e.target.value))
                  );
                }}
              />
            </Styled.ContainerBoxInputsCreditRating>
          </AccordionPanel>
        </AccordionItem>
      )}

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton
          bg={
            disabledIncome && isAssociative ? theme.error50 : theme.disable100
          }
          _hover={{ bg: disabledIncome && isAssociative && theme.error50 }}
          borderRadius={8}
        >
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Valores
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputsCreditRating>
            {isAssociative && (
              <>
                <InputTextEdit
                  readOnly={!edit}
                  label="Renda:"
                  name="income"
                  value={formik.values.income}
                  onChange={(e) => {
                    const { formattedValue } = handleChangeMoneyDiscount(e);
                    formik.handleChange(e);
                    formik.setFieldValue("income", formattedValue);
                  }}
                  errorBorderColor={disabledIncome}
                />
                <InputText
                  readOnly
                  label="Valor Bônus Adimplência:"
                  name="defaultBonusValue"
                  value={formik.values.defaultBonusValue}
                  onInput={(e) => {
                    const { numericValue, formattedValue } =
                      handleChangeMoneyDiscount(e);

                    const currentBonusAdimplenciaFormatted =
                      new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(bonusAdimplencia);

                    const currentBonusAdimplenciaNumeric =
                      Number(
                        currentBonusAdimplenciaFormatted.replace(
                          /[R$.,\s]/g,
                          ""
                        )
                      ) / 100;

                    if (numericValue > currentBonusAdimplenciaNumeric) {
                      formik.setFieldValue(
                        "defaultBonusValue",
                        currentBonusAdimplenciaFormatted
                      );
                      displayMessageError({
                        message: `O campo "Valor Bônus Adimplência" só pode ser editado se for um valor menor do que o que já está preenchido.`,
                      });
                      return;
                    } else {
                      formik.setFieldValue("defaultBonusValue", formattedValue);
                    }
                  }}
                />
              </>
            )}

            <InputText
              readOnly
              label="Valor total da unidade:"
              name="totalUnitValue"
              value={formatMoney(formik.values.totalUnitValue)}
            />

            <InputTextEdit
              readOnly={!edit}
              label="Desconto:"
              name="discount"
              value={formik.values.discount}
              onChange={(e) => {
                const { formattedValue } = handleChangeMoneyDiscount(e);
                formik.handleChange(e);
                formik.setFieldValue("discount", formattedValue);
              }}
            />
            {isAssociative && (
              <InputTextEdit
                readOnly={!edit}
                label="Prestação Financiamento:"
                name="financingProvision"
                value={formik.values.financingProvision}
                onChange={(e) => {
                  const { formattedValue } = handleChangeMoneyDiscount(e);
                  formik.handleChange(e);
                  formik.setFieldValue("financingProvision", formattedValue);
                }}
              />
            )}
          </Styled.ContainerBoxInputsCreditRating>
        </AccordionPanel>
      </AccordionItem>

      {isAssociative && (
        <AccordionItem mt={"10px"} borderRadius={8}>
          <AccordionButton bg={theme.disable100} borderRadius={8}>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontWeight={"bold"}
              fontSize={"18px"}
            >
              Informações pró soluto
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <Styled.ContainerBoxInputsCreditRating>
              <InputText
                readOnly
                label="Saldo:"
                name="balance"
                value={formatMoney(formik.values.balance)}
              />
              <InputText
                readOnly
                label="Saldo total com juros:"
                name="totalBalanceInterest"
                value={formatMoney(formik.values.totalBalanceInterest)}
              />
              <InputText
                readOnly
                label="Comprometimento de renda da parcela:"
                name="incomeCommitmentInstallment"
                value={`${formik.values.incomeCommitmentInstallment}%`}
              />

              <InputText
                readOnly
                label="Comprometimento de renda pró soluto:"
                name="incomeCommitmentProSoluto"
                value={`${formik.values.incomeCommitmentProSoluto}%`}
              />
              <InputText
                readOnly
                label="Pró soluto (Mensal + intercaladas):"
                name="proSolutoInterspersed"
                value={formatMoney(formik.values.proSolutoInterspersed)}
              />
              <InputText
                readOnly
                label="Pró soluto total:"
                name="proSolutoTotal"
                value={formatMoney(formik.values.proSolutoTotal)}
              />
              <InputText
                readOnly
                label="Percentual pró soluto:"
                name="proSolutoPercentage"
                value={`${formik.values.proSolutoPercentage}%`}
              />
              <InputText
                readOnly
                label="Pró soluto com carência:"
                name="proSolutoDeficiency"
                value={formatMoney(formik.values.proSolutoDeficiency)}
              />
              <InputText
                readOnly
                label="Pró soluto Mensal:"
                name="proSolutoMonthly"
                value={formatMoney(formik.values.proSolutoMonthly)}
              />
              <InputText
                readOnly
                label="Máximo renda mensal %:"
                name="maximumMonthlyIncome"
                value={`${formik.values.maximumMonthlyIncome}%`}
              />
              <InputText
                readOnly
                label="Maior valor pró soluto:"
                name="higherProSoluto"
                value={formatMoney(formik.values.higherProSoluto)}
              />
              <InputText
                readOnly
                label="PS - Pós Obra:"
                name="psPostWork"
                value={formatMoney(formik.values.psPostWork)}
              />
              <InputText
                readOnly
                label="PS - Pós Obra %:"
                name="psPostWorkPercent"
                value={`${formik.values.psPostWorkPercent}%`}
              />
              <InputText
                readOnly
                label="Renda Intercalada %:"
                name="interleavedIncome"
                value={`${formik.values.interleavedIncome}%`}
              />
              <InputText
                readOnly
                label="Total mêses correção:"
                name="totalMonthsCorrection"
                value={`${formik.values.totalMonthsCorrection}`}
              />
            </Styled.ContainerBoxInputsCreditRating>
          </AccordionPanel>
        </AccordionItem>
      )}

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Informações sobre a taxa
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputsCreditRating>
            <InputTextEdit
              readOnly={!edit}
              label="Valor total taxas:"
              name="totalAmountFees"
              value={formik.values.totalAmountFees}
              onChange={(e) => {
                const { formattedValue } = handleChangeMoneyDiscount(e);
                formik.handleChange(e);
                formik.setFieldValue("totalAmountFees", formattedValue);
              }}
            />
            <InputTextEdit
              readOnly={!edit}
              label="Juros da taxa:"
              name="rateInterest"
              value={formik.values.rateInterest}
              onChange={(e) => {
                const newValue = handleChangeNumber(e);
                formik.handleChange(e);
                formik.setFieldValue("rateInterest", newValue);
              }}
            />
            <InputTextEdit
              readOnly={!edit}
              label="Quantidade de parcelas da taxa:"
              name="numberFeeInstallments"
              value={formik.values.numberFeeInstallments}
              onChange={(e) => {
                const newValue = handleChangeNumber(e);
                formik.handleChange(e);
                formik.setFieldValue("numberFeeInstallments", newValue);
              }}
            />
            <InputText
              readOnly
              label="Parcela da taxa:"
              name="feePortion"
              value={formatMoney(formik.values.feePortion)}
            />
          </Styled.ContainerBoxInputsCreditRating>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionQuotesComponent;
