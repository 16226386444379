import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { device } from "../../../../styles/variable";

interface IRadioButton {
  error: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
export const Label = styled.label`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p`
  color: ${(props: IRadioButton) =>
    props.error ? `${theme.error}` : `${theme.text}`};
  font-size: 12px;
  font-weight: bold;
  padding-left: 0.6rem;
  opacity: 1;

  @media ${device.laptop} {
    font-size: 12px;
    padding-left: 0.1rem;
  }
`;

export const RadioBox = styled.div`
  height: 1rem;
  width: 1rem;
  border: 2px solid
    ${(props: IRadioButton) =>
      props.error ? `${theme.error}` : `${theme.text}`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.4rem;
  transition: background 0.15s, border-color 0.15s;
  padding: 2px;
  opacity: 1;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    display: block;
    background: ${(props: IRadioButton) =>
      props.error ? `${theme.error}` : `${theme.text}`};
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);

    @media ${device.laptop} {
      width: 10px;
      height: 10px;
    }
  }

  @media ${device.laptop} {
    height: 1.1rem;
    width: 1.2rem;
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    &::after {
      transform: scale(1);
    }
  }
`;
