import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface IBox {
  width?: string;
  height?: string;
}

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: IBox) => (props.width ? props.width : "90%")};
  height: fit-content;
  border: 2px solid ${theme.disable};
  border-radius: 8px;
  padding: 12px 8px;
  align-self: center;

  @media ${device.tablet} {
    padding: 6px 12px;
    margin-top: 0px;
  }
  @media ${device.laptop} {
    width: ${(props: IBox) => (props.width ? props.width : "95%")};
  }
`;

export const Title = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 11px;
  color: ${theme.error};
  font-weight: bold;
`;

export const Body = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  font-size: 10.5px;
  line-height: 0.9rem;
  color: ${theme.text};
  margin-top: 8px;
  text-align-last: left;

  @media ${device.tablet} {
    font-size: 10px;
  }

  > a {
    color: black;
    font-weight: bold;
  }
`;
