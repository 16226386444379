import React from "react";
import * as Styled from "../stylesView&Create";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import { optionEnterprise } from "../../components/options";
import enterprisesService from "@services/Enterprises/enterprisesService";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import InputDate from "@components/Sales/Inputs/InputDate";
import oportunityService from "@services/Oportunity/OportunityService";
import { optionTypeVisit } from "@helpers/optionSelect";
import InputText from "@components/Sales/Inputs/InputText";
import { formatHourTime } from "@helpers/format";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { OportunityContext } from "@context/oportunityContex";
import InputSelectWithService from "@components/Sales/Inputs/InputSelectWithService";
import { useMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { ICreateScheduleComponent } from "src/@types/Sales";

const CreateScheduleComponent = ({
  onBack,
  setSuccessCreateSchedule,
  setShowDetailSchedule,
}: ICreateScheduleComponent) => {
  const displayMessage = useMessageToast();
  const displayMessageError = useMessageErrorToast();
  const unidadelUser: any = localStorage.getItem("@empresa");
  const regionalUser: any = localStorage.getItem("@regional");
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const { setAccessSchedule, imobiliariaId, accessCreateOp } =
    React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [date, setDate] = React.useState("");
  const [time, setTime] = React.useState("");
  const [typeVisit, setTypeVisit] = React.useState("");
  const [pdv, setPdv] = React.useState("");
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingEnterprises, setLoadingEnterprises] = React.useState(false);
  const [dataEnterprises, setDataEnterprises] = React.useState([]);
  const [idEnterprises, setIdEnterprises] = React.useState("");
  const [mainEnterprises, setMainEnterprises] = React.useState("");
  const [selectMainEnterprises, setSelectMainEnterprises] = React.useState("");
  const [observations, setObservations] = React.useState("");
  const [loadingPdv, setLoadingPdv] = React.useState(false);
  const [dataPdv, setDataPdv] = React.useState([]);
  const [idPdv, setIdPdv] = React.useState("");
  const [require, setRequire] = React.useState(false);

  const renderedOptions = optionEnterprise(
    dataEnterprises,
    selectMainEnterprises,
    setSelectMainEnterprises,
    setMainEnterprises,
    setIdEnterprises
  );

  const handleGetEnterprises = async () => {
    setLoadingEnterprises(true);

    try {
      const response = await enterprisesService.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprises(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprises(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprises(false);
    } catch (error) {
      setLoadingEnterprises(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const handleDateTimeFormat = (date: string) => {
    if (!date) {
      return date;
    }
    const data = parseISO(date);
    const formattedDate = format(data, "dd/MM/yyyy", { locale: ptBR });
    return formattedDate;
  };

  const handleGetPdv = async () => {
    setLoadingPdv(true);

    try {
      const response = await oportunityService.getPdv({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingPdv(false);
        setDataPdv([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingPdv(false);
        setIsUnauthorized(true);
        setDataPdv([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataPdv(dataArray);
      setLoadingPdv(false);
    } catch (error) {
      setLoadingPdv(false);
      console.log("errorHandleGetPdv", error);
    }
  };

  const handleCreateSchedule = async () => {
    setLoading(true);
    setSuccessCreateSchedule(false);
    try {
      const response = await oportunityService.createSchedule({
        imobiliaria: imobiliariaId,
        pdv: idPdv,
        relativoA: accessCreateOp.oportunityIdSalesforce,
        tipoDeVisita: typeVisit,
        dataAgendamento: handleDateTimeFormat(date),
        horaAgendamento: time,
        empreendimentoDeInteresse: idEnterprises,
        unidadeDeNegocio: unidadelUser,
        regional: regionalUser,
        observacoes: observations,
        contactIdSalesforce: idCorretor,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({
          message: response?.data?.mensagem
            ? response?.data?.mensagem
            : "Erro ao criar agendamento!",
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        setDataPdv([]);
        return;
      }

      setAccessSchedule({
        idAgendamentoSalesforce: response?.id,
        codigoDoAgendamento: response?.codigoDoAgendamento,
        visitaRealizada: false,
      });

      displayMessage({
        title: "Atividade registrada com sucesso!",
        message:
          "A nova atividade foi criada e você já pode acessar para continuar sua venda.",
        titleButton: "ACESSAR AGENDAMENTO",
        onClick: () => {
          setShowDetailSchedule(true);
          setSuccessCreateSchedule(false);
        },
      });
      setSuccessCreateSchedule(true);
      onBack();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("errorHandleCreateSchedule", error);
    }
  };

  const getDateToDay = () => {
    const today = new Date();
    today.setDate(today.getDate());
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  React.useEffect(() => {
    const isRequirement =
      date.length < 10 || formatHourTime(time).length < 5 || !typeVisit || !pdv;

    setRequire(isRequirement);
  }, [date, pdv, time, typeVisit]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Styled.ContainerSchedule>
        <Styled.TitleSchedule>Novo agendamento:</Styled.TitleSchedule>

        <Styled.ContainerInputsGrid>
          <InputDate
            type="date"
            isRequirement
            min={getDateToDay()}
            name={"date"}
            placeholder="__/__/___"
            label="Data do agendamento:"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />

          <InputText
            isRequirement
            label="Hora do agendamento:"
            placeholder="HH:MM"
            name="time"
            value={formatHourTime(time)}
            onChange={(e) => setTime(e.target.value)}
            maxLength={5}
          />

          <InputSelect
            isRequirement
            widht="28%"
            title="Tipo de visita:"
            label={typeVisit || "Selecione uma opção"}
            isSelected={typeVisit}
            options={optionTypeVisit}
            selectedOption={typeVisit}
            onChange={(e) => setTypeVisit(e.currentTarget.value)}
          />
        </Styled.ContainerInputsGrid>

        <Styled.ContainerInputsGridDuo>
          <InputSelectWithService
            isRequirement
            widht="40%"
            height="28%"
            title="PDV:"
            label={pdv || "Selecione uma opção"}
            isSelected={pdv}
            options={dataPdv}
            selectedOption={pdv}
            onClick={() => handleGetPdv()}
            onChange={(e) => setPdv(e.target.value)}
            setId={setIdPdv}
            loading={loadingPdv}
          />

          <InputSelectSearch
            width="37%"
            height="36%"
            title="Empreendimento de interesse:"
            placeholder="Pesquise por empreendimento"
            value={selectMainEnterprises}
            onChangeInput={(e) => setSelectMainEnterprises(e.target.value)}
            isSelected={mainEnterprises}
            onChangeIcon={() => {
              setMainEnterprises("");
              setSelectMainEnterprises("");
            }}
            onClickSearchIcon={handleGetEnterprises}
            loading={loadingEnterprises}
          >
            {renderedOptions}
          </InputSelectSearch>
        </Styled.ContainerInputsGridDuo>

        <Styled.ContainerInputsGridSolo>
          <InputTextArea
            label="Observações:"
            placeholder="Observações"
            name="Observações"
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
          />
        </Styled.ContainerInputsGridSolo>
      </Styled.ContainerSchedule>

      <Styled.DividerBoxInput />

      <Styled.ContainerFooterBox>
        <Styled.ContainerDuoMandatory>
          <span>(*) Campo de preenchimento obrigatório</span>
          <span>
            (<AiOutlineInfoCircle />) O campo apresenta formato ou dado inválido
          </span>
        </Styled.ContainerDuoMandatory>
        <Styled.ContainerButtonFooter>
          <Button error title="CANCELAR" onClick={onBack} />

          <Button
            title={loading ? <Spinner /> : "AGENDAR"}
            onClick={handleCreateSchedule}
            disabled={require || loading}
          />
        </Styled.ContainerButtonFooter>
      </Styled.ContainerFooterBox>
    </>
  );
};

export default CreateScheduleComponent;
