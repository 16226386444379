import React from "react";
import * as Styled from "./styles";
import { BsPersonCircle } from "react-icons/bs";
import DrawerSidebar from "../DrawerSidebar";

interface IMenu {
  isOpen: boolean;
  onClickProfile?: any;
  isSelected?: boolean;
  selectedOption: string;
  setSelectedOption: any;
}

const Menu = ({
  isOpen,
  onClickProfile,
  isSelected,
  selectedOption,
  setSelectedOption,
}: IMenu) => {
  return (
    <Styled.Container isOpen={isOpen}>
      <Styled.ContainerInput>
        <DrawerSidebar
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </Styled.ContainerInput>

      <Styled.ContainerIcons>
        <Styled.ContainerOtions>
          <Styled.Otions onClick={onClickProfile} isSelected={isSelected}>
            <BsPersonCircle size="20px" />
          </Styled.Otions>
        </Styled.ContainerOtions>
      </Styled.ContainerIcons>
    </Styled.Container>
  );
};

export default Menu;
