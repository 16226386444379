import * as Styled from "../styles";
import Box from "@components/Login/Box";
import { messageBox } from "./messageBox";
import InputCPF from "@components/Login/Inputs/InputCPF";
import { documentMask } from "@helpers/format";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import Helper from "@components/Login/Helper";
import { cpf } from "cpf-cnpj-validator";

interface IFirstLoginComponent {
  error: any;
  setError: any;
  handleEnter: () => void;
  document: string;
  setDocument: any;
  loading: boolean;
  setCode: any;
}

const handleCleanError = (setError: any) => {
  setError((prevState: any) => {
    return {
      ...prevState,
      errorMessage: "",
      errorDocument: false,
    };
  });
};

const FirstLoginComponent = ({
  error,
  setError,
  handleEnter,
  document,
  setDocument,
  loading,
  setCode,
}: IFirstLoginComponent) => {
  const messageButtonText = error.errorDocument
    ? "ACESSO INVÁLIDO"
    : "VERIFICAR";

  const buttonText = loading ? <Spinner /> : messageButtonText;

  return (
    <>
      <Styled.BodyCurrentLogin>
        <Styled.ContainerInputLogin>
          <InputCPF
            placeholder="CPF"
            value={documentMask(document)}
            onChange={(e) => {
              setDocument(e.target.value);
              handleCleanError(setError);
            }}
            iconClick={() => {
              setDocument("");
              handleCleanError(setError);
            }}
            message={<span>Campo obrigatório</span>}
            error={error.errorDocument}
          />
        </Styled.ContainerInputLogin>

        <Styled.ContainerButtonLogin>
          <Button
            title={buttonText}
            onClick={() => {
              setCode("");
              handleEnter();
            }}
            disabled={
              loading || (!cpf.isValid(document) && document.length < 14)
            }
            error={error.errorDocument}
          />
        </Styled.ContainerButtonLogin>

        <Styled.ContainerBox>
          {error.errorDocument && (
            <Box
              title={
                error.errorTitle
                  ? error.errorTitle
                  : "Utilize um CPF com formato válido para ativar seu cadastro."
              }
              body={
                <Styled.ContainerForgotPassword>
                  {messageBox(error.errorMessage)}
                </Styled.ContainerForgotPassword>
              }
            />
          )}
        </Styled.ContainerBox>
      </Styled.BodyCurrentLogin>

      <Helper />
    </>
  );
};

export default FirstLoginComponent;
