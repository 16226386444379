import React, { useState } from "react";
import {
  Table as CKTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as Styled from "./styles";
import Spinner from "@components/common/Spinner";
import { theme } from "src/styles/theme";
import salesService from "@services/Sales/SalesService";
import Notify from "@components/Sales/Notify";
import { UsuallyContext } from "@context/usuallyContex";
import { OportunityContext } from "@context/oportunityContex";

type TableRow = {
  id: string;
  leadIdSalesforce?: string;
  oportunidadeId: string;
  accountIdSalesforce: string;
  ehLead: boolean;
  tipoConta: string;
  regional: string;
  nome: string;
  origemConta: string;
  tipoChave: "email" | "telefone";
  chave: string;
};
interface IDataTable {
  data: TableRow[];
  navigation: any;
  navigationOportunity: any,
  navigationScreenMyOportunity: any,
  onSuccess?: (id: string, name: string) => void;
}

function Table({ data, navigation, navigationScreenMyOportunity, navigationOportunity, onSuccess = () => {} }: Readonly<IDataTable>) {
  const { setAccountIdSalesforce } = React.useContext(UsuallyContext);
  const { setAccessCreateOp } = React.useContext(OportunityContext);
  const sortKey: string = "";
  const sortOrder = "asc";

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    success: false,
    nome: "",
    accountId: "",
    duplicated: false,
  });

  const sortedData = React.useMemo(() => {
    if (!data) return [];
    return data?.slice()?.sort((a: any, b: any) => {
      if (sortKey === "nome") {
        return sortOrder === "asc"
          ? a?.nome?.localeCompare(b?.nome)
          : b?.nome?.localeCompare(a?.nome);
      }
      return sortOrder === "asc"
        ? a[sortKey] - b[sortKey]
        : b[sortKey] - a[sortKey];
    });
  }, [data, sortKey, sortOrder]);

  const closeModal = async (successfull: boolean, reload: boolean) => {
    const accountId = modal.accountId;
    const nome = 'GUILHERME TESTE';
    setModal({
      open: false,
      success: false,
      nome: "",
      accountId: "",
      duplicated: false,
    });
    if (successfull) {

      if(reload) {
        onSuccess(accountId, nome);
      } else {

        const idCorretor: any = localStorage.getItem("@idCorretor");
        const oportunities = await salesService.getOportunity({idCorretorSalesforce: idCorretor});

        const oportunity = oportunities.find((o: any) => o.accountIdSalesforce === accountId);

        if(oportunity !== undefined) {
          setAccessCreateOp({
            oportunidadeId: oportunity.oportunidadeId,
            oportunityIdSalesforce: oportunity.opportunityIdSalesforce,
            nomeDaConta: oportunity.nomeDaConta,
          })
          navigationOportunity();
        } else {
          navigationScreenMyOportunity();
        }

        setAccountIdSalesforce(accountId);
      }

    }
  };

  async function handleButton(
    isLead: boolean,
    accountIdSalesforce: string,
    opportunityId: string,
    row: TableRow
  ) {
    if (isLead === false) {
      navigation();
      setAccountIdSalesforce(accountIdSalesforce);
    } else {
      setLoading(true);
      const idCorretor: any = localStorage.getItem("@idCorretor");

      const result = await salesService.convertLead({
        leadId: row.leadIdSalesforce!,
        telefone: row.tipoChave === "telefone" ? row.chave : undefined,
        email: row.tipoChave === "email" ? row.chave : undefined,
        nome: row.nome,
        idCorretorSalesforce: idCorretor
      });
      setModal({
        open: true,
        success: result.success,
        nome: result.nome,
        accountId: result.accountId,
        duplicated: result.duplicated,
      });

      setLoading(false);
    }
  }

  return (
    <CKTable flexDirection="column" variant="simple" bg="white">
      <Thead>
        <Tr>
          <Th w="17%">
            <Text fontSize={11}>Ação disponível</Text>
          </Th>

          <Th w="17%" textAlign="center">
            <Text fontSize={11}>Tipo de conta</Text>
          </Th>

          <Th w="17%" textAlign="center">
            <Text fontSize={11}>Regional</Text>
          </Th>

          <Th w="17%" textAlign="center">
            <Text fontSize={11}>Nome da conta</Text>
          </Th>

          <Th w="17%" textAlign="center">
            <Text fontSize={11}>Origem da conta</Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortedData?.map((row) => {
          const text =
            row.ehLead === false ? "CRIAR OPORTUNIDADE" : "CONVERTER LEAD";

          return (
            <Tr key={row.accountIdSalesforce}>
              <Td w="17%">
                <Styled.ContainerAction
                  onClick={() =>
                    handleButton(
                      row.ehLead,
                      row.accountIdSalesforce,
                      row.oportunidadeId,
                      row
                    )
                  }
                  theme={{ primary: loading ? theme.disable : theme.primary }}
                >
                  <span>{loading ? <Spinner /> : text}</span>
                </Styled.ContainerAction>
              </Td>

              <Td w="17%">
                <Styled.ContainerTypeAction>
                  <span>{row.tipoConta}</span>
                </Styled.ContainerTypeAction>
              </Td>

              <Td w="17%">
                <Styled.ContainerRegional>
                  <span>{row?.regional}</span>
                </Styled.ContainerRegional>
              </Td>

              <Td w="17%">
                <Styled.ContainerNameAccount>
                  <span>{row.nome}</span>
                </Styled.ContainerNameAccount>
              </Td>

              <Td w="17%">
                <Styled.ContainerOriginAccount>
                  <span>{row.origemConta}</span>
                </Styled.ContainerOriginAccount>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
      <Notify onOpen={modal.open} onClose={() => closeModal(modal.success, true)}>
        <Styled.PopUpTitle>
          <span>
            {modal.success
              ? "Conta criada com sucesso!"
              : "Falha ao criar conta"}
          </span>
        </Styled.PopUpTitle>
        <Styled.ContainerTypeAction>
          {modal.success && (
            <span>
              A conta de <b>{modal.nome}</b> foi criada em nosso sistema 
              e você já pode acessar a sua oportunidade. 
            </span>
          )}
          {!modal.success && modal.duplicated && (
            <span>
              Este cliente possui mais de um cadastro em nosso sistema. 
              Por gentileza, entre em contato com o seu gerente. 
            </span>
          )}
          {!modal.success && !modal.duplicated && (
            <span>
              Não foi possível realizar a conversão, favor entrar em contato com o seu gerente.
            </span>
          )}
        </Styled.ContainerTypeAction>
        <Styled.PopUpSpacer />
        {modal.success && (
          <Styled.ContainerAction onClick={() => closeModal(true, false)}>
            <span>ACESSAR OPORTUNIDADE</span>
          </Styled.ContainerAction>
        )}
      </Notify>
    </CKTable>
  );
}

export default Table;
