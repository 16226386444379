import api from "@services/api";
import axios from "axios";

interface INeedHelpService {
  nome: string;
  regional: string;
  email: string;
  motivos: string;
  mensagem: string;
  incorporadora: string;
}

class NeedHelpService {
  async postNeedHelp({
    nome,
    regional,
    email,
    motivos,
    mensagem,
    incorporadora,
  }: INeedHelpService) {
    const body = {
      nome,
      regional,
      email,
      motivos,
      mensagem,
      incorporadora,
    };

    try {
      const response = await api.post("/preciso-de-ajuda/submeter", body);

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
}

const needHelpServiceInstance = new NeedHelpService();

export default needHelpServiceInstance;
