import * as Styled from "./styles";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface IAlert {
  onOpen: boolean;
  children: ReactNode;
  onClose?: any;
}

const Notify = ({ onOpen, children, onClose }: IAlert) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={onOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={0} ml={2} mt={3}>
          <Styled.ContainerHeader>
            <span>Bora Vender</span>
          </Styled.ContainerHeader>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={2}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Notify;
