import React from "react";
import { useFormik } from "formik";
import { BsGrid1X2, BsPrinter } from "react-icons/bs";
import Button from "@components/common/Button";
import InputLocked from "@components/Enterprises/Inputs/InputLocked";
import { UsuallyContext } from "@context/usuallyContex";
import InputTextarea from "@components/Enterprises/Inputs/InputTextarea";
import * as Styled from "../styles";
import Spinner from "@components/common/Spinner";
import unityService from "@services/Unity/unityService";
import { IValidationUnitySchema } from "src/@types/Enterprises";
import Alert from "@components/common/Alert";
import { capitalize, formatMoney, formatUnit } from "@helpers/format";
import { LoginContext } from "@context/loginContext";
import ReactToPrint from "react-to-print";
import BreadCrumb from "@components/common/BreadCrumb";
import { useNavigate } from "react-router-dom";

const UnityComponent: React.FC = () => {
  const navigate = useNavigate();
  const componentRef = React.useRef(null);
  const regional = localStorage.getItem("@regional");
  const company: any = localStorage.getItem("@empresa");
  const enterprise = `${regional} ${capitalize(company)}`;
  const enterpriseId: any = localStorage.getItem("@idEnterprises");
  const unityId: any = localStorage.getItem("@idUnity");
  const nameEnterprise: any = localStorage.getItem("@nomeEmpreendimento");
  const nameUnity: any = localStorage.getItem("@nameUnity");

  const { signOut, openSidebar, setPageSidebar } =
    React.useContext(LoginContext);
  const { setDataUnity, nameEnterprisesBreadcrumb, nameEnterprises } =
    React.useContext(UsuallyContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [data, setData] = React.useState<IValidationUnitySchema>();

  const breadcrumbItems = [
    {
      name: "Principal",
      link: "/",
      onClick: () => {
        setPageSidebar("Principal");
        localStorage.setItem("@option", "Principal");
      },
    },
    {
      name: "Empreendimento",
      link: "/empreendimentos",
      onClick: () => navigate("/empreendimentos"),
    },
    {
      name: !nameEnterprisesBreadcrumb ? enterprise : nameEnterprisesBreadcrumb,
      link: "/empreendimentos",
      onClick: () => navigate("/empreendimentos"),
    },
    {
      name: nameEnterprises || nameEnterprise || "",
      link: "/detalhesEmpreendimentos",
      onClick: () => navigate("/detalhesEmpreendimentos"),
    },
  ];

  const initialValues = {
    productDirecional: data?.produtoDirecional,
    enterprises: data?.nomeEmpreendimento,
    regional: data?.regional,
    status: data?.statusUnidade,
    bloqUnity: data?.motivoBloqueioUnidade,
    identifier: data?.identificador,
    typeUnity: data?.tipoUnidade,
    typeArea: data?.tipoPlanta,
    description: data?.descricao,
    otherDescription: data?.outrasDescricoes,

    banlValuationValue: formatMoney(data?.valorAvalBancaria),
    reportDate: data?.dataDoLaudo,
    campaignDiscountAmount: formatMoney(data?.valorDescontoCampanha),
    finalValueKit: formatMoney(data?.valorFinalKit),
    minimumSignalValue: formatMoney(data?.valorMinimoSinal),
    baBox: formatMoney(data?.baVoltaCaixa),
    valueFees: formatMoney(data?.valorTaxas),
    baUnity: formatMoney(data?.baUnidade),
    quantityInstallments: data?.qtdeParcelas,
    bonusGoodDebt: formatMoney(data?.bonusAdimplencia),
    valueInstallmentFees: formatMoney(data?.valorParcelaTaxas),
    boxClearance: formatMoney(data?.folgaVoltaCaixa),
    percentagePremium: `${data?.premioPercentual}%`,
    regularPrize: formatMoney(data?.premioNominal),

    blockStage: data?.blocoEtapa,
    floor: data?.andar,
    endUnit: data?.finalUnidade,

    totalCommonArea: formatUnit(data?.areaComumTotal),
    totalPrivateArea: formatUnit(data?.areaPrivativaTotal),
    mainPrivateArea: formatUnit(data?.areaPrivativaPrincipal),
    accessoryArea: formatUnit(data?.areaAcessoria),
    coveredVacancyArea: formatUnit(data?.vagaAreaDescoberta),
    idealFraction: formatUnit(data?.fracaoIdeal),
    totalRealArea: formatUnit(data?.areaRealTotal),
    commercialArea: formatUnit(data?.areaComercial),

    rooms: data?.numQuartos,
    suites: data?.numSuites,
    baths: data?.numBanhos,
    livingRoom: data?.salaJantar,
    kitchen: data?.cozinha,

    parkingSpaces: data?.vagaGaragem,
    vacancyDescription: data?.descVagas,
    quantityVacancy: data?.qtdeVagas,

    deliveryDate: data?.dataEntrega,
    DateIssuancePermit: data?.dataExpedicaoHabitese,
    deliveryDateWork: data?.dataEntregaObra,
    dataPj: data?.dataPJ,
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  React.useEffect(() => {
    const handleGetUnityEnterprises = async () => {
      try {
        const response = await unityService.getUnity({
          idEmpreendimentoSales: enterpriseId,
        });

        if (response.status >= 400 && response?.status !== 401) {
          console.log("status 400", response);
          return;
        }

        if (response?.status === 401) {
          console.log("status 401", response);
          setError(true);
          return;
        }

        setDataUnity(response);
      } catch (error: any) {
        console.log("errorHandleGetUnitybyId", error);
      }
    };
    handleGetUnityEnterprises();
  }, [enterpriseId, setDataUnity]);

  React.useEffect(() => {
    const handleGetUnity = async () => {
      setLoading(true);
      try {
        const response = await unityService.getUnityById({
          idEmpreendimentoSales: enterpriseId,
          IdUnidade: unityId,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoading(false);
          console.log("status 400", response);
          setError(true);
          return;
        }

        if (response?.status === 401) {
          setLoading(false);
          console.log("status 401", response);
          setError(response.status === 401);
          return;
        }

        setData(response);
        setLoading(false);
        setError(false);
      } catch (error: any) {
        setLoading(false);
        console.log("errorHandleGetEnterprisesbyName", error);
      }
    };

    handleGetUnity();
  }, [enterpriseId, unityId]);

  return (
    <Styled.Area open={openSidebar}>
      <Styled.Container hash={""}>
        <Styled.SubContainer ref={componentRef}>
          <Styled.ContainerTop>
            <BreadCrumb items={breadcrumbItems} />
          </Styled.ContainerTop>

          <Styled.ContainerUnity ref={componentRef}>
            <Alert
              onOpen={error || errorEmpty}
              title="Erro ao carregar os dados!"
              titleButton={errorEmpty ? "Voltar" : "Logar novamente"}
              description={
                errorEmpty
                  ? "Não há empreendimentos para os filtros selecionados."
                  : "Sua sessão expirou, faça login novamante para ter acesso."
              }
              onClick={() => {
                if (errorEmpty) {
                  setErrorEmpty(false);
                } else {
                  signOut();
                }
              }}
            />
            <Styled.ContainerTitlePage>
              <Styled.TitlePage>{nameUnity}</Styled.TitlePage>

              <Styled.ContainerTitleButtonUnity>
                <Button
                  title={
                    <>
                      <Styled.AreaTitleButtonEnterprises>
                        <BsGrid1X2 />
                      </Styled.AreaTitleButtonEnterprises>
                      ESPELHO DE VENDAS
                    </>
                  }
                  onClick={() => {}}
                  disabled
                />
                <ReactToPrint
                  trigger={() => (
                    <Button
                      title={
                        <>
                          <Styled.AreaTitleButtonEnterprises>
                            <BsPrinter />
                          </Styled.AreaTitleButtonEnterprises>
                          IMPRIMIR
                        </>
                      }
                    />
                  )}
                  content={() => componentRef.current}
                />
              </Styled.ContainerTitleButtonUnity>
            </Styled.ContainerTitlePage>

            <Styled.Form>
              <Styled.ContainerSection>
                <Styled.ContainerTitleSection>
                  <Styled.TitleSection>
                    Informações da unidade
                  </Styled.TitleSection>
                </Styled.ContainerTitleSection>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <Styled.ContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Produto Direcional:"
                        name="productDirecional"
                        value={formik?.values?.productDirecional}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Empreendimento:"
                        name="enterprises"
                        value={formik?.values?.enterprises}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Regional:"
                        name="regional"
                        value={formik?.values?.regional}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Status:"
                        name="status"
                        value={formik?.values?.status}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Motivo de bloqueio da unidade:"
                        name="bloqUnity"
                        value={formik?.values?.bloqUnity}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Identificador:"
                        name="identifier"
                        value={formik?.values?.identifier}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Tipo de Unidade:"
                        name="typeUnity"
                        value={formik?.values?.typeUnity}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Tipo de planta/área:"
                        name="typeArea"
                        value={formik?.values?.typeArea}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputTextarea
                        label="Descrição:"
                        name="description"
                        value={formik?.values?.description}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputTextarea
                        label="Outras descrições:"
                        name="otherDescription"
                        value={formik?.values?.otherDescription}
                      />
                    </Styled.SubContainerInput>
                  </Styled.ContainerInput>
                )}
              </Styled.ContainerSection>

              <Styled.ContainerSection>
                <Styled.ContainerTitleSection>
                  <Styled.TitleSection>Valores</Styled.TitleSection>
                </Styled.ContainerTitleSection>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <Styled.ContainerInputValues>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Valor final com kit:"
                        name="finalValueKit"
                        value={formik?.values?.finalValueKit}
                      />
                    </Styled.SubContainerInput>

                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Valor de desconto da campanha (viabilidade):"
                        name="campaignDiscountAmount"
                        value={formik?.values?.campaignDiscountAmount}
                      />
                    </Styled.SubContainerInput>

                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Valor mínimo do sinal:"
                        name="minimumSignalValue"
                        value={formik?.values?.minimumSignalValue}
                      />
                    </Styled.SubContainerInput>

                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Valor das taxas:"
                        name="valueFees"
                        value={formik?.values?.valueFees}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Valor da avaliação bancária:"
                        name="banlValuationValue"
                        value={formik?.values?.banlValuationValue}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Data do laudo:"
                        name="reportDate"
                        value={formik?.values?.reportDate}
                      />
                    </Styled.SubContainerInput>

                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Quantidade das parcelas:"
                        name="quantityInstallments"
                        value={formik?.values?.quantityInstallments}
                      />
                    </Styled.SubContainerInput>

                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Valor da parcela taxas:"
                        name="valueInstallmentFees"
                        value={formik?.values?.valueInstallmentFees}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="B.A volta ao caixa:"
                        name="baBox"
                        value={formik?.values?.baBox}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="B.A da unidade:"
                        name="baUnity"
                        value={formik?.values?.baUnity}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Bônus adimplência:"
                        name="bonusGoodDebt"
                        value={formik?.values?.bonusGoodDebt}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Folga volta ao caixa:"
                        name="boxClearance"
                        value={formik?.values?.boxClearance}
                      />
                    </Styled.SubContainerInput>

                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Prêmio percentual:"
                        name="percentagePremium"
                        value={formik?.values?.percentagePremium}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Prêmio normal:"
                        name="regularPrize"
                        value={formik?.values?.regularPrize}
                      />
                    </Styled.SubContainerInput>
                  </Styled.ContainerInputValues>
                )}
              </Styled.ContainerSection>

              <Styled.ContainerSection>
                <Styled.ContainerTitleSection>
                  <Styled.TitleSection>Localização</Styled.TitleSection>
                </Styled.ContainerTitleSection>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <Styled.ContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Bloco/Etapa:"
                        name="blockStage"
                        value={formik?.values?.blockStage}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Andar:"
                        name="floor"
                        value={formik?.values?.floor}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Final da unidade:"
                        name="endUnit"
                        value={formik?.values?.endUnit}
                      />
                    </Styled.SubContainerInput>
                  </Styled.ContainerInput>
                )}
              </Styled.ContainerSection>

              <Styled.ContainerSection>
                <Styled.ContainerTitleSection>
                  <Styled.TitleSection>Área</Styled.TitleSection>
                </Styled.ContainerTitleSection>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <Styled.ContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Área comum total:"
                        name="totalCommonArea"
                        value={formik?.values?.totalCommonArea}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Área privativa total:"
                        name="totalPrivateArea"
                        value={formik?.values?.totalPrivateArea}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Área privativa principal:"
                        name="mainPrivateArea"
                        value={formik?.values?.mainPrivateArea}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Área acessória:"
                        name="accessoryArea"
                        value={formik?.values?.accessoryArea}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Área da vaga descoberta:"
                        name="coveredVacancyArea"
                        value={formik?.values?.coveredVacancyArea}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Fração ideal:"
                        name="idealFraction"
                        value={formik?.values?.idealFraction}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Área real total:"
                        name="totalRealArea"
                        value={formik?.values?.totalRealArea}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Área comercial (campanha):"
                        name="commercialArea"
                        value={formik?.values?.commercialArea}
                      />
                    </Styled.SubContainerInput>
                  </Styled.ContainerInput>
                )}
              </Styled.ContainerSection>

              <Styled.ContainerSection>
                <Styled.ContainerTitleSection>
                  <Styled.TitleSection>Cômodos</Styled.TitleSection>
                </Styled.ContainerTitleSection>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <Styled.ContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Quartos:"
                        name="rooms"
                        value={formik?.values?.rooms}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Suítes:"
                        name="suites"
                        value={formik?.values?.suites}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Banhos:"
                        name="baths"
                        value={formik?.values?.baths}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Sala/Jantar:"
                        name="livingRoom"
                        value={formik?.values?.livingRoom}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Cozinha:"
                        name="kitchen"
                        value={formik?.values?.kitchen}
                      />
                    </Styled.SubContainerInput>
                  </Styled.ContainerInput>
                )}
              </Styled.ContainerSection>

              <Styled.ContainerSection>
                <Styled.ContainerTitleSection>
                  <Styled.TitleSection>Garagem</Styled.TitleSection>
                </Styled.ContainerTitleSection>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <Styled.ContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Vaga(s) de garagem:"
                        name="parkingSpaces"
                        value={formik?.values?.parkingSpaces}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Descrição da(s) vaga(s):"
                        name="vacancyDescription"
                        value={formik?.values?.vacancyDescription}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Quantidade de vaga(s):"
                        name="quantityVacancy"
                        value={formik?.values?.quantityVacancy}
                      />
                    </Styled.SubContainerInput>
                  </Styled.ContainerInput>
                )}
              </Styled.ContainerSection>

              <Styled.ContainerSection>
                <Styled.ContainerTitleSection>
                  <Styled.TitleSection>Datas</Styled.TitleSection>
                </Styled.ContainerTitleSection>
                {loading ? (
                  <Styled.ContainerSpinner>
                    <Spinner />
                  </Styled.ContainerSpinner>
                ) : (
                  <Styled.ContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Data da entrega:"
                        name="deliveryDate"
                        value={formik?.values?.deliveryDate}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Data de expedição do habite-se:"
                        name="DateIssuancePermit"
                        value={formik?.values?.DateIssuancePermit}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Data de entrega da obra:"
                        name="deliveryDateWork"
                        value={formik?.values?.deliveryDateWork}
                      />
                    </Styled.SubContainerInput>
                    <Styled.SubContainerInput>
                      <InputLocked
                        label="Data PJ:"
                        name="dataPj"
                        value={formik?.values?.dataPj}
                      />
                    </Styled.SubContainerInput>
                  </Styled.ContainerInput>
                )}
              </Styled.ContainerSection>
            </Styled.Form>
          </Styled.ContainerUnity>
        </Styled.SubContainer>
      </Styled.Container>
    </Styled.Area>
  );
};

export default UnityComponent;
