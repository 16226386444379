import React, { ReactNode } from "react";
import * as Styled from "../InputSelectSearch/styles";
import { BiSearch } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import { useClickOutside } from "@hook/useClickOutside";

interface IInputSelectSearchAccount {
  title: string;
  value: string;
  onChangeInput: React.ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  isSelected?: string;
  height?: string;
  width?: string;
  disabled?: boolean;
  disabledButton?: boolean;
  isRequirement?: boolean;
  onChangeIcon: React.MouseEventHandler<SVGElement>;
  onClickSearchIcon: () => void;
  loading?: boolean;
  children?: ReactNode;
  isOportunity?: boolean;
  maxLength?: number;
}

const InputSelectSearchAccount = ({
  title,
  value,
  onChangeInput,
  placeholder,
  isSelected,
  height,
  width,
  disabled,
  disabledButton,
  isRequirement,
  onChangeIcon,
  onClickSearchIcon,
  loading,
  children,
  isOportunity,
  maxLength,
}: IInputSelectSearchAccount) => {
  const wrapperRef: any = React.useRef(null);
  const [open, setOpen] = React.useState<boolean>(false);

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  const handleSearchIconClick = () => {
    setOpen(true);
    onClickSearchIcon();
  };

  React.useEffect(() => {
    if (isSelected) {
      setOpen(false);
    }
  }, [isSelected]);

  React.useEffect(() => {
    setOpen(false);
  }, [value]);

  return (
    <Styled.Container ref={wrapperRef}>
      <Styled.Label>
        {isRequirement && <Styled.Requirement>*</Styled.Requirement>}
        {title}
      </Styled.Label>
      <Styled.SelectButton disabled={disabled}>
        <Styled.InputText
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChangeInput}
          maxLength={maxLength}
        />
        {isSelected ? (
          <Styled.ContainerIconClose
            isOportunity={isOportunity}
            disabled={disabled}
          >
            <AiOutlineCloseCircle
              onClick={disabled ? () => {} : onChangeIcon}
            />
          </Styled.ContainerIconClose>
        ) : (
          <Styled.ContainerIcon
            onClick={disabledButton ? () => {} : handleSearchIconClick}
            disabled={disabledButton}
          >
            <BiSearch />
          </Styled.ContainerIcon>
        )}
      </Styled.SelectButton>

      {open && (
        <Styled.ListItems
          width={width}
          height={height}
          isOpen={disabled ? false : open}
        >
          {loading ? (
            <Styled.ContainerLoading>
              <Spinner />
            </Styled.ContainerLoading>
          ) : (
            <>{children}</>
          )}
        </Styled.ListItems>
      )}
    </Styled.Container>
  );
};

export default InputSelectSearchAccount;
