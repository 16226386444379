import { FiUsers } from "react-icons/fi";
import { BiBuilding } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";

export const linkSidebar = [
  {
    label: "Empreendimentos",
    icon: <BiBuilding size="22px" />,
    to: "/empreendimentos",
  },
  {
    label: "Oportunidades",
    subItem: [{ name: "Buscar clientes" }, { name: "Minhas oportunidades" }],
    icon: <FiUsers size="22px" />,
    to: "/oportunidades",
  },
  {
    label: "Comissões",
    subItem: [{ name: "Minhas comissões" }],
    icon: <GiReceiveMoney size="22px" />,
    to: "/comissoes",
  },
];
