import styled from "styled-components";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";

interface ISeeSchedule {
  visitaRealizada?: boolean;
  type?: string;
  buttonUpdate?: boolean;
  status?: string;
}
interface IFase {
  faseOp?: boolean;
}
interface IModalUpdateAdress {
  isModalUpdate?: boolean;
}

function handleColorCreditRating(value?: string) {
  if (!value) return theme.text;
  if (value === "Aprovada") return theme.primary;
  if (value === "Reprovada" || value === "Cancelada") return theme.error;
  if (value === "Em análise") return theme.text;
  if (value === "Rascunho") return theme.disable;
  return "";
}

export const ContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;

export const ContainerBoxInputsObservations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  margin-top: 10px;
`;

export const ContainerFooterDashboard = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
`;

export const ContainerBoxButtonObservations = styled.div`
  display: flex;
  width: 15%;
  margin-top: 4px;
  align-self: flex-end;
`;

export const TitleButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: fit-content;
    color: white;
    font-size: 18px;
  }
`;

export const ContainerBoxInputs = styled.div<IModalUpdateAdress>`
  display: grid;
  grid-template-columns: ${({ isModalUpdate }) =>
    isModalUpdate ?"repeat(3, 1fr)" : "repeat(4, 1fr)"};
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: fit-content;
  }
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;

export const ContainerBoxInputsSearch = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;
export const ContainerBoxInputsCreditRating = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media print {
    grid-row-gap: 25px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;

export const TitleDetail = styled.span`
  font-size: 18px;
  font-weight: bold;
  padding: 4px;
`;

export const ContainerAccordion = styled.div`
  display: flex;
`;
export const ContainerInputObservation = styled.div`
  display: flex;
  margin-top: 10px;
  height: 100px;
`;

export const ContainerActivity = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;

export const TitleActivity = styled.div`
  display: flex;

  > h1 {
    font-size: 13px;
    flex: 1;
    height: 35px;
    color: ${theme.text};
    font-size: 24px;
    font-weight: bold;
    padding-left: 16px;
    border-radius: 8px;
    background-color: ${theme.disable100};
  }
`;

export const ContainerActivitySubTab = styled.div`
  display: flex;
  width: 100%;
  background: white;
  padding: 4px;
  gap: 6px;
  border-radius: 8px;
  margin-top: 8px;

  @media ${device.laptop} {
    padding: 0;
  }
`;

export const AreaQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerTitleNotify = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 30%;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const ContainerSuccess = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 110px;
  justify-content: space-between;

  > span {
    font-size: 12px;
    color: ${theme.text};
    margin-top: 10px;
  }
`;

export const ContainerButtonNotify = styled.div`
  display: flex;
  width: 90%;
  height: fit-content;
  gap: 10px;
  margin-top: 8px;
`;

export const ContainerHistoricSchedule = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;
export const ToastMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px;
  background-color: white;
  border-radius: 5px;
  width: 372px;
  box-shadow: 0px 20px 30px #1c282c33;

  p {
    font-weight: normal;
    font-size: 16px;
    color: ${theme.text};
  }
`;

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  h1 {
    font-weight: bold;
    font-size: 16px;
    color: ${theme.text};
    margin-bottom: 16px;
  }

  .atention {
    color: ${theme.error};
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
    color: ${theme.disable};
    margin-bottom: 4px;
  }

  h3 {
    font-weight: bold;
    font-size: 16px;
    color: ${theme.text};
    margin-bottom: 4px;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    color: ${theme.text};
  }

  .popup-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
export const ContainerQuotes = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
export const ContaineButtonsHeader = styled.div`
  display: flex;
  width: 26%;
  gap: 8px;

  @media ${device.laptopXL} {
    width: 20%;
  }
  @media ${device.laptop} {
    width: 35%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const TitleButon = styled.div<ISeeSchedule>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  > svg {
    font-size: 17px;
    width: fit-content;
    color: ${({ buttonUpdate }) => (buttonUpdate ? theme.primary : "white")};
  }
`;
export const TitleHistoricSchedule = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export const ContainerTitleLabelHistoric = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
`;
export const ContainerTitleCreditRating = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  /* justify-content: space-between; */

  @media ${device.laptop} {
    justify-content: baseline;
  }
`;
export const ContainerTitleLabelQuote = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
`;
export const TitleLabelHistoricLeft = styled.span`
  width: 80%;
  font-size: 10px;
  color: ${theme.text};

  @media ${device.laptopXL} {
    width: 90%;
  }

  @media ${device.laptop} {
    width: 60%;
  }
  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;
export const TitleLabelHistoricRatingRightLeft = styled.span`
  font-size: 10px;
  color: ${theme.text};
  width: 70%;

  @media ${device.laptopXL} {
    width: 90%;
  }

  @media ${device.laptop} {
    width: 60%;
  }

  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;
export const TitleLabelQuotesLeft = styled.span`
  width: 67%;
  font-size: 10px;
  color: ${theme.text};

  @media ${device.laptopXL} {
    width: 90%;
  }
`;
export const TitleLabelHistoricRight = styled.span`
  font-size: 10px;
  color: ${theme.text};
  width: 33%;

  @media ${device.laptopXL} {
    width: 37%;
  }
  @media ${device.laptop} {
    width: 40%;
  }
  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;
export const TitleLabelHistoricCreditRatingRight = styled.span`
  font-size: 10px;
  color: ${theme.text};
  width: 9%;

  @media ${device.laptopXL} {
    width: 10%;
  }
  @media ${device.laptop} {
    width: 12%;
  }

  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;
export const TitleLabelQuoteRight = styled.span`
  width: 75px;
  font-size: 10px;
  color: ${theme.text};

  @media ${device.laptopXL} {
    width: 6%;
  }
`;
export const TitleLabelQuoteRightDate = styled.span`
  width: 20%;
  font-size: 10px;
  color: ${theme.text};

  @media ${device.laptopXL} {
    width: 29%;
  }
`;
export const TitleLabelDataHistoricCreditRatingRight = styled.span`
  font-size: 10px;
  color: ${theme.text};
  width: 10%;

  @media ${device.laptopXL} {
    width: 24%;
  }
  @media ${device.laptop} {
    width: 30%;
  }

  @media ${device.tablet} {
    display: none;
    width: 0;
  }
`;
export const ContainerTodoHistoric = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  @media ${device.tablet} {
    overflow: auto;
  }
`;

export const ContainerTodoHistoricEmpty = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.text};
    font-size: 18px;
  }
`;
export const ContainerTodoHistoricLoading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 10px;
`;

export const ContainerSchedule = styled.div`
  display: flex;
  padding: 6px;
  border-radius: 8px;
  background: #dde1e8b3;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileL} {
    width: 500px;
  }
`;
export const ContainerScheduleSeeSchedule = styled.div`
  display: flex;
  padding: 6px;
  border-radius: 8px;
  background: #dde1e8b3;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    width: 800px;
  }
`;

export const ContainerIconTextHistoric = styled.div`
  display: flex;
  width: 80%;
  align-items: center;

  @media ${device.laptopXL} {
    width: 90%;
  }
  @media ${device.laptop} {
    width: 50%;
  }
`;

export const ContainerLeftCreditRating = styled.div`
  display: flex;
  width: 60%;

  @media ${device.laptopXL} {
    width: 90%;
  }
  @media ${device.laptop} {
    width: 60%;
  }
`;

export const ContainerIconHistoric = styled.div<ISeeSchedule>`
  display: flex;
  width: 50px;
  height: 40px;
  background: ${({ visitaRealizada }) =>
    visitaRealizada ? theme.primary : theme.disable};
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  > svg {
    color: white;
    font-size: 26px;
  }
`;
export const ContainerIconHistoricCreditRating = styled.div<ISeeSchedule>`
  display: flex;
  width: 50px;
  height: 40px;
  background: ${({ status }) => handleColorCreditRating(status)};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  align-self: center;

  > svg {
    color: white;
    font-size: 24px;
  }
`;

export const ContainerTextHistoric = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-left: 5px;
`;
export const TitleTextHistoric = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
export const SubTitleTextHistoric = styled.div`
  font-size: 11px;
`;

export const ContainerDataStatus = styled.div`
  display: flex;
  width: 33%;

  @media ${device.laptopXL} {
    width: 37%;
  }
  @media ${device.laptop} {
    width: 40%;
  }
`;
export const ContainerDataCreditRating = styled.div`
  display: flex;
  width: 30%;

  @media ${device.laptopXL} {
    width: 35%;
  }

  @media ${device.laptop} {
    width: 42%;
  }
`;
export const ContainerDataQuotes = styled.div`
  display: flex;
  width: 36%;

  @media ${device.laptop} {
    width: 40%;
  }
`;

export const ContainerIconTextCreditRating = styled.div`
  display: flex;
  width: 70%;
`;
export const ContainerIconTextQuotes = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerIconTextDataStatus = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
`;

export const ContainerTexDataStatusCreditRating = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
`;

export const ContainerTextDataStatusQuote = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

export const ContainerTexDataStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const TitleTexDataStatus = styled.div<ISeeSchedule>`
  font-size: 12px;
  color: ${({ visitaRealizada }) =>
    visitaRealizada ? theme.primary : theme.text};
`;
export const SubTitleTextDataStatus = styled.div<ISeeSchedule>`
  font-size: 11px;
  font-weight: bold;
  color: ${({ visitaRealizada }) =>
    visitaRealizada ? theme.primary : theme.text};
`;
export const SubTitleTextDataQuote = styled.div<ISeeSchedule>`
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: ${({ visitaRealizada }) =>
    visitaRealizada ? theme.primary : theme.text};
`;

export const ContainerIconDataStatus = styled.div<ISeeSchedule>`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;

  > svg {
    color: ${({ visitaRealizada }) =>
      visitaRealizada ? theme.primary : theme.text};
    font-size: 22px;
  }
`;

export const AreaIconCreditRating = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

export const ContainerIconCreditRating = styled.div<ISeeSchedule>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;

  > span {
    width: fit-content;
    font-size: 11px;
    color: ${theme.text};
  }

  > svg {
    width: fit-content;
    color: ${({ status }) => handleColorCreditRating(status)};
    font-size: 22px;
  }
`;

export const ContainerIconQuote = styled.div<ISeeSchedule>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;

  > span {
    width: fit-content;
    font-size: 11px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const BoxDivider = styled.div`
  display: flex;
  width: fit-content;
`;
export const DividerActivity = styled.div`
  display: flex;
  width: 1px;
  height: 98%;
  background: ${theme.disable};
  margin-left: 5px;
  margin-right: 5px;
`;
export const DividerActivityCreditRating = styled.div`
  display: flex;
  width: 1px;
  height: 98%;
  background: ${theme.disable};
  margin-left: 10px;
  margin-right: 10px;
`;

export const ContainerButtonIconDataStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
`;

export const ContainerButtonView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
`;

export const ContainerButtonDataStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ContainerButtonDataCreditRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

function handleType(type: string) {
  if (!type) return "";
  if (type.toLowerCase().includes("task")) return theme.info;
  if (type.toLowerCase().includes("call")) return theme.available50;
  return "";
}

export const ContainerIconTasksCalls = styled.div<ISeeSchedule>`
  display: flex;
  width: 50px;
  height: 40px;
  background: ${({ type }: any) => handleType(type)};
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  > svg {
    color: white;
    font-size: 26px;
  }
`;

export const ContainerDataTasksCalls = styled.div`
  display: flex;
  width: 40%;

  @media ${device.laptopXL} {
    width: 35%;
  }
  @media ${device.laptop} {
    width: 40%;
  }
`;

export const ContainerButtonIconDataTasksCalls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
`;
export const AreaButtonIconDataTasksCalls = styled.div<IFase>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 1px;
  cursor: ${({ faseOp }) => (faseOp ? "default" : "pointer")};

  > span {
    width: fit-content;
    font-size: 12px;
    text-decoration: underline;
    color: ${({ faseOp }) => (faseOp ? theme.disable : theme.text)};
  }

  > svg {
    width: fit-content;
    color: ${({ faseOp }) => (faseOp ? theme.disable : theme.text)};
    font-size: 22px;
  }
`;

export const ContainerIconTextDataTasksCalls = styled.div`
  display: flex;
  width: 62%;
`;

export const DividerBoxInput = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  height: 2px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContainerFooterBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerDuoMandatory = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  > span {
    display: flex;
    color: ${theme.error};
    font-size: 13px;
    font-weight: bold;
    align-items: center;

    > svg {
      width: fit-content;
      height: fit-content;
    }
  }
`;
export const ContainerButtonFooterEdit = styled.div`
  display: flex;
  width: 80%;
  height: fit-content;
  gap: 10px;

  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.tablet} {
    width: 90%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 15px;
  }
`;

export const LoadingContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 280px;
  width: 100%;
`;

export const ContainerTitleAdress = styled.div`
  display: flex;
  margin-top: 15px;

  > span {
    font-size: 18px;
    font-weight: bold;
  }
`;
export const ContainerCheckboxAdress = styled.div`
  display: flex;
  margin-top: 15px;
  width: fit-content;
`;
export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;

`;
