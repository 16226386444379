import { BsFillInfoCircleFill } from "react-icons/bs";
import { HiOutlineLockClosed } from "react-icons/hi";
import { theme } from "src/styles/theme";
import styled from "styled-components";

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
`;
export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${theme.text};
  margin-left: 5px;
  width: 100%;
`;

export const IconHiOutlineLockClosed = styled(HiOutlineLockClosed)`
  color: ${theme.disable100};
  margin-right: 15px;
  margin-top: 5px;
  font-size: 20px;
  width: fit-content;
`;
export const IconBsFillInfoCircleFill = styled(BsFillInfoCircleFill)`
  color: ${theme.text};
  margin-top: 5px;
  width: fit-content;
`;
