import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import { BsChevronRight } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import logo from "../../../assets/logo.svg";
import logoDirecional from "../../../assets/LogoDirecional.png";
import logoRiva from "../../../assets/Rivalogo.png";
import * as Styled from "./styles";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@helpers/format";
import { LoginContext } from "@context/loginContext";
import { linkSidebar } from "@helpers/linkSidebar";
import { UsuallyContext } from "@context/usuallyContex";

interface IDrawerSidebar {
  selectedOption: string;
  setSelectedOption: any;
}

type SubItem = { name: string };

const DrawerSidebar = ({
  selectedOption,
  setSelectedOption,
}: IDrawerSidebar) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { signOut } = React.useContext(LoginContext);
  const { setHashSalesSidebar } = React.useContext(UsuallyContext);
  const user: any = localStorage.getItem("@user");

  const handleOptionClick = (label: string) => {
    setSelectedOption(label);
    localStorage.setItem("@option", label);
  };

  function handleNavigateSubMenu(name: string) {
    if (name.toLowerCase() === "buscar clientes")
      return setHashSalesSidebar("Oportunity");
    if (name.toLowerCase() === "minhas oportunidades")
      return setHashSalesSidebar("MyOportunityComponent");
    return setHashSalesSidebar("");
  }

  const handleSubItem = (subItem: any) => {
    return subItem?.map((link: SubItem) => (
      <span key={link?.name} onClick={() => handleNavigateSubMenu(link?.name)}>
        {link?.name}
      </span>
    ));
  };

  const memoOptions = React.useMemo(
    () =>
      linkSidebar.map(({ icon, label, to, subItem }) => {
        return (
          <Styled.ContainerOtions
            key={label}
            isSubItem={selectedOption === label && !!subItem}
          >
            <Styled.Otions
              isSelected={selectedOption === label}
              onClick={() => {
                handleOptionClick(label);
                navigate(to);
              }}
            >
              {icon}
              <span>{label}</span>
              <BsChevronRight size="15px" />
            </Styled.Otions>
            {selectedOption === label && subItem && (
              <Styled.ContainerSubOtions>
                {handleSubItem(subItem)}
              </Styled.ContainerSubOtions>
            )}
          </Styled.ContainerOtions>
        );
      }),
    [navigate, selectedOption]
  );

  return (
    <Styled.Container>
      <Styled.IconRxHamburgerMenu onClick={onOpen} />

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Styled.ContainerSidebar>
              <Styled.ContainerTop>
                <Styled.ContainerLogo>
                  <Styled.ContainerBackgroundLogo>
                    <Styled.ImgLarge alt="logo" src={logo} />
                  </Styled.ContainerBackgroundLogo>
                </Styled.ContainerLogo>

                {memoOptions}
              </Styled.ContainerTop>

              <Styled.Divider />

              <Styled.ContainerConfig>
                <Styled.ContainerOtionsConfig>
                  <Styled.OtionsConfig>
                    <Styled.ContainerUser>
                      <Styled.TextUser>{capitalize(user)}</Styled.TextUser>
                      <Styled.TextEmail>
                        {localStorage.getItem("@email")}
                      </Styled.TextEmail>
                    </Styled.ContainerUser>
                    <Styled.ContainerLogout>
                      <MdOutlineLogout
                        size="22px"
                        onClick={() => {
                          setSelectedOption("Principal");
                          // localStorage.setItem("@option", "Principal");
                          localStorage.setItem("@option", "Oportunidades");
                          signOut();
                          onClose();
                        }}
                      />
                    </Styled.ContainerLogout>
                  </Styled.OtionsConfig>
                </Styled.ContainerOtionsConfig>
              </Styled.ContainerConfig>

              <Styled.ContainerAnyLogos>
                <Styled.ContainerLogo>
                  <Styled.ContainerBackgroundAnyLogos>
                    <Styled.ImgLarge
                      alt="logo"
                      src={logoDirecional}
                      style={{ height: "17px" }}
                    />
                  </Styled.ContainerBackgroundAnyLogos>
                </Styled.ContainerLogo>

                <Styled.ContainerLogo>
                  <Styled.ContainerBackgroundAnyLogos>
                    <Styled.ImgLarge
                      alt="logo"
                      src={logoRiva}
                      style={{ height: "28px" }}
                    />
                  </Styled.ContainerBackgroundAnyLogos>
                </Styled.ContainerLogo>
              </Styled.ContainerAnyLogos>
            </Styled.ContainerSidebar>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Styled.Container>
  );
};

export default DrawerSidebar;
