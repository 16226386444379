import React from "react";
import * as Styled from "./styles";

interface IRadioBuutton {
  title: string;
  value: string;
  onChange: React.FormEventHandler<HTMLDivElement>;
  disabled: boolean;
  checked: boolean;
  error: boolean;
}

const InputRadio = ({
  title,
  value,
  onChange,
  disabled,
  checked,
  error
}: IRadioBuutton) => {
  return (
    <Styled.Container>
      <Styled.Label id={title}>
        <Styled.Input
          type="radio"
          name="location"
          id={title}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <Styled.RadioBox error={error} checked={checked} isDisabled={disabled}></Styled.RadioBox>
        <Styled.Paragraph error={error} checked={checked} isDisabled={disabled}>{title}</Styled.Paragraph>
      </Styled.Label>
    </Styled.Container>
  );
};

export default InputRadio;
