import { theme } from "src/styles/theme";
import styled from "styled-components";

export const ContainerName = styled.div`
  display: flex;

  > span {
    font-size: 13px;
    width: 100%;
    color: ${theme.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ContainerDelivery = styled.div`
  display: flex;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerDeveloper = styled.div`
  display: flex;
  justify-content: flex-end;

  > div {
    display: flex;
    background: #dde1e8;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    cursor: pointer;
    gap: 7px;

    > svg {
      display: flex;
      color: ${theme.primary};
      font-size: 22px;
      width: fit-content;
    }

    > span {
      display: flex;
      font-size: 13px;
      font-weight: bold;
      align-self: center;
      justify-content: center;
      width: fit-content;
      color: ${theme.primary};
    }
  }
`;

export const ContainerDashboard = styled.div`
  display: flex;
  justify-content: space-around;
  
  > div {
    background: #dde1e8;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: 120px;
    cursor: pointer;
    height: 30px;
    gap: 7px;

    > svg {
      display: flex;
      font-size: 22px;
      color: ${theme.primary};
      width: fit-content;
    }

    > span {
      font-weight: bold;
      display: flex;
      font-size: 13px;
      justify-content: center;
      align-self: center;
      color: ${theme.primary};
      width: fit-content;
    }

    .error {
      background: #f9e5ea;

      > svg {
        color: ${theme.error}
      }

      > span {
        color: ${theme.error}
      }
    }
  }
`;

export const ContainerUf = styled.div`
  display: flex;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerSpinner = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  align-items: center;
  justify-content: center;

`;

export const TopButton = styled.div`
  width: 150px;
  margin-right: 32px
`;
