import { Textarea } from "@chakra-ui/react";
import * as Styled from "./styles";
import { theme } from "src/styles/theme";

interface ITextarea {
  label: string;
  name: string;
  value: string;
  placeholder?: string;
}

const InputTextarea = ({ label, name, value, placeholder }: ITextarea) => {
  return (
    <Styled.Area>
      {label && <Styled.Label>{label}</Styled.Label>}

      <Textarea
        placeholder={placeholder}
        defaultValue={value}
        name={name}
        resize="none"
        size="lg"
        minH="150px"
        readOnly
        focusBorderColor="none"
        textColor={theme.text}
        fontWeight="bold"
        borderRadius={8}
        fontSize={14}
        borderWidth={2}
        borderColor={theme.disable100}
        cursor="default"
        _hover={{ color: "none" }}
      />
    </Styled.Area>
  );
};

export default InputTextarea;
