import React from "react";
import * as Styled from "../styles";
import RadioSelect from "@components/Sales/Inputs/RadioSelect";
import { UsuallyContext } from "@context/usuallyContex";
import Button from "@components/common/Button";
import { IQuestionsCreateAccountComponent } from "src/@types/Sales";

const QuestionsCreateAccountComponent = ({
  setShowCreateNewAccount,
  navigation,
}: IQuestionsCreateAccountComponent) => {
  const { typeAccount, setTypeAccount } = React.useContext(UsuallyContext);
  const radioJuridica = "Juridica";
  const radioFisica = "Fisica";

  const handleOptionChange = (value: string) => {
    setTypeAccount(value);
  };

  const handleCloseModal = () => {
    setShowCreateNewAccount(false);
    setTypeAccount("");
  };

  return (
    <>
      <Styled.ContainerQuestions>
        <span>1 - Selecione o tipo de conta:</span>
        <Styled.ContainerRadioButtonQuestions>
          <RadioSelect
            label="Pessoa Física"
            checked={typeAccount === radioFisica}
            onChange={() => handleOptionChange(radioFisica)}
          />
          <RadioSelect
            label="Pessoa Jurídica"
            checked={typeAccount === radioJuridica}
            onChange={() => handleOptionChange(radioJuridica)}
          />
        </Styled.ContainerRadioButtonQuestions>
      </Styled.ContainerQuestions>

      <Styled.ContainerButtonNotify>
        <Button error title="CANCELAR" onClick={handleCloseModal} />

        <Button
          title={"Avançar"}
          onClick={() => {
            navigation();
          }}
          disabled={!typeAccount}
        />
      </Styled.ContainerButtonNotify>
    </>
  );
};

export default QuestionsCreateAccountComponent;
