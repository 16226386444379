import * as TextRadio from "@helpers/textRadioButtons";

export const seqPassword = (password: string): any => {
  const sequences = ["012", "123", "234", "345", "456", "567", "678", "789"];
  return sequences.some((seq) => password.includes(seq));
};

export const regexNumber = /^\d+$/;
export const regexRepeat = /(.)\1{2}/;

type ValidateInput = {
  number: boolean;
  length: boolean;
  repeat: boolean;
  document: boolean;
  sequecial: boolean;
  date: boolean;
  confirmPassword?: boolean;
};

const createRadioItem = (title: string, value: string, checked: boolean, error: boolean) => {
  return {
    title: title,
    value: value,
    checked: checked,
    error: error,
  };
};

export const requirementsRadio = (validateInput: ValidateInput) => {
  const radioItems = [
    createRadioItem(TextRadio.isNumeric, TextRadio.isNumeric, !validateInput.number, !validateInput.number),
    createRadioItem(TextRadio.is6Digits, TextRadio.is6Digits, !validateInput.length, !validateInput.length),
    createRadioItem(TextRadio.isDifferentCPFandCNPJ, TextRadio.isDifferentCPFandCNPJ, !validateInput.document, !validateInput.document),
    createRadioItem(TextRadio.isNotSequencial, TextRadio.isNotSequencial, validateInput.sequecial, validateInput.sequecial),
    createRadioItem(TextRadio.isNotRepeat, TextRadio.isNotRepeat, validateInput.repeat, validateInput.repeat),
    createRadioItem(TextRadio.isDifferentData, TextRadio.isDifferentData, !validateInput.date, !validateInput.date),
  ];
  return radioItems;
};
export const requirementsRadioMyProfile = (validateInput: ValidateInput) => {
  const radioItems = [
    createRadioItem(TextRadio.isNumeric, TextRadio.isNumeric, !validateInput.number, !validateInput.number),
    createRadioItem(TextRadio.is6Digits, TextRadio.is6Digits, !validateInput.length, !validateInput.length),
    createRadioItem(TextRadio.isDifferentCPFandCNPJ, TextRadio.isDifferentCPFandCNPJ, !validateInput.document, !validateInput.document),
    createRadioItem(TextRadio.isNotSequencial, TextRadio.isNotSequencial, validateInput.sequecial, validateInput.sequecial),
    createRadioItem(TextRadio.isNotRepeat, TextRadio.isNotRepeat, validateInput.repeat, validateInput.repeat),
    createRadioItem(TextRadio.isDifferentData, TextRadio.isDifferentData, !validateInput.date, !validateInput.date),
    createRadioItem(TextRadio.isConfirmPassword, TextRadio.isConfirmPassword, !validateInput.confirmPassword, !validateInput.confirmPassword),
  ];
  return radioItems;
};
