import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import Button from "@components/common/Button";

interface IAlert {
  onOpen: boolean;
  titleButton?: string;
  onClick?: any;
  onClose: () => void;
  disabledButton?: boolean;
  value1: any;
  value2: any;
}

const AlertCompareValue = ({
  onOpen,
  titleButton,
  onClick,
  onClose,
  disabledButton,
  value1,
  value2,
}: IAlert) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={onOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enviar para aprovação</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} color={theme.error}>
          Parece que o valor dessa unidade fol alterado de <b>{value1}</b> para{" "}
          <b>{value2}</b>, gostaria de prosseguir com essa proposta mesmo assim?
        </ModalBody>
        <ModalBody pb={6}>
          Caso deseje atualizar o valor, faça uma nova cotação para a mesma
          unidade e prossiga a negociação nela.
        </ModalBody>

        {titleButton && (
          <ModalFooter>
            <Button
              title={titleButton}
              onClick={onClick}
              disabled={disabledButton}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AlertCompareValue;
