import React from "react";
import {
  Checkbox,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as Styled from "./styles";
import { AiOutlineDownload } from "react-icons/ai";

type TableAttachment = {
  id: number;
  name: string;
  data: string;
  idAws: string;
};

interface IDataTable {
  data: TableAttachment[];
  nameFilter: string;
  showAllItems?: boolean;
  selectedItems: number[];
  setSelectedItems: (n: number[]) => void;
  downloadFile: (data: TableAttachment) => void;
}

function DataTableAttachment({
  data,
  nameFilter,
  showAllItems,
  selectedItems,
  setSelectedItems,
  downloadFile,
}: Readonly<IDataTable>) {
  const sortKey = "name";
  const sortOrder = "asc";

  const selectCheckbox = (id: number) => {
    const isChecked = selectedItems.includes(id);

    if (isChecked) {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const selectAll = () => {
    if (selectedItems.length === data.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data.map((d) => d.id));
    }
  };

  const sortedData = React.useMemo(() => {
    if (!data) return;
    return data.slice().sort((a: any, b: any) => {
      if (sortKey === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      return sortOrder === "asc"
        ? a[sortKey] - b[sortKey]
        : b[sortKey] - a[sortKey];
    });
  }, [data, sortKey, sortOrder]);

  const filteredRows = sortedData?.filter((row) =>
    row?.name?.toLowerCase()?.includes(nameFilter?.toLowerCase())
  );

  const visibleItemCount = showAllItems ? filteredRows?.length : 4;

  return (
    <Table flexDirection="column" variant="simple" bg="white">
      <Thead>
        <Tr>
          <Th w="5%">
            <Checkbox
              onChange={selectAll}
              isChecked={selectedItems.length === data.length}
            />
          </Th>
          <Th w="30%">
            <Text fontSize={10}>Nome do ativo</Text>
          </Th>

          <Th w="20%" textAlign="center">
            <Text fontSize={10}>Data de envio</Text>
          </Th>

          <Th w="45%" textAlign="end" paddingRight={20}>
            <Text fontSize={10}>Download</Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredRows?.slice(0, visibleItemCount).map((row) => (
          <Tr key={row.id}>
            <Td w="5%">
              <Checkbox
                onChange={() => selectCheckbox(row.id)}
                isChecked={selectedItems.includes(row.id)}
              />
            </Td>

            <Td w="30%">
              <Styled.ContainerName>
                <span>{row.name}</span>
              </Styled.ContainerName>
            </Td>

            <Td w="20%" bg="#dde1e8b3">
              <Styled.ContainerStatus>
                <span>{row.data}</span>
              </Styled.ContainerStatus>
            </Td>

            <Td w="45%">
              <Styled.ContainerDeveloper>
                <div onClick={() => downloadFile(row)}>
                  <AiOutlineDownload />
                  <span>BAIXAR</span>
                </div>
              </Styled.ContainerDeveloper>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default DataTableAttachment;
