import styled from "styled-components";
import { theme } from "../../styles/theme";
import { device } from "../../styles/variable";

export const ContainerCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const BodyCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 85%;
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110px;
  justify-content: flex-end;
`;

export const ContainerInputLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 27%;
`;

export const ContainerButtonLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
  margin-top: 20px;

  @media ${device.tablet} {
    height: 20%;
  }
`;

export const ContainerErrorForgotPassword = styled.div`
  > span {
    color: ${theme.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ContainerButtonForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  justify-content: center;
`;

export const ContainerTwoAuthenticationTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > span {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;
  }

  > svg {
    width: 25px;
    color: ${theme.primary};
  }
`;

export const ContainerTwoAuthenticationSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
  margin-top: 15px;

  > span {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: ${theme.text};
    align-items: center;
  }

  > p {
    display: flex;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
  }
`;

export const ContainerCode = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 15px;

  > span {
    font-size: 12px;
    color: ${theme.text};
  }
`;

export const ContainerInputCode = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 78%;
  justify-content: flex-end;
  align-items: center;
`;

export const ContainerCreatePasswordTitle = styled.div`
  display: flex;
  width: 100%;
  height: 1%;
  justify-content: center;
  align-items: center;

  > span {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;
  }

  > svg {
    width: 25px;
    color: ${theme.primary};
  }
`;

export const ContainerCreatePasswordSubTitle = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  margin-top: 5px;
  margin-bottom: 8px;

  > span {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: ${theme.text};
    align-items: center;
  }
`;

export const ContainerCreatePasswordBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div {
    margin-bottom: 8px;
  }
`;

export const ContainerButtonCreatePassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

export const ContainerSuccessAccessTitle = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  > span {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;
  }

  > svg {
    font-size: 24px;
    width: fit-content;
    color: ${theme.primary};
  }
`;

export const ContainerSuccessAccessBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-bottom: 40px;

  > span {
    display: flex;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    color: ${theme.text};
    font-weight: bold;
    align-items: center;
    margin-bottom: 15px;
  }

  > p {
    display: flex;
    width: 100%;
    font-size: 14px;
    color: ${theme.text};
    align-items: center;
  }
`;
