import api from "@services/api";
import axios from "axios";

interface IUnityService {
  idEmpreendimentoSales: string;
  somenteDisponiveis?: boolean;
}
interface IListSalesMirror {
  IdEmpreendimentoSales: string;
}
interface IUnityByIdService {
  idEmpreendimentoSales: string;
  IdUnidade: string;
}

class UnityService {
  async getUnity({ idEmpreendimentoSales, somenteDisponiveis }: IUnityService) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/unidade/obter", {
        params: { idEmpreendimentoSales, somenteDisponiveis },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async listSalesMirror({ IdEmpreendimentoSales }: IListSalesMirror) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/unidade/obter-espelho-de-vendas", {
        params: { IdEmpreendimentoSales },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async getUnityById({ idEmpreendimentoSales, IdUnidade }: IUnityByIdService) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/unidade/obter-por-id", {
        params: { idEmpreendimentoSales, IdUnidade },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
}

const unityServiceInstance = new UnityService();

export default unityServiceInstance;
