import React from "react";
import * as Styled from "./styles";

interface IRadioBuutton {
  title: string;
  value: string;
  checked: boolean;
  error: boolean;
}

const InputRadioRequirements = ({
  title,
  value,
  checked,
  error,
}: IRadioBuutton) => {
  return (
    <Styled.Container>
      <Styled.Label id={title}>
        <Styled.Input
          type="checkbox"
          name="location"
          id={title}
          value={value}
          checked={checked}
          onChange={() => {}}
        />
        <Styled.RadioBox error={error}></Styled.RadioBox>
        <Styled.Paragraph error={error}>{title}</Styled.Paragraph>
      </Styled.Label>
    </Styled.Container>
  );
};

export default InputRadioRequirements;
