import React from "react";
import * as Styled from "./styles";
import { UsuallyContext } from "@context/usuallyContex";

type DataSalelMirror = {
  nome: string;
  andar: {
    valor: string;
    unidades: {
      idUnidadeSales: number;
      identificador: string;
      statusUnidade: string;
    }[];
  }[];
};

interface IFilterCondition {
  dataSalelMirror: DataSalelMirror[];
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const FilterCondition = ({
  dataSalelMirror,
  setShowError,
  setIndex,
}: IFilterCondition) => {
  const { filterSalesMirror, setFilterSalesMirror } =
    React.useContext(UsuallyContext);
  const [select, setSelect] = React.useState("");

  const statusMappings: { [key: string]: string[] } = {
    "Todas as unidades": ["Todas as unidades"],
    Disponível: ["Disponível", "Mirror - Disponível para venda"],
    Mirror: ["Mirror"],
    Reservada: [
      "Reservada",
      "Reservada aguardando revisão de proposta",
      "Mirror - Reservada",
      "Mirror - Reservada agaurdando revisão de proposta",
    ],
    Bloqueada: [
      "Bloqueada",
      "Distratado",
      "Doação",
      "Fora de venda",
      "Fora de Venda - Comercial",
      "Leilão",
      "Permutada",
      "Suspenso",
    ],
    Vendida: ["Vendida", "Quitado"],
  };

  const lengthAvailable = (filterName: string) => {
    let count = 0;

    dataSalelMirror?.forEach((unity) => {
      unity?.andar.forEach((floor) => {
        floor?.unidades.forEach((unit) => {
          if (
            filterName === "Todas as unidades" ||
            statusMappings[filterName]?.some((status) =>
              unit.statusUnidade.includes(status)
            )
          ) {
            count++;
          }
        });
      });
    });

    return count;
  };

  React.useEffect(() => {
    if (!select) {
      setFilterSalesMirror("Todas as unidades");
      setSelect("Todas as unidades");
    }
  }, [select, setFilterSalesMirror]);

  return (
    <Styled.Container>
      <Styled.Title>Condição da unidade</Styled.Title>
      <Styled.ContainerTab>
        {Object.keys(statusMappings).map((statusName) => (
          <Styled.Tab
            key={statusName}
            name={statusName}
            select={select || filterSalesMirror}
            onClick={() => {
              const availableCount = lengthAvailable(statusName);
              if (availableCount > 0) {
                setFilterSalesMirror(statusName);
                setSelect(statusName);
                setIndex(-1);
              } else {
                setShowError(true);
              }
            }}
          >
            <span>
              {statusName} - {lengthAvailable(statusName)} unid.
            </span>
          </Styled.Tab>
        ))}
      </Styled.ContainerTab>
    </Styled.Container>
  );
};

export default FilterCondition;
