import { Box, Wrap, WrapItem, useToast } from "@chakra-ui/react";
import React from "react";
import { theme } from "src/styles/theme";

interface IMessgeToast {
  onOpen: boolean;
  message: string;
}

const MessageToast = ({ onOpen, message }: IMessgeToast) => {
  const toastIdRef: React.MutableRefObject<any> = React.useRef();
  const toast = useToast();

  const displayMessage = () => {
    toast.close(toastIdRef.current);
    toastIdRef.current = toast({
      status: "error",
      isClosable: true,
      duration: 4000,
      onCloseComplete: () => toast.close(toastIdRef.current),
      render: () => (
        <Box display={"flex"} bg={theme.error} p={4} borderRadius={"8px"}>
          <Box color="white" fontWeight={"bold"}>
            {message}
          </Box>
        </Box>
      ),
      containerStyle: {
        w: "fit-content",
        boxShadow: "dark-lg",
        borderRadius: "8px",
      },
    });
  };

  React.useEffect(() => {
    if (onOpen) {
      displayMessage();
    } else {
      toast.close(toastIdRef.current);
    }
  }, [onOpen, toast]);

  return (
    <Wrap>
      <WrapItem />
    </Wrap>
  );
};

export default MessageToast;
