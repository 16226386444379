import { device } from "src/styles/variable";
import styled from "styled-components";

export const PopoverWrapper = styled.div`
  display: ${(props: { isVisible: boolean }) =>
    props.isVisible ? "block" : "none"};
  position: absolute;
  margin-top: 10px;
  left: 88%;
  width: 20%;
  height: fit-content;
  transform: translateX(-50%);
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 9;

  @media ${device.laptop} {
    width: 25%;
    left: 85%;
  }
  @media ${device.mobileL} {
    width: 80%;
    left: 50%;
  }
`;
