import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

export const ContainerHelp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35%;
  justify-content: center;

  @media ${device.tablet} {
    height: 25%;
  }
`;

export const ContainerHelpBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45%;
  align-items: center;
  justify-content: end;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    color: ${theme.primary};
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
    height: 25px;

    @media ${device.tablet} {
      height: 35px;
    }
  }
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    color: ${theme.primary};
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
    height: 25px;

    @media ${device.tablet} {
      height: 35px;
    }
  }
`;

export const ContainerCount = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.primary};
  font-size: 13px;
  font-weight: bold;
  width: fit-content;
  height: 25px;
  cursor: pointer;

  @media ${device.tablet} {
    height: 35px;
  }
`;
