import React from "react";
import * as Styled from "./styles";
import { BsArrowLeft } from "react-icons/bs";

interface IRange {
  value: string;
  max: number;
  countSteps: string;
  onBack: any;
  disabled?: boolean;
}

const InputSlider = ({ value, max, countSteps, onBack, disabled }: IRange) => {
  return (
    <Styled.Container>
      <Styled.InputSlider type="range" min={0} max={max} value={value} />

      <Styled.ContainerBack>
        <Styled.ContainerLeft>
          <Styled.ButtonBack disabled={disabled}>
            <BsArrowLeft onClick={onBack} />
            <span onClick={onBack}>Voltar</span>
          </Styled.ButtonBack>
        </Styled.ContainerLeft>

        <Styled.Divider />

        <Styled.ContainerRight>
          <Styled.ContainerCountSteps>
            <span>
              Etapa {countSteps}/{max}
            </span>
          </Styled.ContainerCountSteps>
        </Styled.ContainerRight>
      </Styled.ContainerBack>
    </Styled.Container>
  );
};

export default InputSlider;
