import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  HStack,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import * as Styled from "./styles";
import { BiArea } from "react-icons/bi";
import { BsCarFront, BsGrid1X2Fill } from "react-icons/bs";
import { TbPigMoney } from "react-icons/tb";
import { formatMoney, formatUnit } from "@helpers/format";
import { UsuallyContext } from "@context/usuallyContex";
import ModalCard from "../ModalCard";
import Alert from "@components/common/Alert";
import Tooltip from "@components/common/Tooltip";

type DataSalelMirror = {
  nome: string;
  andar: {
    valor: string;
    unidades: {
      idUnidadeSales: number;
      identificador: string;
      statusUnidade: string;
      areaPrivativaPrincipal: string;
      numQuartos: string;
      valorFinalKit: string;
      qtdeVagas: string;
      descVagas: string;
      bonusAdimplencia: string;
      valorAvalBancaria: string;
      outrasDescricoes: string;
    }[];
  }[];
};

type Blocks = {
  idUnidadeSales: number;
  identificador: string;
  statusUnidade: string;
  areaPrivativaPrincipal: string;
  numQuartos: string;
  valorFinalKit: string;
  qtdeVagas: string;
  descVagas: string;
  bonusAdimplencia: string;
  valorAvalBancaria: string;
  outrasDescricoes: string;
};

interface IAccordionBlock {
  dataSalelMirror: DataSalelMirror[];
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const AccordionBlock = ({
  dataSalelMirror,
  showError,
  setShowError,
  index,
  setIndex,
}: IAccordionBlock) => {
  const { filterSalesMirror } = React.useContext(UsuallyContext);
  const statusMappings: { [key: string]: string[] } = {
    "Todas as unidades": ["Todas as unidades"],
    Disponível: ["Disponível", "Mirror - Disponível para venda"],
    Mirror: ["Mirror"],
    Reservada: [
      "Reservada",
      "Reservada aguardando revisão de proposta",
      "Mirror - Reservada",
      "Mirror - Reservada agaurdando revisão de proposta",
    ],
    Bloqueada: [
      "Bloqueada",
      "Distratado",
      "Doação",
      "Fora de venda",
      "Fora de Venda - Comercial",
      "Leilão",
      "Permutada",
      "Suspenso",
    ],
    Vendida: ["Vendida", "Quitado"],
  };

  const [showModalError, setShowModalError] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedUnit, setSelectedUnit] = React.useState<Blocks>({
    idUnidadeSales: 0,
    statusUnidade: "",
    identificador: "",
    descVagas: "",
    numQuartos: "",
    qtdeVagas: "",
    areaPrivativaPrincipal: "",
    valorFinalKit: "",
    bonusAdimplencia: "",
    valorAvalBancaria: "",
    outrasDescricoes: "",
  });

  const filteredData = () => {
    if (!dataSalelMirror) return [];

    return dataSalelMirror;
  };

  const filteredRows = filteredData()?.filter((row) =>
    row?.andar?.some((floor) =>
      floor.unidades.some(
        (unit) =>
          filterSalesMirror === "Todas as unidades" ||
          statusMappings[filterSalesMirror]?.some((status) =>
            unit.statusUnidade?.includes(status)
          )
      )
    )
  );

  const filteredUnits = filteredData()?.map(({ nome, andar }) => {
    const filteredAndar = andar
      ?.map(({ valor, unidades }) => ({
        valor,
        unidades: unidades.filter(
          (unit) =>
            filterSalesMirror === "Todas as unidades" ||
            statusMappings[filterSalesMirror]?.some((status) =>
              unit.statusUnidade?.includes(status)
            )
        ),
      }))
      .filter(({ unidades }) => unidades.length > 0);

    return { nome, andar: filteredAndar };
  });

  const lengthAvailable = (nameFloor: string, filterName: string) => {
    let count = 0;

    filteredData()?.forEach((unity) => {
      if (unity.nome === nameFloor) {
        unity.andar.forEach((floor) => {
          floor.unidades.forEach((unit) => {
            if (
              filterName === "Todas as unidades" ||
              statusMappings[filterName]?.some((status) =>
                unit.statusUnidade.includes(status)
              )
            ) {
              count++;
            }
          });
        });
      }
    });

    return count;
  };

  const block = (name: string, floor: string) => {
    let blocks: Blocks[] = [];
    filteredUnits.forEach((unity) => {
      if (unity.nome === name) {
        unity.andar.forEach((unityFloor) => {
          if (unityFloor.valor === floor) {
            blocks = unityFloor.unidades;
          }
        });
      }
    });
    return blocks;
  };

  React.useEffect(() => {
    if (filteredUnits.length === 0 || filteredRows.length === 0) {
      setShowModalError(true);
    }
  }, [filteredRows.length, filteredUnits.length]);

  return (
    <Accordion allowToggle index={index}>
      <ModalCard
        title={selectedUnit.identificador}
        type={selectedUnit.numQuartos}
        onOpen={showModal}
        onClose={() => setShowModal(false)}
        filterSalesMirror={selectedUnit.statusUnidade.toLowerCase()}
      >
        <Styled.BlockBottomExpand>
          <Styled.ContainerGroupExpand>
            <span>Descrição das Vagas: {selectedUnit.descVagas}</span>
          </Styled.ContainerGroupExpand>
          <Styled.ContainerGroupExpand>
            <span>Quartos: {selectedUnit.numQuartos} </span>
            <span>Vagas: {selectedUnit.qtdeVagas} </span>
          </Styled.ContainerGroupExpand>

          <Styled.ContainerGroupExpandColumnTooltip>
            <span>
              Area Privativa principal:{" "}
              <Tooltip
                text={"Soma da área coberta e área descoberta (se houver)"}
              >
                <Styled.IconBsFillInfoCircleFill size="12px" />
              </Tooltip>
            </span>
            <span>{formatUnit(selectedUnit.areaPrivativaPrincipal)}</span>
          </Styled.ContainerGroupExpandColumnTooltip>
          <Styled.ContainerGroupExpandColumn>
            <span>Valor final: </span>
            <span>{formatMoney(selectedUnit.valorFinalKit)}</span>
          </Styled.ContainerGroupExpandColumn>
          <Styled.ContainerGroupExpand>
            <span>
              Bônus adimplencia da unidade:{" "}
              {formatMoney(selectedUnit.bonusAdimplencia)}
            </span>
          </Styled.ContainerGroupExpand>
          <Styled.ContainerGroupExpand>
            <span>
              Valor da avaliação bancária:{" "}
              {formatMoney(selectedUnit.valorAvalBancaria)}
            </span>
          </Styled.ContainerGroupExpand>
        </Styled.BlockBottomExpand>
        <Styled.ContainerGroupExpandDesc>
          <span>Outras descrições: {selectedUnit.outrasDescricoes}</span>
        </Styled.ContainerGroupExpandDesc>
        <Styled.ContainerGroupExpandButton>
          <Styled.ButtonGroupExpand
            filter={selectedUnit.statusUnidade.toLowerCase()}
          >
            <span>{selectedUnit.statusUnidade}</span>
          </Styled.ButtonGroupExpand>
        </Styled.ContainerGroupExpandButton>
      </ModalCard>

      <Alert
        onOpen={showModalError || showError}
        title="Unidades indisponíveis!"
        titleButton={"Voltar"}
        description={
          "No momento não existe nenhuma unidade com o status selecionado."
        }
        onClick={() => {
          setShowModalError(false);
          setShowError(false);
        }}
      />

      {filteredUnits
        .filter((andar) => andar.andar.length > 0)
        .map(({ nome, andar }, i) => (
          <AccordionItem
            key={i}
            border={"1px"}
            borderColor={"#C3C3C3"}
            borderRadius={8}
            mt={"10px"}
          >
            <AccordionButton
              
              onClick={() => {
                if (index === i) {
                  setIndex(-1);
                } else {
                  setIndex(i);
                }
              }}
            >
              <AccordionIcon />
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                fontSize={"20px"}
              >
                {nome}
              </Box>

              <HStack w={"fit-content"}>
                {Object.keys(statusMappings).map((statusName, i) => (
                  <Styled.ContainerShowFilter key={i} filter={statusName}>
                    {lengthAvailable(nome, statusName)}
                  </Styled.ContainerShowFilter>
                ))}
              </HStack>
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Styled.AreaBlockBottom>
                {andar.map((floor) => (
                  <div key={floor.valor}>
                    <Styled.ContainerFloors>
                      {floor.unidades && <span>{floor.valor}° Andar</span>}
                    </Styled.ContainerFloors>

                    <Styled.AreaBlock>
                      {block(nome, floor.valor).map(
                        ({
                          idUnidadeSales,
                          statusUnidade,
                          identificador,
                          areaPrivativaPrincipal,
                          numQuartos,
                          valorFinalKit,
                          qtdeVagas,
                          descVagas,
                          bonusAdimplencia,
                          valorAvalBancaria,
                          outrasDescricoes,
                        }) => (
                          <Styled.ContainerBlock
                            key={idUnidadeSales}
                            filter={statusUnidade.toLowerCase()}
                            onClick={() => {
                              setShowModal(true);
                              setSelectedUnit({
                                idUnidadeSales,
                                statusUnidade,
                                identificador,
                                descVagas,
                                numQuartos,
                                qtdeVagas,
                                areaPrivativaPrincipal,
                                valorFinalKit,
                                bonusAdimplencia,
                                valorAvalBancaria,
                                outrasDescricoes,
                              });
                            }}
                          >
                            <Styled.BlockTop
                              filter={statusUnidade.toLowerCase()}
                            >
                              <span>{identificador}</span>
                            </Styled.BlockTop>

                            <Styled.Divider />

                            <Styled.BlockBottom
                              filter={statusUnidade.toLowerCase()}
                            >
                              <Styled.ContainerGroupIcon>
                                <BiArea />
                                <span>
                                  {formatUnit(areaPrivativaPrincipal)}
                                </span>
                              </Styled.ContainerGroupIcon>
                              <Styled.ContainerGroupIcon>
                                <BsGrid1X2Fill />
                                <span>{numQuartos}Q</span>
                              </Styled.ContainerGroupIcon>
                              <Styled.ContainerGroupIcon>
                                <TbPigMoney />
                                <span>{formatMoney(valorFinalKit)}</span>
                              </Styled.ContainerGroupIcon>
                              <Styled.ContainerGroupIcon>
                                <BsCarFront />
                                <span>{qtdeVagas}V</span>
                              </Styled.ContainerGroupIcon>
                            </Styled.BlockBottom>
                          </Styled.ContainerBlock>
                        )
                      )}
                    </Styled.AreaBlock>
                  </div>
                ))}
              </Styled.AreaBlockBottom>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
};

export default AccordionBlock;
