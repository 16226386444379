import { theme } from "src/styles/theme";
import styled from "styled-components";

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${theme.text};
  margin-left: 5px;
  width: 100%;
`;
