import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface IDataTable {
  status?: string;
}

export const ContainerName = styled.div`
  display: flex;
  background: #dde1e8;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;

  > span {
    font-size: 13px;
    width: fit-content;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    color: ${theme.primary};
    text-decoration: underline;
  }
`;

export const ContainerDelivery = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerStatus = styled.div`
  display: flex;
  background: ${(props: IDataTable) => {
    switch (props.status) {
      case "PRONTO":
        return "#DDE1E8";
      case "NÃO RELACIONADO":
        return theme.disable100;
      default:
        return "#F9E5EA";
    }
  }};
  border-radius: 8px;
  align-self: center;
  justify-content: center;
  height: 30px;

  @media ${device.mobileL} {
    width: 100px;
  }

  > span {
    display: flex;
    font-size: 13px;
    font-weight: bold;
    align-self: center;
    justify-content: center;
    color: ${(props: IDataTable) => {
      switch (props.status) {
        case "PRONTO":
          return theme.primary;
        case "NÃO RELACIONADO":
          return theme.text;
        default:
          return theme.error;
      }
    }};
  }
`;

export const ContainerDeveloper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

export const ContainerUf = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;
