import { ReactNode } from "react";
import {
  Box,
  Modal as CkModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
interface IModal {
  onOpen: boolean;
  title: string;
  type: string;
  children: ReactNode;
  onClose?: any;
  filterSalesMirror: string;
}

const ModalCard = ({
  onOpen,
  title,
  children,
  onClose,
  filterSalesMirror,
  type,
}: IModal) => {
  function bgColor(filter: string) {
    if (filter === "disponível") return theme.available;
    if (filter === "mirror - disponível para venda") return theme.available;
    if (filter === "mirror") return theme.gold;
    if (filter === "reservada") return theme.error;
    if (filter === "reservada aguardando revisão de proposta")
      return theme.error;
    if (filter === "bloqueada") return theme.text;
    if (filter === "distratado") return theme.text;
    if (filter === "doação") return theme.text;
    if (filter === "fora de venda") return theme.text;
    if (filter === "fora de venda - comercial") return theme.text;
    if (filter === "leilão") return theme.text;
    if (filter === "permutada") return theme.text;
    if (filter === "suspenso") return theme.text;
    if (filter === "vendida") return theme.disable;
    if (filter === "quitado") return theme.disable;
    return theme.primary;
  }

  return (
    <CkModal
      closeOnOverlayClick={false}
      isOpen={onOpen}
      onClose={onClose}
      size={"md"}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(6px)" />
      <ModalContent minH={"fit-content"} borderRadius={8}>
        <ModalHeader
          display={"flex"}
          bg={bgColor(filterSalesMirror)}
          color={"white"}
          justifyContent={"space-between"}
          borderTopRadius={8}
        >
          <Box w={"90%"}>{title}</Box> <Box>Tipologia: Tipo {type}Q</Box>
        </ModalHeader>
        <ModalCloseButton color={"white"} />
        <ModalBody pb={10}>{children}</ModalBody>
      </ModalContent>
    </CkModal>
  );
};

export default ModalCard;
