import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { device } from "../../../../styles/variable";

export const ContainerCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const ButtonFirstAccess = styled.div`
  > span {
    width: fit-content;
    color: ${theme.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const BodyCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  height: 90%;
`;

export const ContainerInputLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
`;
export const ContainerInputPassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 28%;
  margin-top: 8px;

  @media ${device.tablet} {
    height: 26%;
  }
  @media ${device.laptopL} {
    height: 30%;
  }
`;

export const ContainerForgotPassword = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;

  > span {
    width: fit-content;
    font-size: 13px;
    color: ${theme.primary};
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110px;
  justify-content: flex-end;
`;

export const ContainerButtonLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 16%;

  @media ${device.laptopXL} {
    height: 14%;
  }
  @media ${device.tablet} {
    height: 20%;
  }
`;
