import React from "react";
import { LoginContext } from "@context/loginContext";
import BreadCrumb from "@components/common/BreadCrumb";
import { UsuallyContext } from "@context/usuallyContex";
import EnterpriseComponent from "./Steps/EnterpriseComponent";
import DetailEnterpriseComponent from "./Steps/DetailEnterpriseComponent";
import UnityComponent from "./Steps/UnityComponent";
import * as Styled from "./styles";
import { IHashMapKeyString } from "src/@types/Enterprises";
import { capitalize } from "@helpers/format";
import { useNavigate } from "react-router-dom";

const Enterprises = () => {
  const navigate = useNavigate();
  const refToTop = React.useRef<HTMLInputElement>(null);
  const { openSidebar, setPageSidebar } = React.useContext(LoginContext);
  const { nameEnterprisesBreadcrumb } = React.useContext(UsuallyContext);
  const hash = "Enterprise";

  const regional = localStorage.getItem("@regional");
  const company: any = localStorage.getItem("@empresa");

  const enterprise = `${regional} ${capitalize(company)}`;

  const breadcrumbItems = [
    {
      name: "Principal",
      link: "/",
      onClick: () => {
        setPageSidebar("Principal");
        localStorage.setItem("@option", "Principal");
      },
    },
    {
      name: "Empreendimento",
      link: "/empreendimentos",
      onClick: () => navigate("/empreendimentos"),
    },
    {
      name: !nameEnterprisesBreadcrumb ? enterprise : nameEnterprisesBreadcrumb,
      link: "/empreendimentos",
      onClick: () => navigate("/empreendimentos"),
    },
  ];

  const handleTop = () => {
    refToTop.current && refToTop.current.scrollIntoView();
  };

  const EnterprisesHashMap: IHashMapKeyString = {
    Enterprise: <EnterpriseComponent />,
    DetailEnterprise: <DetailEnterpriseComponent />,
    Unity: (
      <UnityComponent
      // onClickSearch={() => {
      //   setHash("DetailEnterprise");
      // }}
      />
    ),
  };

  return (
    <Styled.Area open={openSidebar}>
      <Styled.Container hash={hash}>
        <Styled.SubContainer ref={refToTop}>
          <Styled.ContainerTop>
            <BreadCrumb items={breadcrumbItems} />
          </Styled.ContainerTop>
          {EnterprisesHashMap[hash]}
        </Styled.SubContainer>
      </Styled.Container>
    </Styled.Area>
  );
};

export default Enterprises;
