import React, { useEffect } from "react";
import * as Styled from "../../styles";
import { BsBank, BsClockHistory } from "react-icons/bs";
import Tab from "@components/Sales/Tab";
import ActivityComponent from "../ActivityComponent";
import Button from "@components/common/Button";
import { FiPlus } from "react-icons/fi";
import Modal from "@components/Sales/Modal";
import CreateScheduleComponent from "../Schedule/CreateScheduleComponent";
import ButtonPopover from "@components/Sales/ButtonPopover";
import DetailComponent from "./DetailComponent";
import CreateTaskCallComponent from "../TasksCalls/CreateTaskCallComponent";
import { OportunityContext } from "@context/oportunityContex";
import CreateCreditRatingComponent from "../CreditRating/CreateCreditRatingComponent";
import {
  MdOutlineSpatialAudioOff,
  MdChecklist,
  MdOutlineEditNote,
} from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import Stepp from "@components/Sales/Stepp";
import {
  IDetailOportunityComponent,
  IHashMapKeyString,
} from "src/@types/Sales";
import ViewCreditRatingComponent from "../CreditRating/ViewCreditRatingComponent";
import ContractsComponent from "../Contracts/ContractsComponent";
import { RxUpdate } from "react-icons/rx";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import QuotesComponent from "../Quotes/QuotesComponent";
import ViewQuotesComponent from "../Quotes/ViewQuotesComponent";
import Spinner from "@components/common/Spinner";
import CreateQuotesComponent from "../Quotes/CreateQuotesComponent";
import RelationshipComponent from "../Relationship/RelationshipComponent";

const DetailOportunityComponent = ({
  hash = "DetailOportunity",
  setHash,
  navigationCreateAccount,
}: IDetailOportunityComponent) => {
  const {
    faseOp,
    opportunityId,
    opportunityName,
    accessCreateOp,
    contracts,
    setLoading,
    setContracts,
    setOnboardingDocuments,
    accessQuote,
    accessAttachments,
    setDisabledCreateDocument
  } = React.useContext(OportunityContext);
  const [modalSchedule, setModalSchedule] = React.useState(false);
  const [successCreateSchedule, setSuccessCreateSchedule] =
    React.useState(false);
  const [successCreateTaskCall, setSuccessCreateTaskCall] =
    React.useState(false);
  const [modalTaskCall, setModalTaskCall] = React.useState(false);
  const [modalCreditRating, setModalCreditRating] = React.useState(false);
  const [successCreateCreditRating, setSuccessCreateCreditRating] =
    React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [updateData, setUpdateData] = React.useState({ data: "" });
  const [edit, setEdit] = React.useState(false);
  const [currentFase, setCurrentFase] = React.useState("");
  const [isEditQuote, setIsEditQuote] = React.useState(false);
  const [canEditQuote, setCanEditQuote] = React.useState(false);
  const [loadingGetOportunity, setloadingGetOportunity] = React.useState(false);
  const [dataOportunidade, setDataOportunidade] = React.useState("");
  const [probabilidade, setProbabilidade] = React.useState("");
  const [modalQuot, setModalQuot] = React.useState(false);
  const [successCreateQuote, setSuccessCreateQuote] = React.useState(false);

  const [showDetailTaskCall, setShowDetailTaskCall] = React.useState(false);
  const [showDetailSchedule, setShowDetailSchedule] = React.useState(false);
  const [cancelButtonAdress, setCancelButtonAdress] = React.useState(false);

  let contractsTitle = "Contratos";
  if (contracts.length > 0) {
    contractsTitle += ` (${contracts.length})`;
  }
  const tabLabels = [
    "Detalhes",
    "Atividades",
    "Cotações",
    contractsTitle,
    "Relacionamento",
  ];
  const tabContents = {
    Detalhes: (
      <DetailComponent
        indexFirstTab={index}
        edit={edit}
        setEdit={setEdit}
        setCurrentFase={setCurrentFase}
        updateData={updateData}
        setDataOportunidade={setDataOportunidade}
        loadingGetOportunity={loadingGetOportunity}
        setloadingGetOportunity={setloadingGetOportunity}
        setProbabilidade={setProbabilidade}
        cancelButtonAdress={cancelButtonAdress}
        setCancelButtonAdress={setCancelButtonAdress}
      />
    ),
    Atividades: (
      <ActivityComponent
        indexFirstTab={index}
        successCreateSchedule={successCreateSchedule}
        navigateCreateSchedule={() => setModalSchedule(true)}
        navigateCreateTask={() => setModalTaskCall(true)}
        successCreateTaskCall={successCreateTaskCall}
        navigateCreateCreditRating={() => setModalCreditRating(true)}
        successCreateCreditRating={successCreateCreditRating}
        setHashCreateCreditRating={setHash}
        updateData={updateData}
        showDetailTaskCall={showDetailTaskCall}
        setShowDetailTaskCall={setShowDetailTaskCall}
        showDetailSchedule={showDetailSchedule}
        setShowDetailSchedule={setShowDetailSchedule}
      />
    ),
    Cotações: (
      <QuotesComponent
        indexFirstTab={index}
        setHash={setHash}
        setIsEdit={setIsEditQuote}
        navigateCreateQuote={() => setModalQuot(true)}
        setCanEdit={setCanEditQuote}
        successCreateQuote={successCreateQuote}
        setSuccessCreateQuote={setSuccessCreateQuote}
        updateData={updateData}
      />
    ),
    [contractsTitle]: <ContractsComponent />,
    Relacionamento: (
      <RelationshipComponent
        indexFirstTab={index}
        navigationCreateAccount={navigationCreateAccount}
        updateData={updateData}
      />
    ),
  };

  const arrayButtons = [
    {
      title: `Atividade da oportunidade`,
      onClick: () => setModalTaskCall(true),
    },
    {
      title: `Agendamento da oportunidade`,
      onClick: () => setModalSchedule(true),
    },
    {
      title: `Avaliação de crédito`,
      onClick: () => setModalCreditRating(true),
    },
    {
      title: `Cotação da oportunidade`,
      onClick: () => setModalQuot(true),
    },
  ];

  const steps = [
    {
      title: "Aguardando atendimento",
      icon: <BsClockHistory fontSize={"20px"} />,
      states: ["aguardando atendimento corretor"],
    },
    {
      title: "Em atendimento",
      icon: <MdOutlineSpatialAudioOff fontSize={"20px"} />,
      states: ["em atendimento", "visita agendada", "visita realizada"],
    },
    {
      title: "Análise de crédito",
      icon: <BsBank fontSize={"20px"} />,
      states: ["em análise de crédito", "análise de crédito realizada"],
    },
    {
      title: "Cotação",
      icon: <MdChecklist fontSize={"20px"} />,
      states: [
        "proposta",
        "proposta aprovada",
        "proposta reprovada",
        "análise safi",
        "aprovado safi",
        "rejeitado safi",
      ],
    },
    {
      title: "Contrato",
      icon: <IoDocumentTextOutline fontSize={"20px"} />,
      states: [
        "contrato gerado",
        "contrato comunicado",
        "contrato com pendência comercial",
      ],
    },
    {
      title:
        currentFase?.toLowerCase() === "fechado e perdido"
          ? "Fechado e Perdido"
          : "Fechado e Ganho",
      icon: <></>,
      states:
        currentFase?.toLowerCase() === "fechado e perdido"
          ? ["fechado e perdido"]
          : ["fechado e ganho"],
    },
  ];

  useEffect(() => {
    if (accessQuote.nomeDaCotacao === "") {
      setIndex(0);
    }
  }, [accessQuote]);

  const handleUpdateOportunity = async () => {
    setloadingGetOportunity(true);
    try {
      const response = await oportunityServiceInstance.getOportunityById({
        OportunidadeId: accessCreateOp.oportunidadeId,
      });

      if (response.status === 401) {
        setloadingGetOportunity(false);
        return;
      }

      setDisabledCreateDocument(response?.contaPossuiCPF );
      setCurrentFase(response.fase);
      setloadingGetOportunity(false);
    } catch (error) {
      setloadingGetOportunity(false);
      console.log("ErrorHandleGetOportunityById", error);
    }
  };

  const SalesHashMap: IHashMapKeyString = {
    DetailOportunity: (
      <>
        <Styled.ContainerTitlePage>
          <Styled.ContainerTitle>
            <Styled.TitlePage>
              {opportunityName || accessCreateOp?.nomeDaConta?.toUpperCase()} (
              {opportunityId || accessCreateOp.oportunidadeId})
            </Styled.TitlePage>
          </Styled.ContainerTitle>

          <Styled.ContainerAnyButtons>
            <Styled.ContainerUpdateButton>
              <Button
                title={
                  <Styled.TitleButtons>
                    <RxUpdate />
                  </Styled.TitleButtons>
                }
                disabled={faseOp}
                onClick={() => {
                  setUpdateData({ data: "any" });
                  handleUpdateOportunity();
                  setLoading(true);
                  setOnboardingDocuments([]);
                  oportunityServiceInstance
                    .getOnboardingDocuments({
                      CodigoRelacionamentoComComprador:
                        accessAttachments.codigoRelacionamentoComComprador,
                      CPF: accessAttachments.cpf,
                    })
                    .then((d) => {
                      setOnboardingDocuments(d);
                      setLoading(false);
                    });

                  oportunityServiceInstance
                    .getContracts({
                      opportunityIdSalesforce:
                        accessCreateOp.oportunityIdSalesforce,
                    })
                    .then((c) => {
                      setContracts(c);
                    });
                }}
              />
            </Styled.ContainerUpdateButton>
            <Styled.ContainerButtonPlus indexTab={index === 0}>
              {index === 0 && (
                <Button
                  title={
                    <Styled.TitleButtons>
                      <MdOutlineEditNote />
                      EDITAR
                    </Styled.TitleButtons>
                  }
                  onClick={() => {
                    setEdit(true);
                    setCancelButtonAdress(false);
                  }}
                  disabled={edit || faseOp}
                />
              )}

              <ButtonPopover
                disabled={faseOp}
                title={
                  <Styled.TitleButtons>
                    <FiPlus />
                    CRIAR
                  </Styled.TitleButtons>
                }
                popoverContent={arrayButtons.map((button) => (
                  <Styled.AreaButtonsPopover key={button.title}>
                    <Styled.BoxButtonsPopover>
                      <Styled.TitleButtonsPopover onClick={button.onClick}>
                        {button.title}
                      </Styled.TitleButtonsPopover>

                      <Styled.DividerButtonsPopover />
                    </Styled.BoxButtonsPopover>
                  </Styled.AreaButtonsPopover>
                ))}
              />
            </Styled.ContainerButtonPlus>
          </Styled.ContainerAnyButtons>
        </Styled.ContainerTitlePage>

        <Styled.ContainerFaseDatailOportunity>
          {loadingGetOportunity ? (
            <Styled.AreaFaseDatailOportunityLoading>
              <Spinner />
            </Styled.AreaFaseDatailOportunityLoading>
          ) : (
            <Styled.AreaFaseDatailOportunity>
              <span>Acompanhamento da oportunidade:</span>

              <Styled.ContainerFollow>
                <span>{dataOportunidade}</span>
                <Styled.DividerFollow />
              </Styled.ContainerFollow>

              <Styled.ContainerStepp>
                <Stepp
                  steps={steps}
                  activeStep={currentFase}
                  progressPercent={probabilidade ? Number(probabilidade) : 0}
                />
              </Styled.ContainerStepp>
            </Styled.AreaFaseDatailOportunity>
          )}
        </Styled.ContainerFaseDatailOportunity>

        <Styled.ContainerActivityDatailOportunity>
          <Tab
            widthXl="55%"
            width="80%"
            widthMd="80%"
            widthSm="100%"
            widthBase="100%"
            setIndex={setIndex}
            tabLabels={tabLabels}
            tabContents={tabContents}
            disabled={loadingGetOportunity}
          />
        </Styled.ContainerActivityDatailOportunity>
      </>
    ),

    ViewCreditRatingComponent: <ViewCreditRatingComponent hash={hash} />,

    ViewQuotesComponent: (
      <ViewQuotesComponent
        hash={hash}
        isEdit={isEditQuote}
        setIsEdit={setIsEditQuote}
        canEdit={canEditQuote}
      />
    ),
  };

  return (
    <>
      <Modal
        onOpen={modalSchedule}
        title="Criar agendamento"
        subtitle="Preencha as informações para registro e acompanhamento no sistema."
        onClose={() => setModalSchedule(false)}
      >
        <CreateScheduleComponent
          onBack={() => setModalSchedule(false)}
          setSuccessCreateSchedule={setSuccessCreateSchedule}
          setShowDetailSchedule={setShowDetailSchedule}
        />
      </Modal>

      <Modal
        onOpen={modalTaskCall}
        title="Criar atividade da oportunidade"
        subtitle="Preencha as informações para registro e acompanhamento no sistema."
        onClose={() => setModalTaskCall(false)}
      >
        <CreateTaskCallComponent
          onBack={() => setModalTaskCall(false)}
          setSuccessCreateTaskCall={setSuccessCreateTaskCall}
          setShowDetailTaskCall={setShowDetailTaskCall}
        />
      </Modal>

      <Modal
        onOpen={modalCreditRating}
        title="Criar avaliação de crédito"
        subtitle="Preencha as informações que serão enviadas para o correspondente bancário realizar a análise de crédito."
        onClose={() => setModalCreditRating(false)}
      >
        <CreateCreditRatingComponent
          onBack={() => setModalCreditRating(false)}
          setSuccessCreateCreditRating={setSuccessCreateCreditRating}
          setHash={setHash}
        />
      </Modal>

      <Modal
        onOpen={modalQuot}
        title="Criar cotação"
        subtitle="Preencha as informações para registro e acompanhamento no sistema."
        onClose={() => setModalQuot(false)}
      >
        <CreateQuotesComponent
          onBack={() => setModalQuot(false)}
          setSuccessCreateQuote={setSuccessCreateQuote}
          setIsEdit={setIsEditQuote}
          setHash={setHash}
        />
      </Modal>

      {SalesHashMap[hash]}
    </>
  );
};

export default DetailOportunityComponent;
