import { theme } from "src/styles/theme";
import styled from "styled-components";

interface ISelect {
  isOpen?: boolean;
  disabled?: boolean;
  error?: boolean;
  isSize?: boolean;
  height?: string;
  width?: string;
}

export const Container = styled.div`
  width: 100%;
  height: 50%;
`;

export const SelectButton = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  border: ${(props: ISelect) => {
    if (props.disabled) return `2px solid ${theme.disable100}`;
    if (props.error) return `3px solid ${theme.error}`;
    return `2px solid ${theme.disable}`;
  }};
  z-index: 1;
`;

export const Label = styled.span`
  display: flex;
  font-size: 12px;
  margin-left: 3px;
  width: 98%;
  color: ${theme.text};
`;

export const Title = styled.span`
  color: ${theme.text};
  font-weight: bold;
  font-size: ${(props: ISelect) => (props.isSize ? "12px" : "13px")};
`;

export const ContainerIcon = styled.span`
  display: flex;
  width: fit-content;
  color: #fff;
  align-items: center;
  justify-content: center;

  > svg {
    color: ${theme.disable};
    font-size: 32px;
  }
`;

export const ListItems = styled.ul`
  display: ${(props: ISelect) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  background: white;
  width: ${(props: ISelect) => (props.width ? props.width : "18%")};
  max-height: ${(props: ISelect) => (props.height ? props.height : "16%")};
  position: absolute;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid ${theme.disable};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }
`;
