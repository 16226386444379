import styled from "styled-components";
import { theme } from "../../../../styles/theme";

interface IRadioButton {
  isDisabled: boolean;
  error: boolean;
  checked: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
export const Label = styled.label`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.span<IRadioButton>`
  width: fit-content;
  margin-left: 5px;
  color: ${(props) => {
    if (props.isDisabled) return theme.disable;
    if (props.error) return theme.error;
    if (props.checked) return theme.primary;
    return theme.text;
  }};
  font-size: 14px;
  font-weight: bold;
  opacity: ${(props) => (props.isDisabled ? 0.3 : 1)};
`;

export const RadioBox = styled.div`
  display: flex;
  width: 19px;
  height: 17px;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${(props: IRadioButton) => {
      if (props.isDisabled) return theme.disable;
      if (props.error) return theme.error;
      if (props.checked) return theme.primary;
      return theme.text;
    }};
  border-radius: 100%;
  transition: background 0.15s, border-color 0.15s;
  opacity: ${(props: IRadioButton) => (props.isDisabled ? 0.3 : 1)};
  cursor: pointer;

  &::after {
    display: flex;
    content: "";
    width: 11px;
    height: 11px;
    background: ${theme.primary};
    border-radius: 100%;
    cursor: pointer;
    transform: scale(0);
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    &::after {
      transform: scale(1);
    }
  }
`;
