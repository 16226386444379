import React from "react";
import * as Styled from "./styles";
import { BsArrowLeft } from "react-icons/bs";

interface ITabs {
  onClick?: React.FormEventHandler<HTMLButtonElement>;
  onBack?: React.MouseEventHandler<SVGElement>;
  isActive: string;
  page: string;
  subtitle: string;
}

const TabsSolo = ({ onClick, onBack, isActive, page, subtitle }: ITabs) => {
  return (
    <Styled.Container>
      <Styled.Nav>
        <Styled.ContainerCenter>
          <BsArrowLeft onClick={onBack} />

          <Styled.TabsCenter onClick={onClick} isActive={isActive} page={page}>
            {page}
          </Styled.TabsCenter>
        </Styled.ContainerCenter>
        <Styled.Slider />
      </Styled.Nav>
      <span>{subtitle}</span>
    </Styled.Container>
  );
};

export default TabsSolo;
