import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { device } from "../../../../styles/variable";

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
`;

export const ContainerTabs = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const ContainerCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const BodyCurrentLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 85%;
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110px;
  justify-content: flex-end;
`;

export const ContainerInputLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 33%;
`;

export const ContainerTwoAuthenticationTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > span {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;
  }

  > svg {
    width: 25px;
    color: ${theme.primary};
  }
`;

export const ContainerTwoAuthenticationSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
  margin-top: 15px;

  > span {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: ${theme.text};
    align-items: center;
  }

  > p {
    display: flex;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
  }
`;

export const ContainerCode = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 15px;

  > span {
    font-size: 12px;
    color: ${theme.text};
  }
`;

export const ContainerInputCode = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 78%;
  justify-content: flex-end;
  align-items: center;
`;

export const ContainerButtonLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
`;

export const ContainerButtonForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  justify-content: center;
`;

export const ContainerCreatePasswordTitle = styled.div`
  display: flex;
  width: 100%;
  height: 1%;
  justify-content: center;
  align-items: center;

  > span {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;
  }

  > svg {
    width: 25px;
    color: ${theme.primary};
  }
`;

export const ContainerCreatePasswordSubTitle = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  margin-top: 5px;
  margin-bottom: 8px;

  > span {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: ${theme.text};
    align-items: center;
  }
`;

export const ContainerCreatePasswordBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div {
    margin-bottom: 8px;
  }
`;

export const ContainerButtonCreatePassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  justify-content: flex-end;
`;

export const ContainerSuccessAccessTitle = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  > span {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: ${theme.primary};
    align-items: center;
    margin-left: 10px;
  }

  > svg {
    width: 25px;
    color: ${theme.primary};

    @media ${device.laptop} {
      width: 22px;
    }
  }
`;

export const ContainerSuccessAccessSubTitle = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  margin-top: 12px;

  > span {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: ${theme.text};
    align-items: center;
  }
`;

export const ContainerSuccessAccessBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-top: 15px;
  margin-bottom: 40px;

  > span {
    display: flex;
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    color: ${theme.text};
    font-weight: bold;
    align-items: center;
  }

  > text {
    display: flex;
    width: 100%;
    margin-top: 14px;
    font-size: 14px;
    color: ${theme.text};
    align-items: center;
  }
`;

export const ContainerHelp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35%;

  @media ${device.laptopL} {
    margin-top: 35px;
  }
`;

export const ContainerHelpBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 41%;
  align-items: center;
  justify-content: center;

  > span {
    text-decoration: underline;
    color: ${theme.primary};
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
    height: 18px;
  }
`;

export const Divider = styled.div`
  display: flex;
  align-self: center;
  width: 70%;
  height: 4px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
`;

export const Text = styled.text`
  color: ${theme.text};
`;

export const ContainerForgotPassword = styled.div`
  > a {
    width: fit-content;
    color: ${theme.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`;
