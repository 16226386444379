import { BsFillInfoCircleFill } from "react-icons/bs";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface ISelect {
  isOpen?: boolean;
  disabled?: boolean;
  error?: boolean;
  isSize?: boolean;
  height?: string;
  widht?: string;
  widhtMobile?: string;
  isSelected?: string;
  isOportunity?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 50%;
`;

export const SelectButton = styled.div`
  display: flex;
  height: ${(props: ISelect) => (props.isOportunity ? "34px" : "44px")};
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  border: ${(props: ISelect) => {
    if (props.disabled) return `2px solid ${theme.disable100}`;
    if (props.error) return `3px solid ${theme.error}`;
    return `2px solid ${theme.disable}`;
  }};
  z-index: 1;
`;

export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;


export const Label = styled.span`
  display: flex;
  font-size: 12px;
  margin-left: 3px;
  width: fit-content;
  color: ${theme.text};
`;

export const IconBsFillInfoCircleFill = styled(BsFillInfoCircleFill)`
  color: ${theme.text};
  width: fit-content;
  margin-top: 2px;
  margin-left: 4px;
`;
export const Requirement = styled.span`
  width: fit-content;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.error};
`;

export const Title = styled.span`
  color: ${(props: ISelect) => {
    if (props.disabled) return theme.disable;
    if (props.isSelected) return theme.text;
    return theme.disable;
  }};
  font-weight: ${(props: ISelect) => (props.isSelected ? "bold" : "normal")};
  font-size: ${(props: ISelect) => (props.isSize ? "12px" : "14px")};
`;

export const ContainerIcon = styled.span`
  display: flex;
  flex-direction: column;
  width: fit-content;
  color: #fff;
  align-items: center;
  justify-content: center;

  > svg {
    color: ${theme.primary};
    font-size: 14px;
  }
`;

export const ListItems = styled.ul`
  display: ${(props: ISelect) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  background: white;
  width: ${(props: ISelect) => (props.widht ? props.widht : "23%")};
  max-height: ${(props: ISelect) => (props.height ? props.height : "24%")};
  position: absolute;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid ${theme.disable};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  align-items: center;
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  @media ${device.laptop} {
    width: 40%;
    max-height: 42%;
  }
  @media ${device.tablet} {
    width: 35%;
  }
  @media ${device.mobileL} {
    width: ${(props: ISelect) =>
      props.widhtMobile ? props.widhtMobile : "55%"};
  }
`;

export const Items = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  height: 32px;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px 15px;
  border-radius: 8px;

  &:hover {
    background-color: #e7edfe;
  }
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
export const LabelRadioButton = styled.label`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p`
  color: ${theme.text};
  font-size: 12px;
  padding-left: 0.5rem;
`;

export const RadioBox = styled.div`
  display: flex;
  width: 1.2rem;
  height: 1.1rem;
  align-items: center;
  justify-content: center;
  padding: 1px 1px;
  border: 2px solid ${theme.disable};
  border-radius: 50%;
  transition: background 0.15s, border-color 0.15s;
  cursor: pointer;

  &::after {
    content: "";
    width: 10px;
    height: 9.5px;
    display: block;
    background: ${theme.primary};
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    &::after {
      transform: scale(1);
    }
  }
`;
