import React from "react";
import * as Styled from "../styles";
import { FiUserPlus } from "react-icons/fi";
import Button from "@components/common/Button";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import RadioSelect from "@components/Sales/Inputs/RadioSelect";
import InputSearch from "@components/Sales/Inputs/InputSearch";
import Table from "@components/Sales/Table";
import { documentMask, phoneMask } from "@helpers/format";
import Notify from "@components/Sales/Notify";
import { ISearchClientsComponent } from "src/@types/Sales";
import { UsuallyContext } from "@context/usuallyContex";
import salesService from "@services/Sales/SalesService";
import { isEmail } from "@helpers/emailCheck";
import Spinner from "@components/common/Spinner";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import QuestionsCreateAccountComponent from "../components/QuestionsCreateAccountComponent";
import { OportunityContext } from "@context/oportunityContex";

const SearchClientsComponent = ({
  navigation,
  navigationScreenMyOportunity,
  navigationMyOportunity,
  navigationCreateOportunity,
}: ISearchClientsComponent) => {
  const {
    setTypeAccount,
    successCreateAccountSales,
    setSuccessCreateAccountSales,
    nameCreateAccountSales,
    successCreateOportunity,
    setSuccessCreateOportunity,
    nameCreateOportunity,
    accountIdSalesforce,
  } = React.useContext(UsuallyContext);
  const {
    setOrigemConta,
    setAccessCreateOp,
    setOnboardingDocuments,
    setContracts,
  } = React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [search, setSearch] = React.useState("");
  const [dataSearchSales, setDataSearchSales] = React.useState([]);
  const [showCreateNewAccount, setShowCreateNewAccount] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [disabledSearchButton, setDisabledSearchButton] = React.useState(false);
  const [newSearch, setNewSearch] = React.useState("");
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);

  const [selectRadio, setSelectRadio] = React.useState("Email");
  const radioTel = "Telefone";
  const radioEmail = "Email";
  const radioCpf = "CPFCNPJ";

  const handleOptionChangeSearch = (value: string) => {
    setSelectRadio(value);
    setSearch("");
  };

  const handleSelectOptionPlaceholder = () => {
    if (selectRadio === "Telefone") return "(XX) XXXXX-XXXX";
    if (selectRadio === "Email") return "teste@provedor.com";
    if (selectRadio === "CPFCNPJ")
      return "XXX.XXX.XXX-XX ou XX.XXX.XXX/XXXX-XX";
  };
  const handleSelectOptionOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): string => {
    if (selectRadio === "Telefone")
      return phoneMask(e.target.value?.replace(/\D/g, ""));
    if (selectRadio === "Email") return e.target.value;
    if (selectRadio === "CPFCNPJ")
      return documentMask(e.target.value?.replace(/\D/g, ""));
    else return "";
  };
  const handleSelectOptionMaxLength = () => {
    if (selectRadio === "Telefone") return 15;
    if (selectRadio === "Email") return undefined;
    if (selectRadio === "CPFCNPJ") return 18;
  };

  const handleCloseModal = () => {
    setShowCreateNewAccount(false);
    setSuccessCreateAccountSales(false);
    setTypeAccount("");
  };

  async function handleNavigationOportunity() {
    setShowCreateNewAccount(false);
    setSuccessCreateAccountSales(false);
    setSuccessCreateOportunity(false);
    setTypeAccount("");
    setOnboardingDocuments([]);
    setContracts([]);
    navigationMyOportunity();
  }

  async function handleNavigationScreenMyOoportunity() {
    setShowCreateNewAccount(false);
    setSuccessCreateAccountSales(false);
    setSuccessCreateOportunity(false);
    setTypeAccount("");
    const idCorretor: any = localStorage.getItem("@idCorretor");
    setLoading(true);

    try {
      const oportunities = await salesService.getOportunity({
        idCorretorSalesforce: idCorretor,
      });
      const oportunity = oportunities.find(
        (o: any) => o.accountIdSalesforce === accountIdSalesforce
      );

      if (oportunity !== undefined) {
        setAccessCreateOp({
          oportunityIdSalesforce: oportunity.opportunityIdSalesforce,
          oportunidadeId: oportunity.oportunidadeId,
          nomeDaConta: oportunity.nomeDaConta,
        });
        setOnboardingDocuments([]);
        setContracts([]);
        navigationMyOportunity();
      } else {
        navigationScreenMyOportunity();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigationScreenMyOportunity();
    }
  }

  const handleSelectOptionGet = () => {
    if (selectRadio === radioTel) return { telefone: search };
    if (selectRadio === radioEmail) return { email: search };
    else return { CPFCNPJ: search };
  };

  const handleGetSales = async () => {
    setLoading(true);

    try {
      const response = await salesService.getSales(handleSelectOptionGet());

      if (response?.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        setDataSearchSales([]);
        return;
      }

      setDataSearchSales(response);
      setOrigemConta(response?.origemConta);
      setNewSearch(search);
      setLoading(false);
    } catch (error: any) {
      console.log("errorHandleGetSales", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (selectRadio === radioTel) {
      setDisabledSearchButton(phoneMask(search)?.length < 15);
    }
    if (selectRadio === radioCpf) {
      const isCnpjComplete = documentMask(search)?.length === 18;

      setDisabledSearchButton(
        documentMask(search)?.length < 14 ||
          (documentMask(search)?.length > 14 && !isCnpjComplete)
      );
    }
    if (selectRadio === radioEmail) {
      setDisabledSearchButton(!isEmail(search));
    }
  }, [search, selectRadio]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamante para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Notify
        onOpen={showCreateNewAccount || successCreateAccountSales}
        onClose={handleCloseModal}
      >
        <Styled.AreaQuestions>
          <Styled.ContainerTitleNotify>
            <span>
              {successCreateAccountSales
                ? "Conta criada com sucesso!"
                : "Criar nova conta"}
            </span>
          </Styled.ContainerTitleNotify>
          {successCreateAccountSales ? (
            <Styled.ContainerSuccess>
              <span>
                A conta de <strong>{nameCreateAccountSales}</strong> foi
                adicionada em nosso banco de dados e você já pode acessar pela
                oportunidade vinculada.
              </span>
              <Button
                title="ACESSAR OPORTUNIDADE"
                onClick={handleNavigationScreenMyOoportunity}
              />
            </Styled.ContainerSuccess>
          ) : (
            <QuestionsCreateAccountComponent
              setShowCreateNewAccount={setShowCreateNewAccount}
              navigation={navigation}
            />
          )}
        </Styled.AreaQuestions>
      </Notify>

      <Notify
        onOpen={successCreateOportunity}
        onClose={() => setSuccessCreateOportunity(false)}
      >
        <Styled.AreaQuestions>
          <Styled.ContainerTitleNotify>
            <span>Oportunidade criada com sucesso!</span>
          </Styled.ContainerTitleNotify>
          <Styled.ContainerSuccess>
            <span>
              A oportunidade de <b>{nameCreateOportunity}</b> foi criada em
              nosso sistema e você já pode acessar a sua oportunidade.
            </span>
            <Button
              title="ACESSAR OPORTUNIDADE"
              onClick={handleNavigationOportunity}
            />
          </Styled.ContainerSuccess>
        </Styled.AreaQuestions>
      </Notify>

      <Styled.ContainerTitlePage>
        <Styled.ContainerTitle>
          <MdOutlineScreenSearchDesktop />
          <Styled.TitlePage>Buscar clientes</Styled.TitlePage>
        </Styled.ContainerTitle>

        <Styled.ContainerTitleButtons>
          <Button
            title={
              <Styled.AreaTitleButtons>
                <FiUserPlus />
                CRIAR NOVA CONTA
              </Styled.AreaTitleButtons>
            }
            onClick={() => {
              setShowCreateNewAccount(true);
              setTypeAccount("");
            }}
          />
        </Styled.ContainerTitleButtons>
      </Styled.ContainerTitlePage>

      <Styled.ContainerDisplaySearch>
        <Styled.TitleDisplay>Pesquisa relacionada:</Styled.TitleDisplay>
        <Styled.ContainerRadioButtonSearch>
          <RadioSelect
            label="Telefone"
            checked={selectRadio === radioTel}
            onChange={() => handleOptionChangeSearch(radioTel)}
          />
          <RadioSelect
            label="E-mail"
            checked={selectRadio === radioEmail}
            onChange={() => handleOptionChangeSearch(radioEmail)}
          />
          <RadioSelect
            label="CPF/CNPJ"
            checked={selectRadio === radioCpf}
            onChange={() => handleOptionChangeSearch(radioCpf)}
          />
        </Styled.ContainerRadioButtonSearch>
        <Styled.ContainerDisplayInput>
          <InputSearch
            placeholder={handleSelectOptionPlaceholder()}
            value={search}
            onChange={(e) => setSearch(handleSelectOptionOnChange(e))}
            maxLength={handleSelectOptionMaxLength()}
          />
          <Button
            title={loading ? <Spinner /> : "PESQUISAR"}
            onClick={handleGetSales}
            disabled={loading || disabledSearchButton}
          />
        </Styled.ContainerDisplayInput>
      </Styled.ContainerDisplaySearch>

      <Styled.ContainerBox>
        <Styled.ContainerTable>
          {dataSearchSales?.length === 0 || dataSearchSales === undefined ? (
            <Styled.ContainerSearchMessageUnityModal>
              {dataSearchSales !== undefined && newSearch === "" ? (
                <>
                  <Styled.TitleSection>
                    Pesquise utilizando as seguintes sugestões
                  </Styled.TitleSection>

                  <Styled.BodySearchUnityModalMessage>
                    - Selecione um tipo de pesquisa entre: Telefone ou E-mail;
                  </Styled.BodySearchUnityModalMessage>
                  <Styled.BodySearchUnityModalMessage>
                    - Certifique-se de que os dados estejam digitados
                    corretamente;
                  </Styled.BodySearchUnityModalMessage>
                  <Styled.BodySearchUnityModalMessage>
                    - Tente escolher palavras-chave;
                  </Styled.BodySearchUnityModalMessage>
                  <Styled.BodySearchUnityModalMessage>
                    - Use termos mais genéricos, ou seja, com um conceito mais
                    amplo
                  </Styled.BodySearchUnityModalMessage>
                </>
              ) : (
                <>
                  <Styled.BodySearchUnityModalMessageError>
                    Sua pesquisa por {newSearch} não correspondeu a nenhum
                    resultado.
                  </Styled.BodySearchUnityModalMessageError>

                  <Styled.BodySearchUnityModalMessage>
                    Se preferir, pesquise novamente utilizando as seguintes
                    sugestões:
                  </Styled.BodySearchUnityModalMessage>
                  <Styled.BodySearchUnityModalMessage>
                    - Certifique-se de que todas as palavras estejam digitadas
                    corretamente;
                  </Styled.BodySearchUnityModalMessage>
                  <Styled.BodySearchUnityModalMessage>
                    - Tente escolher palavras-chave diferentes das que você
                    digitou;
                  </Styled.BodySearchUnityModalMessage>
                  <Styled.BodySearchUnityModalMessage>
                    - Use termos mais genéricos, ou seja, com um conceito mais
                    amplo.
                  </Styled.BodySearchUnityModalMessage>
                </>
              )}
            </Styled.ContainerSearchMessageUnityModal>
          ) : (
            <Table
              data={dataSearchSales}
              navigation={() => navigationCreateOportunity()}
              navigationOportunity={() => navigationMyOportunity()}
              navigationScreenMyOportunity={() =>
                handleNavigationScreenMyOoportunity()
              }
              onSuccess={(accountId, accountName) => {
                const processedSearchSalesData = (dataSearchSales as any[]).map(
                  (d) => {
                    if (accountName === d.nome) {
                      return {
                        ...d,
                        accountIdSalesforce: accountId,
                        tipoConta: "Cliente - Pessoa Física",
                        ehLead: false,
                        origemConta: "Oferta do Corretor",
                      };
                    } else {
                      return d;
                    }
                  }
                );

                setDataSearchSales(processedSearchSalesData as never[]);
              }}
            />
          )}
        </Styled.ContainerTable>
      </Styled.ContainerBox>
    </>
  );
};

export default SearchClientsComponent;
