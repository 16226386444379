import * as Styled from "./ContractStyles";
import {
  MdOutlineFolderShared,
  MdExpandMore,
  MdExpandLess,
} from "react-icons/md";
import { theme } from "src/styles/theme";
import { useContext } from "react";
import { OportunityContext } from "@context/oportunityContex";
import oportunityServiceInstance, {
  IContractData,
} from "@services/Oportunity/OportunityService";
import Spinner from "@components/common/Spinner";

const ContractsComponent = () => {
  const { contracts, loading } = useContext(OportunityContext);

  if (loading) {
    return (
      <Styled.LoadingSpinner>
        <Spinner />
      </Styled.LoadingSpinner>
    );
  }

  return (
    <>
      <Styled.ComponentHeader>
        <h1>Contratos da oportunidade</h1>
      </Styled.ComponentHeader>
      <Styled.ContractsTable>
        <thead>
          <tr>
            <th className="table-full pl-16">ID do documento:</th>
            <th className="table-fit">Status:</th>
            <th className="table-120">Data de criação:</th>
            <th className="table-155">Assinatura(s):</th>
            <th className="table-140" />
          </tr>
        </thead>
        <tbody>
          {contracts.map((c, i) => (
            <ContractCell key={c.idDocumentoPrincipal} index={i} contract={c} />
          ))}
        </tbody>
      </Styled.ContractsTable>
    </>
  );
};

export default ContractsComponent;

const ContractCell = ({
  index,
  open = false,
  contract,
}: {
  index: number;
  open?: boolean;
  contract: IContractData;
}) => {
  const ExpandIcon = open ? MdExpandLess : MdExpandMore;

  let folderColor = theme.text;
  let nameColor = "";

  if (contract.status === "Finalizado") {
    folderColor = theme.primary;
    nameColor = " theme-primary";
  } else if (contract.status === "Cancelado") {
    folderColor = theme.error;
    nameColor = " theme-error";
  }

  return (
    <>
      <tr className="mb-8">
        <td
          className={`table-full contract-summary content-row ${
            index % 2 === 0 ? "even-row" : "odd-row"
          }`}
        >
          <div className="pl-16 content-row table-full">
            <MdOutlineFolderShared
              color={folderColor}
              className="contract-icon"
            />
            <p className={`contract-id` + nameColor}>{contract.nome}</p>
          </div>
          <p className="row-text table-fit">
            {contract.status === "Aguardando Assinaturas" &&
            contract.assinantesObrigatoriosFinalizados
              ? "Processando"
              : contract.status}
          </p>
          <div className="table-120 row-divider-lr">
            <p className="row-text">{formatDate(contract.dataCriacao)}</p>
          </div>
          <div className="table-155 content-between">
            <p className="row-text">
              {contract.assinantesObrigatoriosFinalizados
                ? "Assinado"
                : "Aguardando"}
            </p>
            <ExpandIcon className="expand-icon" color={theme.primary} />
          </div>
        </td>
        <td className="table-140">
          <Styled.FilledButton
            className="h-40"
            onClick={async () => {
              if (contract.status !== "Cancelado") {
                const url = await oportunityServiceInstance.getContractURL(
                  contract.idDocumentoPrincipal
                );
                console.log(url);
                window.open(url, "_blank");
              }
            }}
            disabled={contract.status === "Cancelado"}
          >
            BAIXAR
          </Styled.FilledButton>
        </td>
      </tr>
      {open && (
        <Styled.MembersTable>
          <thead>
            <tr>
              <th className="table-full">Nome do signatário D4Sign:</th>
              <th className="table-fit">Nomenclatura:</th>
              <th className="table-120">Data de upload:</th>
            </tr>
          </thead>
          <tbody>
            <SignerRow index={0} />
            <SignerRow index={1} />
            <SignerRow index={2} />
            <SignerRow index={3} />
            <SignerRow index={4} />
            <SignerRow index={5} />
          </tbody>
        </Styled.MembersTable>
      )}
    </>
  );
};

const SignerRow = ({ index }: { index: number }) => {
  return (
    <tr
      className={`member-row even-row ${
        index % 2 === 0 ? "even-row" : "odd-row"
      }`}
    >
      <td className="table-full">
        <Styled.DocumentDataCell>
          <div className="icon-cell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              fill="white"
            >
              <path d="M563-491q73-54 114-118.5T718-738q0-32-10.5-47T679-800q-47 0-83 79.5T560-541q0 14 .5 26.5T563-491ZM120-120v-80h80v80h-80Zm160 0v-80h80v80h-80Zm160 0v-80h80v80h-80Zm160 0v-80h80v80h-80Zm160 0v-80h80v80h-80ZM136-280l-56-56 64-64-64-64 56-56 64 64 64-64 56 56-64 64 64 64-56 56-64-64-64 64Zm482-40q-30 0-55-11.5T520-369q-25 14-51.5 25T414-322l-28-75q28-10 53.5-21.5T489-443q-5-22-7.5-48t-2.5-56q0-144 57-238.5T679-880q52 0 85 38.5T797-734q0 86-54.5 170T591-413q7 7 14.5 10.5T621-399q26 0 60.5-33t62.5-87l73 34q-7 17-11 41t1 42q10-5 23.5-17t27.5-30l63 49q-26 36-60 58t-63 22q-21 0-37.5-12.5T733-371q-28 25-57 38t-58 13Z" />
            </svg>
          </div>
          <div className="spaced-info">
            <p className="document-title">ODQ4MTk</p>
            <p className="document-origin">
              E-mail: cesar.morais@direcional.com.br
            </p>
          </div>
        </Styled.DocumentDataCell>
      </td>
      <td className="table-fit row-text">Assinar como testemunha</td>
      <td className="table-120 row-divider-l row-text">23/01/2023</td>
    </tr>
  );
};

const formatDate = (d: string) => {
  const date = new Date(d.split("T")[0] + "T12:00:00");
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
};
