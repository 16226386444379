import React from "react";
import * as Styled from "./styles";

interface ITextArea {
  name: string;
  label: string;
  placeholder?: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  error?: boolean;
  readOnly?: boolean;
  messageError?: string;
  maxLenght?: number;
  noMaxLength?: boolean;
}

const InputTextArea = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
  readOnly = false,
  messageError,
  maxLenght = 255,
  noMaxLength = false,
}: ITextArea) => {
  return (
    <Styled.Container>
      <Styled.Label>{label}</Styled.Label>
      <Styled.ContainerTextArea readOnly={readOnly} isError={error}>
        <Styled.TextArea
          name={name}
          readOnly={readOnly}
          placeholder={placeholder}
          cols={10}
          rows={1}
          maxLength={maxLenght}
          value={value}
          onChange={onChange}
        />

        {!noMaxLength && (
          <Styled.Counter isError={value?.length === maxLenght}>
            {value?.length}/{maxLenght}
          </Styled.Counter>
        )}
      </Styled.ContainerTextArea>

      {error && <Styled.MessageError>{messageError}</Styled.MessageError>}
    </Styled.Container>
  );
};

export default InputTextArea;
