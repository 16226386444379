import React from "react";
import * as Styled from "../../styles";
import InputRadioRequirements from "@components/Login/Inputs/InputRadioRequirements";
import { requirementsRadioMyProfile } from "@helpers/securePassword";

interface IModalUpdatePasswordRequirements {
  validateInput: any;
}

const ModalUpdatePasswordRequirements = ({
  validateInput,
}: IModalUpdatePasswordRequirements) => {
  return (
    <Styled.ContainerCreatePasswordBody>
      {requirementsRadioMyProfile(validateInput).map((requirement) => (
        <div key={requirement.title}>
          <InputRadioRequirements
            title={requirement.title}
            value={requirement.value}
            checked={requirement.checked}
            error={requirement.error}
          />
        </div>
      ))}
    </Styled.ContainerCreatePasswordBody>
  );
};

export default ModalUpdatePasswordRequirements;
