import React from "react";
import * as Styled from "../../styles";
import { FaRegUser } from "react-icons/fa";
import Button from "@components/common/Button";
import { IAccountsComponents, ValueFormikAccountLegal } from "src/@types/Sales";
import { useFormik } from "formik";
import { isEmail } from "@helpers/emailCheck";
import salesService from "@services/Sales/SalesService";
import Spinner from "@components/common/Spinner";
import enterprisesServiceInstance from "@services/Enterprises/enterprisesService";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { UsuallyContext } from "@context/usuallyContex";
import { SectionMainInformations } from "../../components/SectionLegal/SectionMainInformationsComponent";
import { SectionProductsInterest } from "../../components/SectionProductsInterestComponent";
import { cnpj } from "cpf-cnpj-validator";
import { phoneMask } from "@helpers/format";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { useOnlyMessageToast } from "@hook/useMessageToast";

const AccountsLegalComponents = ({
  onBack,
  isCreateAccount,
  setMandatoryCreateAccount,
  setCnpjCreateAccount,
}: IAccountsComponents) => {
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const regionalUser: any = localStorage.getItem("@regional");
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const regional = regionalUser;
  const { signOut } = React.useContext(LoginContext);
  const {
    setSuccessCreateAccountSales,
    setNameCreateAccountSales,
    setAccountIdSalesforce,
    setTypeAccount,
  } = React.useContext(UsuallyContext);

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [commercialRegional, setCommercialRegional] = React.useState("");
  const [businessUnity, setBusinessUnity] = React.useState("");
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const [purposePurchase, setPurposePurchase] = React.useState("");
  const [detailsLive, setDetailsLive] = React.useState("");
  const [numberRooms, setNumberRooms] = React.useState("");
  const [numberVacancy, setNumberVacancy] = React.useState("");
  const [typeWork, setTypeWork] = React.useState("");
  const [require, setRequire] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingEnterprises, setLoadingEnterprises] = React.useState(false);
  const [dataEnterprises, setDataEnterprises] = React.useState([]);
  const [idEnterprises, setIdEnterprises] = React.useState("");
  const [error, setError] = React.useState({
    email: false,
    telefone: false,
    cnpj: false,
  });
  const [mainEnterprises, setMainEnterprises] = React.useState("");
  const [selectMainEnterprises, setSelectMainEnterprises] = React.useState("");
  const isWhatsAppPrincipal = selectedValues.includes("Whatsapp");
  const isCelularPrincipal = selectedValues.includes("Celular");
  const [originAccount, setOriginAccount] = React.useState("");

  const initialValues = {
    nameAccount: "",
    fantasyName: "",
    stateRegistration: "",
    munipalRegistration: "",
    cnpj: "",
    nire: "",
    mainPhone: "",
    mainEmail: "",
    dateFoundation: "",
    complementaryPhone: "",
    complementaryEmail: "",
    comments: "",
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => handleSubmitFormik(values),
  });

  const handleSubmitFormik = async (value: ValueFormikAccountLegal) => {
    setLoading(true);
    setMandatoryCreateAccount(false);
    setCnpjCreateAccount(false);
    const bodyIsCreateAccount = {
      idCorretorSalesforce: idCorretor,
      tipoRegistro: "Cliente - Pessoa Jurídica",
      nomeDaConta: value?.nameAccount,
      nomeFantasia: value?.fantasyName,
      regional: regional,
      inscricaoEstadual: value?.stateRegistration,
      inscricaoMunicipal: value?.munipalRegistration,
      cnpj: cnpj.format(value?.cnpj),
      nire: value?.nire,
      regionalComercial: commercialRegional,
      telefonePrincipal: phoneMask(value?.mainPhone),
      emailPrincipal: value?.mainEmail,
      dataDeFundacao: value?.dateFoundation,
      unidadeDeNegocio: businessUnity,
      telefonePrincipalEhWhatsapp: isWhatsAppPrincipal,
      telefonePrincipalEhCelular: isCelularPrincipal,
      criarOportunidade: isCreateAccount ? false : true,
    };

    const body = {
      idCorretorSalesforce: idCorretor,
      tipoRegistro: "Cliente - Pessoa Jurídica",
      origemDaConta: originAccount,
      nomeDaConta: value?.nameAccount,
      nomeFantasia: value?.fantasyName,
      regional: regional,
      inscricaoEstadual: value?.stateRegistration,
      inscricaoMunicipal: value?.munipalRegistration,
      cnpj: cnpj.format(value?.cnpj),
      nire: value?.nire,
      regionalComercial: commercialRegional,
      telefonePrincipal: phoneMask(value?.mainPhone),
      emailPrincipal: value?.mainEmail,
      dataDeFundacao: value?.dateFoundation,
      unidadeDeNegocio: businessUnity,
      emailComplementar: value?.complementaryEmail,
      finalidadeDaCompra: purposePurchase,
      detalhesPodeMorar: detailsLive,
      tipoDeTrabalho: typeWork,
      observacoes: value.comments,
      telefonePrincipalEhWhatsapp: isWhatsAppPrincipal,
      telefonePrincipalEhCelular: isCelularPrincipal,
      empreendimentoDeInteressePrincipal: idEnterprises,
      numeroDeQuartos: numberRooms,
      numeroDeVagas: numberVacancy,
      criarOportunidade: isCreateAccount ? false : true,
    };

    try {
      const response = await salesService.createPersonLegal(
        isCreateAccount ? bodyIsCreateAccount : body
      );

      if (
        response?.status === 400 &&
        (response?.data?.emailJaUtilizado ||
          response?.data?.telefoneJaUtilizado ||
          response?.data?.cnpjJaUtilizado)
      ) {
        setError((prevState) => ({
          ...prevState,
          email: response?.data.emailJaUtilizado,
          telefone: response?.data.telefoneJaUtilizado,
          cnpj: response?.data.cnpjJaUtilizado,
        }));
        setLoading(false);
        return;
      }

      if (response?.status === 400) {
        setLoading(false);
        displayMessageError({ message: "Erro ao criar conta!" });
        return;
      }

      if (response?.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      if (isCreateAccount) {
        onBack();
        displayMessage("Conta criada com sucesso!");
        setMandatoryCreateAccount(true);
        setCnpjCreateAccount(true);
      } else {
        setSuccessCreateAccountSales(true);
        setNameCreateAccountSales(value?.nameAccount);
        setAccountIdSalesforce(response?.data.id);
      }
      setTypeAccount("");
      setLoading(false);
    } catch (error) {
      console.log("ErrorFormik", error);
      setLoading(false);
    }
  };

  const handleGetEnterprises = async () => {
    setLoadingEnterprises(true);

    try {
      const response = await enterprisesServiceInstance.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprises(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprises(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprises(false);
    } catch (error) {
      setLoadingEnterprises(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  React.useEffect(() => {
    if (isCreateAccount) {
      const isRequirement =
        !formik.getFieldProps("nameAccount").value ||
        /\d/.test(formik.getFieldProps("nameAccount").value) ||
        /[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(
          formik.getFieldProps("nameAccount").value
        ) ||
        !businessUnity ||
        cnpj.format(formik?.getFieldProps("cnpj")?.value)?.length < 14 ||
        !cnpj.isValid(formik?.getFieldProps("cnpj")?.value) ||
        !phoneMask(formik.getFieldProps("mainPhone").value) ||
        phoneMask(formik?.getFieldProps("mainPhone")?.value)?.length < 15 ||
        !formik.getFieldProps("mainEmail").value ||
        (!isEmail(formik.getFieldProps("mainEmail").value) &&
          formik.getFieldProps("mainEmail").value) ||
        (!isEmail(formik.getFieldProps("complementaryEmail").value) &&
          formik.getFieldProps("complementaryEmail").value);
      setRequire(isRequirement);
    } else {
      const isRequirement =
        !formik.getFieldProps("nameAccount").value ||
        /\d/.test(formik.getFieldProps("nameAccount").value) ||
        /[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(
          formik.getFieldProps("nameAccount").value
        ) ||
        !businessUnity ||
        !originAccount ||
        cnpj.format(formik?.getFieldProps("cnpj")?.value)?.length < 14 ||
        !cnpj.isValid(formik?.getFieldProps("cnpj")?.value) ||
        !phoneMask(formik.getFieldProps("mainPhone").value) ||
        phoneMask(formik?.getFieldProps("mainPhone")?.value)?.length < 15 ||
        !formik.getFieldProps("mainEmail").value ||
        (!isEmail(formik.getFieldProps("mainEmail").value) &&
          formik.getFieldProps("mainEmail").value) ||
        (!isEmail(formik.getFieldProps("complementaryEmail").value) &&
          formik.getFieldProps("complementaryEmail").value);
      setRequire(isRequirement);
    }
  }, [originAccount, businessUnity, formik, isCreateAccount]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {!isCreateAccount && (
        <Styled.ContainerTitlePage>
          <Styled.ContainerTitle>
            <FaRegUser />
            <Styled.TitlePage>Contas</Styled.TitlePage>
          </Styled.ContainerTitle>
        </Styled.ContainerTitlePage>
      )}

      <Styled.ContainerBoxAccounts isRelationship={isCreateAccount}>
        {!isCreateAccount && (
          <Styled.ContainerTitleBox>
            <Styled.ContainerTitleWithCloseButton>
              <span>Nova Conta: Cliente - Pessoa Jurídica</span>
            </Styled.ContainerTitleWithCloseButton>

            <Styled.IconAiOutlineCloseCircle onClick={onBack} />
          </Styled.ContainerTitleBox>
        )}

        <Styled.ContainerBoxInfo>
          <SectionMainInformations
            formik={formik}
            error={error}
            setError={setError}
            regional={regional}
            originAccount={originAccount}
            setOriginAccount={setOriginAccount}
            commercialRegional={commercialRegional}
            setCommercialRegional={setCommercialRegional}
            selectedCheckBox={selectedValues}
            setSelectedCheckBox={setSelectedValues}
            businessUnity={businessUnity}
            setBusinessUnity={setBusinessUnity}
            purposePurchase={purposePurchase}
            setPurposePurchase={setPurposePurchase}
            detailsLive={detailsLive}
            setDetailsLive={setDetailsLive}
            typeWork={typeWork}
            setTypeWork={setTypeWork}
            isCreateAccount={isCreateAccount}
          />
        </Styled.ContainerBoxInfo>

        {!isCreateAccount && (
          <Styled.ContainerBoxInfo>
            <SectionProductsInterest
              setIdEnterprise={setIdEnterprises}
              selectMainEnterprises={selectMainEnterprises}
              setSelectMainEnterprises={setSelectMainEnterprises}
              dataEnterprises={dataEnterprises}
              mainEnterprises={mainEnterprises}
              setMainEnterprises={setMainEnterprises}
              loadingEnterprises={loadingEnterprises}
              getEnterprises={handleGetEnterprises}
              numberRooms={numberRooms}
              setNumberRooms={setNumberRooms}
              numberVacancy={numberVacancy}
              setNumberVacancy={setNumberVacancy}
            />
          </Styled.ContainerBoxInfo>
        )}

        <Styled.DividerBoxInput />
        <Styled.ContainerFooterBox>
          <Styled.ContainerMandatory>
            <span>(*) Campo de preenchimento obrigatório</span>
          </Styled.ContainerMandatory>
          <Styled.ContainerButton>
            <Button error title="CANCELAR" onClick={onBack} />

            <Button
              title={loading ? <Spinner /> : "CRIAR CONTA"}
              onClick={formik.handleSubmit}
              disabled={
                require ||
                loading ||
                error.email ||
                error.telefone ||
                error.cnpj
              }
            />
          </Styled.ContainerButton>
        </Styled.ContainerFooterBox>
      </Styled.ContainerBoxAccounts>
    </>
  );
};

export default AccountsLegalComponents;
