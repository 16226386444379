import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface IStepStatus {
  active: boolean;
  completed: boolean;
  incompleted: boolean;
  error?: string;
}
interface IProgressContainer {
  progress: number;
  error: string;
}

function handleColorText({
  active,
  completed,
  incompleted,
  error,
}: IStepStatus) {
  if (active) return "white";
  if (completed) return "white";
  if (incompleted) return theme.primary;
  if (error === "fechado e perdido") return theme.error;
  return theme.primary;
}

export const StepperContainer = styled.div`
  display: flex;
  position: relative;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepIndicator = styled.div`
  text-align: center;
  margin-right: 10px;
  z-index: 2;
`;

export const StepStatus = styled.div<IStepStatus>`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  background-color: ${(props) => {
    if (props.error === "fechado e perdido") return theme.error;
    if (props.active) return theme.primary;
    if (props.completed) return theme.primary;
    if (props.incompleted) return "white";
    return "white";
  }};
  color: ${(props) =>
    handleColorText({
      active: props.active,
      completed: props.completed,
      incompleted: props.incompleted,
      error: props.error,
    })};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  @media ${device.mobileL} {
    width: 35px;
    height: 35px;
  }

  > svg {
    color: ${(props) =>
      handleColorText({
        active: props.active,
        completed: props.completed,
        incompleted: props.incompleted,
      })};

    @media ${device.mobileL} {
      font-size: 17px;
    }
  }
`;

export const StepTitle = styled.div`
  display: flex;
  width: 70%;
  font-size: 15px;
  font-weight: bold;

  @media ${device.mobileL} {
    width: 80px;
    font-size: 11px;
  }
`;

export const StepDescription = styled.div`
  color: ${theme.disable};
  font-size: 13px;

  @media ${device.mobileL} {
    font-size: 11px;
  }
`;

export const ProgressContainer = styled.div`
  position: absolute;
  top: 15px;
  height: 15px;
  width: 85%;
  background-color: #002a6d1a;
  z-index: 1;

  @media ${device.mobileL} {
    width: 100%;
    top: 10px;
  }
  @media ${device.mobileM} {
    width: 98%;
  }
`;
export const ProgressIndicator = styled.div<IProgressContainer>`
  position: absolute;
  height: 100%;
  width: ${(props) => `${props.progress}%`};
  background-color: ${(props) =>
    props.error === "fechado e perdido"
      ? theme.error
      : theme.backgroundPrimary};
  transition: width 0.3s ease;
  border-radius: 30px;
`;
