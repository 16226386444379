import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import * as Styled from "../InputText/styles";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Tooltip from "@components/common/Tooltip";
import { IInputText } from "../InputText";

const InputTextEdit = ({
  isOportunity = false,
  isTelephone = false,
  readOnly = false,
  iconSee = true,
  label,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  iconClick,
  maxLength,
  error,
  errorBorderColor,
  messageError,
  isRequirement,
  tooltip = false,
  tooltipToRight,
  tooltipText,
}: IInputText) => {
  const getLabel = () => (
    <>
      {isRequirement && <Styled.Requirement>*</Styled.Requirement>}
      {label}
    </>
  );

  function messageErrorInput() {
    if (error && !isTelephone)
      return (
        <Styled.MessageError isOportunity={isOportunity}>
          {messageError ?? "Formato inválido"} <AiOutlineInfoCircle />
        </Styled.MessageError>
      );

    if (error && isTelephone)
      return (
        <Styled.MessageErrorTelephone isOportunity={isOportunity}>
          {messageError ?? "Formato inválido"} <AiOutlineInfoCircle />
        </Styled.MessageErrorTelephone>
      );
  }

  return (
    <Styled.Area>
      <Styled.ContainerTop>
        {label && <Styled.Label>{getLabel()}</Styled.Label>}
        {tooltip && (
          <Tooltip text={tooltipText ?? value} isRight={tooltipToRight}>
            <Styled.IconBsFillInfoCircleFill size="12px" />
          </Tooltip>
        )}
        {messageErrorInput()}
      </Styled.ContainerTop>

      <InputGroup>
        <Input
          bg={"white"}
          readOnly={readOnly}
          isDisabled={readOnly}
          placeholder={placeholder}
          name={name}
          isInvalid={error || errorBorderColor}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          focusBorderColor={theme.primary}
          borderWidth={2}
          borderColor={theme.disable}
          height="44px"
          borderRadius={8}
          fontSize={14}
          fontWeight="bold"
          _placeholder={{ color: theme.disable, fontWeight: "normal" }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
        />
        {iconSee && (
          <InputRightElement>
            <Styled.IconMdOutlineEditNote
              readOnly={readOnly}
              size="32px"
              onClick={iconClick}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Styled.Area>
  );
};
export default InputTextEdit;
