import api from "@services/api";
import axios from "axios";

interface IEnterprisesService {
  regional: string;
  unidadeDeNegocio?: string;
  regiao?: string;
}
interface IEnterprisesByNameService {
  nomeEmpreendimento: string;
}
interface IlistAttachment {
  codEmpreendimentoUAU: string;
  codEmpresaUAU: string;
}
interface IGetEnterpriseByUrl {
  idAws: string;
}

class EnterprisesService {
  async getEnterprises({
    regional,
    unidadeDeNegocio,
    regiao,
  }: IEnterprisesService) {
    const access_token = sessionStorage.getItem("@token");
    const body = {
      unidadeDeNegocio,
      regional,
      regiao,
    };
    try {
      const response = await api.get("/empreendimento/obter", {
        params: body,
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async getEnterprisesByName({
    nomeEmpreendimento,
  }: IEnterprisesByNameService) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/empreendimento/obter-por-nome", {
        params: { nomeEmpreendimento },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async listAttachment({
    codEmpreendimentoUAU,
    codEmpresaUAU,
  }: IlistAttachment) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/empreendimento/obter-lista-anexos", {
        params: { codEmpreendimentoUAU, codEmpresaUAU },
        headers: {
          access_token: `${access_token}`,
        },
      });

      if (response.status === 204) return [];
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async getEnterpriseByUrl({ idAws }: IGetEnterpriseByUrl) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/empreendimento/obter-url-por-id-aws", {
        params: { idAws },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }

  async getRegions() {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/empreendimento/obter-regionais", {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
}

const enterprisesServiceInstance = new EnterprisesService();

export default enterprisesServiceInstance;
