import styled from "styled-components";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";

type Sidebar = {
  isOpen?: boolean;
  size?: string;
  isSelected?: boolean;
};

export const Container = styled.div<Sidebar>`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background: ${theme.text};
  justify-content: space-between;
  transition: all 0.2s ease;
  z-index: -9999;

  @media ${device.tablet} {
    width: 100%;
    left: 0;
  }
  @media ${device.laptopXL} {
    height: 55px;
  }
`;

export const ContainerInput = styled.div<Sidebar>`
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
`;

export const ContainerIcons = styled.div`
  display: flex;
  width: 200px;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ContainerOtions = styled.div`
  display: flex;
  width: 60px;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

export const Notify = styled.div`
  display: flex;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${theme.error};
  top: 5px;
  margin-right: -35px;
`;

export const Otions = styled.div<Sidebar>`
  display: flex;
  width: 70%;
  height: 80%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ isSelected }) =>
    isSelected ? "white" : `${theme.backgroundGray}`};
  cursor: pointer;

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
  }
`;
