export const isEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const hideEmail = (email: string | undefined) => {
  if (email !== undefined && email !== null) {
    let [emailToHide, domain] = email.split("@");

    const firstChar = emailToHide[0];

    const lastTwoChars = emailToHide.substring(
      emailToHide.length - 2,
      emailToHide.length
    );

    const hiddenChars = emailToHide
      .substring(1, emailToHide.length - 2)
      .replace(/\w/g, "*");

    return `${firstChar}${hiddenChars}${lastTwoChars}@${domain}`;
  }
};
