import { RxHamburgerMenu } from "react-icons/rx";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

type Sidebar = {
  isLogged?: boolean;
  isOpen?: boolean;
  size?: string;
  isSelected?: boolean;
  isSubItem?: boolean;
};

export const Container = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
  }
`;

export const IconRxHamburgerMenu = styled(RxHamburgerMenu)`
  color: white;
  font-size: 23px;
  width: fit-content;
`;

export const ContainerSidebar = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${theme.text};
  transition: all 0.2s ease;
  z-index: 9999;
`;

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
`;

export const ContainerSubOtions = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  height: 55px;
  justify-content: center;
  color: white;

  @media ${device.laptopXL} {
    height: 15px;
    margin-top: 10px;
  }

  > span {
    width: fit-content;
    padding-left: 15px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ContainerAnyLogos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 120px;
  padding-bottom: 15px;
`;

export const ContainerBackgroundAnyLogos = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerLogo = styled.div`
  display: flex;
  width: 95%;
  height: 15%;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
`;

export const ContainerBackgroundLogo = styled.div`
  display: flex;
  width: 99%;
  height: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${theme.backgroundGray};
`;

export const ImgLarge = styled.img`
  display: flex;
  width: fit-content;
  height: 29px;
  object-fit: contain;
`;

export const ContainerOtions = styled.div<Sidebar>`
  display: flex;
  flex-direction: column;
  width: 97%;
  height: fit-content;
  min-height: 60px;
  justify-content: center;
  align-items: center;

  margin-top: ${({ isSubItem }) => (!isSubItem ? "0" : "5px")};
  margin-bottom: ${({ isSubItem }) => (!isSubItem ? "0" : "15px")};
`;

export const Otions = styled.div<Sidebar>`
  display: flex;
  width: 100%;
  height: 80%;
  padding-left: 8px;
  align-items: center;
  border-radius: 8px;
  background: ${({ isSelected }) =>
    isSelected ? "white" : `${theme.backgroundGray}`};
  cursor: pointer;

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
  }

  > span {
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
    font-size: 15px;
    font-weight: bold;
    padding-left: 8px;
    width: 80%;
  }
`;

export const ContainerNeedHelp = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 10%;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
`;

export const ContainerOtionsNeedHelp = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.div`
  display: flex;
  width: 90%;
  height: 2px;
  background: white;
  border-radius: 8px;
  align-self: center;
`;

export const ContainerConfig = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 10%;
  justify-content: center;
  align-items: center;
`;

export const ContainerOtionsConfig = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
`;

export const OtionsConfig = styled.div<Sidebar>`
  display: flex;
  width: 100%;
  height: 80%;
  padding-left: 8px;
  align-items: center;
  border-radius: 8px;
`;

export const ContainerIconConfig = styled.div<Sidebar>`
  display: flex;
  width: 20%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ isSelected }) =>
    isSelected ? "white" : `${theme.backgroundGray}`};

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
  }
`;
export const ContainerIconConfigClose = styled.div<Sidebar>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ isSelected }) =>
    isSelected ? "white" : `${theme.backgroundGray}`};

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: ${({ isSelected }) => (isSelected ? `${theme.text}` : "white")};
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  flex-direction: column;
  width: 88%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export const TextUser = styled.span`
  display: flex;
  color: white;
  font-size: 11px;
  width: 90%;
`;
export const TextEmail = styled.span`
  display: flex;
  color: white;
  font-size: 10px;
  width: 90%;
`;

export const ContainerLogout = styled.div<Sidebar>`
  display: flex;
  width: fit-content;
  height: 100%;
  justify-content: center;
  align-items: center;

  > svg {
    width: fit-content;
    font-size: ${({ size }) => size};
    color: white;
    cursor: pointer;
  }
`;
