export const messageBox = (
  message: any,
  setPage: any,
  setErrorForgotPassword: any
) => {
  if (message) {
    if (
      message ===
      "Antes de aproveitar todas as funcionalidades do Bora Vender, é preciso que você realize o primeiro acesso utilizando o seu CPF para ativar sua conta."
    ) {
      const messageBody = (
        <>
          Antes de aproveitar todas as funcionalidades do Bora Vender, é preciso
          que você realize o{" "}
          <span
            onClick={() => {
              setPage("");
              setErrorForgotPassword(true);
            }}
          >
            Primeiro acesso
          </span>{" "}
          utilizando o seu CPF para ativar sua conta.
        </>
      );

      return messageBody;
    } else if (message !== "Houve algum erro ao enviar o código.") {
      const messageBody = (
        <>
          {message}{" "}
          <span
            onClick={() => {
              setPage("");
              setErrorForgotPassword(true);
            }}
          >
            Primeiro acesso
          </span>
        </>
      );
      return messageBody;
    } else {
      const messageBody = message;

      return messageBody;
    }
  } else {
    const messageBody = `Caso não lembre seus dados ou tenha alguma dúvida entre em contato pelo "Precisa de ajuda".`;

    return messageBody;
  }
};
