import React from "react";
import * as Styled from "../stylesView&Create";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import InputDate from "@components/Sales/Inputs/InputDate";
import oportunityService from "@services/Oportunity/OportunityService";
import InputText from "@components/Sales/Inputs/InputText";
import { optionNumbersTry, optionStatusTask } from "@helpers/optionSelect";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { OportunityContext } from "@context/oportunityContex";
import { useMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { ICreateTaskCallComponent } from "src/@types/Sales";

const CreateTaskCallComponent = ({
  onBack,
  setSuccessCreateTaskCall,
  setShowDetailTaskCall,
}: ICreateTaskCallComponent) => {
  const displayMessage = useMessageToast();
  const displayMessageError = useMessageErrorToast();
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const {
    opportunityIdSalesforce,
    opportunityId,
    opportunityName,
    setCreateTaskOrCall,
    setAccessTasksCalls,
    accessCreateOp,
  } = React.useContext(OportunityContext);
  const [isTask, setIsTask] = React.useState(true);
  const [isCall, setIsCall] = React.useState(false);
  const { signOut } = React.useContext(LoginContext);
  const [dueDate, setDueDate] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [numbersTry, setNumbersTry] = React.useState("");
  const [nameAccount, setNameAccount] = React.useState(
    accessCreateOp.oportunidadeId ? accessCreateOp.nomeDaConta : opportunityName
  );
  const [oportunity, setOportunity] = React.useState(
    accessCreateOp.oportunidadeId
      ? `${accessCreateOp.oportunidadeId}-${accessCreateOp.nomeDaConta}`
      : `${opportunityId}-${opportunityName}`
  );
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [observations, setObservations] = React.useState("");
  const [require, setRequire] = React.useState(false);

  const handleDateFormat = (date: string) => {
    if (!date) {
      return date;
    }
    const data = parseISO(date);
    const formattedDate = format(data, "dd/MM/yyyy", { locale: ptBR });
    return formattedDate;
  };

  const handleCreateTask = async () => {
    setLoading(true);
    setSuccessCreateTaskCall(false);

    const getDateToDay = () => {
      const today = new Date();
      today.setDate(today.getDate());
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${day}/${month}/${year}`;
    };

    try {
      const response = await oportunityService.createTask({
        idCorretorSalesforce: idCorretor,
        assunto: subject,
        dataDeVencimento: isTask
          ? `${handleDateFormat(dueDate)}`
          : `${getDateToDay()}`,
        relativoA:
          opportunityIdSalesforce || accessCreateOp.oportunityIdSalesforce,
        status,
        tipoDeRegistro: isTask ? "Task" : "Call",
        numeroDeTentativas: parseInt(numbersTry),
        comentarios: observations,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({
          message: response?.data?.mensagem
            ? response?.data?.mensagem
            : `Erro ao criar ${isTask ? "tarefa" : "chamada"}!`,
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      setAccessTasksCalls({
        idTarefaSalesforce: response?.id,
        tipoDeRegistro: response?.tipoDeRegistro,
      });

      onBack();
      setCreateTaskOrCall(isTask ? "Tarefa" : "Chamada");
      displayMessage({
        title: `${isTask ? "Tarefa" : "Chamada"} registrada com sucesso!`,
        message:
          "A nova atividade foi criada e você já pode acessar para continuar sua venda.",
        titleButton: `ACESSAR ${isTask ? "TAREFA" : "CHAMADA"}`,
        onClick: () => {
          setShowDetailTaskCall(true);
          setSuccessCreateTaskCall(false);
        },
      });
      setSuccessCreateTaskCall(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("errorHandleCreateTask", error);
    }
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 30);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const getDateToDay = () => {
    const today = new Date();
    today.setDate(today.getDate());
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleClearInput = () => {
    setDueDate("");
    setSubject("");
    setStatus("");
    setNumbersTry("");
    setObservations("");
  };

  React.useEffect(() => {
    if (isTask) {
      const isRequirementTask =
        !subject ||
        !status ||
        !nameAccount ||
        !oportunity ||
        dueDate.length < 10;

      setRequire(isRequirementTask);
    }
    if (isCall) {
      const isRequirementCall = !nameAccount || !oportunity;

      setRequire(isRequirementCall);
    }
  }, [dueDate, isCall, isTask, nameAccount, oportunity, status, subject]);

  React.useEffect(() => {
    if (isCall) {
      setSubject("Chamada");
      setStatus("Concluído");
    }
  }, [isCall]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      <Styled.ContainerButtonsHeaderTaskCall>
        <Button
          title={"NOVA TAREFA"}
          colorInverter={!isTask}
          onClick={() => {
            setIsTask(true);
            setIsCall(false);
            handleClearInput();
          }}
        />
        <Button
          title={"REGISTRAR UMA CHAMADA"}
          colorInverter={!isCall}
          onClick={() => {
            setIsCall(true);
            setIsTask(false);
            handleClearInput();
          }}
        />
      </Styled.ContainerButtonsHeaderTaskCall>

      <Styled.ContainerTaskCall>
        <Styled.TitleSchedule>
          {isTask ? "Nova tarefa:" : "Nova chamada:"}
        </Styled.TitleSchedule>

        <Styled.ContainerInputsGrid>
          <InputText
            readOnly={isCall}
            isRequirement={isTask}
            label="Assunto:"
            placeholder="Assunto"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          {isTask && (
            <InputDate
              type="date"
              isRequirement
              max={getMaxDate()}
              min={getDateToDay()}
              name={"dueDate"}
              placeholder="__/__/___"
              label="Data de vencimento:"
              value={dueDate}
              onChange={(e) => {
                const inputDate = e.target.value;
                const maxDate = getMaxDate();

                if (inputDate > maxDate) {
                  setDueDate(maxDate);
                } else {
                  setDueDate(inputDate);
                }
              }}
            />
          )}

          {isTask ? (
            <InputSelect
              isRequirement
              widht="28%"
              title="Status:"
              label={status || "Selecione uma opção"}
              isSelected={status}
              options={optionStatusTask}
              selectedOption={status}
              onChange={(e) => setStatus(e.currentTarget.value)}
            />
          ) : (
            <InputSelect
              widht="28%"
              title="Numero de tentativas:"
              label={numbersTry || "Selecione uma opção"}
              isSelected={numbersTry}
              options={optionNumbersTry}
              selectedOption={numbersTry}
              onChange={(e) => setNumbersTry(e.currentTarget.value)}
            />
          )}
        </Styled.ContainerInputsGrid>

        <Styled.ContainerInputsGridSolo>
          <InputText
            readOnly
            isRequirement
            label="Nome da conta:"
            placeholder="Nome da conta"
            name="nameAccount"
            value={nameAccount}
            onChange={(e) => setNameAccount(e.target.value)}
          />
        </Styled.ContainerInputsGridSolo>

        <Styled.ContainerInputsGridSolo>
          <InputText
            readOnly
            isRequirement
            label="Oportunidade:"
            placeholder="Oportunidade"
            name="oportunity"
            value={oportunity}
            onChange={(e) => setOportunity(e.target.value)}
          />
        </Styled.ContainerInputsGridSolo>

        <Styled.ContainerInputsGridSolo>
          <InputTextArea
            label="Observações:"
            placeholder="Observações"
            name="Observações"
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
            maxLenght={32000}
          />
        </Styled.ContainerInputsGridSolo>
      </Styled.ContainerTaskCall>

      <Styled.DividerBoxInput />

      <Styled.ContainerFooterBox>
        <Styled.ContainerDuoMandatory>
          <span>(*) Campo de preenchimento obrigatório</span>
          <span>
            (<AiOutlineInfoCircle />) O campo apresenta formato ou dado inválido
          </span>
        </Styled.ContainerDuoMandatory>
        <Styled.ContainerButtonFooter>
          <Button error title="CANCELAR" onClick={onBack} />

          <Button
            title={loading ? <Spinner /> : "CRIAR"}
            onClick={handleCreateTask}
            disabled={require || loading}
          />
        </Styled.ContainerButtonFooter>
      </Styled.ContainerFooterBox>
    </>
  );
};

export default CreateTaskCallComponent;
