import React from "react";
import * as Styled from "./styles";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IInputSearch {
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: any;
}

const InputSearch = ({ value, onChange, placeholder, error }: IInputSearch) => {
  return (
    <Styled.Area>
      <InputGroup>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isInvalid={error}
          focusBorderColor={theme.primary}
          borderWidth={2}
          borderColor={theme.disable100}
          height="34px"
          borderRadius={8}
          fontSize={14}
          fontWeight="bold"
          _placeholder={{ color: theme.disable }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
          bg="white"
        />
        <InputLeftElement>
          <Styled.IconBiSearch size="25px" />
        </InputLeftElement>
      </InputGroup>
    </Styled.Area>
  );
};
export default InputSearch;
