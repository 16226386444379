import React from "react";
import * as Styled from "./styles";
import { Input, InputGroup } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IInputDigits {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  maxLength?: number;
  message?: any;
  error?: boolean;
}

const InputDigits = ({
  value,
  onChange,
  message,
  maxLength = 100,
  error = false,
}: IInputDigits) => {
  return (
    <Styled.Area>
      <InputGroup>
        <Input
          value={value}
          onChange={onChange}
          variant="flushed"
          maxLength={maxLength}
          focusBorderColor={theme.primary}
          borderColor={theme.disable}
          height="40px"
          letterSpacing={"1.5rem"}
          fontSize={22}
          textAlign="center"
          _hover={{ color: "none" }}
          textColor={error ? theme.error : theme.text}
        />
      </InputGroup>

      <span>{message}</span>
    </Styled.Area>
  );
};

export default InputDigits;
