import React from "react";
import { Box, useRadio } from "@chakra-ui/react";
import { EqualInputSelect } from "@hook/useEqualCode";

interface IRadioButtonProps {
  id: string;
  name: string;
  checked: any;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const InputRadioButton = ({
  id,
  name,
  checked,
  onChange,
}: IRadioButtonProps) => {
  const { getInputProps, getRadioProps, getRootProps } = useRadio({
    name,
    id,
    value: name,
    onChange,
    isChecked: checked,
  });

  const inputProps = getInputProps();
  const checkboxProps = getRadioProps();
  const rootProps = getRootProps();

  return (
    <Box
      as="li"
      display="flex"
      alignItems="center"
      listStyleType="none"
      py="4px"
      px="15px"
      my="4px"
    >
      <EqualInputSelect
        id={id}
        name={name}
        checked={checked}
        checkboxProps={checkboxProps}
        inputProps={inputProps}
        rootProps={rootProps}
      />
    </Box>
  );
};

export default InputRadioButton;
