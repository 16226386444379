import React from "react";
import * as Styled from "../../styles";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import InputText from "@components/Sales/Inputs/InputText";
import SelectCheckbox from "@components/Sales/Inputs/SelectCheckbox";
import { Checkbox, Text } from "@chakra-ui/react";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import {
  optionBusinessUnity,
  optionCommercialRegional,
  optionDetailsLive,
  optionOriginAccount,
  optionPurposePurchase,
  optionTypeWork,
} from "@helpers/optionSelect";
import { formatDatePtBr, phoneMask } from "@helpers/format";
import { isEmail } from "@helpers/emailCheck";
import { cnpj } from "cpf-cnpj-validator";
import { ISectionMainInformationsAccountLegal } from "src/@types/Sales";

const renderInputCheckbox = (
  value: string,
  selectedValues: string[],
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>,
  disabled: boolean = false
) => {
  return (
    <Checkbox
      value={value}
      onChange={() => {
        if (selectedValues.includes(value)) {
          setSelectedValues((prevSelectedValues) =>
            prevSelectedValues.filter((v) => v !== value)
          );
        } else {
          setSelectedValues((prevSelectedValues) => [
            ...prevSelectedValues,
            value,
          ]);
        }
      }}
      borderRadius={8}
      disabled={disabled}
    >
      <Text fontSize={12} fontWeight="bold">
        {value}
      </Text>
    </Checkbox>
  );
};

export const SectionMainInformations = ({
  formik,
  error,
  setError,
  regional,
  originAccount,
  setOriginAccount,
  commercialRegional,
  setCommercialRegional,
  selectedCheckBox,
  setSelectedCheckBox,
  businessUnity,
  setBusinessUnity,
  purposePurchase,
  setPurposePurchase,
  detailsLive,
  setDetailsLive,
  typeWork,
  setTypeWork,
  isCreateAccount,
}: ISectionMainInformationsAccountLegal) => {
  const handleMessageNameAccount = () => {
    if (
      /\d/.test(formik.values.nameAccount) ||
      /[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(formik.values.nameAccount)
    )
      return "O nome deve conter apenas letras";

    return undefined;
  };

  return (
    <>
      <Styled.ContainerBoxTitleInfo>
        <span>Informações principais</span>
      </Styled.ContainerBoxTitleInfo>
      <Styled.ContainerBoxInputs>
        {!isCreateAccount && (
          <InputSelect
            isRequirement
            title="Origem da conta:"
            label={originAccount || "Selecione uma opção"}
            isSelected={originAccount}
            options={optionOriginAccount}
            selectedOption={originAccount}
            onChange={(e) => setOriginAccount(e.currentTarget.value)}
          />
        )}

        <InputText
          isRequirement
          label="Nome da Conta:"
          placeholder="Nome Completo (Nome e Sobrenome)"
          name="nameAccount"
          value={formik.values.nameAccount}
          onChange={formik.handleChange}
          error={
            /\d/.test(formik.values.nameAccount) ||
            /[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(formik.values.nameAccount)
          }
          messageError={handleMessageNameAccount()}
        />
        <InputText
          label="Nome fantasia:"
          placeholder="Nome Fantasia Completo"
          name="fantasyName"
          value={formik.values.fantasyName}
          onChange={formik.handleChange}
        />
        <InputText
          label="Regional"
          name="stateRegistration"
          value={regional}
          readOnly
        />
        <InputText
          label="Inscrição estadual:"
          placeholder="XX.XXX.XXXX-X"
          name="stateRegistration"
          value={formik.values.stateRegistration}
          onChange={formik.handleChange}
        />
        <InputText
          label="Inscrição municipal:"
          placeholder="XX.XXX.XXXX-X"
          name="munipalRegistration"
          value={formik.values.munipalRegistration}
          onChange={formik.handleChange}
        />
        <InputText
          isRequirement
          label="CNPJ:"
          placeholder="XX.XXX.XXX/XXXX-XX"
          name="cnpj"
          value={cnpj.format(formik.values.cnpj)}
          onChange={(e) => {
            if (e.target.value.replace(/[^\d]/g, "")?.length <= 14) {
              formik.handleChange(e);

              setError((prevState: any) => ({
                ...prevState,
                cnpj: false,
              }));
            }
          }}
          error={
            error.cnpj ||
            (formik.values.cnpj && !cnpj.isValid(formik.values.cnpj))
          }
          messageError={
            cnpj.format(formik.values.cnpj)?.length < 14 ||
            !cnpj.isValid(formik.values.cnpj)
              ? "Formato inválido"
              : "CNPJ já cadastrado"
          }
        />
        <InputText
          label="NIRE:"
          placeholder="XX.XXX.XXXX-X"
          name="nire"
          value={formik.values.nire}
          onChange={formik.handleChange}
        />
        <InputSelect
          isRequirement
          title="Regional Comercial:"
          label={commercialRegional || "Selecione uma opção"}
          isSelected={commercialRegional}
          options={optionCommercialRegional}
          selectedOption={commercialRegional}
          onChange={(e) => setCommercialRegional(e.currentTarget.value)}
        />

        <Styled.ContainerBoxInfoCheckbox>
          <InputText
            isTelephone
            isRequirement
            label="Telefone principal:"
            placeholder="(XX) XXXXX-XXXX"
            name="mainPhone"
            value={phoneMask(formik.values.mainPhone)}
            onChange={(e) => {
              if (e.target.value.replace(/[^\d]/g, "")?.length <= 15) {
                formik.handleChange(e);
                setError((prevState: any) => {
                  return {
                    ...prevState,
                    telefone: false,
                  };
                });
              }
            }}
            maxLength={15}
            error={
              (phoneMask(formik.values.mainPhone)?.length < 15 &&
                phoneMask(formik.values.mainPhone)) ||
              error.telefone
            }
            messageError={
              phoneMask(formik.values.mainPhone)?.length < 15
                ? "Formato inválido"
                : "Telefone já cadastrado"
            }
          />
          <SelectCheckbox value={selectedCheckBox}>
            {renderInputCheckbox(
              "Whatsapp",
              selectedCheckBox,
              setSelectedCheckBox
            )}
            {renderInputCheckbox(
              "Celular",
              selectedCheckBox,
              setSelectedCheckBox
            )}
          </SelectCheckbox>
        </Styled.ContainerBoxInfoCheckbox>

        <InputText
          isRequirement
          label="E-mail principal:"
          placeholder="exemplo@provedor.com"
          name="mainEmail"
          value={formik.values.mainEmail}
          onChange={(e) => {
            formik.handleChange(e);
            setError((prevState: any) => {
              return {
                ...prevState,
                email: false,
              };
            });
          }}
          error={
            (!isEmail(formik.values.mainEmail) && formik.values.mainEmail) ||
            error.email
          }
          messageError={
            error.email ? "E-mail já cadastrado " : "E-mail inválido"
          }
        />
        <InputText
          label="Data de fundação:"
          placeholder="XX/XX/XXXX"
          name="dateFoundation"
          value={formik.values.dateFoundation}
          onChange={(e) => {
            const newValue = formatDatePtBr(e.target.value);
            formik.handleChange(e);
            formik.setFieldValue("dateFoundation", newValue);
          }}
          maxLength={10}
        />
        <InputSelect
          isRequirement
          title="Unidade de negócio:"
          label={businessUnity || "Selecione uma opção"}
          isSelected={businessUnity}
          options={optionBusinessUnity}
          selectedOption={businessUnity}
          onChange={(e) => setBusinessUnity(e.currentTarget.value)}
        />

        {!isCreateAccount && (
          <>
            <InputText
              label="E-mail Complementar:"
              placeholder="exemplo@provedor.com"
              name="complementaryEmail"
              value={formik.values.complementaryEmail}
              onChange={formik.handleChange}
              error={
                !isEmail(formik.values.complementaryEmail) &&
                formik.values.complementaryEmail
              }
              messageError={"E-mail inválido"}
            />
            <InputSelect
              title="Finalidade da Compra:"
              label={purposePurchase || "Selecione uma opção"}
              isSelected={purposePurchase}
              options={optionPurposePurchase}
              selectedOption={purposePurchase}
              onChange={(e) => setPurposePurchase(e.currentTarget.value)}
            />
            {purposePurchase === "Para morar" && (
              <InputSelect
                title="Detalhes - Para Morar:"
                label={detailsLive || "Selecione uma opção"}
                isSelected={detailsLive}
                options={optionDetailsLive}
                selectedOption={detailsLive}
                onChange={(e) => setDetailsLive(e.currentTarget.value)}
              />
            )}

            <InputSelect
              title="Tipo de trabalho:"
              label={typeWork || "Selecione uma opção"}
              isSelected={typeWork}
              options={optionTypeWork}
              selectedOption={typeWork}
              onChange={(e) => setTypeWork(e.currentTarget.value)}
            />
          </>
        )}
      </Styled.ContainerBoxInputs>

      {!isCreateAccount && (
        <Styled.ContainerBoxInfoTextBox>
          <InputTextArea
            name="comments"
            label="Observações:"
            placeholder="Observações sobre a conta em questão..."
            value={formik.values.comments}
            onChange={formik.handleChange}
          />
        </Styled.ContainerBoxInfoTextBox>
      )}
    </>
  );
};
