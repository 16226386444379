import { Select } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { theme } from "src/styles/theme"

interface IInputSelect {
  value: string
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  children: ReactNode
  onClick?: React.MouseEventHandler<HTMLSelectElement>
}

const InputSelect = ({ value, onChange, onClick, children }: IInputSelect) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      onClick={onClick}
      bg="white"
      height="36px"
      focusBorderColor={theme.primary}
      borderWidth="2px"
      borderColor={theme.disable}
      _hover={{ borderColor: "none" }}
      _focus={{ borderWidth: "1.5px" }}
    >
      {children}
    </Select>
  )
}

export default InputSelect
