import InputRadioButton from "@components/MyProfile/Inputs/InputRadioButton";
import React from "react";

interface Option {
  id: string;
  name: string;
}

interface RadioButtonProps {
  id: string;
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  name,
  checked,
  onChange,
}) => {
  return (
    <InputRadioButton
      key={id}
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
    />
  );
};

export const RadioButtonsList: React.FC<{
  options: Option[];
  selectedOption: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ options, selectedOption, onChange }) => {
  return (
    <>
      {options.map(({ id, name }) => (
        <RadioButton
          key={id}
          id={id}
          name={name}
          checked={name === selectedOption}
          onChange={onChange}
        />
      ))}
    </>
  );
};
