import React from "react";
import { IValidationMyProfileSchema } from "src/@types/MyProfile";
import { IUsuallyContext } from "../@types/context/usuallyContext.structure";
import { IValidationEnterprisesSchema } from "src/@types/Enterprises";

interface IProps {
  children: React.ReactNode;
}

export const UsuallyContext = React.createContext({} as IUsuallyContext);

const UsuallyContextProvider: React.FC<IProps> = ({ children }) => {
  const [dataMyProfile, setDataMyProfile] =
    React.useState<IValidationMyProfileSchema>();
  const [loadingUpdateProfile, setLoadingUpdateProfile] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [emptyInput, setEmptyInput] = React.useState<string[]>([]);
  const [dataEnterprises, setDataEnterprises] =
    React.useState<IValidationEnterprisesSchema>();
  const [dataUnity, setDataUnity] = React.useState([]);
  const [dataSalesMirror, setDataSalesMirror] = React.useState([]);
  const [nameEnterprisesBreadcrumb, setNameEnterprisesBreadcrumb] =
    React.useState("");
  const [nameEnterprisesUnity, setNameEnterprisesUnity] = React.useState(false);
  const [nameEnterprises, setNameEnterprises] = React.useState("");
  const [idEnterprises, setIdEnterprises] = React.useState("");
  const [idUnity, setIdUnity] = React.useState("");
  const [nameUnity, setNameUnity] = React.useState("");
  const [typeAccount, setTypeAccount] = React.useState("Fisica");
  const [successCreateAccountSales, setSuccessCreateAccountSales] =
    React.useState(false);
  const [nameCreateAccountSales, setNameCreateAccountSales] =
    React.useState("");
  const [nameCreateOportunity, setNameCreateOportunity] = React.useState("");
  const [successCreateOportunity, setSuccessCreateOportunity] =
    React.useState(false);
  const [hashSalesSidebar, setHashSalesSidebar] = React.useState("Oportunity");
  const [hashDetailOportunity, setHashDetailOportunity] =
    React.useState("DetailOportunity");
  const [filterSalesMirror, setFilterSalesMirror] =
    React.useState("Todas as unidades");
  const [accountIdSalesforce, setAccountIdSalesforce] = React.useState("");

  const [dataTableAttachment, setDataTableAttachment] = React.useState<
    { id: number; name: string; data: string; idAws: string }[]
  >([]);

  const initialValuesMyProfile = {
    name: dataMyProfile?.nome,
    regional: dataMyProfile?.regional,
    team: dataMyProfile?.nomeEquipe,

    sexo: dataMyProfile?.sexo,
    schooling: dataMyProfile?.escolaridade,

    dateBt: dataMyProfile?.dataAniversario,
    estadoCivil: dataMyProfile?.estadoCivil,
    cpf: dataMyProfile?.cpf,
    rg: dataMyProfile?.rg,

    nacionality: dataMyProfile?.nacionalidade,
    naturalness: dataMyProfile?.naturalidade,
    pis: dataMyProfile?.pis,
    uf: dataMyProfile?.ufrg,

    telephone: dataMyProfile?.telefone,
    cell: dataMyProfile?.celular1,
    cell2: dataMyProfile?.celular2,

    email: dataMyProfile?.emailPessoal,
    emailDirecional: dataMyProfile?.emailDirecional,
    otherTell: dataMyProfile?.outroTelefone,

    fatherName: dataMyProfile?.nomePai,
    spouse: dataMyProfile?.nomeConjuge,
    motherName: dataMyProfile?.nomeMae,

    quantitySoon: dataMyProfile?.quantidadeFilhos,
    dtRegistrationTti: dataMyProfile?.dataMatricula,
    creci: dataMyProfile?.creci,

    validCreci: dataMyProfile?.validadeCreci,
    responsible: dataMyProfile?.nomeResponsavel,
    dtConclusion: dataMyProfile?.dataConclusao,

    statusCreci: dataMyProfile?.statusCRECI,
    obs: dataMyProfile?.observacoes,
    responsibleCreci: dataMyProfile?.creciResponsavel,

    bank: dataMyProfile?.banco,
    agency: dataMyProfile?.agencia,
    typeAccount: dataMyProfile?.tipoConta,
    account: dataMyProfile?.conta,

    typeCorretor: dataMyProfile?.tipoCorretor,
    commissionBilling: dataMyProfile?.faturamentoComissao,
    cnpj: dataMyProfile?.cnpj,

    number: dataMyProfile?.numero,
    neighborhood: dataMyProfile?.bairro,
    logradouro: dataMyProfile?.logradouro,

    state: dataMyProfile?.uf,
    complement: dataMyProfile?.complemento,
    city: dataMyProfile?.cidade,
    cep: dataMyProfile?.cep,
  };

  return (
    <UsuallyContext.Provider
      value={{
        initialValuesMyProfile,
        setDataMyProfile,
        loadingUpdateProfile,
        setLoadingUpdateProfile,
        isSuccess,
        setIsSuccess,
        dataTableAttachment,
        setDataTableAttachment,
        emptyInput,
        setEmptyInput,
        dataEnterprises,
        setDataEnterprises,
        dataUnity,
        setDataUnity,
        dataSalesMirror,
        setDataSalesMirror,
        nameEnterprisesBreadcrumb,
        setNameEnterprisesBreadcrumb,
        nameEnterprises,
        setNameEnterprises,
        idEnterprises,
        setIdEnterprises,
        idUnity,
        setIdUnity,
        nameUnity,
        setNameUnity,
        nameEnterprisesUnity,
        setNameEnterprisesUnity,
        typeAccount,
        setTypeAccount,
        successCreateAccountSales,
        setSuccessCreateAccountSales,
        nameCreateAccountSales,
        setNameCreateAccountSales,
        filterSalesMirror,
        setFilterSalesMirror,
        nameCreateOportunity,
        setNameCreateOportunity,
        successCreateOportunity,
        setSuccessCreateOportunity,
        hashSalesSidebar,
        setHashSalesSidebar,
        accountIdSalesforce,
        setAccountIdSalesforce,
        hashDetailOportunity,
        setHashDetailOportunity,
      }}
    >
      {children}
    </UsuallyContext.Provider>
  );
};

export default UsuallyContextProvider;
