import { theme } from "src/styles/theme";
import styled from "styled-components";

interface INotify {
  height?: string;
  noContent?: boolean;
  noButton?: boolean;
}

const handleHeight = (
  noContent?: boolean,
  noButton?: boolean,
  height?: string
) => {
  if (noButton) return "80px";
  if (noContent) return "110px";
  if (height) return height;
  return "170px";
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: ${(props: INotify) =>
    handleHeight(props.noContent, props.noButton, props.height)};
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

export const ContainerSuccess = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 70px;
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 12%;
  z-index: 1;

  > span {
    font-size: 12px;
    font-weight: bold;
    color: ${theme.disable};
  }
`;

export const ContainerHeaderSuccess = styled.div`
  display: flex;
  width: 100%;
  height: 20px;
  z-index: 1;

  > span {
    font-size: 12px;
    font-weight: bold;
    color: ${theme.disable};
  }
`;

export const ContainerTitleSuccess = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
  justify-content: center;
  height: ${(props: INotify) => (props.noContent ? "40px" : undefined)};

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const Obs = styled.div`
  display: flex;
  width: 100%;
  height: 35%;

  > span {
    font-size: 15px;
    color: ${theme.text};
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props: INotify) => (props.noContent ? "50px" : "30%")};
  justify-content: flex-end;
`;

export const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
