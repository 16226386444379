/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import * as Styled from "./styles";
import { UsuallyContext } from "@context/usuallyContex";
import { formatMoney, formatUnit } from "@helpers/format";

type TableUnity = {
  idUnidadeSales: string;
  produtoDirecional: string;
  statusUnidade: string;
  valorFinalKit: string;
  areaPrivativaTotal: string;
};

interface IDataTable {
  data: TableUnity[];
  onClick: any;
  nameFilter: string;
  showAllItems?: boolean;
}

function DataTableUnity({
  data,
  onClick,
  nameFilter,
  showAllItems = false,
}: Readonly<IDataTable>) {
  const { setIdUnity, setNameUnity } = React.useContext(UsuallyContext);
  const sortKey = "produtoDirecional";
  const sortOrder = "asc";

  const sortedData = React.useMemo(() => {
    if (!data) return;
    return data.slice().sort((a: any, b: any) => {
      if (sortKey === "produtoDirecional") {
        return sortOrder === "asc"
          ? a.produtoDirecional.localeCompare(b.produtoDirecional)
          : b.produtoDirecional.localeCompare(a.produtoDirecional);
      }
      return sortOrder === "asc"
        ? a[sortKey] - b[sortKey]
        : b[sortKey] - a[sortKey];
    });
  }, [data, sortKey, sortOrder]);

  const handleNameClick = (
    idEmpreendimentoSales: string,
    produtoDirecional: string
  ) => {
    setIdUnity(idEmpreendimentoSales);
    setNameUnity(produtoDirecional);
    localStorage.setItem("@idUnity", idEmpreendimentoSales);
    localStorage.setItem("@nameUnity", produtoDirecional);
  };

  const filteredRows = sortedData?.filter((row) =>
    row?.produtoDirecional?.toLowerCase()?.includes(nameFilter?.toLowerCase())
  );

  const visibleItemCount = showAllItems ? filteredRows?.length : 4;

  return (
    <Table flexDirection="column" variant="simple" bg="white">
      <Thead>
        <Tr>
          <Th w="20%">
            <Text display={"flex"} fontSize={11}>
              Produtos Direcional{" "}
            </Text>
          </Th>

          <Th w="20%" textAlign="center">
            <Text fontSize={11}>Status</Text>
          </Th>

          <Th w="20%" textAlign="center">
            <Text fontSize={11}>Valor final do kit</Text>
          </Th>

          <Th w="20%" textAlign="center">
            <Text fontSize={11}>Área privativa total</Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredRows?.slice(0, visibleItemCount).map((row) => (
          <Tr key={row.idUnidadeSales}>
            <Td w="20%">
              <Styled.ContainerName>
                <a
                  onClick={() => {
                    handleNameClick(row.idUnidadeSales, row.produtoDirecional);
                    onClick();
                  }}
                >
                  {row.produtoDirecional}
                </a>
              </Styled.ContainerName>
            </Td>

            <Td w="20%" bg="#dde1e8b3">
              <Styled.ContainerStatus>
                <span>{row.statusUnidade}</span>
              </Styled.ContainerStatus>
            </Td>

            <Td w="20%">
              <Styled.ContainerValue>
                <span>{formatMoney(row.valorFinalKit)}</span>
              </Styled.ContainerValue>
            </Td>

            <Td w="20%" bg="#dde1e8b3">
              <Styled.ContainerArea>
                <span>{formatUnit(row.areaPrivativaTotal)}</span>
              </Styled.ContainerArea>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default DataTableUnity;
