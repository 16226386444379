import { format, parseISO } from "date-fns";

export const formatDate = (date: string) => {
  if (typeof date !== "string") {
    return "";
  }
  return format(parseISO(date), "ddMMyyyy");
};

export const formatDatePtBr = (inputValue: string): any => {
  if (typeof inputValue !== "string") {
    return undefined;
  }

  const numericValue = inputValue.replace(/\D/g, "");

  const limitedNumericValue = numericValue.substring(0, 8);

  let formattedDate = "";

  for (let i = 0; i < limitedNumericValue.length; i++) {
    if (i === 2 || i === 4) {
      formattedDate += "/";
    }
    formattedDate += limitedNumericValue[i];
  }

  return formattedDate;
};

export const capitalize = (name: string) => {
  if (typeof name !== "string") {
    return "";
  }

  const words = name.split(" ");

  const capitalizedWords = words.map((word) => {
    const capitalizedFirstLetter = word.charAt(0).toUpperCase();
    const lowercasedRest = word.slice(1).toLowerCase();
    return capitalizedFirstLetter + lowercasedRest;
  });

  return capitalizedWords.join(" ");
};

export const phoneMask = (input: string): string => {
  const numeroLimpo = input?.replace(/\D/g, "");

  const match = RegExp(/^(\d{2})(\d{5})(\d{4})$/)?.exec(numeroLimpo);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return input;
};

export const documentMask = (v: string) => {
  v = v?.replace(/\D/g, "");

  if (v?.length <= 11) {
    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v?.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v?.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v?.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
};

export const cepMask = (v: string) => {
  return v
    ?.replace(/\D/g, "")
    ?.replace(/(\d{5})(\d{1,3})/, "$1-$2")
    ?.slice(0, 9);
};

export const cnpjMask = (v: string) => {
  v = v?.replace(/\D/g, "");

  v = v?.replace(/^(\d{2})(\d)/, "$1.$2");
  v = v?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v?.replace(/\.(\d{3})(\d)/, ".$1/$2");
  v = v?.replace(/(\d{4})(\d)/, "$1-$2");

  return v;
};

export function rgMask(value: string) {
  value = value?.replace(/\D/g, "");

  if (value?.length <= 2) {
    value = value?.replace(/^(\d{0,2})/, "$1");
  } else if (value?.length <= 5) {
    value = value?.replace(/^(\d{2})(\d{0,3})/, "$1.$2");
  } else if (value?.length <= 8) {
    value = value?.replace(/^(\d{2})(\d{3})(\d{0,3})/, "$1.$2.$3");
  } else if (value?.length <= 9) {
    value = value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{0,1})/, "$1.$2.$3-$4");
  } else {
    value = value?.substring(0, 12);
  }

  return value;
}

export const formatRegional = (regional: string): string => {
  if (typeof regional !== "string" || regional?.length < 2) {
    return "";
  }

  const [state] = regional.split(" ");

  return state?.length === 2 ? state : "";
};

export const formatIncorporation = (incorporation: string): string => {
  if (typeof incorporation !== "string") {
    return "";
  }

  if (incorporation.length === 2) {
    return incorporation;
  }

  const [, inc] = incorporation.split(" ");

  return inc || "";
};

export const removeAccent = (texto: string) => {
  if (typeof texto !== "string") {
    return "";
  }

  const mapaAcentosHex: { [key: string]: RegExp } = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  for (let letra in mapaAcentosHex) {
    const expressaoRegular: RegExp = mapaAcentosHex[letra];
    texto = texto.replace(expressaoRegular, letra);
  }

  return texto;
};

export function formatMoney(value: any) {
  if (typeof value !== "number") {
    return;
  }

  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedValue;
}

export function formatUnit(value: any) {
  if (typeof value !== "number") {
    return;
  }

  const formattedValue = `${value} m²`;

  return formattedValue;
}

export function formatHourTime(value: string): string {
  const valorNumerico = value?.replace(/\D/g, "");

  const match = RegExp(/^(\d{0,2})(\d{0,2})$/).exec(valorNumerico);

  if (!match) {
    return "";
  }

  if (valorNumerico.length <= 2) {
    return valorNumerico;
  }

  const [, hora, minuto] = match;

  if (Number(hora) <= 23 && Number(minuto) <= 59) {
    const valorFormatado = valorNumerico.replace(
      /^(\d{0,2})(\d{0,2})$/,
      "$1:$2"
    );
    return valorFormatado;
  }

  return "";
}
