import InputRadioRequirements from "@components/Login/Inputs/InputRadioRequirements";
import { HiOutlineLockClosed } from "react-icons/hi";
import * as Styled from "../styles";
import InputDigits from "@components/Login/Inputs/InputDigits";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import { requirementsRadio } from "@helpers/securePassword";

interface ICreatePasswordComponent {
  newPassword: string;
  setNewPassword: any;
  validateInput: any;
  error: any;
  loading: boolean;
  handleCreatePassword: () => void;
}

const CreatePasswordComponent = ({
  newPassword,
  setNewPassword,
  validateInput,
  error,
  loading,
  handleCreatePassword,
}: ICreatePasswordComponent) => {
  return (
    <Styled.BodyCurrentLogin>
      <Styled.ContainerCreatePasswordTitle>
        <HiOutlineLockClosed />
        <span>Criar uma senha</span>
      </Styled.ContainerCreatePasswordTitle>

      <Styled.ContainerCreatePasswordSubTitle>
        <span>
          Para finalizar seu cadastro, crie uma senha conforme as seguintes
          regras:
        </span>
      </Styled.ContainerCreatePasswordSubTitle>

      <Styled.ContainerCreatePasswordBody>
        {requirementsRadio(validateInput).map((requirement, index) => (
          <div key={requirement.title}>
            <InputRadioRequirements
              title={requirement.title}
              value={requirement.value}
              checked={requirement.checked}
              error={requirement.error}
            />
          </div>
        ))}
      </Styled.ContainerCreatePasswordBody>

      <Styled.ContainerCode>
        <Styled.ContainerInputCode>
          <InputDigits
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            message="Campo obrigatório"
            error={!!newPassword && error.errorValidPassword}
          />
        </Styled.ContainerInputCode>
      </Styled.ContainerCode>

      <Styled.ContainerButtonCreatePassword>
        <Button
          title={!loading ? "CONFIRMAR" : <Spinner />}
          onClick={() => handleCreatePassword()}
          disabled={loading || !!error.errorValidPassword}
        />
      </Styled.ContainerButtonCreatePassword>
    </Styled.BodyCurrentLogin>
  );
};

export default CreatePasswordComponent;
