import React from "react";
import * as Styled from "./styles";
import { theme } from "src/styles/theme";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";

interface IInputLogin {
  placeholder?: string;
  isLabel?: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  iconClick?: React.MouseEventHandler<SVGElement>;
  error?: any;
  message?: any;
}

const InputEmail = ({
  value,
  onChange,
  placeholder,
  isLabel,
  error,
  message,
  iconClick,
}: IInputLogin) => {
  return (
    <Styled.Area>
      {isLabel && <Styled.Label>E-mail:</Styled.Label>}

      <InputGroup>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isInvalid={error}
          focusBorderColor={theme.primary}
          borderWidth={2}
          borderColor={theme.disable}
          height="44px"
          borderRadius={8}
          fontSize={14}
          fontWeight="bold"
          _placeholder={{ color: theme.text }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
        />
        <InputRightElement>
          {value ? (
            <Styled.IconAiOutlineCloseCircle onClick={iconClick} />
          ) : (
            <Styled.IconBsPersonSquare />
          )}
        </InputRightElement>
      </InputGroup>

      <span>{message}</span>
    </Styled.Area>
  );
};

export default InputEmail;
