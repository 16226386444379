import React from "react";
import { Box, useRadio } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IRadioSelectProps {
  label?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioSelect = ({ label, checked, onChange }: IRadioSelectProps) => {
  const { getInputProps, getRadioProps, getRootProps } = useRadio({
    onChange,
    isChecked: checked,
  });

  const inputProps = getInputProps();
  const checkboxProps = getRadioProps();
  const rootProps = getRootProps();

  return (
    <Box
      as="label"
      {...rootProps}
      display="flex"
      alignItems="center"
      cursor="pointer"
      fontSize="14px"
    >
      <input {...inputProps} style={{ display: "none" }} />
      <Box
        {...checkboxProps}
        mr="8px"
        w="20px"
        h="20px"
        borderWidth="2px"
        borderColor={theme.disable}
        borderRadius="full"
        _checked={{
          borderColor: theme.primary,
        }}
        pos="relative"
      >
        <Box
          visibility={checked ? "visible" : "hidden"}
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          w="12px"
          h="12px"
          bg={theme.primary}
          borderRadius="full"
        />
      </Box>
      {label}
    </Box>
  );
};

export default RadioSelect;
