import axios from "axios";
import api from "../api";

interface IRequestData {
  [key: string]: any;
}

const handleApiCall = async (url: string, requestData: IRequestData) => {
  try {
    const response = await api.post(url, requestData);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorRes = error.response;
      return errorRes;
    }
    return false;
  }
};

class ForgotPasswordService {
  async sendCodeForgotPassword({ cpf }: IRequestData) {
    const requestData = { cpf };
    return handleApiCall("/autenticacao/esqueci-a-senha", requestData);
  }

  async confirmCodeForgotPassword({
    email,
    senhaTemporaria,
    codigoVerificacao,
  }: IRequestData) {
    const requestData = { email, senhaTemporaria, codigoVerificacao };
    return handleApiCall("/autenticacao/confirmar-codigo-esqueci-a-senha", requestData);
  }

  async resendCode({ email }: IRequestData) {
    const requestData = { email };
    return handleApiCall("/autenticacao/reenviar-codigo-confirmacao-esqueci-a-senha", requestData);
  }

  async createNewPassword({
    email,
    SenhaAntiga,
    SenhaAtual,
  }: IRequestData) {
    const requestData = { email, SenhaAntiga, SenhaAtual };
    return handleApiCall("/autenticacao/alterar-senha", requestData);
  }
}

const forgotPasswordServiceInstance = new ForgotPasswordService();

export default forgotPasswordServiceInstance;
