import { ReactNode, useEffect, useState, useRef } from "react";
import * as Styled from "./styles";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { useClickOutside } from "@hook/useClickOutside";

interface ISelect {
  title: string;
  label: string;
  children?: ReactNode;
  error?: boolean;
  messageError?: string;
  isSelected?: string;
  isHeight?: boolean;
}

const InputSelect = ({
  title,
  label,
  children,
  error,
  messageError,
  isSelected,
  isHeight,
}: ISelect) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (isSelected) {
      setOpen(false);
    }
  }, [isSelected]);

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Styled.Container ref={wrapperRef}>
      <Styled.Label>{title}</Styled.Label>
      <Styled.SelectButton isError={error} onClick={handleToggleOpen}>
        <Styled.Title isError={error}>{label}</Styled.Title>
        <Styled.Arrow isError={error} isOpen={open}>
          <IoMdArrowDropdownCircle />
        </Styled.Arrow>
      </Styled.SelectButton>

      {open && (
        <Styled.ListItems isHeight={isHeight} isOpen={open}>
          {children}
        </Styled.ListItems>
      )}

      {error && <Styled.MessageError>{messageError}</Styled.MessageError>}
    </Styled.Container>
  );
};

export default InputSelect;
