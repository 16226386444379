import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface ISelect {
  isOpen?: boolean;
  isError?: boolean;
  isHeight?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 50%;
`;

export const SelectButton = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid
    ${(props: ISelect) =>
      props.isError ? `${theme.error}` : `${theme.disable}`};
  z-index: 1;
`;

export const Label = styled.span`
  display: flex;
  font-size: 12px;
  margin-left: 3px;
  width: 98%;
  color: ${theme.text};
`;

export const Title = styled.span`
  font-size: 14px;
  color: ${(props: ISelect) =>
    props.isError ? `${theme.error}` : `${theme.text}`};
`;

export const Arrow = styled.span`
  display: flex;
  width: fit-content;
  color: #fff;
  font-size: 14px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  > svg {
    font-size: 22px;
    color: ${(props: ISelect) =>
      props.isError ? `${theme.error}` : `${theme.primary}`};
    transition: 0.3s;
    transform: ${(props: ISelect) =>
      props.isOpen ? "rotate(-180deg)" : "rotate(0)"};
  }
`;

export const ListItems = styled.ul`
  display: ${(props: ISelect) => (props.isOpen ? "block" : "none")};
  background: white;
  width: 23%;
  height: ${(props: ISelect) => (props.isHeight ? "50%" : "fit-content")};
  position: fixed;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid ${theme.disable};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  @media ${device.tablet} {
    width: 41.5%;
  }
  @media ${device.mobileL} {
    width: 61%;
  }
`;

export const MessageError = styled.span`
  display: flex;
  width: 95%;
  justify-content: flex-end;
  font-size: 10px;
  color: ${theme.text};
`;
