import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

type ISales = {
  open?: boolean;
  hash?: string;
  currentFase?: boolean;
  indexTab?: boolean;
};

export const Area = styled.div<ISales>`
  display: flex;
  position: relative;
  flex-direction: column;
  left: ${({ open }) => (!open ? " 80px" : "230px")};
  width: ${({ open }) => (!open ? `calc(100% - 80px)` : `calc(100% - 230px)`)};
  height: 100%;
  background: ${theme.disable100};
  transition: all 0.2s ease;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  > form {
    display: flex;
    flex-direction: column;
  }

  @media ${device.tablet} {
    width: 100%;
    left: 0;
  }
`;

export const Container = styled.div<ISales>`
  display: flex;
  position: relative;
  flex-direction: column;
  height: max-content;
`;

export const SubContainer = styled.div`
  display: flex;
  padding-left: 5px;
  flex-direction: column;
  height: max-content;
  padding-top: 25px;

  @media ${device.mobileL} {
    padding-left: 3px;
  }
`;

export const ContainerTop = styled.section`
  display: flex;
  width: 100%;
  margin-top: 35px;

  @media ${device.laptop} {
    margin-top: 23px;
  }
  @media ${device.tablet} {
    margin-top: 35px;
  }
  @media ${device.mobileL} {
    margin-top: 35px;
  }
`;
