import React from "react";
import * as Styled from "./styles";

interface IBox {
  title?: string;
  body: any;
  width?: string;
  height?: string;
}

const Box = ({ title, body, width, height }: IBox) => {
  return (
    <Styled.ContainerBox width={width}>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Body>{body}</Styled.Body>
    </Styled.ContainerBox>
  );
};

export default Box;
