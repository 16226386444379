import React from "react";
import { LoginContext } from "@context/loginContext";
import * as Styled from "./styles";

const Home = () => {
  const { openSidebar } = React.useContext(LoginContext);

  return (
    <Styled.Area open={openSidebar}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          height: "100%",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 22,
          }}
        >
          Home
        </h1>
      </div>
    </Styled.Area>
  );
};

export default Home;
