import React from "react";
import * as Styled from "../styles";
import { MdMarkEmailRead } from "react-icons/md";
import { hideEmail } from "@helpers/emailCheck";
import Spinner from "@components/common/Spinner";
import InputDigits from "@components/Login/Inputs/InputDigits";
import Button from "@components/common/Button";
import Helper from "@components/Login/Helper";

interface ITwoFactorAuthenticationComponent {
  email: string;
  digits: string;
  setDigits: any;
  loading: boolean;
  loadingResend: boolean;
  error: any;
  setError: any;
  handleResendCode: () => void;
}

const TwoFactorAuthenticationComponent = ({
  email,
  digits,
  setDigits,
  loading,
  loadingResend,
  error,
  setError,
  handleResendCode,
}: ITwoFactorAuthenticationComponent) => {
  return (
    <>
      <Styled.BodyCurrentLogin>
        <Styled.ContainerTwoAuthenticationTitle>
          <MdMarkEmailRead />
          <span>Autenticação em dois fatores</span>
        </Styled.ContainerTwoAuthenticationTitle>
        <Styled.ContainerTwoAuthenticationSubTitle>
          <span>
            Enviamos um código de verificação para o e-mail cadastrado:
          </span>

          <p>{hideEmail(email)}</p>
        </Styled.ContainerTwoAuthenticationSubTitle>

        <Styled.ContainerCode>
          <span>Insira o código de seis dígitos nos campos abaixo:</span>

          <Styled.ContainerInputCode>
            {loading ? (
              <Spinner />
            ) : (
              <InputDigits
                value={digits}
                onChange={(e) => {
                  setDigits(
                    e.target.value.replace(/[^0-9]/g, "").replace(/\s/g, "")
                  );
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      confirmCode: false,
                    };
                  });
                }}
                maxLength={6}
                message="Campo obrigatório"
              />
            )}
          </Styled.ContainerInputCode>
        </Styled.ContainerCode>

        {error.confirmCode ? (
          <Styled.ContainerButtonForgotPassword>
            <Button
              title="CÓDIGO INVÁLIDO"
              onClick={() => {}}
              error={error.confirmCode}
            />
          </Styled.ContainerButtonForgotPassword>
        ) : (
          <Styled.ContainerButtonForgotPassword />
        )}
      </Styled.BodyCurrentLogin>

      <Helper
        isResendCode
        loadingResend={loadingResend}
        onClickResend={handleResendCode}
      />
    </>
  );
};

export default TwoFactorAuthenticationComponent;
