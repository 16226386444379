import { ReactNode } from "react";
import {
  Modal as CkModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
interface IModal {
  onOpen: boolean;
  title: string;
  children: ReactNode;
  onClose?: any;
}

const Modal = ({ onOpen, title, children, onClose }: IModal) => {
  return (
    <CkModal
      closeOnOverlayClick={false}
      isOpen={onOpen}
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent minH={"350px"}>
        <ModalHeader color={theme.text}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={10}>{children}</ModalBody>
      </ModalContent>
    </CkModal>
  );
};

export default Modal;
