import React, { ReactNode } from "react";
import * as Styled from "./styles";

interface ITabs {
  childrean: ReactNode;
  onClickLeft?: React.FormEventHandler<HTMLButtonElement>;
  onClickRight?: React.FormEventHandler<HTMLButtonElement>;
  onClickCenter?: React.FormEventHandler<HTMLButtonElement>;
  isActive: string;
  page: string;
}

const Tabs = ({
  childrean,
  onClickLeft,
  onClickRight,
  onClickCenter,
  isActive,
  page,
}: ITabs) => {
  return (
    <Styled.Container>
      <Styled.Nav>
        {page ? (
          <Styled.ContainerCenter>
            <Styled.TabsCenter
              onClick={onClickCenter}
              isActive={isActive}
              page={page}
            >
              {page}
            </Styled.TabsCenter>
          </Styled.ContainerCenter>
        ) : (
          <>
            <Styled.TabsLeft onClick={onClickLeft} isActive={isActive}>
              Fazer login
            </Styled.TabsLeft>
            <Styled.TabsRight onClick={onClickRight} isActive={isActive}>
              Primeiro acesso
            </Styled.TabsRight>
          </>
        )}
      </Styled.Nav>
      <Styled.Slider isActive={isActive} page={page} />
      <Styled.Body>{childrean}</Styled.Body>
    </Styled.Container>
  );
};

export default Tabs;
