import {
  Tabs,
  TabList,
  Tab as CkTab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { useScrollableBoxProps } from "@hook/useEqualCode";
interface ITab {
  width: string;
  widthXl?: string;
  widthMd?: string;
  widthSm?: string;
  widthBase?: string;
  fontSize?: string;
  tabLabels: string[];
  tabContents: Record<string, React.ReactNode>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
}

const Tab = ({
  width,
  widthXl,
  widthBase,
  widthSm,
  widthMd,
  fontSize,
  tabLabels,
  tabContents,
  setIndex,
  disabled = false,
}: ITab) => {
  const scrollProps = useScrollableBoxProps();

  return (
    <Tabs onChange={(index) => setIndex(index)}>
      <TabList
        {...scrollProps}
        w={{
          base: widthBase,
          sm: widthSm,
          md: widthMd,
          lg: width,
          xl: widthXl,
        }}
      >
        {tabLabels.map((label) => (
          <CkTab
            fontSize={fontSize}
            key={label}
            color={theme.text}
            _selected={{ color: theme.primary, fontWeight: "bold" }}
            isDisabled={disabled}
          >
            {label}
          </CkTab>
        ))}
      </TabList>

      <TabPanels>
        {tabLabels.map((label) => (
          <TabPanel key={label}>{tabContents[label]}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default Tab;
