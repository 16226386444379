import React from "react";
import * as Styled from "../../styles";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import { optionCapaign, optionEnterprise } from "../options";
import { ISectionProductInterestComponent } from "src/@types/Sales";

const SectionProductInterestComponent = ({
  campaign,
  setCampaign,
  selectCampaign,
  setSelectCampaign,
  selectMainEnterprises,
  setSelectMainEnterprises,
  mainEnterprises,
  setMainEnterprises,
  dataEnterprises,
  dataCampaign,
  setIdEnterprise,
  setIdCampaign,
  getEnterprises,
  getCampaign,
  loadingEnterprises,
  loadingCampaign,
}: ISectionProductInterestComponent) => {
  const renderedOptionsEnterprise = optionEnterprise(
    dataEnterprises,
    selectMainEnterprises,
    setSelectMainEnterprises,
    setMainEnterprises,
    setIdEnterprise
  );

  const renderedOptionsCapaign = optionCapaign(
    dataCampaign,
    selectCampaign,
    setSelectCampaign,
    setCampaign,
    setIdCampaign
  );

  return (
    <>
      <Styled.ContainerBoxTitleInfo>
        <span>Produtos de interesse</span>
      </Styled.ContainerBoxTitleInfo>
      <Styled.ContainerBoxMyOportunityProductsInputs>
        <InputSelectSearch
          isOportunity
          width="38%"
          height="18%"
          title="Empreendimento de interesse principal:"
          placeholder="Pesquise por empreendimento"
          value={selectMainEnterprises}
          onChangeInput={(e) => setSelectMainEnterprises(e.target.value)}
          isSelected={mainEnterprises}
          onChangeIcon={() => {
            setMainEnterprises("");
            setSelectMainEnterprises("");
          }}
          onClickSearchIcon={getEnterprises}
          loading={loadingEnterprises}
        >
          {renderedOptionsEnterprise}
        </InputSelectSearch>

        <InputSelectSearch
          isOportunity
          width="38%"
          height="18%"
          title="Campanha:"
          placeholder="Pesquise por campanha"
          value={selectCampaign}
          onChangeInput={(e) => setSelectCampaign(e.target.value)}
          isSelected={campaign}
          onChangeIcon={() => {
            setSelectCampaign("");
            setCampaign("");
          }}
          onClickSearchIcon={getCampaign}
          loading={loadingCampaign}
        >
          {renderedOptionsCapaign}
        </InputSelectSearch>
      </Styled.ContainerBoxMyOportunityProductsInputs>
    </>
  );
};

export default SectionProductInterestComponent;
