import { ReactNode, useEffect, useState, useRef } from "react";
import { MdOutlineEditNote } from "react-icons/md";
import * as Styled from "./styles";
import { useClickOutside } from "@hook/useClickOutside";

interface ISelect {
  title: string;
  label: string;
  children?: ReactNode;
  error?: boolean;
  isSelected?: string;
  height?: string;
  width?: string;
  disabled?: boolean;
}

const InputSelectEditable = ({
  title,
  label,
  children,
  error,
  isSelected,
  height,
  width,
  disabled,
}: ISelect) => {
  const wrapperRef: any = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (isSelected) {
      setOpen(false);
    }
  }, [isSelected]);

  return (
    <Styled.Container ref={wrapperRef}>
      <Styled.Label>{title}</Styled.Label>
      <Styled.SelectButton
        disabled={disabled}
        error={error}
        onClick={() => setOpen(!open)}
      >
        <Styled.Title isSize={label.length > 20}>{label}</Styled.Title>
        <Styled.ContainerIcon>
          <MdOutlineEditNote />
        </Styled.ContainerIcon>
      </Styled.SelectButton>

      {open && (
        <Styled.ListItems
          width={width}
          height={height}
          isOpen={disabled ? false : open}
        >
          {children}
        </Styled.ListItems>
      )}
    </Styled.Container>
  );
};

export default InputSelectEditable;
