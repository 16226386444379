import { theme } from "src/styles/theme";
import styled from "styled-components";

export const ContainerToast = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 80px;
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

export const ContainerToastButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 110px;
  background: white;
  padding: 10px;
  border-radius: 8px;
`;
export const ContainerHeaderToast = styled.div`
  display: flex;
  width: 100%;
  height: 16%;
  z-index: 1;

  > span {
    font-size: 12px;
    font-weight: bold;
    color: ${theme.disable};
  }
`;

export const ContainerMessageToast = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
  justify-content: center;
  height: 40px;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const ContainerButtonToast = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  justify-content: flex-end;
`;
