import { BsFillInfoCircleFill } from "react-icons/bs";
import { HiOutlineLockClosed } from "react-icons/hi";
import { MdOutlineEditNote } from "react-icons/md";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

type InputText = {
  isOportunity?: boolean;
  readOnly?: boolean;
};

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
`;

export const ContainerTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

export const IconBsFillInfoCircleFill = styled(BsFillInfoCircleFill)`
  color: ${theme.text};
  margin-top: 2px;
  margin-left: 4px;
  width: fit-content;
`;

export const Label = styled.span`
  display: flex;
  width: fit-content;
  font-size: 12px;
  color: ${theme.text};
  margin-left: 5px;
`;

export const Requirement = styled.span`
  width: fit-content;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.error};
`;

export const MessageError = styled.span<InputText>`
  display: flex;
  width: ${({ isOportunity }) => (!isOportunity ? "70%" : "60%")};
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 11px;
  font-weight: bold;
  color: ${theme.error};
  gap: 6px;

  @media ${device.laptop} {
    width: 55%;
  }

  @media ${device.laptopXL} {
    width: 80%;
  }

  > svg {
    width: fit-content;
    font-size: 13px;
  }
`;

export const MessageErrorTelephone = styled.span<InputText>`
  display: flex;
  position: absolute;
  width: ${({ isOportunity }) => (!isOportunity ? "26%" : "32%")};
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 11px;
  font-weight: bold;
  color: ${theme.error};
  gap: 6px;

  @media ${device.laptop} {
    width: 31%;
  }
  @media ${device.tablet} {
    width: ${({ isOportunity }) => (!isOportunity ? "36%" : "47%")};
  }
  @media ${device.mobileL} {
    width: ${({ isOportunity }) => (!isOportunity ? "64%" : "93%")};
  }

  > svg {
    width: fit-content;
    font-size: 13px;
  }
`;

export const IconMdOutlineEditNote = styled(MdOutlineEditNote)<InputText>`
  color: ${({ readOnly }) => (readOnly ? theme.disable100 : theme.disable)};
  margin-right: 15px;
  margin-top: 5px;
  font-size: 20px;
  width: fit-content;
`;

export const IconHiOutlineLockClosed = styled(HiOutlineLockClosed)`
  color: ${theme.disable100};
  margin-right: 15px;
  margin-top: 5px;
  font-size: 20px;
  width: fit-content;
`;
