import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

interface ISelect {
  isOportunity?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  error?: boolean;
  isSize?: boolean;
  height?: string;
  width?: string;
}

export const Container = styled.div`
  width: 100%;
  height: 50%;
`;

export const SelectButton = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #fff;
  opacity: ${(props: ISelect) => (props.disabled ? 0.5 : 1)};
  border: 2px solid ${theme.disable};
  z-index: 1;
`;

export const Label = styled.span`
  display: flex;
  font-size: 12px;
  margin-left: 3px;
  width: 98%;
  color: ${theme.text};
`;
export const Requirement = styled.span`
  width: fit-content;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.error};
`;

export const Title = styled.span`
  display: flex;
  color: ${theme.text};
  font-weight: bold;
  font-size: ${(props: ISelect) => (props.isSize ? "12px" : "13px")};
  width: 100%;
  padding-left: 10px;
`;
export const InputText = styled.input`
  display: flex;
  color: ${theme.text};
  font-weight: bold;
  font-size: ${(props: ISelect) => (props.isSize ? "12px" : "13px")};
  width: 80%;
  height: 40px;
  border-radius: 8px;
  padding-left: 10px;
  outline: none;
  border: none;
  background: white;
  opacity: ${(props: ISelect) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props: ISelect) => (props.disabled ? "default" : null)};

  &::placeholder {
    font-weight: normal;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: ${(props: ISelect) => (props.disabled ? "0" : "7px")};
  background: ${(props: ISelect) => (props.disabled ? theme.disable: theme.primary)};
  cursor: ${(props: ISelect) => (props.disabled ? "not-allowed" : "pointer")};

  > svg {
    display: flex;
    width: fit-content;
    color: white;
    font-size: 22px;
  }
`;

export const ContainerIconClose = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: ISelect) => (props.isOportunity ? "10%" : "20%")};
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  background: transparent;

  > svg {
    display: flex;
    width: fit-content;
    color: ${theme.text};
    font-size: 22px;
    cursor: ${(props: ISelect) => (props.disabled ? "not-allowed" : "pointer")};
    margin-right: 8px;
  }
`;

export const ListItems = styled.ul`
  display: ${(props: ISelect) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  background: white;
  width: ${(props: ISelect) => (props.width ? props.width : "23%")};
  max-height: ${(props: ISelect) => (props.height ? props.height : "20%")};
  position: absolute;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid ${theme.disable};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  @media ${device.tablet} {
    width: 38%;
  }
  @media ${device.mobileL} {
    width: 93%;
  }
`;

export const Items = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px 15px;
  border-radius: 8px;

  &:hover {
    background-color: #e7edfe;
  }
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
export const LabelRadioButton = styled.label`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p`
  color: ${theme.text};
  font-size: 12px;
  padding-left: 0.5rem;
`;

export const RadioBox = styled.div`
  display: flex;
  width: 1.2rem;
  height: 1.1rem;
  align-items: center;
  justify-content: center;
  padding: 1px 1px;
  border: 2px solid ${theme.disable};
  border-radius: 50%;
  transition: background 0.15s, border-color 0.15s;
  cursor: pointer;

  &::after {
    content: "";
    width: 10px;
    height: 9.5px;
    display: block;
    background: ${theme.primary};
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    &::after {
      transform: scale(1);
    }
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
`;
