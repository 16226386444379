import { useToast, Box, Text, CloseButton } from "@chakra-ui/react";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import { OportunityContext } from "@context/oportunityContex";
import { useCallback, useContext } from "react";
import { theme } from "src/styles/theme";

interface MessageParams {
  title: string;
  message: string;
  titleButton?: any;
  onClick?: any;
  loading?: boolean;
}

interface MessageDeleteParams {
  title: string;
  message: string;
  onClick: () => void;
  loading?: boolean;
}

interface IMessage {
  onClick: React.FormEventHandler<HTMLButtonElement>;
  title: any;
  message: string;
}

export const useMessageToast = () => {
  const toast = useToast();

  const displayMessage = useCallback(
    ({ title, message, titleButton, onClick }: MessageParams) => {
      toast.closeAll();
      toast({
        position: "top-right",
        status: undefined,
        duration: 6000,
        render: () => (
          <Box
            display={"flex"}
            flexDir={"column"}
            w={"98%"}
            h={"160px"}
            bg={"white"}
            padding={"10px"}
            borderRadius={"8px"}
          >
            <Box display={"flex"} w={"100%"} h={"16%"} zIndex={1}>
              <Text fontSize={"12px"} fontWeight={"bold"} color={theme.disable}>
                Bora Vender
              </Text>
              <CloseButton onClick={() => toast.closeAll()} />
            </Box>

            <Box
              display={"flex"}
              flexDir={"column"}
              w={"100%"}
              mb={"6px"}
              justifyContent={"center"}
              h={"40px"}
            >
              <Text fontSize={"17px"} fontWeight={"bold"} color={theme.text}>
                {title}
              </Text>
            </Box>

            <Box display={"flex"} w={"100%"} h={"45%"}>
              <Text fontSize={"15px"} color={theme.text}>
                {message}
              </Text>
            </Box>

            <Box
              display={"flex"}
              flexDir={"column"}
              w={"100%"}
              h={"80px"}
              justifyContent={"flex-end"}
              mt={"6px"}
            >
              <Button
                title={titleButton}
                onClick={(e) => {
                  onClick(e);
                  toast.closeAll();
                }}
              />
            </Box>
          </Box>
        ),
        containerStyle: {
          maxWidth: "350px",
          marginTop: "65px",
          boxShadow: "dark-lg",
          borderRadius: "8px",
          bg: "white",
        },
      });
    },
    [toast]
  );

  return displayMessage;
};

export const useOnlyMessageToast = () => {
  const toast = useToast();

  const displayMessage = useCallback(
    (message: string) => {
      toast.closeAll();
      toast({
        position: "top-right",
        status: undefined,
        duration: 6000,
        render: () => (
          <Box
            display={"flex"}
            flexDir={"column"}
            w={"98%"}
            h={"60px"}
            bg={"white"}
            padding={"10px"}
            borderRadius={"8px"}
          >
            <Box display={"flex"} w={"100%"} h={"16%"} zIndex={1}>
              <Text fontSize={"12px"} fontWeight={"bold"} color={theme.disable}>
                Bora Vender
              </Text>
              <CloseButton onClick={() => toast.closeAll()} />
            </Box>

            <Box
              display={"flex"}
              flexDir={"column"}
              w={"100%"}
              mt={"15px"}
              justifyContent={"center"}
              h={"60px"}
            >
              <Text fontSize={"17px"} fontWeight={"bold"} color={theme.text}>
                {message}
              </Text>
            </Box>
          </Box>
        ),
        containerStyle: {
          maxWidth: "350px",
          marginTop: "65px",
          boxShadow: "dark-lg",
          borderRadius: "8px",
          bg: "white",
        },
      });
    },
    [toast]
  );

  return displayMessage;
};

export const useMessageDeleteToast = () => {
  const toast = useToast();

  const Message = ({ onClick, title, message }: IMessage) => {
    const { loadingDeleteRelationship } = useContext(OportunityContext);

    return (
      <Box
        display={"flex"}
        flexDir={"column"}
        w={"98%"}
        h={"180px"}
        bg={"white"}
        padding={"10px"}
        borderRadius={"8px"}
      >
        <Box display={"flex"} w={"100%"} h={"16%"} zIndex={1}>
          <Text fontSize={"12px"} fontWeight={"bold"} color={theme.disable}>
            Bora Vender
          </Text>
          <CloseButton onClick={() => toast.closeAll()} />
        </Box>

        <Box
          display={"flex"}
          flexDir={"column"}
          w={"100%"}
          mb={"6px"}
          justifyContent={"center"}
          h={"40px"}
        >
          <Text fontSize={"17px"} fontWeight={"bold"} color={theme.text}>
            {title}
          </Text>
        </Box>

        <Box display={"flex"} w={"100%"} h={"45%"}>
          <Text fontSize={"14px"} color={theme.text}>
            {message}
          </Text>
        </Box>

        <Box
          display={"flex"}
          w={"100%"}
          h={"80px"}
          justifyContent={"flex-end"}
          mt={"6px"}
          gap={"6px"}
        >
          <Button
            colorInverter
            title={"CANCELAR"}
            onClick={(e) => {
              toast.closeAll();
            }}
          />

          <Button
            colorError
            disabled={loadingDeleteRelationship}
            title={loadingDeleteRelationship ? <Spinner /> : "EXCLUIR"}
            onClick={onClick}
          />
        </Box>
      </Box>
    );
  };

  const displayMessage = ({ title, message, onClick }: MessageDeleteParams) => {
    toast.closeAll();
    toast({
      position: "top-right",
      status: undefined,
      duration: null,
      render: () => (
        <Message onClick={onClick} title={title} message={message} />
      ),
      containerStyle: {
        maxWidth: "350px",
        marginTop: "65px",
        boxShadow: "dark-lg",
        borderRadius: "8px",
        bg: "white",
      },
    });
  };

  return displayMessage;
};
