import React from "react";
import * as Styled from "./styles";
import BreadCrumb from "@components/common/BreadCrumb";
import { LoginContext } from "@context/loginContext";
import { IHashMapKeyString } from "src/@types/Sales";
import SearchClientsComponent from "./Steps/SearchClientsComponent";
import { UsuallyContext } from "@context/usuallyContex";
import AccountsPhysicalComponents from "./Steps/CreateAccount/AccountsPhysicalComponents";
import AccountsLegalComponents from "./Steps/CreateAccount/AccountsLegalComponents";
import CreateMyOportunityComponent from "./Steps/Oportunity/CreateMyOportunityComponent";
import MyOportunityComponent from "./Steps/Oportunity/MyOportunityComponent";
import DetailOportunityComponent from "./Steps/Oportunity/DetailOportunityComponent";
import { OportunityContext } from "@context/oportunityContex";

const Sales = () => {
  const { openSidebar, setPageSidebar } = React.useContext(LoginContext);
  const {
    typeAccount,
    successCreateAccountSales,
    hashSalesSidebar,
    setHashSalesSidebar,
    hashDetailOportunity,
    setHashDetailOportunity,
  } = React.useContext(UsuallyContext);
  const {
    accessCreditRating,
    setAccessCreateOp,
    accessCreateOp,
    setAccessCreditRating,
    accessQuote,
    setAccessQuote,
  } = React.useContext(OportunityContext);
  const [hash, setHash] = React.useState("Oportunity");

  const [breadcrumbItems, setBreadcrumbItems] = React.useState([
    {
      name: "Principal",
      link: "/",
      onClick: () => {
        setPageSidebar("Principal");
        localStorage.setItem("@option", "Principal");
      },
    },
    {
      name: "Buscar clientes",
      link: "#",
      onClick: () => setHash("Oportunity"),
    },
  ]);

  function handleNavigationSales() {
    setHash("Oportunity");
    setHashSalesSidebar("Oportunity");
    setHashDetailOportunity("DetailOportunity");
  }
  function handleNavigationMyOportunity() {
    setHash("MyOportunityComponent");
    setHashSalesSidebar("MyOportunityComponent");
    setHashDetailOportunity("DetailOportunity");
    setAccessCreateOp({
      nomeDaConta: "",
      oportunidadeId: "",
      oportunityIdSalesforce: "",
    });
  }
  function handleNavigationDetailOportunity() {
    setHash("DetailOportunityComponent");
    setHashSalesSidebar("DetailOportunityComponent");
    setAccessCreditRating({
      idAvaliacaoCreditoSalesforce: "",
      nome: "",
    });
    setAccessQuote({
      idCotacaoSalesforce: "",
      nomeDaCotacao: "",
    });
    setHashDetailOportunity("DetailOportunity");
  }

  const SalesHashMap: IHashMapKeyString = {
    Oportunity: (
      <SearchClientsComponent
        navigationMyOportunity={handleNavigationDetailOportunity}
        navigationScreenMyOportunity={handleNavigationMyOportunity}
        navigation={() => {
          if (typeAccount === "Fisica") {
            setHash("AccountsPhysical");
            setHashSalesSidebar("AccountsPhysical");
          } else {
            setHash("AccountsLegal");
            setHashSalesSidebar("AccountsLegal");
          }
        }}
        navigationCreateOportunity={() => {
          setHash("CreateMyOportunityComponent");
          setHashSalesSidebar("CreateMyOportunityComponent");
        }}
      />
    ),
    AccountsPhysical: (
      <AccountsPhysicalComponents
        onBack={handleNavigationSales}
        setMandatoryCreateAccount={() => {}}
        setCnpjCreateAccount={() => {}}
        setCpfCreateAccount={() => {}}
      />
    ),
    AccountsLegal: (
      <AccountsLegalComponents
        onBack={handleNavigationSales}
        setMandatoryCreateAccount={() => {}}
        setCnpjCreateAccount={() => {}}
        setCpfCreateAccount={() => {}}
      />
    ),
    CreateMyOportunityComponent: (
      <CreateMyOportunityComponent
        onBack={handleNavigationSales}
        navigation={handleNavigationSales}
      />
    ),
    MyOportunityComponent: (
      <MyOportunityComponent
        navigation={() => setHash("DetailOportunityComponent")}
      />
    ),
    DetailOportunityComponent: (
      <DetailOportunityComponent
        hash={hashDetailOportunity}
        setHash={setHashDetailOportunity}
        navigationCreateAccount={handleNavigationSales}
      />
    ),
  };

  React.useEffect(() => {
    if (successCreateAccountSales) {
      handleNavigationSales();
    }
  }, [successCreateAccountSales]);

  React.useEffect(() => {
    let newBreadcrumbItems = [
      {
        name: "Principal",
        link: "/",
        onClick: () => {
          setPageSidebar("Principal");
          localStorage.setItem("@option", "Principal");
        },
      },
      {
        name: "Buscar clientes",
        link: "#",
        onClick: () => handleNavigationSales(),
      },
    ];

    if (hash === "AccountsPhysical" || hash === "AccountsLegal") {
      newBreadcrumbItems.push({
        name: "Criar nova conta",
        link: "#",
        onClick: () => handleNavigationSales(),
      });
    }

    if (
      [
        "MyOportunityComponent",
        "CreateMyOportunityComponent",
        "DetailOportunityComponent",
      ].includes(hash) &&
      hash !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: "Minhas Oportunidades",
        link: "#",
        onClick: () => handleNavigationMyOportunity(),
      });
    }

    if (["CreateMyOportunityComponent"].includes(hash) && hash !== "Sales") {
      newBreadcrumbItems.push({
        name: "Criar oportunidade",
        link: "#",
        onClick: () => handleNavigationMyOportunity(),
      });
    }
    if (
      ["DetailOportunityComponent"].includes(hash) &&
      accessCreateOp.oportunidadeId &&
      hash !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: accessCreateOp.oportunidadeId,
        link: "#",
        onClick: () => {
          handleNavigationDetailOportunity();
          setAccessCreditRating({
            idAvaliacaoCreditoSalesforce: "",
            nome: "",
          });
        },
      });
    }
    if (
      ["DetailOportunityComponent"].includes(hash) &&
      accessCreditRating.nome &&
      hash !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: accessCreditRating.nome,
        link: "#",
        onClick: () => handleNavigationDetailOportunity(),
      });
    }
    if (
      ["DetailOportunityComponent"].includes(hash) &&
      ["ViewQuotesComponent"].includes(hashDetailOportunity) &&
      accessQuote.nomeDaCotacao &&
      hash !== "Oportunity"
    ) {
      newBreadcrumbItems.push({
        name: accessQuote.nomeDaCotacao,
        link: "#",
        onClick: () => handleNavigationDetailOportunity(),
      });
    }

    setBreadcrumbItems(newBreadcrumbItems);
  }, [
    hash,
    accessCreateOp,
    accessCreditRating,
    setPageSidebar,
    hashDetailOportunity,
    accessQuote.nomeDaCotacao,
    setAccessCreditRating,
  ]);

  React.useEffect(() => {
    if (hashSalesSidebar === "Oportunity") {
      setHash("Oportunity");
    }
    if (hashSalesSidebar === "MyOportunityComponent") {
      setHash("MyOportunityComponent");
    }
  }, [hashSalesSidebar]);

  return (
    <Styled.Area open={openSidebar}>
      <Styled.Container>
        <Styled.SubContainer>
          <Styled.ContainerTop>
            <BreadCrumb items={breadcrumbItems} />
          </Styled.ContainerTop>
          {SalesHashMap[hash]}
        </Styled.SubContainer>
      </Styled.Container>
    </Styled.Area>
  );
};

export default Sales;
