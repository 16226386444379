import { theme } from "src/styles/theme";
import styled from "styled-components";
import * as SharedStyles from '../SharedStyles';
export { ComponentHeader, FilledButton, DocumentDataCell, LoadingSpinner } from '../SharedStyles';


export const ContractsTable = styled(SharedStyles.DataTable)`

  .pl-16 {
    padding-left: 16px;
  }

  .pr-16 {
    padding-right: 16px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .table-fit {
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    text-align: right;
  }

  .table-155 {
    width: 155px;
    padding-left: 16px;
    padding-right: 8px;
  }

  .table-140 {
    width: 140px;
    padding-left: 16px;
  }

  .table-120 {
    width: 120px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .contract-summary {
    border-radius: 8px;
    height: 40px;
  }

  .h-40 {
    height: 40px;
  }

  .content-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .content-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .contract-icon {
    width: fit-content;
    height: 24px;
    width: 24px;
  }

  .contract-id {
    font-weight: bold;
    color: ${theme.text};
    font-size: 20px;
    padding-left: 8px;
    text-decoration: underline;
    width: fit-content;
  }

  .theme-primary {
    color: ${theme.primary};
  }

  .theme-error {
    color: ${theme.error};
  }

  .row-text {
    font-weight: bold;
    color: ${theme.text};
    font-size: 14px;
  }

  .row-divider-lr {
    border-width: 0px 1px 0px 1px;
    border-color: ${theme.text};
  }

  .expand-icon {
    width: 24px;
    height: 24px;
    display: none;
  }

`

export const MembersTable = styled(SharedStyles.DataTable)`

  border-color: ${theme.disable100};
  border-radius: 8px;
  border-width: 2px;
  padding: 16px;
  margin-bottom: 16px;


  .row-divider-l {
    border-width: 0px 0px 0px 1px;
    border-color: ${theme.text};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
  }

  .member-row {
    margin-top: 8px;
    border-radius: 5px;
    height: 60px;
    align-items: center;
  }

`