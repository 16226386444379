import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { device } from "../../../styles/variable";

interface ITabs {
  isActive: string;
  page?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > span {
    display: flex;
    font-size: 11px;
    margin-left: 2px;
  }
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;

  @media ${device.laptopXL} {
    height: 35%;
  }
`;

export const ContainerCenter = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;

  > svg {
    display: flex;
    font-size: 25px;
    width: fit-content;
    color: ${theme.disable};
    margin-right: 5px;
    cursor: pointer;
  }
`;

export const TabsCenter = styled.button`
  display: flex;
  color: ${theme.primary};
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  background: none;
  border: none;
  outline: none;
  opacity: ${(props: ITabs) => props.isActive === props.page && 1};
`;

export const Slider = styled.div`
  width: 100%;
  height: 4px;
  margin-top: 3px;
  background: ${theme.primary};
  border-radius: 4px;
  transition: 0.4s;
`;
