import React, { ReactNode } from "react";
import * as Styled from "./styles";

interface ITooltip {
  children: ReactNode;
  text: string;
  disabled?: boolean;
  isRight?: boolean;
}

const Tooltip = ({ children, text, disabled, isRight }: ITooltip) => {
  const [show, setShow] = React.useState(false);
  return (
    <Styled.Container
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {show && (
        <Styled.TooltipArea isRight={isRight}>{text}</Styled.TooltipArea>
      )}
    </Styled.Container>
  );
};

export default Tooltip;
