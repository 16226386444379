import React from "react";
import * as Styled from "../stylesView&Create";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import oportunityService from "@services/Oportunity/OportunityService";
import InputText from "@components/Sales/Inputs/InputText";
import { MdOutlineEditNote } from "react-icons/md";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionNumbersTry, optionStatusTask } from "@helpers/optionSelect";
import { OportunityContext } from "@context/oportunityContex";
import { useMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { IIViewScheduleComponent } from "src/@types/Sales";
import InputDate from "@components/Sales/Inputs/InputDate";
import { format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";

const ViewTasksCallComponent = ({
  onBack,
  idTarefaSalesforce,
  isTaskOrCall,
  isEdit = false,
  setIsEdit,
  setShowDetailTaskCall,
  setSuccessEditTaskCall,
}: IIViewScheduleComponent) => {
  const displayMessage = useMessageToast();
  const displayMessageError = useMessageErrorToast();
  const { faseOp } = React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [data, setData] = React.useState<any>([]);
  const [observations, setObservations] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [numbersTry, setNumbersTry] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [date, setDate] = React.useState("");

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingTaskCall, setLoadingTaskCall] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  const handleCancelButton = () => {
    setEdit(false);
    setIsEdit(false);
    setStatus(data?.status);
    setObservations(data?.comentarios);
    setNumbersTry(data?.numeroDeTentativas);
    setDate(formatDate(data?.dataDeVencimento));
  };

  const handleUpdateTaskCall = async () => {
    setLoading(true);
    setSuccessEditTaskCall(false);
    const formatDatePtBr = (dateString: string) => {
      if (!dateString) return "";

      const inputFormat = "yyyy-MM-dd";

      const date = parse(dateString, inputFormat, new Date(), { locale: ptBR });

      const outputFormat = "dd/MM/yyyy";

      return format(date, outputFormat);
    };

    try {
      const response = await oportunityService.updateTask({
        idTarefaSalesforce,
        assunto: subject,
        comentarios: observations,
        dataVencimento: formatDatePtBr(date),
        numeroDeTentativas: parseInt(numbersTry),
        status,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({
          message: response?.data?.mensagem
            ? response?.data?.mensagem
            : `Erro ao atualizar ${
                isTaskOrCall === "Tarefa" ? "tarefa" : "chamada"
              }!`,
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      setLoading(false);
      setIsEdit(false);
      onBack();
      displayMessage({
        title: "Atividade editada com sucesso!",
        message:
          "Suas alterações foram salvas e você já pode continuar sua venda.",
        titleButton: "ACESSAR ATIVIDADE",
        onClick: () => setShowDetailTaskCall(true),
      });
      setSuccessEditTaskCall(true);
    } catch (error) {
      setLoading(false);
      console.log("errorHandleUpdateTaskCall", error);
    }
  };

  const handleMinDate = (date: string) => {
    if (!date) return "";

    const [a] = date.split(" ");
    return a;
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return "";

    const inputFormat = "dd/MM/yyyy";

    const date = parse(dateString, inputFormat, new Date(), { locale: ptBR });

    const outputFormat = "yyyy-MM-dd";

    return format(date, outputFormat);
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 30);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  React.useEffect(() => {
    if (isEdit) {
      setEdit(true);
    }
  }, [isEdit]);

  React.useEffect(() => {
    const handleGetTaskCallById = async () => {
      setLoadingTaskCall(true);

      try {
        const response = await oportunityService.getTasksById({
          idTarefaSalesforce,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingTaskCall(false);
          setData([]);
          console.log("status 400", response);
          return;
        }
        if (response.status === 401) {
          setLoadingTaskCall(false);
          setIsUnauthorized(true);
          setData([]);
          return;
        }

        setDate(formatDate(response?.dataDeVencimento));
        setNumbersTry(response?.numeroDeTentativas);
        setObservations(response?.comentarios);
        setStatus(response?.status);
        setSubject(response?.assunto);
        setData(response);
        setLoadingTaskCall(false);
      } catch (error) {
        setLoadingTaskCall(false);
        console.log("errorHandleGetTaskCallById", error);
      }
    };

    handleGetTaskCallById();
  }, [idTarefaSalesforce]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {loadingTaskCall ? (
        <Styled.ContainerLoadingView>
          <Spinner />
        </Styled.ContainerLoadingView>
      ) : (
        <>
          <Styled.AreaSchedule>
            <Styled.ContainerSchedule>
              <Styled.TitleSchedule>Detalhes da tarefa</Styled.TitleSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly={isTaskOrCall === "Chamada" ? true : !edit}
                  label="Assunto:"
                  name="Assunto"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />

                {isTaskOrCall === "Chamada" ? (
                  <InputText
                    readOnly
                    label="Data da criação:"
                    name="Data da criação"
                    value={handleMinDate(data?.dataCriacao)}
                  />
                ) : (
                  <InputDate
                    readOnly={isTaskOrCall === "Chamada" ? true : !edit}
                    type="date"
                    min={handleMinDate(data.dataCriacao)}
                    max={getMaxDate()}
                    name={"date"}
                    placeholder="__/__/___"
                    label="Data de vencimento:"
                    value={handleMinDate(date)}
                    onChange={(e) => setDate(e.target.value)}
                  />
                )}

                {isTaskOrCall === "Tarefa" ? (
                  <InputSelect
                    readOnly={!edit}
                    widht="28%"
                    title="Status:"
                    label={status || "Selecione uma opção"}
                    isSelected={status}
                    options={optionStatusTask}
                    selectedOption={status}
                    onChange={(e) => setStatus(e.currentTarget.value)}
                  />
                ) : (
                  <InputSelect
                    readOnly={!edit}
                    widht="28%"
                    title="Número de tentativas:"
                    label={numbersTry || "Selecione uma opção"}
                    isSelected={numbersTry}
                    options={optionNumbersTry}
                    selectedOption={numbersTry}
                    onChange={(e) => setNumbersTry(e.currentTarget.value)}
                  />
                )}
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="Nome da conta:"
                  name="Nome da conta"
                  value={data?.nomeDaConta}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                {isTaskOrCall === "Chamada" && (
                  <InputSelect
                    readOnly={!edit}
                    widht="40%"
                    title="Status:"
                    label={status || "Selecione uma opção"}
                    isSelected={status}
                    options={optionStatusTask}
                    selectedOption={status}
                    onChange={(e) => setStatus(e.currentTarget.value)}
                  />
                )}
                <InputText
                  readOnly
                  tooltip={data?.nomeDaOportunidade?.length >= 39}
                  label="Oportunidade:"
                  name="Oportunidade"
                  value={data?.nomeDaOportunidade}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                <InputTextArea
                  readOnly={!edit}
                  label="Observações:"
                  placeholder=""
                  name="Observações"
                  value={observations}
                  onChange={(e) => setObservations(e.target.value)}
                  maxLenght={32000}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerButtonEdit>
                <Styled.AreaButtonEdit>
                  <Button
                    title={
                      <Styled.TitleButtons>
                        <MdOutlineEditNote />
                        EDITAR
                      </Styled.TitleButtons>
                    }
                    disabled={edit || faseOp}
                    onClick={() => setEdit(true)}
                  />
                </Styled.AreaButtonEdit>
              </Styled.ContainerButtonEdit>
            </Styled.ContainerSchedule>
          </Styled.AreaSchedule>

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooterEdit>
              {/* <Button
                error
                title={loadingDelete ? <Spinner /> : "EXCLUIR"}
                onClick={handleDeleteTaskCall}
                disabled={faseOp || loadingDelete}
              /> */}
              <Button
                error
                title="CANCELAR"
                onClick={handleCancelButton}
                disabled={!edit || faseOp}
              />

              <Button
                title={loading ? <Spinner /> : "SALVAR"}
                onClick={handleUpdateTaskCall}
                disabled={loading || !edit || faseOp}
              />
            </Styled.ContainerButtonFooterEdit>
          </Styled.ContainerFooterBox>
        </>
      )}
    </>
  );
};

export default ViewTasksCallComponent;
