import axios from "axios";
import api from "../api";

interface IRequestData {
  [key: string]: any;
}

const handleApiCall = async (url: string, requestData: IRequestData) => {
  try {
    const response = await api.post(url, requestData);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorRes = error.response;
      return errorRes;
    }
    return false;
  }
};

class FirstAccessService {
  async postFirstAccessDocument({ cpf, senha }: IRequestData) {
    const requestData = { cpf, senha };
    return handleApiCall("/autenticacao/primeiro-acesso", requestData);
  }

  async sendCodeConfirmation({ email, codigoVerificacao }: IRequestData) {
    const requestData = { email, codigoVerificacao };
    return handleApiCall("/autenticacao/confirmar-codigo-primeiro-acesso", requestData);
  }

  async resendCode({ email }: IRequestData) {
    const requestData = { email };
    return handleApiCall("/autenticacao/reenviar-codigo-confirmacao", requestData);
  }

  async createNewPassword({ email, SenhaAntiga, SenhaAtual }: IRequestData) {
    const requestData = { email, SenhaAntiga, SenhaAtual };
    return handleApiCall("/autenticacao/alterar-senha", requestData);
  }
}

const firstAccessServiceInstance = new FirstAccessService();

export default firstAccessServiceInstance;
