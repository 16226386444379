import React from "react";

function useKey(key: string, cb: any) {
  const callbackRef: any = React.useRef(cb);

  React.useEffect(() => {
    callbackRef.current = cb;
  });

  React.useEffect(() => {
    function handle(e: any) {
      if (e.code === key) {
        callbackRef.current(e);
      }
    }

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [key]);
}

export default useKey;
