import React from "react";
import useKey from "@hook/useKey";
import { LoginContext } from "@context/loginContext";
import Button from "@components/common/Button";
import InputEmail from "@components/Login/Inputs/InputEmail";
import InputPassword from "@components/Login/Inputs/InputPassword";
import { isEmail } from "@helpers/emailCheck";
import Spinner from "@components/common/Spinner";
import Box from "@components/Login/Box";
import Helper from "@components/Login/Helper";
import * as Styled from "./styles";

const CurrentLogin = () => {
  useKey("Enter", handleEnter);
  useKey("NumpadEnter", handleEnter);
  const {
    setPage,
    signIn,
    loadingLogin,
    errorLogin,
    setErrorLogin,
    isRemakeLogin,
    setHashLogin,
  } = React.useContext(LoginContext);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  function handleEnter() {
    handleLogin();
  }

  const handleLogin = async () => {
    const data = { email, password };

    await signIn(data);
  };

  function titleButton() {
    if (errorLogin) return "ACESSO INVÁLIDO";
    if (loadingLogin) return <Spinner />;
    return "ENTRAR";
  }

  return (
    <Styled.ContainerCurrentLogin>
      <Styled.BodyCurrentLogin>
        <Styled.ContainerInputLogin>
          <InputEmail
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorLogin(false);
            }}
            iconClick={() => {
              setEmail("");
              setErrorLogin(false);
            }}
            message={"Campo obrigatório"}
            error={errorLogin || isRemakeLogin}
          />
        </Styled.ContainerInputLogin>

        <Styled.ContainerInputPassword>
          <InputPassword
            placeholder="Senha"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorLogin(false);
            }}
            message={"Campo obrigatório"}
            error={errorLogin || isRemakeLogin}
          />
        </Styled.ContainerInputPassword>

        <Styled.ContainerButtonLogin>
          <Button
            title={titleButton()}
            onClick={handleLogin}
            disabled={!isEmail(email) || loadingLogin || !password}
            error={errorLogin || isRemakeLogin}
          />
        </Styled.ContainerButtonLogin>

        <Styled.ContainerForgotPassword
          onClick={() => {
            setPage("Esqueci minha senha");
            setErrorLogin(false);
          }}
        >
          <span>Esqueceu sua senha?</span>
        </Styled.ContainerForgotPassword>

        <Styled.ContainerBox>
          {(errorLogin || isRemakeLogin) && (
            <Box
              width="92%"
              title={
                isRemakeLogin
                  ? "Houve atualização no seu cadastro"
                  : "Usuário e/ou senha inválidos:"
              }
              body={
                isRemakeLogin ? (
                  <Styled.ButtonFirstAccess>
                    Seus dados cadastrais foram atualizados no nosso sistema.
                    Por isso, será necessário que você clique no link{" "}
                    <span onClick={() => setHashLogin("FirstAccess")}>
                      Primeiro acesso.
                    </span>
                  </Styled.ButtonFirstAccess>
                ) : (
                  <Styled.ButtonFirstAccess>
                    Caso tenha esquecido seus dados de acesso, clique no link{" "}
                    <span
                      onClick={() => {
                        setPage("Esqueci minha senha");
                        setErrorLogin(false);
                      }}
                    >
                      Esqueceu sua senha.
                    </span>
                  </Styled.ButtonFirstAccess>
                )
              }
            />
          )}
        </Styled.ContainerBox>
      </Styled.BodyCurrentLogin>

      <Helper />
    </Styled.ContainerCurrentLogin>
  );
};

export default CurrentLogin;
