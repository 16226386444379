import React from "react";
import * as Styled from "./styles";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IInputSearch {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: any;
  maxLength?: number;
  isOportunity?: boolean;
}

const InputSearch = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  maxLength,
  isOportunity,
}: IInputSearch) => {
  return (
    <Styled.Area>
      {label && <Styled.Label>{label}</Styled.Label>}
      <InputGroup>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isInvalid={error}
          focusBorderColor={theme.primary}
          borderWidth={2}
          borderColor={theme.disable100}
          height={isOportunity ? "44px" : "34px"}
          borderRadius={8}
          fontSize={14}
          _placeholder={{ color: theme.disable }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
          bg="white"
          maxLength={maxLength}
        />
        <InputLeftElement>
          <Styled.IconBiSearch />
        </InputLeftElement>
      </InputGroup>
    </Styled.Area>
  );
};
export default InputSearch;
