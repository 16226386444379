import React from "react";
import * as Styled from "./styles";
import { Input, InputGroup } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IInputDate {
  isRequirement?: boolean;
  type?: string;
  label?: string;
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  error?: boolean;
  maxLength?: number;
  max?: string;
  min?: string;
  readOnly?: boolean;
}

const InputDate = ({
  readOnly = false,
  isRequirement,
  type,
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  maxLength,
  max,
  min,
}: IInputDate) => {
  const getLabel = () => (
    <>
      {isRequirement && <Styled.Requirement>*</Styled.Requirement>}
      {label}
    </>
  );

  return (
    <Styled.Area>
      <Styled.ContainerTop>
        {label && <Styled.Label>{getLabel()}</Styled.Label>}
      </Styled.ContainerTop>
      <InputGroup>
        <Input
          readOnly={readOnly}
          isDisabled={readOnly}
          type={type ?? "datetime-local"}
          max={max}
          min={min}
          placeholder={placeholder}
          name={name}
          isInvalid={error}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          focusBorderColor={theme.primary}
          borderWidth={2}
          borderColor={theme.disable}
          height="44px"
          borderRadius={8}
          fontSize={14}
          fontWeight={"bold"}
          bg={"white"}
          _placeholder={{ color: theme.disable }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
        />
      </InputGroup>
    </Styled.Area>
  );
};
export default InputDate;
