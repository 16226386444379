import { theme } from "src/styles/theme";
import styled from "styled-components";
import * as SharedStyles from '../SharedStyles';
export { ComponentHeader, OutlineButton, FilledButton, DocumentDataCell, LoadingSpinner } from '../SharedStyles';

export const RedOutlineButton = styled(SharedStyles.OutlineButton)`
  color: ${props => props.disabled ? theme.disable : theme.error};
  border-color: ${props => props.disabled ? theme.disable : theme.error};
  min-width: 172px;
  > span {
    width: auto;
  }
`

export const RedFilledButton = styled(SharedStyles.OutlineButton)`
  color: white;
  background-color: ${props => props.disabled ? theme.disable : theme.error};
  border-color: ${props => props.disabled ? theme.disable : theme.error};
  min-width: 172px;
`

export const AttachmentsTable = styled(SharedStyles.DataTable)`

  padding: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${theme.disable100};

  .attachment-row {
    margin-top: 8px;
    background-color: #B3B3B319;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
  }

  .table-230 {
    width: 246px;
    align-self: stretch;
  }

  .table-205 {
    width: 205px;
    padding-left: 16px;
  }

  .table-175 {
    width: 175px;
  }

  .table-115 {
    width: 115px;
    padding-left: 16px;
  }

  .bold-label {
    font-weight: bold;
    color: ${theme.text};
    font-size: 14px;
    line-height: 18px;
  }

  .date-cell {
    border-width: 0px 1px 0px 1px;
    border-color: ${theme.text};
    padding-right: 16px;
  }

  .reject-cell {
    border-width: 0px 1px 0px 0px;
    border-color: ${theme.text};
    padding-right: 16px;
    align-self: stretch;
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .left-aligned {
    align-items: flex-end
  }



`

export const DocumentDashboardCell = styled.div<{excludeDisabled?: boolean}>`

  color: ${props => props.excludeDisabled ? theme.disable : theme.text};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 42px;
  padding-right: 16px;

  .exclude-link {
    display: flex;
    gap: 2px;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;

    &:hover {
      user-select: none;
      opacity: ${props => props.excludeDisabled ? '1.0' : '0.8'};
      cursor: ${props => props.excludeDisabled ? 'default' : 'pointer'};
    }
  }

  .exclude-icon {
    width: 20px;
    height: 16px;
  }

  .exclude-text {
    font-size: 14px;
    text-decoration: underline;
    font-weight: normal;
  }
`

export const ToastMessage = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px;
  background-color: white;
  border-radius: 5px;
  width: 372px;
  box-shadow: 0px 20px 30px #1C282C33;

  p {
    font-weight: normal;
    font-size: 16px;
    color: ${theme.text};
  }

`

export const PopupHeader = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  h1 {
    font-weight: bold;
    font-size: 16px;
    color: ${theme.text};
    margin-bottom: 16px;
  }

  .atention {
    color: ${theme.error}
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
    color: ${theme.disable};
    margin-bottom: 4px;
  }  

  h3 {
    font-weight: bold;
    font-size: 16px;
    color: ${theme.text};
    margin-bottom: 4px;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    color: ${theme.text};
  }

  .popup-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const RejectTextArea = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${theme.disable100};
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 28px;
  padding-right: 98px;

  strong {
    color: ${theme.error};
  }

  p {
    font-weight: normal;
    font-size: 12px;
    color: ${theme.text};
  }

  .text-area {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 8px;
    border-width: 2px;
    border-color: ${theme.disable};
    gap: 4px;

    padding: 16px 12px 16px 12px;

    > svg {
      width: 24px;
      height: 24px;
      color: ${theme.primary};
    }
  }

  textarea {
    border-style: none; 
    border-color: transparent; 
    overflow: auto;     
    flex: 1;
    resize: none;
    height: 114px;

    font-size: 14px;
    font-weight: bold;

    &:focus {
      outline: 0;
    }
  }


`

export const ModalDashboard = styled.div`

  display: flex;
  flex-direction: row;
  border-width: 2px 0px 0px 0px;
  border-color: ${theme.disable100};
  margin-top: 16px;
  gap: 16px;
  min-height: 56px;
  align-items: stretch;
  padding-top: 16px;

  .button-162 {
    min-width: 162px;
  }

  .button-204 {
    min-width: 204px;
  }

  .dashboard-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  p {
    font-weight: bold;
    font-size: 14px;
    color: ${theme.text};
    width: auto;
  }

  strong {
    color: ${theme.error};
  }

  .svg-sentence {
    display: flex;
    flex-direction: row;

    svg {
      padding-top: 4px;
    }
  }
`

export const RejectReason = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin-top: 8px;

  gap: 4px;

  p {
    font-weight: normal;
    font-size: 12px;
    color: ${theme.text};
  }

  .reason-container {
    border-radius: 8px;
    border-width: 2px;
    border-color: ${theme.disable};
    min-height: 100px;
    padding: 10px 16px 10px 16px;
    font-weight: bold;
  }
`

export const UploadFileForm = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  border-color: ${theme.disable100};
  border-width: 2px;
  border-radius: 8px;

  padding: 16px 98px 8px 16px;

  h1 {
    font-weight: bold;
    font-size: 24px;
    color: ${theme.text};
    margin-bottom: 12px;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 4px;

    .row-left {
      flex: 1;
    }

    .row-right {
      width: 310px;
      min-width: 310px;
    }

    .dropdown-menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      border-radius: 5px;
      border-width: 1px;
      border-color: #e0e0e0;
      height: 40px;
      padding-left: 16px;
      padding-right: 8px;
      margin-bottom: 12px;

      p {
        font-size: 14px;
        font-weight: bold;
      }

    }

    .dropdown-list {
      height: 350px;
      overflow: auto;
    }

    .selected-option {
      text-align: left;
    }

  }
  
  p {
    font-size: 12px;
    font-weight: normal;
    color: ${theme.text};
  }

  strong {
    color: ${theme.error};
  }

  .form-button {
    min-width: 210px;
    height: 40px;
  }

  .file-sumary {
    border-radius: 5px;
    border-width: 1px;
    border-color: #e0e0e0;
    height: 40px;
    padding: 0px 16px 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 1rem;
      font-weight: normal;
      color: ${theme.text};
    }

    .save-row {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      width: auto;
    }


    .mega-bytes {
      font-size: 14px;
      font-weight: bold;
      color: ${theme.primary};
    }

    .over-limit {
      color: ${theme.error};
    }
  }
  

`