import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

type Table = {
  fase: string;
};

function handleBackgroundFase(value: string) {
  if (value === "cancelada" || value === "cancelado") return theme.error;

  return theme.primary;
}

export const ContainerFase = styled.div<Table>`
  display: flex;
  background: ${({ fase }) => handleBackgroundFase(fase)};
  border-radius: 8px;
  align-self: center;
  justify-content: center;
  min-height: 30px;
  width: 160px;
  padding: 4px;

  @media ${device.laptop} {
    width: 120px;
    min-height: 38px;
    text-align: center;
  }
  @media ${device.laptopXL} {
    width: 200px;
  }

  > span {
    display: flex;
    font-size: 13px;
    font-weight: bold;
    align-self: center;
    justify-content: center;
    color: white;
  }
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerEmpreendimento = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerNameAccount = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 13px;
    width: fit-content;
  }
`;

export const ContainerUnidade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    text-align: center;
    font-size: 13px;
    width: fit-content;
  }
`;
export const ContainerIdOportunidade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    width: 100px;
    min-height: 38px;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  > span {
    color: ${theme.primary};
    text-decoration: underline;
    text-align: center;
    font-size: 13px;
    width: fit-content;
    cursor: pointer;
  }
`;

export const ContainerSearchMessageUnityModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  border: 1px solid ${theme.disable100};
  padding: 5px;
  margin: 5px;

  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;

export const BodySearchUnityModalMessage = styled.span`
  display: flex;
  font-size: 12px;
  color: ${theme.text};
`;
export const TitleSection = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.text};
`;

export const BodySearchUnityModalMessageError = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.error};
`;
export const Icons = styled.div`
  display: flex;
  width: fit-content;
  > svg {
    width: fit-content;
    font-size: 14px;
  }
`;
