import { theme } from "src/styles/theme";
import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;

  > span {
    font-size: 13px;
    color: ${theme.text};
  }
`;
