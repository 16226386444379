/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useFormik } from "formik";
import { UsuallyContext } from "@context/usuallyContex";
import { AiOutlineDownload } from "react-icons/ai";
import Button from "@components/common/Button";
import InputSearch from "@components/Enterprises/Inputs/InputSearch";
import DataTableUnity from "@components/Enterprises/Table/DataTableUnity";
import DataTableAttachment from "@components/Enterprises/Table/DataTableAttachment";
import * as Styled from "../styles";
import { BsArrowRight, BsGrid1X2 } from "react-icons/bs";
import InputLocked from "@components/Enterprises/Inputs/InputLocked";
import Spinner from "@components/common/Spinner";
import enterprisesService from "@services/Enterprises/enterprisesService";
import unityService from "@services/Unity/unityService";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { capitalize } from "@helpers/format";
import ShowAllOptionComponent from "../components/showAllOptionComponent";
import ShowSalesMirrorComponent from "../components/showSalesMirrorComponent";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "@components/common/BreadCrumb";

const DetailEnterpriseComponent: React.FC = () => {
  const enterpriseId: any = localStorage.getItem("@idEnterprises");
  const nameEnterprise: any = localStorage.getItem("@nomeEmpreendimento");
  const codEmpresaUAU: any = localStorage.getItem("@codEmpresaUAU");
  const codEmpreendimentoUAU: any = localStorage.getItem(
    "@codEmpreendimentoUAU"
  );
  const navigate = useNavigate();
  const { openSidebar, setPageSidebar, signOut } =
    React.useContext(LoginContext);
  const regional = localStorage.getItem("@regional");
  const company: any = localStorage.getItem("@empresa");
  const enterprise = `${regional} ${capitalize(company)}`;
  const {
    nameEnterprises,
    nameEnterprisesBreadcrumb,
    setNameEnterprises,
    dataEnterprises,
    setDataEnterprises,
    setIdEnterprises,
    dataUnity,
    setDataUnity,
    dataTableAttachment,
    setDataTableAttachment,
    dataSalesMirror,
    setDataSalesMirror,
  } = React.useContext(UsuallyContext);
  const [searchUnity, setSearchUnity] = React.useState("");
  const [searchUnityModal, setSearchUnityModal] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingUnity, setLoadingUnity] = React.useState(false);
  const [loadingSalesMirror, setLoadingSalesMirror] = React.useState(false);
  const [loadingAttachments, setLoadingAttachments] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [showAllUnity, setShowAllUnity] = React.useState(false);
  const [showAllAttachment, setShowAllAttachment] = React.useState(false);
  const [showSalesMirror, setShowSalesMirror] = React.useState(false);
  const [searchAttachment, setSearchAttachment] = React.useState("");
  const [searchAttachmentModal, setSearchAttachmentModal] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const [selectedAttachments, setSelectedAttachments] = React.useState<
    number[]
  >([]);

  const breadcrumbItems = [
    {
      name: "Principal",
      link: "/",
      onClick: () => {
        setPageSidebar("Principal");
        localStorage.setItem("@option", "Principal");
      },
    },
    {
      name: "Empreendimento",
      link: "/empreendimentos",
      onClick: () => navigate("/empreendimentos"),
    },
    {
      name: !nameEnterprisesBreadcrumb ? enterprise : nameEnterprisesBreadcrumb,
      link: "/empreendimentos",
      onClick: () => navigate("/empreendimentos"),
    },
    {
      name: nameEnterprises || nameEnterprise || "",
      link: "/detalhesEmpreendimentos",
      onClick: () => navigate("/detalhesEmpreendimentos"),
    },
  ];

  let initialValues = {
    nameEnterprises:
      dataEnterprises?.nomeEmpreendimento === null
        ? ""
        : dataEnterprises?.nomeEmpreendimento,
    regional:
      dataEnterprises?.regional === null ? "" : dataEnterprises?.regional,
    region: dataEnterprises?.regiao === null ? "" : dataEnterprises?.regiao,
    spe: dataEnterprises?.spe === null ? "" : dataEnterprises?.spe,
    formFinancing:
      dataEnterprises?.formaFinanciamento === null
        ? ""
        : dataEnterprises?.formaFinanciamento,
    incorporadora:
      dataEnterprises?.incorporadora === null
        ? ""
        : dataEnterprises?.incorporadora,
    registrationConfirmation:
      dataEnterprises?.confirmacaoAverbacaoHabitese === null
        ? ""
        : dataEnterprises?.confirmacaoAverbacaoHabitese,
    awardPolicy:
      dataEnterprises?.politicaPremiacaoVigente === null
        ? ""
        : dataEnterprises?.politicaPremiacaoVigente,

    statusWork:
      dataEnterprises?.statusObra === null ? "" : dataEnterprises?.statusObra,
    unityNumber:
      dataEnterprises?.numeroDeUnidades === null
        ? ""
        : dataEnterprises?.numeroDeUnidades,
    numberBlocks:
      dataEnterprises?.numeroDeBlocos === null
        ? ""
        : dataEnterprises?.numeroDeBlocos,
    numberFloors:
      dataEnterprises?.numeroDeAndares === null
        ? ""
        : dataEnterprises?.numeroDeAndares,
    numberTowers:
      dataEnterprises?.numeroDeTorres === null
        ? ""
        : dataEnterprises?.numeroDeTorres,
    cnpjBrokerage:
      dataEnterprises?.cnpjTomador === null ? "" : dataEnterprises?.cnpjTomador,
    cnpjBorrower:
      dataEnterprises?.cnpjCorretora === null
        ? ""
        : dataEnterprises?.cnpjCorretora,
    borrowerCorporateName:
      dataEnterprises?.razaoSocialTomador === null
        ? ""
        : dataEnterprises?.razaoSocialTomador,

    dateRelease:
      dataEnterprises?.dataDeLancamento === null
        ? ""
        : dataEnterprises?.dataDeLancamento,
    dateDelivery:
      dataEnterprises?.dataTerminoObra === null
        ? ""
        : dataEnterprises?.dataTerminoObra,
    dateIssuance:
      dataEnterprises?.dataExpedicaoHabitese === null
        ? ""
        : dataEnterprises?.dataExpedicaoHabitese,

    logradouro:
      dataEnterprises?.logradouroObra === null
        ? ""
        : dataEnterprises?.logradouroObra,
    number:
      dataEnterprises?.numeroObra === null ? "" : dataEnterprises?.numeroObra,
    neighborhood:
      dataEnterprises?.bairroObra === null ? "" : dataEnterprises?.bairroObra,
    complemento:
      dataEnterprises?.complementoObra === null
        ? ""
        : dataEnterprises?.complementoObra,
    city:
      dataEnterprises?.cidadeObra === null ? "" : dataEnterprises?.cidadeObra,
    cep: dataEnterprises?.cepObra === null ? "" : dataEnterprises?.cepObra,
    state:
      dataEnterprises?.estadoObra === null ? "" : dataEnterprises?.estadoObra,
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const filteredUnity = () => {
    if (!dataUnity) return [];
    return dataUnity;
  };

  const searchResults = filteredUnity()?.filter((row: any) =>
    row?.produtoDirecional
      ?.toLowerCase()
      ?.includes(searchUnityModal?.toLowerCase())
  );
  const searchResultsAttachment = dataTableAttachment?.filter((row: any) =>
    row?.produtoDirecional
      ?.toLowerCase()
      ?.includes(searchUnityModal?.toLowerCase())
  );

  React.useEffect(() => {
    const handleGetEnterprisesbyName = async () => {
      try {
        setLoading(true);
        const response = await enterprisesService.getEnterprisesByName({
          nomeEmpreendimento: nameEnterprise,
        });

        if (!response) {
          setLoading(false);
          setErrorEmpty(true);
          return;
        }
        if (response?.status === 400) {
          console.log("Bad Request:", response);
        }
        if (response?.status === 401) {
          console.log("Unauthorized");
          setError(true);
        }

        setNameEnterprises(nameEnterprises);
        setDataEnterprises(response);
        localStorage.setItem("@idEnterprises", response?.idEmpreendimentoSales);
      } catch (error: any) {
        console.log("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    handleGetEnterprisesbyName();
  }, [
    nameEnterprise,
    nameEnterprises,
    setDataEnterprises,
    setIdEnterprises,
    setNameEnterprises,
  ]);

  React.useEffect(() => {
    const handleGetUnity = async () => {
      setLoadingUnity(true);
      try {
        const response = await unityService.getUnity({
          idEmpreendimentoSales: enterpriseId,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingUnity(false);
          console.log("status 400", response);
          return;
        }

        if (response?.status === 401) {
          setLoadingUnity(false);
          console.log("status 401", response);
          setError(true);
          return;
        }

        setLoadingUnity(false);
        setDataUnity(response);
      } catch (error: any) {
        setLoadingUnity(false);
        console.log("errorHandleGetUnitybyId", error);
      }
    };
    handleGetUnity();
  }, [enterpriseId, setDataUnity]);

  React.useEffect(() => {
    const handleListSalesMirror = async () => {
      setLoadingSalesMirror(true);
      try {
        const response = await unityService.listSalesMirror({
          IdEmpreendimentoSales: enterpriseId,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingSalesMirror(false);
          console.log("status 400", response);
          return;
        }

        if (response?.status === 401) {
          setLoadingSalesMirror(false);
          console.log("status 401", response);
          setError(true);
          return;
        }

        setLoadingSalesMirror(false);
        setDataSalesMirror(response.blocos);
      } catch (error: any) {
        setLoadingSalesMirror(false);
        console.log("errorListSalesMirror", error);
      }
    };
    handleListSalesMirror();
  }, [enterpriseId, setDataSalesMirror]);

  React.useEffect(() => {
    setDataEnterprises({});
  }, [setDataEnterprises]);

  React.useEffect(() => {
    const handleGetAttachments = async () => {
      try {
        setLoadingAttachments(true);
        const response = await enterprisesService.listAttachment({
          codEmpreendimentoUAU,
          codEmpresaUAU,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingAttachments(false);
          console.log("attachment status 400", response);
          return;
        }

        const processed = response.map((r: any) => {
          const uploadDateObject = r.metadados.find(
            (m: any) => m.key === "dataupload"
          );
          let data = "";
          if (uploadDateObject) {
            const unformated = uploadDateObject.value.split(" ")[0].split("-");
            data = `${unformated[2]}/${unformated[1]}/${unformated[0]}`;
          }

          return {
            id: Number(r.idAws.replace(/\D/g, "").substr(0, 10)),
            name: r.nomeArquivo,
            data: data,
            idAws: r.idAws,
          };
        });

        setDataTableAttachment(processed);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingAttachments(false);
      }
    };
    handleGetAttachments();
  }, [setLoadingAttachments, setDataTableAttachment, enterpriseId]);

  const handleDownloadSingleAttachment = async (data: {
    idAws: string;
    name: string;
  }) => {
    setLoadingAttachments(true);
    console.log("downloading", data);

    const downloadUrl = await enterprisesService.getEnterpriseByUrl(data);
    console.log("download URL is", downloadUrl);

    await fetch(downloadUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const urlObject = window.URL || window.webkitURL;
        const objectUrl = urlObject.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = objectUrl;
        a.download = data.name;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        urlObject.revokeObjectURL(objectUrl);
        setLoadingAttachments(false);
      })
      .catch((error) => {
        console.error(
          "Erro ao baixar arquivo: redirecionando para outra página",
          error
        );
        window.open(downloadUrl, "_blank");
        setLoadingAttachments(false);
      });
  };

  const handleDownloadSelectedAttachments = async () => {
    const selectedRows = dataTableAttachment.filter((d) =>
      selectedAttachments.includes(d.id)
    );

    for (let row of selectedRows) {
      handleDownloadSingleAttachment(row);
    }
  };

  return (
    <>
      <Alert
        onOpen={error || errorEmpty}
        title="Erro ao carregar os dados!"
        titleButton={errorEmpty ? "Voltar" : "Logar novamente"}
        description={
          errorEmpty
            ? "Não há empreendimentos para os filtros selecionados."
            : "Sua sessão expirou, faça login novamante para ter acesso."
        }
        onClick={() => {
          if (errorEmpty) {
            setErrorEmpty(false);
          } else {
            signOut();
            localStorage.setItem("@option", "Principal");
          }
        }}
      />
      <Styled.Area open={openSidebar}>
        <Styled.Container hash={""}>
          <Styled.SubContainer>
            <Styled.ContainerTop>
              <BreadCrumb items={breadcrumbItems} />
            </Styled.ContainerTop>
            <Styled.ContainerTitlePage>
              <Styled.TitlePage>
                {capitalize(nameEnterprise || nameEnterprises)}
              </Styled.TitlePage>

              <Styled.ContainerTitleButtonEnterprises>
                <Button
                  title={
                    <>
                      <Styled.AreaTitleButtonEnterprises>
                        <BsGrid1X2 />
                      </Styled.AreaTitleButtonEnterprises>
                      TODAS AS UNIDADES
                    </>
                  }
                  onClick={() => {
                    setShowSalesMirror(false);
                    setShowAllUnity(true);
                  }}
                  disabled={showAllUnity}
                />
                <Button
                  title={
                    <>
                      <Styled.AreaTitleButtonEnterprises>
                        <BsGrid1X2 />
                      </Styled.AreaTitleButtonEnterprises>
                      ESPELHO DE VENDAS
                    </>
                  }
                  onClick={() => {
                    setShowAllUnity(false);
                    setShowSalesMirror(true);
                  }}
                  disabled={showSalesMirror}
                />
              </Styled.ContainerTitleButtonEnterprises>
            </Styled.ContainerTitlePage>

            <Styled.Form>
              {showAllUnity && (
                <Styled.ContainerSectionModal>
                  <ShowAllOptionComponent
                    title="Unidades do empreendimento"
                    setShow={setShowAllUnity}
                    search={searchUnityModal}
                    setSearch={setSearchUnityModal}
                    searchResults={searchResults}
                  />
                  <Styled.TableDetailModal>
                    {loadingUnity ? (
                      <Styled.ContainerSpinner>
                        <Spinner />
                      </Styled.ContainerSpinner>
                    ) : (
                      <DataTableUnity
                        data={dataUnity}
                        onClick={() => navigate("/unidade")}
                        nameFilter={searchUnityModal}
                        showAllItems
                      />
                    )}
                  </Styled.TableDetailModal>
                </Styled.ContainerSectionModal>
              )}
              {showSalesMirror && (
                <Styled.ContainerSectionSalesMirrorModal>
                  <Styled.SalesMirrorItems>
                    {loadingSalesMirror ? (
                      <Styled.ContainerSpinner>
                        <Spinner />
                      </Styled.ContainerSpinner>
                    ) : (
                      <ShowSalesMirrorComponent
                        dataSalesMirror={dataSalesMirror}
                        setShow={setShowSalesMirror}
                        showError={showError}
                        setShowError={setShowError}
                      />
                    )}
                  </Styled.SalesMirrorItems>
                </Styled.ContainerSectionSalesMirrorModal>
              )}
              {showAllAttachment && (
                <Styled.ContainerSectionModal>
                  <ShowAllOptionComponent
                    title="Anexos do empreendimento"
                    setShow={setShowAllAttachment}
                    search={searchAttachmentModal}
                    setSearch={setSearchAttachmentModal}
                    searchResults={searchResultsAttachment}
                  />

                  <Styled.TableDetailModal>
                    {loadingUnity || loadingAttachments ? (
                      <Styled.ContainerSpinner>
                        <Spinner />
                      </Styled.ContainerSpinner>
                    ) : (
                      <>
                        <DataTableAttachment
                          data={dataTableAttachment}
                          nameFilter={searchAttachmentModal}
                          showAllItems
                          selectedItems={selectedAttachments}
                          setSelectedItems={setSelectedAttachments}
                          downloadFile={handleDownloadSingleAttachment}
                        />
                      </>
                    )}
                  </Styled.TableDetailModal>
                  <Styled.ContainerFooterTableAttachment>
                    <Styled.ContainerTitleButtonEnterprises>
                      <Button
                        title={
                          <Styled.SubContainerFooterButton>
                            <AiOutlineDownload />
                            BAIXAR SELECIONADO(S)
                          </Styled.SubContainerFooterButton>
                        }
                        onClick={handleDownloadSelectedAttachments}
                        disabled={loadingAttachments}
                      />
                    </Styled.ContainerTitleButtonEnterprises>
                  </Styled.ContainerFooterTableAttachment>
                </Styled.ContainerSectionModal>
              )}
              {!showAllUnity && !showAllAttachment && !showSalesMirror && (
                <>
                  <Styled.ContainerSection>
                    <Styled.ContainerTitleSection>
                      <Styled.TitleSection>
                        Informações de empreendimento
                      </Styled.TitleSection>
                    </Styled.ContainerTitleSection>
                    {loading ? (
                      <Styled.ContainerSpinner>
                        <Spinner />
                      </Styled.ContainerSpinner>
                    ) : (
                      <Styled.ContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Nome do empreendimento:"
                            name="nameEnterprises"
                            value={formik?.values?.nameEnterprises}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Regional:"
                            name="regional"
                            value={formik?.values?.regional}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Região:"
                            name="region"
                            value={formik?.values?.region}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="SPE:"
                            name="spe"
                            value={formik?.values?.spe}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Forma de financiamento:"
                            name="formFinancing"
                            value={formik?.values?.formFinancing}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Incorporadora:"
                            name="incorporadora"
                            value={formik?.values?.incorporadora}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Confirmação de averbação do habite-se:"
                            name="registrationConfirmation"
                            value={
                              formik?.values?.registrationConfirmation === false
                                ? "Não"
                                : "Sim"
                            }
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Política de premiação vigente:"
                            name="awardPolicy"
                            value={formik?.values?.awardPolicy}
                          />
                        </Styled.SubContainerInput>
                      </Styled.ContainerInput>
                    )}
                  </Styled.ContainerSection>

                  <Styled.ContainerSection>
                    <Styled.ContainerTitleSection>
                      <Styled.TitleSection>
                        Informações de obra
                      </Styled.TitleSection>
                    </Styled.ContainerTitleSection>
                    {loading ? (
                      <Styled.ContainerSpinner>
                        <Spinner />
                      </Styled.ContainerSpinner>
                    ) : (
                      <Styled.ContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Status da obra:"
                            name="statusWork"
                            value={formik?.values?.statusWork}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Número de unidades:"
                            name="unityNumber"
                            value={formik?.values?.unityNumber}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Número de blocos:"
                            name="numberBlocks"
                            value={formik?.values?.numberBlocks}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Número de andares:"
                            name="numberFloors"
                            value={formik?.values?.numberFloors}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Número de torres:"
                            name="numberTowers"
                            value={formik?.values?.numberTowers}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="CNPJ corretora:"
                            name="cnpjBrokerage"
                            value={formik?.values?.cnpjBrokerage}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="CNPJ tomador:"
                            name="cnpjBorrower"
                            value={formik?.values?.cnpjBorrower}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Razão social tomador:"
                            name="borrowerCorporateName"
                            value={formik?.values?.borrowerCorporateName}
                          />
                        </Styled.SubContainerInput>
                      </Styled.ContainerInput>
                    )}
                  </Styled.ContainerSection>

                  <Styled.ContainerSection>
                    <Styled.ContainerTitleSection>
                      <Styled.TitleSection>Datas</Styled.TitleSection>
                    </Styled.ContainerTitleSection>
                    {loading ? (
                      <Styled.ContainerSpinner>
                        <Spinner />
                      </Styled.ContainerSpinner>
                    ) : (
                      <Styled.ContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Data de lançamento:"
                            name="dateRelease"
                            value={formik?.values?.dateRelease}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Data de entrega da obra:"
                            name="dateDelivery"
                            value={formik?.values?.dateDelivery}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Data de expedição do habite-se:"
                            name="dateIssuance"
                            value={formik?.values?.dateIssuance}
                          />
                        </Styled.SubContainerInput>
                      </Styled.ContainerInput>
                    )}
                  </Styled.ContainerSection>

                  <Styled.ContainerSection>
                    <Styled.ContainerTitleSection>
                      <Styled.TitleSection>
                        Endereço da obra
                      </Styled.TitleSection>
                    </Styled.ContainerTitleSection>
                    {loading ? (
                      <Styled.ContainerSpinner>
                        <Spinner />
                      </Styled.ContainerSpinner>
                    ) : (
                      <Styled.ContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Logradouro da obra:"
                            name="logradouro"
                            value={formik?.values?.logradouro}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Número:"
                            name="number"
                            value={formik?.values?.number}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Bairro:"
                            name="neighborhood"
                            value={formik?.values?.neighborhood}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Complemento:"
                            name="complemento"
                            value={formik?.values?.complemento}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Cidade:"
                            name="city"
                            value={formik?.values?.city}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="CEP:"
                            name="cep"
                            value={formik?.values?.cep}
                          />
                        </Styled.SubContainerInput>
                        <Styled.SubContainerInput>
                          <InputLocked
                            label="Estado:"
                            name="state"
                            value={formik?.values?.state}
                          />
                        </Styled.SubContainerInput>
                      </Styled.ContainerInput>
                    )}
                  </Styled.ContainerSection>

                  <Styled.ContainerTableDuo>
                    <Styled.ContainerTableDetail>
                      <Styled.ContainerTitleTable>
                        <Styled.TitleSection>
                          Unidades do empreendimento
                        </Styled.TitleSection>

                        <Styled.ContainerSearchUnity>
                          <Styled.TitleDisplay>
                            Pesquisa Relacionada:
                          </Styled.TitleDisplay>
                          <Styled.ContainerSearchDetailEnterprise>
                            <InputSearch
                              placeholder="Pesquisar por unidade do empreendimento"
                              value={searchUnity}
                              onChange={(e) => {
                                setSearchUnity(e.target.value);
                              }}
                            />
                          </Styled.ContainerSearchDetailEnterprise>
                        </Styled.ContainerSearchUnity>
                      </Styled.ContainerTitleTable>

                      {loadingUnity ? (
                        <Styled.ContainerSpinner>
                          <Spinner />
                        </Styled.ContainerSpinner>
                      ) : (
                        <>
                          <Styled.TableDetail>
                            <DataTableUnity
                              data={dataUnity}
                              onClick={() => navigate("/unidade")}
                              nameFilter={searchUnity}
                            />
                          </Styled.TableDetail>
                          <Styled.ContainerFooterTableDetail>
                            <Styled.ContainerTabPagination>
                              <a onClick={() => setShowAllUnity(true)}>
                                VER TODO O CONTEÚDO
                              </a>
                              <BsArrowRight />
                            </Styled.ContainerTabPagination>
                          </Styled.ContainerFooterTableDetail>
                        </>
                      )}
                    </Styled.ContainerTableDetail>

                    <Styled.ContainerTableAttachment>
                      <Styled.ContainerTitleTable>
                        <Styled.TitleSection>
                          Anexos do empreendimento
                        </Styled.TitleSection>

                        <Styled.ContainerSearchUnity>
                          <Styled.TitleDisplay>
                            Pesquisa Relacionada:
                          </Styled.TitleDisplay>
                          <Styled.ContainerSearchDetailEnterprise>
                            <InputSearch
                              placeholder="Pesquisar por anexo do empreendimento"
                              value={searchAttachment}
                              onChange={(e) => {
                                setSearchAttachment(e.target.value);
                              }}
                            />
                          </Styled.ContainerSearchDetailEnterprise>
                        </Styled.ContainerSearchUnity>
                      </Styled.ContainerTitleTable>

                      {loadingAttachments ? (
                        <Styled.ContainerSpinner>
                          <Spinner />
                        </Styled.ContainerSpinner>
                      ) : (
                        <>
                          <Styled.TableAttachment>
                            <DataTableAttachment
                              selectedItems={selectedAttachments}
                              setSelectedItems={setSelectedAttachments}
                              data={dataTableAttachment}
                              nameFilter={searchAttachment}
                              downloadFile={handleDownloadSingleAttachment}
                            />
                          </Styled.TableAttachment>

                          <Styled.ContainerFooterTableAttachment>
                            <Styled.ContainerTitleButtonEnterprises>
                              <Button
                                title={
                                  <Styled.SubContainerFooterButton>
                                    <AiOutlineDownload />
                                    BAIXAR SELECIONADO(S)
                                  </Styled.SubContainerFooterButton>
                                }
                                onClick={handleDownloadSelectedAttachments}
                              />
                            </Styled.ContainerTitleButtonEnterprises>
                            <Styled.ContainerTabPagination>
                              <Styled.ContainerTabPagination>
                                <a onClick={() => setShowAllAttachment(true)}>
                                  VER TODO O CONTEÚDO
                                </a>
                                <BsArrowRight />
                              </Styled.ContainerTabPagination>
                            </Styled.ContainerTabPagination>
                          </Styled.ContainerFooterTableAttachment>
                        </>
                      )}
                    </Styled.ContainerTableAttachment>
                  </Styled.ContainerTableDuo>
                </>
              )}
            </Styled.Form>
          </Styled.SubContainer>
        </Styled.Container>
      </Styled.Area>
    </>
  );
};

export default DetailEnterpriseComponent;
