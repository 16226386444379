import { CheckboxGroup, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ISelectCheckbox {
  children: ReactNode;
  value: (string | number)[];
}

const SelectCheckbox = ({ children, value }: ISelectCheckbox) => {
  return (
    <CheckboxGroup colorScheme="radioButtonScheme" value={value}>
      <Stack
        spacing={0}
        direction={["row", "column"]}
        w="fit-content"
        justifyContent="center"
        alignSelf="center"
        h="fit-content"
        marginTop={4}
      >
        {children}
      </Stack>
    </CheckboxGroup>
  );
};

export default SelectCheckbox;
