import axios from "axios";

export const brasilApi = axios.create({
  baseURL: "https://brasilapi.com.br/api/cep/v2",
  timeout: 60000,
});

class CepService {
  async getCep({ cep }: any) {
    try {
      const response = await brasilApi.get(`/${cep}`);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
}

const cepServiceInstance = new CepService();
export default cepServiceInstance;
