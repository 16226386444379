import { theme } from "src/styles/theme";
import styled from "styled-components";

interface INotify {
  isSuccess: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: ${(props: INotify) => (props.isSuccess ? "130px" : "180px")};
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

export const ContainerUpdatePassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 200px;
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 12%;

  > span {
    font-size: 13px;
    color: ${theme.text};
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const ContainerTitleError = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.error};
  }
`;

export const ContainerTitleSuccess = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 55%;
  margin-top: 8px;
  margin-bottom: 12px;
  justify-content: center;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const Obs = styled.div`
  display: flex;
  width: 100%;
  height: 35%;

  > span {
    font-size: 15px;
    color: ${theme.text};
  }
`;

export const ObsUpdatePassword = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  > span {
    font-size: 14px;
    color: ${theme.text};

    > span {
      font-size: 14px;
      color: ${theme.primary};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  gap: 10px;
`;

export const ContainerButtonUpdatePassword = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  gap: 10px;
  margin-top: 10px;
`;

export const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
