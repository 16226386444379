import { theme } from "src/styles/theme";
import styled from "styled-components";

interface ISlider {
  disabled?: boolean;
}

const height = "30px";
const thumbHeight = 14;
const trackHeight = "5px";

const upperColor = `${theme.disable}`;
const lowerColor = `${theme.primary}`;
const thumbColor = `${theme.primary}`;
const thumbHoverColor = `${theme.primary}`;
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

const makeLongShadow = (color: string, size: string) => {
  let i = 0;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 706; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }

  return shadow;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
`;

export const InputSlider = styled.input`
  overflow: hidden;
  display: flex;
  appearance: none;
  width: 100%;
  height: ${height};

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${lowerBackground};
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbHeight}px;
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: ${makeLongShadow(upperColor, "-4px")};
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${thumbHoverColor};
    }
  }
`;

export const ContainerBack = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
`;

export const ContainerLeft = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
`;

export const ButtonBack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  > span {
    display: flex;
    width: 26%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${(props: ISlider) =>
      props.disabled ? `${theme.disable}` : `${theme.primary}`};
    cursor: ${(props: ISlider) => (props.disabled ? "default" : "pointer")};
  }

  > svg {
    width: fit-content;
    font-size: 17px;
    color: ${(props: ISlider) =>
      props.disabled ? `${theme.disable}` : `${theme.primary}`};
    cursor: ${(props: ISlider) => (props.disabled ? "default" : "pointer")};
  }
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  width: 1%;
  height: 50%;
  border-radius: 8px;
  background: ${theme.disable};
  align-self: center;
  justify-content: center;
`;

export const ContainerRight = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
`;

export const ContainerCountSteps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${theme.disable};
  }
`;
