import React, {
  ChangeEventHandler,
  createRef,
  useEffect,
  useState,
} from "react";
import * as Styled from "./AttachmentsStyles";
import {
  MdAdd,
  MdTab,
  MdTabUnselected,
  MdDeleteOutline,
  MdOutlineChat,
  MdEditNote,
  MdInfoOutline,
  MdUnfoldMore,
  MdOutlineSave,
} from "react-icons/md";
import {
  CloseButton,
  ModalOverlay,
  Modal,
  ModalContent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  InputGroup,
  InputRightElement,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { OportunityContext } from "@context/oportunityContex";
import oportunityServiceInstance, {
  IOnboardingDocument,
} from "@services/Oportunity/OportunityService";
import useWindowDimensions from "../../../../hook/useWindowDimensions";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { useOnlyMessageToast } from "@hook/useMessageToast";

interface IAttachmentsComponent {
  idRelacionamentoComprador: string;
  codigoRelacionamentoComComprador: string;
  cpf: null;
  disabledCreateDocument: boolean;
}

const AttachmentsComponent = ({
  idRelacionamentoComprador,
  codigoRelacionamentoComComprador: CodigoRelacionamentoComComprador,
  cpf,
  disabledCreateDocument,
}: IAttachmentsComponent) => {
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const {
    onboardingDocuments,
    setOnboardingDocuments,
    accessCreateOp,
    // loading: contextLoading,
    faseOp,
    accessAttachments,
  } = React.useContext(OportunityContext);
  const opportunityId = accessCreateOp.oportunidadeId;
  const [focussed, setFocus] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [rejectDocument, setRejectDocument] = useState<
    IOnboardingDocument | undefined
  >(undefined);
  const [deleteDocument, setDeleteDocument] = useState<
    IOnboardingDocument | undefined
  >(undefined);
  const [uploadDocument, setUploadDocument] = useState(false);
  const { width } = useWindowDimensions();
  const loadingRef = React.useRef(false);

  let attachmentsTitle = "Anexos do relacionamento";
  if (onboardingDocuments.length > 0) {
    attachmentsTitle += ` (${onboardingDocuments.length})`;
  }

  React.useEffect(() => {
    const handleGetDocuments = async () => {
      if (loadingRef.current) {
        return;
      }

      setLoading(true);
      loadingRef.current = true;

      try {
        const response = await oportunityServiceInstance.getOnboardingDocuments(
          {
            CodigoRelacionamentoComComprador:
              accessAttachments.codigoRelacionamentoComComprador,
            CPF: accessAttachments.cpf,
          }
        );

        setOnboardingDocuments(response);
      } catch (error) {
        displayMessageError({
          message:
            "Por favor, tente novamente mais tarde ou entre em contato com nossa assistência técnica.",
        });

        console.log("errorHandleGetDocuments", error);
      } finally {
        setLoading(false);
        loadingRef.current = false;
      }
    };

    handleGetDocuments();
  }, [
    accessAttachments.codigoRelacionamentoComComprador,
    accessAttachments.cpf,
    displayMessageError,
    idRelacionamentoComprador,
    setOnboardingDocuments,
  ]);

  if (loading) {
    return (
      <Styled.LoadingSpinner>
        <Spinner />
      </Styled.LoadingSpinner>
    );
  }

  const handleSendLink = async () => {
    setLoading(true);
    const res = await oportunityServiceInstance.getOportunityById({
      OportunidadeId: opportunityId,
    });
    if (res.nomeDaConta === undefined) {
      setLoading(false);
      displayMessageError({
        message:
          "Por favor, tente novamente mais tarde ou entre em contato com nossa assistência técnica.",
      });
      return;
    }

    await oportunityServiceInstance.sendOnboardingLink({
      email: res.emailPrincipal,
      telefone: "+55" + res.telefonePrincipal.replace(/\D/g, ""),
      nome: res.nomeDaConta,
      OportunidadeId: opportunityId,
    });

    setLoading(false);
    displayMessage("E-mail enviado com sucesso!");
  };

  return (
    <>
      <Styled.ComponentHeader>
        <h1>
          {attachmentsTitle}{" "}
          {(cpf === null || cpf === "") && <span>(Informe o CPF/CNPJ para ativar o botão de "Novo Documento")</span>}
        </h1>
        {/* <Styled.OutlineButton
          onClick={() => {
            if (faseOp === false) {
              handleSendLink();
            }
          }}
          disabled={faseOp}
        >
          <MdDriveFolderUpload />
          <span className="solicitar-documento">SOLIC. DOCS</span>
        </Styled.OutlineButton> */}
        <Styled.FilledButton
          onClick={() => {
            if (cpf !== null || cpf !== "") {
              setUploadDocument(true);
            }
          }}
          disabled={cpf === null || cpf === ""}
        >
          <MdAdd />
          <span className="novo-documento">NOVO DOCUMENTO</span>
        </Styled.FilledButton>
      </Styled.ComponentHeader>
      <Styled.AttachmentsTable>
        <thead>
          <tr>
            <th className="table-full">Nome do documento:</th>
            <th className="table-175">Tipo de documento:</th>
            <th className="table-115">Data de upload:</th>
            <th className="table-205">Rejeitar / Motivo:</th>
            <th className="table-230" />
          </tr>
        </thead>
        <tbody>
          {onboardingDocuments.map((d, i) => (
            <AttachmentRow
              key={d.idAws}
              index={i}
              focussed={i === focussed}
              onFocus={(index) => setFocus(index)}
              document={d}
              onReject={(document) => setRejectDocument(document)}
              onDelete={(document) => setDeleteDocument(document)}
              availableWidth={width - 321}
            />
          ))}
        </tbody>
      </Styled.AttachmentsTable>
      <RejectDocumentModal
        document={rejectDocument}
        onClose={() => setRejectDocument(undefined)}
        onReject={async (document, reason) => {
          setLoading(true);
          const success = await oportunityServiceInstance.updateDocumentStatus({
            NomeDocumento: document.nomeArquivo,
            Status: "Reprovado",
            Justificativa: reason,
          });
          setLoading(false);
          if (success) {
            const index = onboardingDocuments.findIndex(
              (d) => d.idAws === document.idAws
            );
            onboardingDocuments[index].statusDocumento = "Reprovado";
            onboardingDocuments[index].justificativa = reason;
            setOnboardingDocuments([...onboardingDocuments]);
            displayMessage("Documento rejeitado com sucesso!");
          } else {
            displayMessageError({
              message:
                "Por favor, tente novamente mais tarde ou entre em contato com nossa assistência técnica.",
            });
          }
        }}
      />
      <SaveFileModal
        isOpen={uploadDocument}
        onClose={() => setUploadDocument(false)}
        onSendDocument={async (o) => {
          if (o.name) {
            const type = o?.file?.name?.split(".")[1] ?? "pdf";
            const name = o.name + "." + type;
            const newFile = new File([o.file], name, { type: o.file.type });
            o.file = newFile;
          }

          setLoading(true);
          const formData = new FormData();

          formData.append("documentoAnexado", o.file);
          formData.append("OportunidadeId", opportunityId);
          formData.append(
            "CodigoRelacionamentoComComprador",
            accessAttachments.codigoRelacionamentoComComprador
          );
          formData.append("CPF", accessAttachments.cpf);
          formData.append("tipoDocumento", o.type);

          const success = await oportunityServiceInstance.uploadDocument(
            formData
          );
          if (success) {
            const updatedDocuments =
              await oportunityServiceInstance.getOnboardingDocuments({
                CodigoRelacionamentoComComprador:
                  accessAttachments.codigoRelacionamentoComComprador,
                CPF: accessAttachments.cpf,
              });
            setOnboardingDocuments(updatedDocuments);

            displayMessage("Documento anexado com sucesso!");
          } else {
            displayMessageError({
              message:
                "Por favor, tente novamente mais tarde ou entre em contato com nossa assistência técnica.",
            });
          }
          setLoading(false);
        }}
      />
      <DeleteDocumentModal
        document={deleteDocument}
        onClose={() => setDeleteDocument(undefined)}
        onDelete={async (document) => {
          setLoading(true);
          const success = await oportunityServiceInstance.deleteDocument(
            document.idAws
          );
          if (success) {
            const remainingDocuments =
              await oportunityServiceInstance.getOnboardingDocuments({
                CodigoRelacionamentoComComprador:
                  accessAttachments.codigoRelacionamentoComComprador,
                CPF: accessAttachments.cpf,
              });
            setOnboardingDocuments(remainingDocuments);
            displayMessage("Documento deletado com sucesso!");
          } else {
            displayMessageError({
              message:
                "Por favor, tente novamente mais tarde ou entre em contato com nossa assistência técnica.",
            });
          }
          setLoading(false);
        }}
      />
    </>
  );
};

export default AttachmentsComponent;

type AttachmentRowProps = {
  index: number;
  focussed: boolean;
  onFocus: (index: number) => void;
  document: IOnboardingDocument;
  onReject: (d: IOnboardingDocument) => void;
  onDelete: (d: IOnboardingDocument) => void;
  availableWidth: number;
};
const AttachmentRow = ({
  index,
  focussed,
  onFocus,
  document,
  onReject,
  onDelete,
  availableWidth,
}: AttachmentRowProps) => {
  const rejected = document.statusDocumento === "Reprovado";
  //const rejected = false;
  const rejectable = document.origem === "Onboarding";
  const [loadingUrl, setLoadingUrl] = useState(false);
  const [url, setUrl] = useState("");

  const handleGetUrlS3 = async (idAws: string) => {
    setLoadingUrl(true);

    try {
      const response = await oportunityServiceInstance.getUrlS3(idAws);

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingUrl(false);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingUrl(false);
        return;
      }

      setUrl(response);
      setLoadingUrl(false);
    } catch (error) {
      setLoadingUrl(false);
      console.log("errorHandleGetUrlS3", error);
    }
  };

  let documentName = document.nomeArquivo;
  if (availableWidth < 1300) {
    documentName = documentName?.split("_")[0] + "...";
  }

  if (documentName?.split("_")[0].length > 50) {
    documentName = documentName.substring(0, 49) + "...";
  }

  React.useEffect(() => {
    if (url) {
      window.open(url, "_blank");
    }
  }, [url]);

  return (
    <tr
      className={`attachment-row ${index % 2 === 0 ? "even-row" : "odd-row"}`}
    >
      <td className="table-full">
        <Styled.DocumentDataCell rejected={rejected}>
          <div className="icon-cell">
            {rejected ? (
              <MdTabUnselected className="document-icon" />
            ) : (
              <MdTab className="document-icon" />
            )}
          </div>
          <div className="spaced-info">
            <p className="document-title">{documentName}</p>
            <p className="document-origin">Origem: {document.origem}</p>
          </div>
        </Styled.DocumentDataCell>
      </td>

      <td className="table-175 bold-label">{document.tipoDocumento}</td>
      <td className="table-115 date-cell">
        <div className="spaced-info left-aligned">
          <p className="bold-label">{document?.dataUpload?.split(" ")[0]}</p>
        </div>
      </td>
      <td className="table-205 reject-cell">
        {rejected ? (
          <Popover isOpen={focussed}>
            <PopoverTrigger>
              <Styled.RedFilledButton onClick={() => onFocus(index)}>
                <MdOutlineChat />
                MOTIVO
              </Styled.RedFilledButton>
            </PopoverTrigger>
            <PopoverContent
              padding="16px"
              width="512px"
              marginLeft={`${window.innerWidth - 780}px`}
            >
              <Styled.PopupHeader>
                <div className="popup-title">
                  <h3 className="atention">Documento reprovado</h3>
                  <p>Nome do documento: {document.nomeArquivo}</p>
                </div>
                <CloseButton onClick={() => onFocus(-1)} />
              </Styled.PopupHeader>
              <Styled.RejectReason>
                <p>Motivo:</p>
                <p className="reason-container">{document.justificativa}</p>
              </Styled.RejectReason>
            </PopoverContent>
          </Popover>
        ) : (
          <Styled.RedOutlineButton
            disabled={!rejectable}
            onClick={() => {
              if (rejectable) {
                onReject(document);
              }
            }}
          >
            REJEITAR
          </Styled.RedOutlineButton>
        )}
      </td>
      <td className="table-230">
        <Styled.DocumentDashboardCell excludeDisabled={rejectable}>
          <div
            className="exclude-link"
            onClick={() => {
              if (!rejectable) {
                onDelete(document);
              }
            }}
          >
            <MdDeleteOutline className="exclude-icon" />
            <p className="exclude-text">Excluir</p>
          </div>
          <Styled.FilledButton
            onClick={() => {
              if (loadingUrl) {
                return;
              } else {
                handleGetUrlS3(document.idAws);
              }
            }}
            disabled={loadingUrl}
          >
            {loadingUrl ? (
              <div className="loading">
                <Spinner />
              </div>
            ) : (
              "VISUALIZAR"
            )}
          </Styled.FilledButton>
        </Styled.DocumentDashboardCell>
      </td>
    </tr>
  );
};

type RejectDocumentProps = {
  document?: IOnboardingDocument;
  onClose: () => void;
  onReject: (d: IOnboardingDocument, r: string) => void;
};
const RejectDocumentModal = ({
  document,
  onClose,
  onReject,
}: RejectDocumentProps) => {
  const [reason, setReason] = useState("");

  useEffect(() => {
    setReason("");
  }, [document]);

  return (
    <Modal isOpen={document !== undefined} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent padding="16px">
        <Styled.PopupHeader>
          <div className="popup-title">
            <h3>Rejeitar documento</h3>
            <p>Nome do documento: {document?.nomeArquivo}</p>
          </div>
          <CloseButton onClick={onClose} />
        </Styled.PopupHeader>
        <Styled.RejectTextArea>
          <p>
            <strong>*</strong>Motivo:
          </p>
          <div className="text-area">
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            <MdEditNote />
          </div>
        </Styled.RejectTextArea>
        <ModalDashboard
          onCancel={onClose}
          onClick={() => {
            if (document) {
              onReject(document!, reason);
            }
            onClose();
          }}
          disabled={reason === ""}
        />
      </ModalContent>
    </Modal>
  );
};

const MAX_CAPACITY = 33554432;
type SaveFileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSendDocument: (sendForm: { file: any; name: string; type: string }) => void;
};
const SaveFileModal = ({
  isOpen,
  onClose,
  onSendDocument,
}: SaveFileModalProps) => {
  const displayMessageError = useMessageErrorToast();
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const { width } = useWindowDimensions();
  const inputFile = createRef<HTMLInputElement>();

  useEffect(() => {
    setFileName("");
    setFileType("");
    setSelectedFile(undefined);
  }, [isOpen]);

  let right = 0;
  if (width < 1024) {
    right = 117;
  } else if (width < 1287) {
    right = width - (width / 2 + 405);
  }

  let dashboardDisabled = true;
  if (
    selectedFile &&
    selectedFile.size <= MAX_CAPACITY &&
    fileType !== "" &&
    fileName !== ""
  ) {
    dashboardDisabled = false;
  }

  const handleUploadFile: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    if (
      event.target !== null &&
      event.target.files !== null &&
      event.target.files.length > 0
    ) {
      const file: any = event.target.files[0];

      if (
        file?.name?.match(/[^A-Za-z0-9.\s\-_]/g)?.length > 0 ||
        file?.name?.match(/\./g)?.length > 1
      ) {
        displayMessageError({
          message:
            "Selecione um documento somente com letras e números no nome",
        });
        event.target.files = null;
        setSelectedFile(undefined);
      } else {
        setSelectedFile(file);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent padding="16px">
        <Styled.PopupHeader>
          <div className="popup-title">
            <h3>Enviar anexo</h3>
            <p>Selecione um arquivo para ser enviado.</p>
          </div>
          <CloseButton onClick={onClose} />
        </Styled.PopupHeader>
        <Styled.UploadFileForm>
          <h1>Novo documento:</h1>
          <div className="form-row">
            <p className="row-left">
              <strong>*</strong>Nome do documento:
            </p>
            <p className="row-right">
              <strong>*</strong>Tipo de documento:
            </p>
          </div>
          <div className="form-row">
            <InputGroup className="row-left">
              <InputRightElement pointerEvents="none">
                <MdEditNote size="24px" color={theme.primary} />
              </InputRightElement>
              <Input
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
            </InputGroup>
            <Menu>
              <MenuButton className="row-right">
                <div className="dropdown-menu">
                  <p className="selected-option">
                    {fileType === "" ? "Selecionável" : fileType}
                  </p>
                  <MdUnfoldMore
                    size="24px"
                    style={{ width: "24px" }}
                    color={theme.primary}
                  />
                </div>
              </MenuButton>
              <MenuList
                className="row-right"
                style={{ position: "absolute", top: -16, right }}
              >
                <div className="dropdown-list">
                  {documentTypes.map((d) => (
                    <MenuItem
                      onClick={() => setFileType(d.label)}
                      key={d.label}
                    >
                      {d.label}
                    </MenuItem>
                  ))}
                </div>
              </MenuList>
            </Menu>
          </div>
          {selectedFile !== undefined ? (
            <div className="form-row">
              <div className="row-left file-sumary">
                <p>
                  {selectedFile.name.length > 50
                    ? selectedFile.name.substr(0, 50) + "..."
                    : selectedFile.name}
                </p>
                <div className="save-row">
                  <p
                    className={
                      "mega-bytes" +
                      (selectedFile.size > MAX_CAPACITY ? " over-limit" : "")
                    }
                  >
                    {Math.ceil(selectedFile.size / 1024 / 1024)}mb
                  </p>
                  <MdOutlineSave
                    size="30px"
                    color={
                      selectedFile.size > MAX_CAPACITY
                        ? theme.error
                        : theme.primary
                    }
                  />
                </div>
              </div>
              <Styled.RedOutlineButton
                className="row-right"
                iconWidth="24px"
                onClick={() => setSelectedFile(undefined)}
              >
                <MdDeleteOutline />
                <span>REMOVER</span>
              </Styled.RedOutlineButton>
            </div>
          ) : (
            <div className="form-row">
              <Styled.FilledButton
                className="form-button"
                onClick={() => inputFile.current!.click()}
              >
                CARREGAR ARQUIVO
              </Styled.FilledButton>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={handleUploadFile}
                accept=".pdf,.png,.jpg,.jpeg"
              />
            </div>
          )}
          <div className="form-row">
            <p>
              <strong>*</strong>Tamanho máximo de "32"mb
            </p>
          </div>
        </Styled.UploadFileForm>
        <ModalDashboard
          onCancel={onClose}
          onClick={() => {
            onSendDocument({
              file: selectedFile,
              name: fileName,
              type: fileType,
            });
            onClose();
          }}
          disabled={dashboardDisabled}
        />
      </ModalContent>
    </Modal>
  );
};

type DeleteDocumentModalProps = {
  document?: IOnboardingDocument;
  onClose: () => void;
  onDelete: (d: IOnboardingDocument) => void;
};
const DeleteDocumentModal = ({
  document,
  onClose,
  onDelete,
}: DeleteDocumentModalProps) => {
  return (
    <Modal isOpen={document !== undefined} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding="16px">
        <Styled.PopupHeader>
          <div className="popup-title">
            <h3>Deletar documento</h3>
            <p>Nome do documento: {document?.nomeArquivo}</p>
          </div>
          <CloseButton onClick={onClose} />
        </Styled.PopupHeader>
        <ModalDashboard
          onCancel={onClose}
          onClick={() => {
            if (document) {
              onDelete(document);
            }
            onClose();
          }}
          disabled={false}
          label="EXCLUIR"
          hideInputs
        />
      </ModalContent>
    </Modal>
  );
};

export type ModalDashboardProps = {
  onCancel: () => void;
  onClick: () => void;
  disabled: boolean;
  hideInputs?: boolean;
  label?: string;
};
const ModalDashboard = ({
  onCancel,
  onClick,
  disabled,
  hideInputs = false,
  label = "SALVAR",
}: ModalDashboardProps) => {
  return (
    <Styled.ModalDashboard>
      <div className="dashboard-text">
        {hideInputs === false && (
          <>
            <p>
              {"( "}
              <strong>*</strong>
              {" )"} Campo de preenchimento obrigatório.
            </p>
            <div className="svg-sentence">
              <p>{"( "}</p>
              <p>
                <MdInfoOutline size="16px" color={theme.error} />
              </p>
              <p>{" )"} O campo apresenta formato ou dado inválido.</p>
            </div>
          </>
        )}
      </div>
      <Styled.RedOutlineButton className="button-162" onClick={onCancel}>
        CANCELAR
      </Styled.RedOutlineButton>
      <Styled.FilledButton
        className="button-204"
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
      >
        {label}
      </Styled.FilledButton>
    </Styled.ModalDashboard>
  );
};

const documentTypes = [
  {
    label: "Anexos",
    value: "Anexos",
  },
  {
    label: "Aprovação Banco",
    value: "Aprovação Banco",
  },
  {
    label: "Ata de Assembleia",
    value: "Ata de Assembleia",
  },
  {
    label: "Averbação do Habite-se",
    value: "Averbação do Habite-se",
  },
  {
    label: "Carta de crédito",
    value: "Carta de crédito",
  },
  {
    label: "Carteira de Trabalho",
    value: "Carteira de Trabalho",
  },
  {
    label: "CNPJ",
    value: "CNPJ",
  },
  {
    label: "Comprovante de Estado Civil",
    value: "Comprovante de Estado Civil",
  },
  {
    label: "Comprovante de Renda",
    value: "Comprovante de Renda",
  },
  {
    label: "Comprovante de Residência",
    value: "Comprovante de Residência",
  },
  {
    label: "Comunicado de venda",
    value: "Comunicado de venda",
  },
  {
    label: "Contrato de Compra e Venda",
    value: "Contrato de Compra e Venda",
  },
  {
    label: "Contrato de Compra e Venda - Chancelado",
    value: "Contrato de Compra e Venda - Chancelado",
  },
  {
    label: "Contrato Conf Dívida",
    value: "Contrato Conf Dívida",
  },
  {
    label: "Contrato de Financiamento",
    value: "Contrato de Financiamento",
  },
  {
    label: "Convenção de Condomínio",
    value: "Convenção de Condomínio",
  },
  {
    label: "CPF",
    value: "CPF",
  },
  {
    label: "Documento de Identidade",
    value: "Documento de Identidade",
  },
  {
    label: "Documentos Pessoais",
    value: "Documentos Pessoais",
  },
  {
    label: "Documentos Financeiros",
    value: "Documentos Financeiros",
  },
  {
    label: "Extrato FGTS",
    value: "Extrato FGTS",
  },
  {
    label: "Extrato Serasa",
    value: "Extrato Serasa",
  },
  {
    label: "Foto empreendimento",
    value: "Foto empreendimento",
  },
  {
    label: "Identificação",
    value: "Identificação",
  },
  {
    label: "Habite-se",
    value: "Habite-se",
  },
  {
    label: "Informativo",
    value: "Informativo",
  },
  {
    label: "Kit Acabamento",
    value: "Kit Acabamento",
  },
  {
    label: "Manual do proprietário",
    value: "Manual do proprietário",
  },
  {
    label: "Matrícula Autenticada",
    value: "Matrícula Autenticada",
  },
  {
    label: "Nota Fiscal de Pagamentos e Transações",
    value: "Nota Fiscal de Pagamentos e Transações",
  },
  {
    label: "Relatório comissão de representantes",
    value: "Relatório comissão de representantes",
  },
  {
    label: "Relatório de serviço",
    value: "Relatório de serviço",
  },
  {
    label: "Vaga de Garagem",
    value: "Vaga de Garagem",
  },
  {
    label: "Comprovante Sinal Caução",
    value: "Comprovante Sinal Caução",
  },
];
