import axios from "axios";
import api from "../api";

interface IUpdate {
  usuarioId: number;
  dadosPessoais: {
    dataAniversario: string;
    tamanhoCamiseta: string;
    escolaridade: string;
    estadoCivil: string;
    ufrg: string;
    ufNaturalidade: string;
    nacionalidade: string;
    pis: string;
    naturalidade: string;
  };
  dadosContato: {
    telefone: string;
    outroTelefone: string;
    celular1: string;
    celular2: string;
  };
  dadosFamilia: {
    nomePai: string;
    nomeMae: string;
    nomeConjuge: string;
    quantidadeFilhos: number;
  };
  dadosFinanceiros: {
    banco: string;
    agencia: string;
    tipoConta: string;
    conta: string;
  };
  dadosTipoComissionamento: {
    tipoCorretor: string;
    cnpj: string;
    faturamentoComissao: string;
  };
}

interface IUpdatePassword {
  email: string;
  SenhaAntiga: string;
  SenhaAtual: string;
}

class MyProfileService {
  async getProfile() {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get(`/perfil/obter`, {
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }

  async updateProfile({
    usuarioId,
    dadosPessoais,
    dadosContato,
    dadosFamilia,
    dadosFinanceiros,
    dadosTipoComissionamento,
  }: IUpdate) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      usuarioId,
      dadosPessoais,
      dadosContato,
      dadosFamilia,
      dadosFinanceiros,
      dadosTipoComissionamento,
    };

    try {
      const response = await api.patch(`/perfil/atualizar`, body, {
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }

  async updatePassord({ email, SenhaAntiga, SenhaAtual }: IUpdatePassword) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      email,
      SenhaAntiga,
      SenhaAtual,
    };

    try {
      const response = await api.post("/perfil/atualizar-senha", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
}

const myProfileServiceInstance = new MyProfileService();

export default myProfileServiceInstance;
