import React from "react";
import * as Styled from "./styles";

interface ITextArea {
  label: string;
  placeholder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  error?: boolean;
  messageError?: string;
}

const InputTextArea = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  messageError,
}: ITextArea) => {
  const maxLenght = 300;

  return (
    <Styled.Container>
      <Styled.Label>{label}</Styled.Label>
      <Styled.ContainerTextArea isError={error}>
        <Styled.TextArea
          name={label}
          placeholder={placeholder}
          id={label}
          cols={10}
          rows={1}
          maxLength={maxLenght}
          value={value}
          onChange={onChange}
          isError={error}
        />

        <Styled.Counter isError={value.length === maxLenght}>
          {value.length}/{maxLenght}
        </Styled.Counter>
      </Styled.ContainerTextArea>

      {error && <Styled.MessageError>{messageError}</Styled.MessageError>}
    </Styled.Container>
  );
};

export default InputTextArea;
